// ==============================================
// STRATE 2x1 CONTENU TEXTE
// ==============================================

.ct-view-strate_2x1_contenu_texte {
    .strate-2x1-section-visual{
        margin-bottom:0;
        padding-bottom: 61%;
        overflow: hidden;

        img{
            position: absolute;
        }
    }


    .strate-2x1-section:not(.strate-2x1-section-right){
        @include breakpoint(md) {
            width: calc(50% + 60px);
            padding-right:0;
        }
        .strate-2x1-img-container{
            margin-bottom:10px;

            @include breakpoint(md) {
                padding-right:60px;
                border-right:1px solid $c-black;
            }
        }
    }

    .strate-2x1-section-right{
        margin-top:40px;
        padding-top:30px;

        @include breakpoint(only-md) {
            border-top:1px solid $c-black;
        }

        @include breakpoint(md) {
            margin-top:0;
            padding-top:20px;
            padding-left:60px;
        }

        .strate-item-title:nth-child(3){
            margin-top:50px;
        }

        .strate-item-title{
            text-transform: uppercase;
        }

        .strate-item-text{
            font-size: 1.4rem;
            line-height:1.4;
            width: 100%;

            @include breakpoint(sm) {
                font-size: 1.8rem;
            }
        }
    }

    // Color variants
    &.universe-green{
        @include breakpoint(sm) {
            .strate-2x1-img-container{
                border-right: 1px solid $c-green !important;
            }
        }
        @include breakpoint(only-sm) {
            .strate-2x1-section-right{
                border-top: 1px solid $c-green !important;
            }
        }
    }
    &.universe-blue{
        @include breakpoint(sm) {
            .strate-2x1-img-container{
                border-right: 1px solid $c-blue2-dark !important;
            }
        }
        @include breakpoint(only-sm) {
            .strate-2x1-section-right{
                border-top: 1px solid $c-blue2-dark !important;
            }
        }
    }
    &.universe-yellow{
        @include breakpoint(sm) {
            .strate-2x1-img-container{
                border-right: 1px solid $c-yellow-dark !important;
            }
        }
        @include breakpoint(only-sm) {
            .strate-2x1-section-right{
                border-top: 1px solid $c-yellow-dark !important;
            }
        }
    }
}
