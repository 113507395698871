// ==============================================
// AGENDA - Layout
// ==============================================

.agenda-monetaire-page, .agenda-culturel-page {
    margin-top: 32px;

    .mdp-filter-btn-open-container {
        border-bottom: 1px solid $c-grey5;
        margin-bottom: 30px;
        @media(min-width: $medium) {
            margin-bottom: 0;
            border-bottom: unset;
        }
    }

    .cm-filter-current {
        @media(max-width: $medium-max) {
            margin-bottom: 50px;
        }
    }

    .message.message-error.empty {
        margin-bottom: 40px;
    }

    .agenda-list-show-more {
        display:none;
        margin: 40px auto 0;
        border-radius: 60px;
        background-color: $c-dark;
        padding: 10px 21px;
        font-size: 15px;
        line-height: 21px;
        font-weight: 500;
        color: $c-white;
        width: fit-content;
        //TODO : display: flex with JS fix on element loader on prod
        display: none;
        transition: .3s;
        cursor: pointer;

        &:hover {
            @media(min-width: $medium) {
                background-color: $c-orange2;
            }
        }
    }
}

.agenda-monetaire-page {
    .agenda-list-header {
        margin-bottom: 0;
        padding-bottom: 30px;
        border-bottom: 1px solid $c-grey5;
        @media(min-width: $medium) {
            border-bottom: unset;
            margin-bottom: 50px;
            padding-bottom: 0;
        }
    }
}
