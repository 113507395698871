// ==============================================
// SIDEBAR - catalog category
// ==============================================

//
//  Configuration
//  _____________________________________________

$account-sidebar-delimiter:         1px solid lighten($c-black, 80%);

.sidebar {
    .filter {
        margin-bottom: 40px;
    }

    .block-subtitle {
        margin-bottom: 0;
        padding: 10px 10px 20px;
        border-bottom: $account-sidebar-delimiter;
        font-size: 14px;
        font-weight: 600;
        line-height: 1em;
    }

    .block {
        margin-bottom: 40px;
    }

    .product-items-names .product-item {
        margin-bottom: 20px;
    }

    .block-compare .block-title,
    .block-reorder .block-title,
    .widget .block-title,
    .block-wishlist .block-title,
    .block-wishlist .block-content .subtitle {
        display: block;
        margin: 0 0 20px;

        strong {
            font-size: 18px;
            font-weight: 300;
        }
    }
}

.reorder {
    .product-item {
        margin: 10px 0;
    }
}

.block-compare {
    .product-item {
        .action.delete {
            display: block;
            margin-top: 10px;
        }
    }

    .actions-toolbar {
        margin: 17px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

