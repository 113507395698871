// ==============================================
// STRATE COMMON
// ==============================================

.ct-view {

    // Block with dynamic background color
    &.with-background-color {
        margin-bottom: $gutter-large + $gutter;

        @include breakpoint(lg) {
            margin-bottom: $gutter-large + $gutter;
        }

        & > .block-content{
            padding-top: $gutter-large;

            @include breakpoint(lg) {
                padding-top: $gutter-large*2;
            }
        }

    }

    // Default block padding
    & > .block-content {
        padding-bottom: $gutter*2;

        @include breakpoint(lg) {
            padding-bottom: $gutter*2 + $gutter-large;
        }

        // Use for strate-title-link element position on mobile device
        @include breakpoint(only-lg) {
            &.with-label-link {
                position: relative;
                padding-bottom: $gutter-large*2;
                margin-bottom: $gutter-large + $gutter;
            }
        }

        .block-content {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    // Default Content text
    .block-content {
        .description,
        .text {
            ol:not(.items):not(.slick-dots):not(.strate-list):not(.products),
            ul:not(.items):not(.slick-dots):not(.strate-list):not(.products) {
                padding-left: $gutter;
                list-style: revert;

                li {
                    padding: 5px 0;
                }
            }
        }
    }

    // Background color
    &.bg-gris {
        background-color: $c-light7;
    }
}

// Universe variants for backgrounds
.universe-blue{
    .strate-background{
        background-color: $c-blue2-light;
    }
}
.universe-green{
    .strate-background{
        background-color: $c-green-light;
    }
}
.universe-yellow{
    .strate-background{
        background-color: $c-yellow-light;
    }
}


// Page text introduction
.ct-view-intro {
    max-width: 915px;
    margin-bottom: $gutter*2;

    @include breakpoint(md) {
        margin-bottom: $gutter-large*2;
    }

    @include breakpoint(xl) {
        max-width: 65vw;
    }

    &,
    & p {
        font-size: 2.4rem;
        font-weight: $fw-benton-B;
        line-height: 1.3;

        @include breakpoint(md) {
            font-size: 3.2rem;
        }
    }
}


// Strate title container (Use this wrapper of strate title if block content is full page)
.strate-title-container {
    padding-left: $gutter;
    padding-right: $gutter;

    @include breakpoint(lg) {
        max-width: $container-width-xl;
        padding-left: $gutter-large;
        padding-right: $gutter-large;
        margin-left: auto;
        margin-right: auto;
    }
}

// Strate title
.strate-title {
    @extend .title-1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: 1px solid $input-border-color;
    line-height: 1.05;
    padding-bottom: 10px;

    @include breakpoint(md) {
        margin-bottom: 40px;
    }
}

.strate-title-link {
    position: relative;
    top: 20px;
    align-self: center;
    font-size: 1.6rem;
    font-weight: $fw-benton-B;
    font-family: $f-benton;
    white-space: nowrap;
    text-decoration: underline;

    @include breakpoint(only-lg) {
        width:50%;
        margin-top: -$gutter-large*1.2;
        text-align:right;
    }

    @include breakpoint(lg) {
        margin-top: -$gutter-large*1.2;
        margin-right: 0;
        margin-left: $gutter;
    }

    &:focus{
        text-decoration: none;
    }
}

// Strate descriptif
.strate-desc {
    margin-bottom: 20px;

    @include breakpoint(lg) {
        margin-bottom: 30px;
    }
}

// Strate list
.strate-list {

    // Pictos
    &--pictos {
        @include breakpoint(only-md) {
            .slick-list {
                padding-right: 50px;
            }
        }

        @include breakpoint(md) {
            display: flex;
            flex-wrap: wrap;

            > * {
                width: $w-third;
                margin-bottom: 30px;
            }

        }

        @include breakpoint(lg) {
            > * {
                width: $w-fourth;
                margin-bottom: 60px;
            }
        }
    }
}

// Strate list item
.strate-list-item {

    // Pictos
    &--pictos {
        &.grey {
            .strate-item-visual,
            .strate-item-content {
                background-color: #f2f2f2;
            }

            .strate-item-content {
                padding: 15px;
            }
        }
    }
}

// Strate item
.strate-item {
    display: flex;
    flex-direction: column;
    height: 100%;

    // Pictos
    &--pictos {
        padding: 0 15px;
        border-left: 1px solid $c-light6;
    }
}

// Strate Item Subtitle
.strate-item-subtitle {
    margin-bottom: 5px;
    font-size: 1.8rem;

    @include breakpoint(lg) {
        font-size: 2rem;
    }
}

// Strate Item Visual
.strate-item-visual {

    // Visual
    &--pictos {
        position: relative;
        height: 0;
        padding-bottom: 100%;
        background: $c-light;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 120px;
        }
    }
}

// Strate Item Content
.strate-item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}

// Strate Item title
.strate-item-title {
    display: block;
    margin-bottom: 5px;
    font-size: 1.8rem;
    font-weight: $fw-benton-B;
    line-height: 1.2;

    @include breakpoint(lg) {
        font-size: 2.2rem;
    }
}

// Strate Item Desc
.strate-item-desc {
    line-height: 1.6;

    &.with-link-after {
        flex: 1;
        margin-bottom: 30px;
    }

    &--gmap {
        strong {
            display: block;
            margin-bottom: 15px;
            font-family: $f-bentonSC;
            font-weight: $fw-benton-M;
        }
    }
}

// Strate Item Link
.strate-item-link {
    margin-top: auto;

    // Gmap
    &--gmap {
        margin-top: 25px;

        @include breakpoint(md) {
            margin-top: 30px;
        }
    }

    a {
        font-weight: $fw-benton-M;

        &.btn,
        &.bouton {
            // Gmap
            &--gmap {
                &::after {
                    content: "\e965";
                }
            }
        }

        &.bouton {
            @extend .btn;
        }

        &.souligne {
            @extend .link-arrow;
        }

        &.link-ext {
            display: inline-flex;
            align-items: center;
        }
    }
}

// Strate block legend
.strate-legend {
    display: block;
    margin-top: $gutter-large;
    font-size: 1.6rem;
    font-family: $f-bentonSC;
    font-weight: $fw-benton-B;

    &::before {
        display: inline-block;
        height: 2px;
        width: 20px;
        margin-right: 10px;
        background-color: currentColor;
        content:"";
        vertical-align: middle;
    }

    @include breakpoint(lg) {
        font-size: 1.8rem;
    }
}


// Overlay content block (Requires parent to be in relative position)
.overlay-content-block {
    position: absolute;
    z-index:2;
    bottom:0;
    width: 100%;
    padding: 25px;
    color: $c-white;
    font-family: $f-bentonSC;
    font-weight: $fw-benton-M;
    line-height: 1.2;
    background: linear-gradient(to top, $c-dark, #00000000 100%);
    @include breakpoint(md) {
        padding:40px 30px 30px 30px;
    }
    @include breakpoint(lg) {
        padding-top:80px;
    }
    // Content text
    .title {
        display: block;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.2;

        @include breakpoint(lg) {
            font-size: 2rem;
        }
    }

    .ontitle {
        display: block;
        margin-bottom: 5px;
        font-size: 1.6rem;
        font-weight:700;
        text-transform: uppercase;

        &--welcome {
            margin-bottom: 10px;
            font-family: $f-benton;
            font-weight: $fw-benton-B;
        }
    }

    a,
    .btn {
        margin-top: 30px;
    }
}

// Media player block

.media-player {
    padding-top: $gutter-large;
    max-width: 685px;
    margin-left: auto;
    margin-right: auto;
    background-color: $c-dark;

    // Picture
    .media-player__picture {
        img {
            width: 100%;
        }
    }

    // Video
    .media-player__video {
        @extend .video-wrapper;
    }

    // Content text
    .media-player__content-text {
        color: $c-white;
        padding: $gutter+5px;

        @include breakpoint(md) {
            padding: $gutter-large $gutter*2;
        }

        .title {
            font-size: 2.4rem;
            line-height: 1.8;
            font-weight: $fw-benton-B;
        }

        .on-title {
            font-size: 1.6rem;
            line-height: 1.8;

            @include breakpoint(md) {
                font-size: 2.4rem;
            }
        }

        .link {
            @extend .link-arrow;
            color: $c-white;
            margin-top: $gutter-large;
            font-weight: $fw-benton-M;
            font-size: 1.4rem;

            @include breakpoint(md) {
                margin-top: $gutter-large*2;
                font-size: 1.6rem;
            }
        }
    }
}

// Media player block variant for Savoir faire

.product-savoirfaire-wrapper{
    .media-player{
        margin-left:0;
    }
}



//
//  Slick slider custom displaying
//  _____________________________________________

.ct-view-strate_4_x_1,
.ct-view-strate_produits,
.ct-view-strate_agenda_mon_taire,
.ct-view-strate_articles_de_blog {

    .slick-list {
        @extend .slick-list-cropping;
    }
}

.ct-view-strate_3x1_collections {

    .slick-list {
        padding-right: 50px;
    }
}

.ct-view-strate_collection_tab{
    .slick-list {
        padding-right: 100px;

        @include breakpoint(md) {
            padding-right: 0;
        }
    }
}
