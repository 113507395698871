// ==============================================
// CONTENT GRID
// ==============================================

//
//  Configuration
//  _____________________________________________

$listing-list-item-gutter:      9px;

$cm-grid-column-width:          320px;
$cm-grid-column-width-large:    450px;

// if you change the height value change it in collection.js too
$cm-content-item-img-height:        361px;
$cm-content-item-hover-img-height:  490px;

// Space with toolbar
.products-grid,
.products-list {
    padding: 30px 0;
}

.contentmanager-contentlist-view {

    // Main title
    .page-title {
        @extend .title-1;
        margin-top: 50px;
        margin-bottom: 0;
        padding-bottom: $gutter/2;
        line-height: 1;
        border-bottom: 1px solid $c-light6;

        @include breakpoint(md) {
            margin-top: 70px;
            margin-bottom: 20px;
        }
    }


    //
    //  Collections listing page
    //  _____________________________________________

    .contents-grid {

        .block-text {
            margin-top: 20px;

            @include breakpoint(lg) {
                font-size: 1.8rem;
                margin-bottom: 50px;
            }

            &.block-text-bottom {
                text-align: center;

                @include breakpoint(lg) {
                    font-size: 1.6rem;
                }
            }
        }

        .block-content {
            padding-top: 0;
            padding-bottom: 0;
        }
    }


    // Content manager filters
    .filter-current .block-subtitle {
        @extend .visually-hidden;
    }

    @include breakpoint(md) {
        .selected-filters-container {
            float: left;
            padding-right: 20px;
        }
    }

    // Toolbar
    .toolbar{}

    .toolbar-bottom {
        text-align: center;
        max-width: 450px;
        margin: 50px auto 80px;

        .pager {
            text-align: center;
            margin-bottom: 10px;
        }

        .toolbar-amount {
            display:none;
        }

        .pages {

            margin-bottom: 15px;

            @include breakpoint(lg) {
                float: left;
                position: relative;
                top: 5px;
                margin-bottom: 15px;
            }

            ul.items {
                justify-content: center;
            }
        }

        .see-all-collections {
            @extend .btn;

            @include breakpoint(lg) {
                margin-left: 10px;
            }
        }
    }
}


//
//  ACM Content Manager - Filters (based on Amasty shop by filters css)
//  _____________________________________________

.cm-filter-current {
    text-transform: capitalize;
    margin-bottom: 30px;

    > .items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: -5px;
        padding: 0;

        > .item {
            position: relative;
            display: flex;
            align-items: center;
            padding: 7px 5px 5px;
            margin: 5px;
            border: 1px solid $c-light6;
            border-radius: $radius-btn;
            font-family: $f-benton;
            font-size: 1.4rem;
            font-weight: $fw-benton-R;
            transition: border-color .3s;

            &:hover,
            &:focus,
            &:active {
                border-color: currentColor;
            }
        }

        .action {

            &.remove {
                &::after {
                    @extend %icon-icomoon;
                    content: "\e91c";
                    position: static;
                    width: inherit;
                    height: inherit;
                    transform: inherit;
                    font-size: 1.1rem;
                    margin-left: 15px;
                }
            }
        }
    }
}


//
//  Grid view
//  _____________________________________________

.widget-content-grid {

    @include breakpoint(sm) {
        display: flex;
        flex-wrap: wrap;
        margin-left: (-$listing-list-item-gutter);
        margin-right: (-$listing-list-item-gutter);
    }

    li.content-item {
        width: $w-full;

        @include breakpoint(only-sm) {
            width: $w-full;
            margin: 0 auto;

            + li.content-item {
                padding-top: 35px;
            }
        }

        @include breakpoint(sm) {
            width: $w-half;

            // &:nth-child(even) {
            //     border-left: 1px solid $input-border-color;
            // }

            &:not(:nth-child(-n+2)) {
                padding-top: 35px;
            }
        }
        @include breakpoint(lg) {
            width: $w-third;
            //border-left: 1px solid $input-border-color;

            &:nth-child(3n+1) {
                border-left: none;
            }

            &:not(:nth-child(-n+2)) {
                padding-top: 0;
            }

            &:not(:nth-child(-n+3)) {
                padding-top: 40px;
            }
        }
    }
}


//
//  Grid item content
//  _____________________________________________

// Widget content list Item
:root {
    --hover-height: 0px;
}
.widget-content-grid .content-item {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0 10px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 0;
    }

    // Hover desktop animation
    @include breakpoint(lg) {
        &:hover {
            z-index: 5;

            &::before {
                height: var(--hover-height);
            }

            .collection-photo {

                // Animation with hover image
                &.images {
                    .image-hover {opacity: 1;}
                    .image {opacity: 0;}
                }
            }

            .collection-photo + .collection-informations {
                top: 0;
            }

            .descriptif {
                opacity: 0;
            }

            .title {
                background-size: 100% 1px;
            }
        }
    }

    // Content item block
    .block.widget {
        height: 100%;
        // border-bottom: 1px solid $input-border-color;
        padding-bottom: $gutter;
        transition: padding-bottom .3s linear;
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
    }

    .block-content {
        height: 100%;

        > a {
            display: block;
            height: 100%;
            position: relative;
        }

        .labels{
            position: absolute;
            z-index: 2;
        }
    }

    // Content item picture
    .collection-photo {
        display: block;
        position: relative;

        @include breakpoint(lg) {
            transition: height .3s linear;
            overflow: hidden;
        }

        .image {
            display: none;

            @include breakpoint(lg) {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                opacity: 1;
                transition: opacity .3s linear;
                background-color: $c-white;
            }
        }

        .image-hover {
            display: block;
            position: relative;

            @include breakpoint(lg) {
                opacity: 0;
                transition: opacity .3s ease-out;
            }
        }

        img {
            width: 100%;
        }
    }


    // Content item information
    .collection-photo + .collection-informations {
        padding-top: $gutter;

        @include breakpoint(lg) {
            position: relative;
            top: -80px;
            transition: top .3s ease-out;
            z-index: 1;
        }
    }

    .collection-informations {
        padding:20px 15px 10px 15px;
        background-color: $c-white;
    }


    // Date
    .millesime {
        display: block;
        margin-bottom: 5px;
        font-size: 1.4rem;

        @include breakpoint(lg) {
            font-size: 1.8rem;
        }
    }

    // Title
    .title {
        position: relative;
        align-self: start;
        display: inline;
        font-size: 1.7rem;
        line-height: 1.2;
        font-weight: $fw-benton-B;
        font-family: $f-benton;
        background-image: linear-gradient(currentColor, currentColor);
        background-size: 0% 1px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        transition: background-size .5s ease-out;

        @include breakpoint(lg) {
            font-size: 2.8rem;
        }
    }

    // Text
    .descriptif {
        display: block;
        opacity: 1;
        padding-right: $gutter*2;
        margin-top: 10px;
        font-size:1.4rem;
        line-height: 1.4;
        @include breakpoint(only-sm){
            display:none;
        }
    }
}

// Slick item
.slick-slide .content-item {
    height: 100%;
}

//
//  3x1 Grid
//  _____________________________________________


//flex container
.cols-container{
    display: flex;
    margin: 0 -30px;
    flex-wrap: wrap;

    //items
    .cols{
        width: 100%;
        padding: 0 30px 40px 30px;
    }

    .cols3{
        @extend .cols;

        @include breakpoint(md) {
            width: 50%;
        }

        @include breakpoint(lg) {
            width: 33.3333%;
        }
    }
}
