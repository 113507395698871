.blog-post-view .post-view {
    .post-holder {
        .item.post-categories {
            margin-bottom: 12px;
            margin-top: 50px;

            @include breakpoint(md) {
                margin-top: 70px;
            }

            span a {
                @extend .tag-item;
                @extend .black-tag;
            }
        }

        .post-title {
            @extend .strate-title;
            margin-bottom: $gutter/2;

            .post-item-link {}
        }
    }

    .post-image {
        @include gutter-revert($gutter);

        @include breakpoint(lg) {
            margin-bottom: $gutter;
            @include gutter-revert($gutter-large);
        }

        img {
            max-height: 648px;
            width: 100%;
            object-fit: cover;
        }
    }

    .post-container {
        @extend .container;
        padding-left: 0;
        padding-right: 0;
        margin-top: $gutter-large*2;
        max-width: $container-width;

        @include breakpoint(md) {
            display: flex;
            align-items: flex-start;
            margin-top: $gutter*4;
        }
    }

    .sidebar {
        @include breakpoint(md) {
            width: 100%;
            max-width: 350px;
            padding-right: 90px;
            position: sticky;
            top: 100px;
        }

        .widget.block .block-title {
            padding-bottom: 5px;
            border-bottom: 1px solid $c-light6;
            margin-bottom: 18px;

            strong {
                font-family: $f-bentonSC;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 22px;
            }
        }

        .widget.block.block-popular-posts.block-list-posts {
            font-family: $f-benton;

            .block-title {

            }

            .block-content {
                .item {
                    & > div > span {
                        display: block;
                        font-size: 18px;
                        line-height: 25px;
                        margin-bottom: 3px;
                    }
                }

                .post-item-link {
                    font-size: 20px;
                    line-height: 24px;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    font-weight: bold;
                    border-bottom: 1px solid $c-light6;
                    display: block;
                }
            }
        }

        .widget.block.block-archive {
            margin-top: 50px;

            .block-title {}

            .block-content {
                .item {
                    margin-bottom: 10px;

                    & > a {
                        font-size: 18px;
                        line-height: 25px;
                        text-decoration: underline;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .main-content {
        padding-right: $gutter-large*2;

        .post-short-description {
            @extend .visually-hidden;
        }

        .post-description {
            color: $c-base;

            // Text
            p {
                margin-bottom: $gutter;
                font-size: 1.8rem;
                line-height: 2;
            }

            // Link
            a {
                color: $c-base !important;
            }

            // Button
            .pagebuilder-button-primary {
                color: $c-white !important;
            }
        }
    }
}
