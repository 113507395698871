// ==============================================
// HEADER - Top header
// ==============================================

.header-top {
    padding: 10px 20px;
    background-color: lighten($c-black, 95%);

    .header-top-items {
        @extend .container;
        display: flex;
        justify-content: space-between;
    }
}