// ==============================================
// HEADER - Burger
// ==============================================

//
//  Configuration
//  _____________________________________________

$burger-size:           40px;
$burger-size-desktop:   76px;

.jd-burger {

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: $burger-size;
    margin-left: $gutter/2;
    margin-right: $gutter;
    padding: 5px 10px;
    overflow: hidden;
    font-size: 2.3rem;
    text-align: center;

    @include breakpoint(lg) {
        // height: $burger-size-desktop;
        // margin-right: 0;
        display: none;
    }

    &:hover { cursor: pointer; }

    .icon {
        @include breakpoint(lg) {
            margin-top: $gutter/2;
        }
    }

    span {
        font-size: 1.2rem;
        font-weight: $fw-benton-R;
        margin-top: $gutter-large/2;
        @include breakpoint(lg) {
            margin-top:0;
        }
    }
}
