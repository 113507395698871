// ==============================================
// STRATE SLIDER IMAGE ET TEXTE
// ==============================================

.ct-view-strate_slider_image_et_texte {

    .strate-image-et-texte-list-item{

        a{
            display:block;

            &:hover, &:focus, &:active{
                .image-container{
                    img{
                        transform: scale(1.04);
                    }
                }
    
                .title{
                    background-size: 100% 1px;
                }
            }
        }

        .image-container{
            display: block;
            position: relative;
            overflow:hidden;
            margin-bottom: $gutter;
            padding-bottom:100%;

            img{
                display:block;
                position:absolute;
                object-fit: cover;
                width:100%;
                height: 100%;
                margin-bottom: 0px;
                transition: transform .2s linear;
            }
        }

        .surtitre,
        .title,
        .descriptif{
            display:block;
        }

        .surtitre{
            margin-bottom: 10px;
            font-size:1.6rem;
            color: $c-green2;
            text-transform: uppercase;
            font-weight: $fw-benton-B;
        }

        .title{
            margin-bottom: 10px;
            font-size: 1.8rem;
            font-weight: $fw-benton-B;
            display: inline-block;
            text-decoration: none;
            background-image: linear-gradient(currentColor, currentColor);
            background-size: 0% 1px;
            background-position: 0 100%;
            background-repeat: no-repeat;
            transition: background-size .5s ease-out;
            border-bottom: none;
        }

        .descriptif{
            font-size: 1.4rem;
            line-height: 1.4;
            font-weight: $fw-benton-R;
        }
    }

    .slick-arrows-outside{
        .slick-arrow{
            display:none;
            position:absolute;
            top: -85px;
            left:auto;
            width:40px;
            height:40px;
            margin-top:0;
            background-color:transparent;
    
            @include breakpoint(lg) {
                display:block;
            }
    
            &:before{
                color: $c-black;
            }
    
            &.slick-arrow.slick-prev{
                @include breakpoint(lg) {
                    right: calc(100vw - 100% + 85px);
                }
            
                @media screen and (min-width: $ultra-large){
                    right: calc(100vw - 100% + 145px);
                }
            }
    
            &.slick-arrow.slick-next{
                @include breakpoint(lg) {
                    right: calc(100vw - 100% + 45px);
                }
            
                @media screen and (min-width: $ultra-large){
                    right: calc(100vw - 100% + 105px);
                }
            }
        }

        &.slick-arrows-no-title{
            @include breakpoint(lg) {
                padding-top:50px
            }

            .slick-arrow{
                @include breakpoint(lg) {
                    top: -10px;
                }
            }
        }
    }
}
