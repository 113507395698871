// ==============================================
// ACCOUNT - Preferences
// ==============================================

.newsletter-manage-index {

    .preference .form-newsletter-manage {
        .field {
            margin-top: 10px;
        }

        .intro {
            margin-bottom: 10px;
        }
    }

    .form-newsletter-manage {
        legend {
            @extend .visually-hidden;
        }

        .field {
            margin: 0;
        }

        

        .nl-type {
            @include breakpoint(md) {
                display: flex;

                .nl-type-choice {
                    &:first-child {
                        margin-right: 50px;
                    }
                }
            }

            @include breakpoint(only-md) {
                .nl-type-choice {
                    &:first-child {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
