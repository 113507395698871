// ==============================================
// VIEW - Product full view
// ==============================================

//
//  Configuration
//  _____________________________________________

$product-media-width:           55%;
$product-info-main-width:       45%;
$product-content-block-gutter:  300px;
//
//  Magento native
//  _____________________________________________

.page-layout-1column {

    // Reset container padding
    &.catalog-product-view .page-main {

        @include breakpoint(only-lg) {
            padding: 0;
            overflow: hidden;
        }

        .breadcrumbs {
            display: none;

            @include breakpoint(md) {
                display: block;
                padding-left: 0;

                .items {
                    margin-bottom: $gutter-large;
                }
            }
        }
    }

    .product-info-top {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @include breakpoint(md) {
            flex-direction: row;
            margin-bottom: $gutter-large*2;

        }
    }

    .product-info-main-wrapper {
        order: 2;

        @include breakpoint(only-md) {
            //width: calc(100% + 40px);
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
        }

        @include breakpoint(md) {
            width: $product-info-main-width;
        }
    }

    .product.media {
        order: 1;
        width: $product-media-width;
        flex: 1 1 auto;
        position: relative;

        @include breakpoint(only-md) {
            width: 100%;
        }
    }

    // Product view tags wrapper
    .product-view-labels {
        display: flex;
        align-items: flex-start;
        position: absolute;
        z-index: 2; // Use to display above milople personalization content
        top: 50px;

        @include breakpoint(md) {
            top: 80px;
        }

        .discount-percent {
            margin-bottom: $gutter/2;
        }
    }
}

//
//  Product visual (skin in _gallery.scss)
//  _____________________________________________

.column.main > .social-sharing-wrapper, body > .social-sharing-wrapper {
    display: none;
}

.product {

    // Gallery
    &.media {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        @include breakpoint(lg) {
            display: block;
            padding-left: 0;
            padding-right: 4%;
        }

        .gallery-placeholder {
            width: 100%;
            //margin-top: 15px;
        }

        .gallery-placeholder__image {
            max-width: 550px;
        }

        // Product media links
        .product-media-links {
            font-size: 2.4rem;

            @include breakpoint(only-lg) {
                margin-right: -10px;
                margin-left: auto;
                display: flex;
                flex-direction: row-reverse;
            }

            @include breakpoint(lg) {
                position: absolute;
                right: $gutter;
                z-index: 2;
            }
        }

        // Actions

        .action.towishlist,
        .icon-fullscreen,
        .sharing-icon {
            display: block;
            padding: 10px;

            @include breakpoint(lg) {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        // Wishlist
        .action.towishlist {

            @include breakpoint(lg) {
                top: 0;
            }

            span {
                @extend .visually-hidden;
            }
        }

        // Share elements
        .social-sharing-wrapper {
            text-align: right;
            margin-left: auto;
            position: relative;

            // Displaying animation
            &.is-active {
                .sharing-list {
                    visibility: visible;
                    opacity: 1;
                    animation-name: movingRightToLeft;
                    animation-duration: .5s;
                }
            }

            .sharing-list {
                visibility: hidden;
                opacity: 0;
                transition: opacity .3s ease-out;
            }
        }

        .sharing-icon {
            &::before {
                content:"\e95c";
                display: block;
                font-size: 2rem;
                @extend %icon-icomoon;

                @include breakpoint(lg) {
                    font-size: 2.4rem;
                }
            }
        }

        .sharing-list {
            width: 200px;
            position: absolute;
            right: 0;
            z-index: 1;
            font-size: 1.4rem;
            text-align: right;
            border: 1px solid;
            border-radius: $radius-box;
            background-color: $c-white;

            @include breakpoint(lg) {
                right: 50px;
                top: 0;
            }

            li {
                a {
                    display: block;
                    padding: 10px;

                    &::before {
                        content:"";
                        display: block;
                        font-size: 2rem;

                        @include breakpoint(lg) {
                            font-size: 2.4rem;
                        }
                    }

                    &.facebook::before {
                        content: "";
                    }
                }
            }
        }

        .icon-fullscreen {
            display: none;

            @include breakpoint(md) {
                display: block;
            }
        }
    }
}

//
//  Product sticky sidebar
//  _____________________________________________

.product-info-content-sticky {
    position: sticky;
    top: 0;
    font-size: 1.6rem;
    line-height: 1.4;
    z-index: 1;

    @include breakpoint(md) {
        display: none;
        top: 110px;
        width: 280px;
        padding-left: 30px;
        float: left;

        // Media
        .product-media-sticky {

            max-width: 168px;
        }

        // Product title
        .product-title {
            font-weight: $fw-benton-M;
        }

        // Price box
        .price-box {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .special-price {
                margin-left: 10px;
            }
            .old-price {
                margin-left: 0;
            }
        }

        // Actions
        .actions {
            margin-top: $gutter/2;

            button {
                padding: 5px 15px;
                font-size: 1.5rem;
                line-height: 1.9;

                &.alert {
                }
            }
        }
    }

    @include breakpoint(lg) {
        padding: 0 10px;
    }

    // Hide elements on mobile
    @include breakpoint(only-md) {
        .product-media-sticky,
        .product-title,
        .nombis-caracteristiques,
        .price-box,
        .actions {
            display: none;
        }
    }
}

.product-info-progress {
    background-color: $c-white;
    display: flex;
    border-bottom: 1px solid $input-border-color;
    overflow-x: auto;

    @include breakpoint(md) {
        flex-direction: column;
        margin-top: $gutter-large;
        padding-top: $gutter;
        border-bottom: none;
        border-top: 1px solid $input-border-color;
    }

    .item-progress {
        display: block;
        padding: $gutter/2 $gutter-large/2;
        position: relative;
        white-space: nowrap;
        font-size: 1.6rem;
        font-family: $f-bentonSC;
        font-weight: $fw-benton-M;

        @include breakpoint(md) {
            padding: $gutter/2 0;
        }

        &.current {
            a {
                color: $c-base;
            }

            .item-progress-bar  {
                opacity: 1;
            }
        }

        a {
            color: #A2978D;
        }
    }

    .item-progress-bar {
        width: 100%;
        height: 3px;
        position: relative;
        bottom: -10px;
        border-radius: 2px;
        background-color: $c-grey;
        overflow: hidden;
        opacity: 0;

        @include breakpoint(md) {
            width: 3px;
            height: 100px;
            bottom: initial;
        }

        span {
            position: absolute;
            background: $c-black;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 2px;

            @include breakpoint(md) {
                bottom: initial;
                right: 0;
            }
        }

        &[style="display: block;"] {
            animation: hideBeforeShowing .5s;
        }
    }
}


//
//  Product info main
//  _____________________________________________

.product-info-main-wrapper {
    padding: 28px 0 80px;
    background-color: $c-light7;
    position: relative;

    @include breakpoint(md) {
        padding: 55px 15px 60px 15px;
        margin-bottom: 30px;

        > * {
            display: block;
            max-width: 500px;
            margin: 0 0 0 auto;
            position: relative;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            top: 0;
            bottom: 0;
            background-color: $c-light7;
        }
    }

    @include breakpoint(lg) {
        padding-left: $gutter;
        padding-right: $gutter;
    }

    // Common html tag css
    a {
        text-decoration: underline;
        font-size: 1.4rem;
    }

    ul {
        @extend .list-custom;
        font-size: 1.4rem;
    }

    script {
        opacity: 0;
    }

    // Product title
    .page-title {
        margin-bottom: 5px;
        font-size: 2rem;
        font-weight: $fw-benton-M;
        line-height: 1.2;
    }

    .nombis-caracteristiques {
        font-weight: $fw-benton-R;
        margin-bottom: 20px;
    }

    // Price
    .product-info-price {
        margin-top: 25px;
        margin-bottom: 30px;
        text-align: right;

        .price-box {
            font-size: 2rem;
            font-weight: $fw-benton-M;
            justify-content: flex-end;

            @include breakpoint(md) {
                font-size: 2.2rem;
            }
        }

        // Stock
        .product-info-stock-sku {
            margin-top: 10px;
        }

        .stock {
            font-size: 1.6rem;
        }
    }

    // SKU
    .product.sku {
        display: flex;
        margin-bottom: 20px;
        font-size: 1.2rem;
        font-weight: $fw-benton-M;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        .type {
            font-weight: inherit;
        }

        .value {
            &::before {
                content: ".";
            }
        }
    }

    // Price
    .price-box {

        .price-label {
            display: block;
        }

        .normal-price {
            .price {
                font-size: 3rem;
                font-weight: bold;
            }
        }

        .old-price {
            margin-right: 10px;

            .price {
                font-size: 1.6rem;
                text-decoration: line-through;
            }
        }

        .special-price {
            .price {
                font-size: 2.4rem;
                font-weight: bold;
            }
        }
    }

    // Add to cart
    .product-add-form {
        margin-bottom: 30px;

        .actions {
            button {
                width: 100%;
                font-size: 1.8rem;
                border-radius: 100px;

                &.disabled {
                    border: 1px solid $input-border-color;
                }
            }

            .paypal.checkout,
            #paypal-smart-button {
                margin-top: $gutter;
                margin-bottom: $gutter;
            }

        }
    }

    .box-tocart {

        > .fieldset {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
        }

        .field.qty {
            margin-right: 28px;

            // Hide input qty before soon qty update initialization
            .soon-qty-updater-wrap .input-text.qty {
                opacity: 1;
                transition: opacity .3s linear;
            }
            .input-text.qty {
                opacity: 0;
            }
        }

        .product-infos-reinsurance {
            margin-top: $gutter;
            margin-bottom: $gutter;
        }

        .product-infos-dispo {

            .stocks {
                margin-top: 6px;
            }

            .stock > span {
                @extend .list-2;
            }
        }

        // mobile fixed bar
        @include breakpoint(only-md) {
            .field.qty {
                position: fixed;
                background-color: $c-white;
                bottom: 0;
                left: 0;
                z-index: 2;
                padding: 10px 15px;
                margin-right: 0;

                .soon-qty-updater-wrap {
                    height: 50px;
                    width: 95px;
                }
            }

            .actions {
                position: fixed;
                bottom: 0;
                right: 0;
                height: 70px;
                width: calc(100% - 125px); // 100% - field.qty width
                padding: 10px 15px;
                z-index: 4;
                background-color: $c-white;

                .btn--cart {
                    font-size: 1.4rem;
                }
            }
        }
        @include breakpoint(only-xs) {
            .actions .btn--cart {
                font-size: 1.2rem;
                padding: 10px;
            }
        }

        ul > li > strong {
            white-space: nowrap;
        }

        // Only store stock (not available on web)
        &.box-tocart-only-store-stock {
            margin-top: $gutter;

            // Manage alignment if no select quantity element
            & > .fieldset {
                justify-content: flex-end;
            }

            @include breakpoint(md) {

            }

            .tooltip-wrapper .icon + * {
                left: auto;
                right: -$gutter-large/2;

                &::after {
                    left: auto;
                    right: 12px;
                }
            }
        }
    }

    // Stock alert
    .alert.stock {

        .email[style="display: none"] + .action {
            @extend .visually-hidden;
        }

        .action {
            width: 100%;
        }

        .message {
            &.success {
                color: $c-success;
            }
        }
    }

    // Additional attributes
    .additional-attributes-wrapper {
        margin-bottom: 20px;

        .additional-attributes {
            width: 100%;

            tbody {
                th, td {
                    width: 50%;
                    padding: 4px 0 !important;
                    text-align: left;
                }

                td {
                    position: relative;
                    font-weight: $fw-benton-M;
                }
            }

            .more-wrapper {
                @extend .tooltip-wrapper;
            }
        }
    }

    // Size guide
    .size-guide-link-wrapper {
        margin-bottom: 35px;
    }

    // Short description
    .short-desc-block {
        .short-desc-content {
            margin-bottom: $gutter;
            line-height: 1.4;

            > * {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                /* autoprefixer: ignore next */
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }

    // Social
    .product-social-links {
        display: flex;

        .action {
            margin-right: 10px;
        }
    }
}


//
//  Product Bundle
//  _____________________________________________

.page-product-bundle .bundle-actions,
.page-product-bundle .price-wrapper[data-price-type=maxPrice],
.page-product-bundle .bundle-options-wrapper .fieldset-bundle-options,
.page-product-bundle .bundle-options-wrapper .required,
.page-product-bundle .block-bundle-summary .title,
.page-product-bundle .block-bundle-summary .product-image-container,
.page-product-bundle .bundle-summary,
.page-product-bundle .bundle-info .product-details .name,
.page-product-bundle .bundle-info .product-details .available,
.page-product-bundle .bundle-info .product-details .price-box,
.opc-sidebar .product.options {
    display:none;
}



//
//  Product view blocks
//  _____________________________________________

.product-info-content {

    @include breakpoint(md) {
        min-height: 500px;
    }
}

.product {

    // Details
    &.info.detailed {

        .content {
            font-size: 1.6rem;

            @include breakpoint(md) {
                font-size: 1.8rem;
            }

            .value {
                font-size: 1.6rem;
                line-height: 1.4;

                @include breakpoint(md) {
                    font-size: 1.8rem;
                }

                & > div {
                    margin-bottom: 25px;

                    &:first-child {
                        font-size: 1.6rem;
                        font-weight: $fw-benton-B;

                        @include breakpoint(md) {
                            font-size: 2.4rem;
                        }
                    }
                }

                & > *:first-child,
                strong {
                    font-weight: $fw-benton-B;
                    font-size: 1.8rem;

                    @include breakpoint(md) {
                        font-size: 2.4rem;
                    }
                }
            }
        }
    }
}

//
//  Common content block
//  _____________________________________________
.product-view-content-block {

    // Excluding recommended product
    &:not(.recommended-products) {
        padding-bottom: $gutter-large*2;

        @include breakpoint(only-md) {
            padding-top: $gutter-large*2;
        }

        @include breakpoint(md) {
            padding-bottom: 120px;
            padding-left: $product-content-block-gutter;
            padding-right: 20px;
        }

        .block-content {
            padding-left: $gutter;
            padding-right: $gutter/2;
            max-width: none;

            @media (min-width: $container-width) {
                max-width: none;
            }


            @include breakpoint(md) {
                padding-left: $gutter-large;
                padding-right: 0;
            }
        }

        .content-text {
            line-height: 1.4;

            .descriptif{
                margin-bottom: $gutter;
                font-size:1.8rem;
                font-weight:$fw-benton-B;

                @include breakpoint(md) {
                    font-size:2.4rem;
                }

                &:before{
                    content:'“';
                    display:inline;
                    margin-right:5px;
                }

                &:after{
                    content:'“';
                    display:inline;
                    margin-left:5px;
                }
            }

            .gravername{
                margin-bottom: $gutter*2;
                font-size:1.6rem;
                font-family:$f-bentonSC;
                font-weight:$fw-benton-B;

                @include breakpoint(md) {
                    font-size:1.8rem;
                }

                p:before{
                    content:'—';
                    display:inline;
                    margin-right:5px;
                }
            }
        }
    }

    .block-title {
        @extend .strate-title;
    }
}


//
//  Collections
//  _____________________________________________
.product-collection-wrapper {

    .name,
    .descriptif {
        font-size: 1.6rem;
        font-weight: $fw-benton-B;
        margin-bottom: $gutter-large;

        @include breakpoint(md) {
            font-size: 2.4rem;
        }

    }

    .images {
        margin-right: -$gutter;
        margin-left: -8px;

        @include breakpoint(lg) {
            margin-right: -$gutter-large;
        }

        .slick-list {
            padding-right: 10vw;

            div {
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .slick-dots {
            padding-right: 10vw;
        }

        .slick-prev {
            left: 20px;
        }

        .slick-next {
            right: calc(10vw + #{$gutter});
        }

        .slick-slide .image-item {
            opacity: 1;
        }

        .image-item {
            opacity: 0;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

//
//  Jobs
//  _____________________________________________

.product-jobs-wrapper {

    .content li {
        margin-bottom: $gutter-large;

        & + li {
            padding-top: $gutter-large;
            border-top: 1px solid $input-border-color;
        }

        // 2 columns tablet
        @include breakpoint(sm) {
            display: flex;

            &:nth-child(odd) {
                .content-text {
                    padding-left: $gutter;
                }
            }

            &:nth-child(even) {
                flex-direction: row-reverse;

                .content-text {
                    padding-right: $gutter;
                }
            }
        }

        // 1 column for tablet landscape
        @include breakpoint(md) {
            display: block;

            &:nth-child(odd),
            &:nth-child(even) {
                flex-direction: column;

                .content-text {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        // 2 columns for desktop
        @include breakpoint(lg) {
            display: flex;

            &:nth-child(odd) {
                flex-direction: row;
                .content-text {
                    padding-left: $gutter;
                }
            }

            &:nth-child(even) {
                flex-direction: row-reverse;

                .content-text {
                    padding-right: $gutter;
                }
            }
        }
    }

    .image,
    .content-text {

        @include breakpoint(sm) {
            width: 50%;
        }

        @include breakpoint(md) {
            width: auto;
        }
    }

    // Image
    .image {

        img {
            display: block;
            width: 100%;

            @include breakpoint(lg) {
                width: 365px;
                max-width: none;
            }
        }
    }

    // Content text
    .content-text {
        margin-top: $gutter;

        @include breakpoint(lg) {
            margin-top: 0;
        }

        .name {
            font-size: 2.4rem;
            font-weight: $fw-benton-B;
            margin-bottom: $gutter;
        }

        .description {
            font-size: 1.6rem;
            line-height: 1.6;
        }
    }
}


//
//  Product Upsell / Related / Recently viewed
//  _____________________________________________


.catalog-product-view {

    @include breakpoint(lg) {

        // Remove padding left & right cause product view padding on desktop
        .recommended-products .reco-wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }

}
.recommended-products {

    .slick-list {
        @extend .slick-list-cropping;
    }

    form[data-role="tocart-form"] {
        display: none;
    }

    .reco-wrapper {
        margin-bottom: 80px;

        @extend .container;

        padding-top: $gutter*2;
        padding-bottom: $gutter*2;

        @include breakpoint(md) {
            margin-bottom: 130px;
        }

        @include breakpoint(lg) {
            padding-top: $gutter-large*2;
            padding-bottom: $gutter-large*2;
        }
    }

    //update for recommended products
    .tabs-wrapper{
        @include breakpoint(only-md){
            flex-flow: column;
            align-items: flex-start;
        }

        .tab__name > *{
            font-family: $f-benton;
            font-size: 2.6rem;

            @include breakpoint(lg) {
                font-size: 3.4rem;
            }
        }
    }
}

.block.related,
.block.upsell,
.block-viewed-products-grid {
    margin-top: 30px;
    padding-left: $gutter;
    padding-right: $gutter;

    @include breakpoint(md) {
        margin-top: 60px;
    }

    @include breakpoint(lg) {
        margin-top: 80px;
        padding-left: 0;
        padding-right: 0;
    }

    // Title
    .block-title {
        @extend .strate-title;
    }

    // Update listing grid product item
    .product-items {
        display: flex;
        margin-left: -$gutter/2;
        margin-right: -$gutter/2;

        li.product-item {
            padding: 0 3px;
            margin-bottom: 18px;
            height: 100%;

            @include breakpoint(md) {
                margin-bottom: 25px;
                padding: 0 10px;
            }
        }
    }

    .products-grid {
        padding: 0;
    }

    .product-item-photo {
        display: block;
    }

    .product-image-wrapper {
        display: block;
        padding-bottom: 0 !important;
    }

    // Slick
    .slick-slider {

        &.slick-initialized {
            margin-right: -$gutter;

            @include breakpoint(lg) {
                margin-right: -$gutter-large;
            }
        }

        .slick-list {
            padding-right: 100px;
        }
    }

    .slick-arrow {
        border: 1px solid $c-gold;
        opacity: 1;
        transition: opacity .2s linear;
        box-shadow: none;
    }

    .slick-prev {
        left: 20px;
    }
    .slick-next {
        right: 20px;
    }
}

// Related product block
.block.related {

    .block-actions {
        text-align: center;

        .action {
            margin: 5px;
        }
    }
}

.block.related-selection {

    // Select all product items
    .select-all {
        margin-bottom: 10px;
        padding: 20px 20px 15px;
        background-color: $c-light7;
        font-weight: bold;

        &:hover {
            cursor: pointer;
        }
    }

    // Product items list
    .products {

        &.is-show {
            .items {
                height: auto !important;
            }
            .toggle-related-products::after {
                transform: rotate(90deg) scale(-1);
            }
        }

        &:not(.is-show) .items {
            &:after {
                content:"";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 50px;
                background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
            }
        }
    }
    .items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 20px;
        position: relative;
        overflow: hidden;
        transition: height .3s ease-out;
        height: 580px;

        @include breakpoint(md) {
            height: 400px;
        }
    }

    // Product item wrapper
    .item {
        padding: 10px 5px;
        @include breakpoint(xs) {
            padding: 10px;
        }

        // Current product item
        &.current {
            width: 100%;

            @include breakpoint(only-md) {
                padding-bottom: 20px;
            }

            @include breakpoint(md) {
                width: 25%;
            }

            .item-content {
                border: 2px solid $c-yellow;
                padding: 20px;
                @include breakpoint(only-md) {
                    flex-direction: row;

                    img {
                        width: 65px;
                        margin-bottom: 0;
                    }

                    .item-content-information {
                        padding-left: 25px;
                    }

                    .name {
                        margin-bottom: 5px;
                    }

                    .caracteristiques {
                        margin-bottom: 10px;
                    }

                    .price {
                        margin-top: 0;
                    }
                }

                // Current item "+" decoration
                &::after {
                    content:"+";
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%, 50%);
                    background-color: $c-gold4;
                    border: 10px solid $c-white;
                    border-radius: 100%;
                    font-size: 2rem;
                    font-weight: $fw-benton-B;

                    @include breakpoint(md) {
                        left: auto;
                        bottom: auto;
                        right: 0;
                        top: 50%;
                        transform: translate(50%, -50%);
                    }
                }
            }


            // Current product tag
            .tag {
                display: inline-block;
                background-color: $c-yellow;
                padding: 6px 10px;
                position: absolute;
                top: 0;
                left: 0;
                font-weight: bold;
                font-size: 12px;
            }
        }

        // Product item to select
        &.to-select {
            width: 50%;

            @include breakpoint(md) {
                width: 25%;
            }
        }
    }

    // Product item content
    .item-content {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 25px 10px 15px;
        height: 100%;
        font-size: 1.4rem;
        transition: border .3s ease-out;
        border: 2px solid transparent;
        transition: border .3s ease-out;

        &:hover,
        &.is-selected {
            border-color: $c-grey5;
        }

        @include breakpoint(xs) {
            padding: 25px 20px 15px;
        }

        // Product item selection element
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 2;
            right: 3px;
            top: 3px;
            padding: 5px;

            &:before {
                display: block;
                position: static;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: 2px solid $c-grey6;
                background-color: $c-light7;
                transition: all .3s ease-out;
            }
            &:after {
                color: $c-white;
                left: 15px;
                top: 15px;
                transform: none;
                font-size: 1.4rem;
            }
        }

        // Input checkbox invisible
        input[type="checkbox"] {
            &:checked + label::before {
                border-color: $c-black;
                background-color: $c-black;
            }
        }

        // Product item picture
        img {
            width: 100px;
            margin-bottom: 25px;
            align-self: center;
        }

        // Wrapper information excluded image
        .item-content-information {
            display: flex;
            flex-direction: column;
            height: 100%;

            @include breakpoint(only-md) {
                padding-bottom: 10px;
            }
        }

        // Product item name
        .name-wrapper {
            margin-bottom: 20px;
        }
        .name {
            display: block;
            padding: 2px 0 2px 6px;
            margin-bottom: 15px;
            border-left: 5px solid $c-yellow;
            font-weight: bold;
            line-height: 1.2;
        }

        // Redirect link area
        a.name {
            &::before {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        // Product item name bis
        .name-bis {
            line-height: 1.2;
        }
        .caracteristiques {
            line-height: 1.2;
        }

        // Product item price
        .price {
            font-weight: bold;
            margin-top: auto;
            font-size: 1.4rem;

            @include breakpoint(md) {
                font-size: 1.6rem;
            }
        }
    }

    // Toggle element to display/hide all products
    .toggle-related-products {
        display: inline-block;
        padding: 5px 0;
        font-weight: bold;
        text-decoration: underline;
        font-size: 1.3rem;
        line-height: 1.4;

        &::after {
            content:"\e907";
            margin-left: 5px;
            display: inline-block;
            vertical-align: bottom;
            font-size: 1.6rem;
            text-decoration: none;
            @extend %icon-icomoon;
            transform: rotate(90deg) scale(1);
        }

        &:hover {
            cursor: pointer;
        }

        .mobile {
            display: inline;
            @include breakpoint(md) {
                display: none;
            }
        }

        .desktop {
            display: none;
            @include breakpoint(md) {
                display: inline;
            }
        }
    }

    // Related actions
    .related-actions .component-wrapper {
        margin-top: 40px;
        margin-bottom: 80px;

        // Totals & form submit
        .main-content {
            padding: 30px 20px;
            background-color: $c-light7;

            @include breakpoint(md) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 40px;
            }

            .label {
                font-weight: bold;
            }

            .add-to-cart {
                margin-top: 15px;
                @include breakpoint(md) {
                    display: flex;
                    align-items: center;
                    margin-top: 0;
                }

                .total {
                    font-weight: bold;
                    font-size: 1.8rem;
                }

                .action {
                    margin-top: 10px;
                    font-size: 1.4rem;
                    width: 100%;
                    @include breakpoint(md) {
                        margin-left: 20px;
                        margin-top: 0;
                        width: auto;
                    }
                }
            }
        }

        .shipping-content {
            display: flex;
            align-items: center;
            padding: 10px;
            background-color: $c-grey6;
            font-size: 1.3rem;

            @include breakpoint(md) {
                justify-content: center;
            }

            * {
                display: inline-block;
                vertical-align: middle;
            }

            .icon {
                margin-right: 10px;
            }
        }
    }
}

// Upsell product block
.block.upsell {}


// Block recently viewed
.block-viewed-products-grid {

    .block-title span {
        color: $c-brown;
    }

    .product-items {
        display: flex;
    }

    .product-item {
        flex: 1 1 0;
    }

    .product-item-info {
        display: flex;
        flex-wrap: wrap;

        // Reorder children
        .stickers {
            order: 1;
        }
        .product-item-photo {
            order: 2;
        }
        .product-item-details {
            order: 3;
        }
        .nom_bis {
            order: 4;
        }
        .caracteristique,
        .caracteristiques {
            order: 5;
        }
    }

    .stickers,
    .product-item-details {
        width: 100%;
    }

    .stickers {
        min-height: 25px;
        padding-left: $gutter-large/2;
    }

    .product-item-photo {
        width: 100%;
        text-align: center;
    }

    .product-item-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.6;
    }

    .product-item-name {
        font-weight: $fw-benton-M;
    }

    .nom_bis,
    .caracteristique {
        line-height: 1.6;
    }


    .price-box {
    }
}
