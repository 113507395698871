// ==============================================
// UI WIDGETS - Dialog
// ==============================================

// Hide/Show Dropdown Dialog Content
// Can't Display none it cause this element is included inside UI Dialog Tag

.ui-dialog {
    > *[data-role="dropdownDialog"] {
        visibility: visible;
        opacity: 1;
        height: auto;
        overflow: auto;
    }
}

*[data-role="dropdownDialog"] {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
}