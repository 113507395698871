.product.alert.stock {
    .field {
        margin: 20px 0;
    }

    .message {
        text-align: center;
        font-weight: $fw-benton-M;
        margin: 20px;
    }
}