

.blog-page {

    .page-title {
        @extend .strate-title;
        margin-top: 50px;
        margin-bottom: $gutter;

        @include breakpoint(md) {
            margin-top: 70px;
        }
    }

    // Hide elements
    .addthis_toolbox {
        @extend .visually-hidden;
    }
}



.widget.block.block-categories {
    margin: 0 -20px 89px;
    background-color: $c-light7;
    padding: 30px 35px;

    @media(min-width: 1025px) {
        margin: 0 -30px 89px;
    }

    .accordion {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 50px;

            a {
                display: flex;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                font-family: $f-benton;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 5px;
                    border: 1px solid $c-light6;
                    margin-right: 18px;
                }
            }

            &.active {
                a {
                    &:after {
                        content: '';
                        position: absolute;
                        left: 6px;
                        border-right: 3px solid $c-black;
                        border-bottom: 3px solid $c-black;
                        width: 7px;
                        height: 14px;
                        transform: rotate(41deg);
                    }
                }
            }
        }
    }
}

.post-list-wrapper {
    margin: 0 auto;
    max-width: 670px;

    @media(min-width: 1440px) {
        max-width: unset;
        width: fit-content;
    }

    .post-list {
        @media(min-width: 1440px) {
            width: fit-content;
            display: grid;
            grid-template-columns: 670px 670px;
            grid-gap: 30px;
        }

        .post-holder {
            position: relative;
            border-bottom: unset;

            &:after {
                content: '';
                height: 100%;
                width: 1px;
                position: absolute;
                right: - 15px;
                top: 0;
                bottom: 0;
                background-color: $c-light6;
            }

            &:nth-child(2n) {
                &:after {
                    display: none !important;
                }
            }

            .post-categories {
                position: absolute;
                top: 15px;
                left: 15px;

                a {
                    border-radius: 3px;
                    padding: 1px 10px;
                    font-size: 14px;
                    line-height: 25px;
                    font-weight: 500;
                    font-family: $f-bentonSC;
                    letter-spacing: 0.1em;
                    color: $c-white;
                    background-color: $c-black;
                }
            }

            .post-ftimg-hld {
                max-height: 420px;
                height: 100%;
                width: 100%;
                overflow: hidden;
                margin-bottom: 15px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .post-publish-date {
                font-size: 20px;
                line-height: 28px;
                font-family: $f-benton;
                margin-bottom: 5px;
            }

            .post-title-holder {
                font-size: 22px;
                line-height: 31px;
                font-weight: bold;
                font-family: $f-benton;
                margin-bottom: 5px;
            }

            .post-description {
                max-width: 480px;
                margin-bottom: 30px;
                color: $c-base;
                font-size: 1.5rem;

                *[data-content-type="heading"] {
                    @extend .visually-hidden;
                }
            }

            .post-read-more {
                font-size: 14px;
                font-family: $f-bentonSC;
                line-height: 20px;
                font-weight: 500;
                padding: 10px 21px;
                border-radius: 60px;
                background-color: $c-black;
                transition: .3s;
                color: $c-white;


                &:hover, &:focus {
                    background-color: $c-orange;
                }
            }

            .post-footer {
                margin-top: 20px;
            }
        }
    }
}
