// ==============================================
// FORMS - Checkbox
// ==============================================

//
//  Configuration
//  _____________________________________________

$checkbox-width:                18px;
$checkbox-height:               18px;
$checkbox-border-weight:        1px;
$checkbox-border-color:         $c-light6;
$checkbox-border-radius:        5px;

.choice {
    input[type="checkbox"] {
        & + label {
            font-size: 1.4rem;
        }
    }
}

input[type="checkbox"] {
    @extend .visually-hidden;

    & + label {
        position: relative;
        display: inline-block;
        font-size: 1.4rem;
        line-height: 1.4;
        padding-top: 1px;
        padding-left: 36px;
        min-height: 24px;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            content: "";
            cursor: pointer;
            transition: all .2s;
        }

        // Fake checkbox
        &::before {
            left: 0;
            top: 0;
            width: $checkbox-width;
            height: $checkbox-height;
            border: $checkbox-border-weight solid $checkbox-border-color;
            border-radius: $checkbox-border-radius;
            background-color: $c-white;
        }

        // Check icon
        &::after {
            content: "\e91a";
            left: calc(calc(#{$checkbox-width} + #{$checkbox-border-weight} * 2) / 2);
            top: calc(calc(#{$checkbox-height} + #{$checkbox-border-weight} * 2) / 2);
            opacity: 0;
            font-size: 2rem;
            color: $c-base;
            transform: scale(0.5) translate(-100%, -100%);
            @extend %icon-icomoon;
        }
    }

    &:not(:checked):hover {
        & + label {
        }
    }

    &:checked {
        & + label {
            // Fake checkbox
            &::before {
                border-color: lighten($c-black, 40%);
                background-color: $c-white;
                transition: all .2s;
            }

            // Check icon
            &::after {
                opacity: 1;
                transform: scale(1) translate(-28%, -73%);
                transition: all .2s;
            }
        }
    }
}