// ==============================================
// PRODUCTS LISTING
// ==============================================

//
//  Configuration
//  _____________________________________________

$listing-list-item-gutter:      14px;

$grid-column-width:             50%;
$grid-column-width-tablet:      calc(100%/3);
$grid-column-width-desktop:     25%;
$grid-column-gap:               0;
$grid-row-gap:                  60px;
$grid-row-gap-desktop:          100px;

// Space with toolbar
.products-grid,
.products-list {
    padding: 30px 0;
}

.page-products {

    // Main title
    .page-title-wrapper {
        @extend .title-1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 0;
        padding-bottom: $gutter/2;
        line-height: 1;
        border-bottom: 1px solid $c-light6;

        @include breakpoint(md) {
            margin-top: 70px;
            margin-bottom: 20px;
        }

        .category-count {
            margin-left: $gutter-large/2;
            white-space: nowrap;

            &::before {
                content:"(";
                //margin-right: -$gutter/2; // Use to reduce spacing inside product count variable

                @include breakpoint(lg) {
                    //margin-right: -$gutter-large/2; // Use to reduce spacing inside product count variable
                }
            }
            &::after {
                content:")";
                //margin-left: -$gutter/2; // Use to reduce spacing inside product count variable

                @include breakpoint(lg) {
                    //margin-left: -$gutter-large/2; // Use to reduce spacing inside product count variable
                }
            }
        }
    }

    @include breakpoint(md) {
        .selected-filters-container {
            float: left;
            padding-right: 20px;
        }

        #toolbar-top {
            float: right;
        }

        .products.wrapper.grid.products-grid {
            clear: both;
        }
    }

    // Toolbar
    .toolbar.toolbar-products {
        margin-bottom: 0;

        .sorter-options {
            padding-left: 0;
        }

        .pages {
            margin-left: auto;
        }
    }

    // Category view
    .category-view {
        margin-top: 30px;
        margin-bottom: 0;

        @include breakpoint(md) {
            margin-top: 0;
            margin-bottom: 70px;
        }
    }


    // Hide add to cart form
    .page-main form[data-role="tocart-form"] {
        display: none;
    }
}

//
//  Grid view
//  _____________________________________________

.products-grid {
    .product-items.list {
        @include breakpoint(only-sm) {
            display: flex;
            flex-direction: column;
        }

        li.product-item {
            order: 3;

            &:nth-child(-n+8) {
                order: 1;
            }

            &.product-fake-item {
                order: 2;
            }


            @include breakpoint(only-sm) {
                width: $w-full;
                max-width: 300px;
                margin: 0 auto;

                + li.product-item,
                &.product-fake-item {
                    padding-top: 30px;
                    margin-top: 30px;
                    border-top: 1px solid $c-light6;
                }
            }
        }
    }
}

//
//  Not Support grid
//  _____________________________________________

@supports not (display: grid) {
    .products-grid {
        .product-items.list {
            @include breakpoint(sm) {
                display: flex;
                flex-wrap: wrap;
                margin-left: (-$listing-list-item-gutter);
                margin-right: (-$listing-list-item-gutter);
            }

            li.product-item {
                @include breakpoint(sm) {
                    width: $w-half;
                    padding: 0 $listing-list-item-gutter;
                    margin-bottom: 30px;
                }

                @include breakpoint(md) {
                    width: $w-third;
                }

                @include breakpoint(lg) {
                    width: $w-fourth;
                    margin-bottom: 100px;
                }
            }
        }
    }
}

//
//  Support grid
//  _____________________________________________

@supports (display: grid) {
    .products-grid {
        .product-items.list {
            @include breakpoint(sm) {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax($grid-column-width, $grid-column-width));
                grid-column-gap: $grid-column-gap;
                grid-row-gap: $grid-row-gap;
                justify-content: center;

                li.product-item {
                    width: 100%;

                    > * {
                        position: relative;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            @include breakpoint(md) {
                grid-row-gap: $grid-row-gap-desktop;
                grid-template-columns: repeat(auto-fill, minmax($grid-column-width-tablet, $grid-column-width-tablet));
            }

            @include breakpoint(lg) {
                grid-template-columns: repeat(auto-fill, minmax($grid-column-width-desktop, $grid-column-width-desktop));
            }
        }
    }
}

//
//  Catalog result page
//  _____________________________________________
.catalogsearch-result-index {

}


//
//  New product style for strates products and shop list pages
//  _____________________________________________

.products{
    &.wrapper{
        margin-left:-20px;
    }
    .product-item {
        padding: 20px 0 20px 20px;

        .product-item__inner{
            padding: 20px;

            &:before {
                content: none;
            }

            &:hover{
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
            }

            &:hover, &:focus, &:active{
                .product-item-link > * {
                    background:none;
                }
            }

            .product-item-metas{
                padding-left: 0;
            }

            .product-item-details{

                &:before{
                    width: 3px;
                    background-color: $c-gold4;
                }

                .product-item-discover-link{
                    padding-top:15px;
                }

                .product-item-link{
                    @include breakpoint(only-lg) {
                        font-size:1.4rem;
                    }
                }

                .price-box{
                    font-weight: $fw-benton-R;

                    @include breakpoint(only-lg) {
                        margin-top: auto;
                        font-size:1.4rem;
                    }
                }
            }
        }
    }
}
