// ==============================================
// AHEADWORKS - Faq
// ==============================================

// Index, Search result
body.faq-index-index, body.faq-search-index {
    h1 {
        @extend .title-1;
        font-size: 4rem;
        text-align: center;

        @include breakpoint(lg) {
            font-size: 7.5rem;
        }
    }

    .aw-faq-search {
        form {
            position: relative;

            @include breakpoint(only-md) {
                width: 100%;
            }

            #aw-faq-search-field {
                width: 100%;
            }
        }

        .aw-search-field {
            width: 100%;
        }

        .input {
            margin-bottom: 1rem;
            width: 100%;
        }

        .action {
            @extend .btn;
            height: 6rem;
            width: 6rem;
            border-radius: 100%;
            padding: 0.5rem;
            position: absolute;
            right: 0;

            @include breakpoint(lg) {
                width: auto;
                height: 7rem;
                border-radius: 10rem;
                font-size: 1.5rem;
                padding: 0 6.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @include breakpoint(only-lg) {
                &::after {
                    content: "\e957";
                    @include icon-icomoon;
                    font-size: 2rem;
                }

                span {
                    position: absolute;
                    clip: rect(0, 0, 0, 0);
                    height: 0.1rem;
                    width: 0.1rem;
                }
            }
        }
    }

    .aw-container {
        display: flex;
        justify-content: space-around;

        a {
            display: inline;
            text-decoration: none;
            color: $c-black;
            transition: text-decoration 0.2s;

            &:hover {
                color: $c-black;
                text-decoration: underline;
            }
        }

        @media screen and (min-width: $small) and (max-width: $large-max) {
            .aw-column {
                width: 100%;

                .aw-subcategory > i {
                    float: initial;
                    margin-left: 1rem;
                }
            }
        }
    }

    .article-list {
        h3 {
            a {
                font-weight: 600;
                font-size: 1.8rem;
                padding-left: 0;
                vertical-align: middle;
            }

            .category-icon {
                position: initial;
                vertical-align: middle;
            }
        }

        ul.aw-list-style > li::before {
            background-color: $c-black;
        }
    }

    .aw-list-style > li::before {
        vertical-align: middle;
    }

    .aw-subcategory {
        vertical-align: middle;
    }

    .search-results {
        .results-list.article-list {
            width: 100%;

            @include breakpoint(md) {
                width: 50%
            }

            h3 {
                margin: 1rem 0;
            }

            a {
                display: inline;
                text-decoration: none;
                color: $c-black;
                transition: text-decoration 0.2s;

                &:hover {
                    text-decoration: underline;
                    color: $c-black;
                    border-bottom: none;
                }
            }
        }
    }
}

// Index
body.faq-index-index {
    .aw-list-style > li {
        position: relative;

        .aw-subcategory {
            a {
                white-space: normal;
            }

            @include breakpoint(lg) {
                .arrow {
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
}

// Category all levels
body.faq-category-index, body.faq-article-index {
    .aw-faq-sidebar-collapsible {
        margin-bottom: 1rem;

        .aw-faq-sidebar-collapsible-button {
            background-color: #E6E5DE;
            font-weight: 700;
            padding: 0.3rem 2rem 0 2rem;
        }
    }

    .breadcrumbs {
        margin: 1rem 0 3rem 0;
    }

    h1 {
        margin-bottom: 3rem;
        font-weight: 700;
        font-size: 2rem;
    }

    .aw-faq-sidebar-content .aw-faq-sidebar-tree .jstree-node.aw-faq-sidebar-tree-top-level > .jstree-anchor[aria-level="1"] {
        line-height: 2.5rem;
    }

    .aw-faq-sidebar-collapsible .aw-faq-sidebar-collapsible-button::before,
    .aw-faq-category-children .aw-faq-category-child-item.collapsible .arrow::before {
        content: "\e907";
        @include icon-icomoon;
    }

    .aw-faq-sidebar-collapsible-button::before {
        display: inline-block;
        transition: transform 0.1s ease-in-out;
        font-size: 1.5rem;
        vertical-align: initial;
    }

    .aw-faq-sidebar-collapsible-button[aria-selected="true"]::before {
        transform: rotate(90deg);
    }

    div[aria-expanded="true"] {
        display: block;
    }

    .arrow {
        display: inline-block;
        transition: transform 0.1s ease-in-out;
    }

    div[aria-expanded="true"] .arrow {
        transform: rotate(90deg);
        transform-origin: center;
        display: inline-block;
    }

    // Dropdown menu
    .jstree-wholerow {
        position: absolute;
        width: 100%;
        z-index: -1;
        left: 0;
    }

    .aw-faq-sidebar-content {
        .aw-faq-sidebar-tree {
            .jstree-node.aw-faq-sidebar-tree-top-level > .jstree-anchor {
                font-family: $f-bentonSC;
            }

            .jstree-node {
                &> .jstree-anchor {
                    font-family: $f-benton;
                    font-weight: 500;
                    white-space: normal;
                }

                &:not(.jstree-leaf) > .jstree-icon::before {
                    content: "\e907"!important;
                    @include icon-icomoon;
                    display: inline-block;
                    transition: transform 0.1s ease-in-out;
                    font-size: 1.5rem;
                    vertical-align: initial;
                    margin-right: 1rem;
                }

                &:not(.jstree-leaf).jstree-open > .jstree-icon::before {
                    content: "\e907"!important;
                    @include icon-icomoon;
                    transform: rotate(90deg);
                }

                margin-left: 0;

                .jstree-icon {
                    width: 2.5rem;
                    vertical-align: top;
                }

                [role="group"] {
                    .jstree-anchor {
                        line-height: 1.2;
                        min-height: auto;
                    }
                }

                .jstree-children {
                    max-width: 100%;

                    .jstree-node {
                        padding: 1rem 0;

                        .jstree-anchor {
                            width: calc(100% - 3.4rem);
                            white-space: normal;
                            display: inline-block;
                        }
                    }
                }

                .jstree-children {
                    .jstree-node {
                        ul {
                            margin-left: 4rem;
                        }
                    }
                }

                &.aw-faq-sidebar-tree-top-level > .jstree-children {
                    margin-left: 0;
                }
            }

            // Dropdown tree ul
            .jstree-container-ul {


                // Category
                .jstree-children {
                    margin-bottom: 20px;
                    &.is-active {
                        .jstree-node {
                            &:nth-child(n+4) {
                                display: block;
                                animation-name: movingTopToBottom;
                                animation-duration: .5s;
                            }
                        }
                    }

                    .jstree-node {
                        &:nth-child(n+4) {
                            display: none;
                        }
                    }

                    // See all action
                    .see-all-toggle {
                        padding: 10px 0;
                        font-size: 1.4rem;
                        text-decoration: underline;
                        &:hover {
                            cursor: pointer;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .aw-faq-sidebar-search {
        display: block;
        position: relative;
        height: auto;
        padding: 0;
        background-color: transparent;
        border-bottom: 0;
        margin: 2rem 0;

        .aw-faq-sidebar-search-text-input {
            height: 4rem;
            padding-left: 1rem;
            font-size: 1.4rem;
        }

        .action.search {
            @extend .btn;
            border-radius: 100%;
            padding: 0.5rem;
            position: absolute;
            right: 0;
            top: 0;
            height: 4rem;
            width: 4rem;

            &::after {
                content: "\e957";
                @include icon-icomoon;
                font-size: 2rem;
            }

            &::before {
                content: none;
            }
        }
    }

    @media screen and (min-width: 767px) { // breakpoint aheadworks value
        &.page-layout-2columns-left .page-main .columns .column.main {
            width: calc(100% - 30rem);
            float: right;
        }

        &.page-layout-2columns-left .columns .sidebar.sidebar-main, &.page-layout-2columns-left .columns .sidebar-additional {
            width: 30rem;
            padding-right: 2%;
        }
    }

    .aw-faq-category-children + script + section,
    .aw-faq-category-children + section {
        margin-top: 4rem;
    }

    // Remove selected effect
    .jstree-wholerow {
        display: none;
    }

    // New selected effect
    .aw-faq-sidebar-tree-active-node > a {
        width: 100%;
        border-left: 0.4rem solid #dab561;
        padding-left: 0.5rem;
    }

    .block-wishlist {
        display: none;
    }
}

// Article
body.faq-article-index {
    .actions-toolbar .btn {
        height: 4.4rem;
        width: 18rem;
        margin: auto;
    }

    .question {
        margin-bottom: 0.5rem;
    }

    .faq-question-form {
        margin-bottom: 3rem;
    }

    .aw-contact {
        margin-top: 1.5rem;
    }

    @media screen and (min-width: $medium) and (max-width: $large-max) {
        .faq-question-from-container {
            width: 60%;
        }
    }

    .faq-question-from-container {
        width: 100%;
    }

    .field.question {
        width: 100%;

        .label {
            margin-bottom: 1rem;
        }
    }

    .faq-question-from-container {
        width: 60%;
    }
}

// Resize sidebar and main content for desktop
body.faq-category-index.page-layout-2columns-left,
body.faq-article-index.page-layout-2columns-left,
body.faq-article-index.page-layout-2columns-left {
    @include breakpoint(lg) {
         .columns .sidebar-main {
            width: 25%;
        }

        .columns .column.main {
            width: 75%;
        }
    }
}
