// ==============================================
// VARIABLES - Colors
// ==============================================

//
//  Base colors
//  _____________________________________________

$c-black:           #000000;
$c-white:           #ffffff;
$c-transparent:     transparent;

//
//  Project colors
//  _____________________________________________

$c-base:            $c-black;

// Primary
$c-gold:            #AD9766;
$c-orange:          #FF603D;

// Secondary
$c-beige:           #CBB29F;
$c-brown:           #A39079;
$c-brown-grey:      #A1978D;
$c-brown-grey2:     #E8DDCA;
$c-gold2:           #DABC63;
$c-gold3:           #D8AA6C;
$c-gold4:           #ffcc6d;
$c-yellow:          #FFD265;
$c-yellow-dark:     #DAB561;
$c-yellow-light:    #FFF9EC;
$c-grey:            #A6A69A;
$c-grey2:           #8F9C9C;
$c-grey3:           #828282;
$c-grey5:           #E0E0E0;
$c-grey6:           #ebeae3;

// Light
$c-light:           #ECE2DA;
$c-light2:          #D1CCC7;
$c-light3:          #F3E9C8;
$c-light4:          #F5E8D6;
$c-light5:          #DED7CE;
$c-light6:          #CCCCCC;
$c-light7:          #F8F7F1;

// Others
$c-dark:            #0E0E0E;
$c-orange2:         #FF7759;
$c-green:           #BCCDCD;
$c-green-light:     #E7EEEE;
$c-green2:          #98a6a6;
$c-blue:            #93b4d4;
$c-blue2:           #8FBDEB;
$c-blue2-dark:      #7197BC;
$c-blue2-light:     #DDE9F4;
$c-blue3:           #998d83;


// Espace Pro
$c-pro-area:       #2825C8;

//
//  Notifications
//  _____________________________________________

$c-success:         #68AC92;
$c-success-light:   #D4E8E0;
$c-info:            $c-base;
$c-warning:         $c-gold;
$c-error:           #E96462;
$c-error-light:     #FADCDB;

//
//  Availability
//  _____________________________________________

$c-available:       $c-success;
$c-unavailable:     $c-error;

//
//  Socials
//  _____________________________________________

$c-facebook:        #3B5997;
$c-google:          #DC4E41;


