// ==============================================
// ONEPAGE - Sidebar
// ==============================================

//
//  Sidebar
//  _____________________________________________

.opc-sidebar {
    @include breakpoint(lg) {
        float: left;
        width: $opc-sidebar-width;
        max-width: 344px;
        margin-left: $opc-sidebar-margin;
    }

    .action-close {
        display: none;
    }

    &._show {
        .action-close {
            display: block;
        }
    }
}

// Sidebar Content
.opc-block-summary {
    display: flex;
    flex-direction: column;

    > .summary-title {
        order: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        margin-bottom: 20px;
        border-bottom: 1px solid $c-light6;

        .title {
            font-size: 2rem;
            font-weight: $fw-benton-B;
        }

        @include breakpoint(lg) {
            padding-bottom: 15px;

            .title {
                font-size: 2.2rem;
            }
        }

        a {
            border-bottom: 1px solid;
            font-size: 1.4rem;

            &::before {
                margin-right: 6px;
            }
        }
    }

    > .data.table.table-totals {
        order: 2;
        margin-bottom: 30px;
        border-bottom: 1px solid $c-light6;

        tr {
            &.grand.totals {
                margin-top: 14px;

                th,
                td {
                    border-top: 0;
                }

                td {
                    color: $c-orange;
                }
            }

            &:last-child {
                th,
                td {
                    padding-bottom: 15px;
                }
            }
        }

        .totals.charge {
            display: none;
        }
    }

    .shipping-information {
        order: 3;
    }

    // Sidebar Title
    > .title {
        order: 4;
        display: block;
        padding-bottom: 5px;
        margin-bottom: 20px;
        border-bottom: 1px solid $c-light6;
        font-size: 1.6rem;
        font-weight: $fw-benton-M;
    }

    .items-in-cart {
        order: 5;

        > .title {
            display: none;
        }
    }

    // Items List
    .minicart-items {
        .subtitle {
            display: none;
        }
    }

    //Item Gift
    .summary-gift-item {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid $c-light6;
    }

    // Item Container
    .product-item {

        + .product-item {
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid $c-light6;
        }

        // Item Content
        > .product {
            display: flex;
            padding: 20px 15px;
            background-color: $c-white;
            font-size: 1.4rem;
            font-weight: $fw-benton-M;

            .product-image-container {
                width: 100px !important;
                height: auto !important;

                > .product-image-wrapper {
                    width: 100%;

                    > img {
                        display: block;
                        width: 100%;
                    }
                }
            }

            .product-item-details {
                line-height: 1.2;
                margin-left: 20px;
                flex: 1 1 0;

                &::after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }

            .product-item-inner {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .product-item-name-block {
                width: 100%;
                margin-bottom: 10px;
                min-height: 60px;
            }

            .product-item-name {
                display: block;
                font-weight: $fw-benton-M;
            }

            .product-mdp-caracteristique,
            .product-mdp-nombis {
                font-weight: initial;
            }

            .details-qty {
                color: $c-grey3;
                font-weight: $fw-benton-R;

                > .label {
                    &::after {
                        content: ':';
                    }
                }
            }

            .subtotal {
                float: right;
                margin-top: -2px;
                font-size: 1.4rem;
            }

            .options {
                margin-top: 10px;

                // Toggle Element Action
                .toggle {
                    cursor: pointer;
                    display: block;
                    margin-bottom: 5px;

                    span {
                        display: inline-block;
                        border-bottom: 1px solid;
                    }
                }

                // Items Options Content
                .item-options {
                    display: flex;
                    flex-wrap: wrap;
                }

                .label::after {
                    content:":";
                    margin: 0 5px;
                }
                .label,
                .values {
                    display: inline-block;
                    vertical-align: middle;
                    flex: 50%;
                }
            }
        }
    }
}
