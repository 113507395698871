// ==============================================
// STRATE GMAP
// ==============================================

.strate-gmap {

    @include breakpoint(md) {
        display: flex;
    }

    &__desc {
        margin-bottom: 40px;

        @include breakpoint(md) {
            padding-right: 4%;
            margin-bottom: 0;
        }

        @include breakpoint(lg) {
            padding-right: 8%;
        }
    }

    &__map {
        position: relative;
        height: 0;
        padding-bottom: 95%;
        border: 1px solid $c-light6;

        @include breakpoint(md) {
            flex: 1;
            padding-bottom: 45%;
        }

        > p,
        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
        }
    }
}
