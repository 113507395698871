// ==============================================
// BUTTONS - Native
// ==============================================

//
//  Configuration
//  _____________________________________________

$button-icons-color:              $c-black;

//
//  Magento filled buttons
//  _____________________________________________

.action {
    &.action-apply,
    &.action.apply,
    &.action-auth-toggle,
    &.action-login,
    &.action-menu-item,
    &.action-show-popup,
    &.continue,
    &.checkout,
    &.clear,
    &.compare,
    &.create,
    &.customize,
    &.login,
    &.save,
    &.select,
    &.share,
    &.submit,
    &.subscribe,
    &.tocart,
    &.update {
        @extend .btn;
    }

    &.viewcart,
    &.alert {
        @extend .btn--border;
    }

    &.tocart {
        @extend .btn--cart;
    }
}

//
//  Magento links buttons
//  _____________________________________________

.action {
    &.add,
    &.action-remind {
        @extend .link-hover-black;
    }
}

//
//  Magento icon buttons
//  _____________________________________________

.action {
    &.change-password,
    &.edit,
    &.action-edit,
    &.delete,
    &.action-edit-address,
    &.action-delete,
    &.mailto,
    &.next,
    &.previous,
    &.sorter-action,
    &.tocompare,
    &.towishlist {
        color: $button-icons-color;
        text-decoration: none;

        &::before {
            content: "";
            display: inline-block;
            @extend %icon-icomoon;
        }

        span {
            margin-left: 4px;
        }
    }
}

// Edit
.action.edit,
.action.action-edit,
.action.action-edit-address,
.action.change-password {
    &::before {
        content: "\e944";
    }
}

// Delete
.action.delete,
.action.action-delete {
    &::before {
        content: "\e91c";
    }
}

// Wishlist
.action.towishlist {
    &::before {
        content: "\e92f";
    }

    &:hover,
    &:focus,
    &:active {
        &::before {
            content: "\e92e";
        }
    }
}

.active .action.towishlist {
    pointer-events: none;

    &::before {
        content: "\e92e";
    }
}

// Compare
.action.tocompare::before {
    content: "\e61e";
}

// Sorter
.action.sorter-action::before {
    content: "\e614";
}

// Next
.action.previous,
.action.next {
    &::before {
        content: "\e907";
    }

    span {
        @extend .visually-hidden;
    }
}

// Previous
.action.previous {
    &::before {
        display: inline-block;
        transform: scaleX(-1);
    }
}

// Mail
.action.mailto::before {
    content: "\e61d";
}

// Full width button on mobile
@include breakpoint(only-md) {
    .actions-toolbar button.action,
    .actions-toolbar .primary a.action,
    form button.action {
        display: block;
        width: 100%;
    }
}
