// ==============================================
// UI COMPONENTS - Modals
// ==============================================

//
//  Configuration
//  _____________________________________________

$modal-background-overlay:              $c-overlay;
$modal-radius:                          $radius-box;
$modal-width:                           800px;
$modal-padding:                         40px;
$modal-background:                      $c-white;

body._has-modal {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.modals-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $modal-background-overlay;
    z-index: 99;
}

.modal-slide,
.modal-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 0;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    visibility: hidden;
    opacity: 0;
    overflow-y: auto;
    pointer-events: none;
    z-index: 100;

    &._show,
    &._show .modal-inner-wrap,
    &._show .modal {
        visibility: visible;
        opacity: 1;
    }

    &._show .modal-content > div:not([class]){
        text-align:center;
    }

    .modal-inner-wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        max-width: $modal-width;
        max-height: 100vh;
        padding: 40px 20px;
        background-color: $modal-background;
        opacity: 0;
        visibility: hidden;
        //overflow: auto;
        transition: opacity 250ms ease-in 100ms;
        pointer-events: auto;

        @include breakpoint(xs) {
            width: 90%;
            max-height: 90vh;
            padding: $modal-padding;
            padding-bottom: 0;
            border-radius: $modal-radius;
        }
    }

    // Header
    .modal-header {
        text-align: center;

        .modal-title {
            @extend .title-3;
        }
    }

    // Content
    .modal-content {
        min-height: 40px;
        max-height: 65vh;
        overflow: auto;

        @include breakpoint(xs) {
            margin-bottom: 30px;
        }
    }

    // Forms
    .form {
        .field {
            text-align: left;

            &.note {
                text-align: center;
                font-weight: $fw-benton-M;

                @include breakpoint(md) {
                    font-size: 1.6rem;
                }
            }
        }
    }

    // Footer
    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: $modal-padding;

        button {
            @extend .btn;

            + button {
                margin-left: 10px;
            }
        }
    }

    // Action buttons
    .actions-toolbar {
        display: flex;
        justify-content: space-between;
    }

    // Close button
    .action-close {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 5px;
        right: 10px;
        padding: 0;
        border: none;
        background-color: $c-white;
        cursor: pointer;
        z-index: 2;

        &::after {
            @extend %icon-jade;
            content: "\e616";
            font-size: 2.2rem;
        }

        > span {
            @extend .visually-hidden;
        }

        &.disabled,
        &[disabled] {
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5
        }
    }
}

fieldset[disabled] {
    .modal-popup,
    .modal-slide {
        .action-close {
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5
        }
    }
}

// Agreements
.checkout-agreements-item-content {

    .modal-content & {
        overflow: inherit !important;
    }
}

// Popup Widget
.popup-widget {

    &__content {
        text-align: center;
    }

    &__title {
        position: relative;
        line-height: 1;

        &::after {
            content: '';
            display: block;
            width: 80px;
            height: 1px;
            margin: 15px auto;
            background-color: $c-grey;

            @include breakpoint(lg) {
                width: 100px;
                margin: 25px auto;
            }
        }
    }

    &__image {
        margin: 0 auto;
    }

    &__text {
        margin-top: 15px;
        margin-bottom: 25px;
        font-size: 1.2rem;
        line-height: 1.5;

        @include breakpoint(lg) {
            margin-top: 25px;
            margin-bottom: 55px;
            font-size: 1.6rem;
        }
    }
}


// Checkout cart text modal

.init-modal {

    .modal-inner-wrap {
        padding-bottom: 0;
    }

    .modal-content {
        overflow: visible;
    }

    .checkout-text {
        display: block;
    }
}

.checkout-text {
    display: none;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    font-size: 2rem;
}

//authentification modal
.modal-slide,
.modal-popup {
    .block-authentication {
        .block-title {
            margin-bottom: 20px;
            font-size: 1.8rem;
        }

        .login .field .label {
            margin-bottom: 10px;
        }

        .block-new-customer {
            border-bottom: 1px solid #EDEEF4;
            margin-bottom: 20px;
        }

        ul {
            @extend .list-2;
        }

        .action.action-register {
            @extend .btn;
            margin: 10px 0 15px;
        }

        .actions-toolbar {
            margin-top: 10px;

            .secondary {
                display: flex;
                align-items: center;
            }
        }
    }
}

.modals-wrapper .popup-authentication .action-close{
    top:10px !important; //overwrite important from personnalized.css
    right:10px !important; //overwrite important from personnalized.css
}


/* MODAL GDPR COOKIE - Custom styles cause error in the structure of the modal => modal-inner-wrap doesn't exist */
.modal-popup.amgdprcookie-modal-container {
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100vh;
        left: 0;
        width: 100vw;
        background-color: rgba(0,0,0,0.5);
    }
    .modal-inner-wrap {
        min-height: 40px;
        max-height: 65vh;
        overflow: auto;
        padding: 85px 30px 20px;
        @include breakpoint(xs) {
            margin-bottom: 30px;
        }
        @include breakpoint(lg) {
            padding: 85px 30px 20px;
        }
    }
    .amgdprcookie-cookie-container {
        margin: 0 30px 20px 0;
        padding: 15px 0 25px;
        height: auto !important;
        overflow-y: initial;
        .amgdprcookie-header {
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
        }
        .amgdprcookie-caption {
            margin: 0 0 15px;
            font-size: 16px;
            font-weight: bold;
            line-height: 30px;
            word-break: break-word;
        }
        .amgdprcookie-toggle-cookie {
            position: relative;
            padding-left: 0;
            .amgdprcookie-input {
                height: 0;
                width: 0;
                visibility: hidden;
                &.disabled, &:disabled {
                    + .amgdprcookie-label {
                        background: #E8E8E8;
                    }
                }
                &:checked {
                    + label {
                        opacity: 1;
                        transition: all .2s;
                    }
                    &:not([disabled]) {
                        background-color: #68AC92;
                    }
                    &:disabled, &:not([disabled]) {
                        + .amgdprcookie-label:after {
                            left: calc(95%);
                            transform: translateX(-100%);
                        }
                    }
                }
            }
            .amgdprcookie-label {
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 29px;
                border-radius: 40px;
                cursor: pointer;
                background: #A5A5A5;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
                &:after {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    width: 25px;
                    height: 25px;
                    background: #FFF;
                    border-radius: 40px;
                    opacity: 1;
                    transition: all .4s ease;
                }
            }
        }
        .amgdprcookie-text {
            margin-bottom: 0;
            padding: 0 15px;
            font-size: 12px;
        }
        .amgdprcookie-link {
            display: block;
            font-size: 12px;
            color: #0990DF;
            text-align: right;
            letter-spacing: .3px;
        }
    }
}
