// ==============================================
// FIXES
// ==============================================

//
//  Visible / Hidden class
//  _____________________________________________

* {
    outline: none;
    box-sizing: border-box;
    &:active,
    &:focus { outline: none; }
}

//
//  Remove Background for autocomplete (only Webkit)
//  _____________________________________________

*:-webkit-autofill {
    // @use !important due to style for remove other border on autofill
    -webkit-box-shadow: 0 0 0 1000px $c-white inset;
    -webkit-text-fill-color: $c-base;
    &:hover,
    &:focus {
        // @use !important due to style for remove other border on autofill
        border-bottom: 1px solid $c-light6 !important;
    }
}

//
//  Remove default icon (cross) for input in IE
//  _____________________________________________

*::-ms-clear { display: none; }

//
//  Remove dotted on firefox focus
//  _____________________________________________

*::-moz-focus-inner {
    border:  0;
    padding: 0;
}

//
//  Main tag display type for IE
//  _____________________________________________

@include only_ie {
    main {
        display: block;
    }
}