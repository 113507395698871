// ==============================================
// VARIABLES - Fonts
// ==============================================

//
//  Base fonts
//  _____________________________________________

$Arial:             Arial, "Helvetica Neue", Helvetica, serif;
$Helvetica:         "Helvetica Neue", Helvetica, Arial, sans-serif;
$Tahoma:            Tahoma, Verdana, Segoe, sans-serif;
$TimesNewRoman:     "Times New Roman", Times, Baskerville, Georgia, serif;
$Verdana:           Verdana, Geneva, sans-serif;

//
//  Project fonts
//  _____________________________________________

$f-benton:          'BentonSans';
$f-bentonSC:        'BentonSans SC';
$source-serif-pro:  'Source Serif Pro';

$f-base:            $f-benton;
$f-icons:           'icomoon';


// Benton weights
$fw-benton-R: 400;
$fw-benton-M: 500;
$fw-benton-B: 700;

