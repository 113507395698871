// ==============================================
// BASE - Common
// ==============================================

html,
body {
    height: 100%;
    font-size: 62.5%;
}

body {
    font-family: $f-base;
    color: $c-base;
    font-size: 1.6em;
    font-weight: $fw-benton-R;
    overflow-x: hidden;

    &.locked,
    &.nav-is-open {
        overflow: hidden;
    }
}

a {
    color: $c-base;
    text-decoration: none;
}

address {
    font-style: normal;
    line-height: 1.5;
}

del {
    text-decoration: line-through;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

strong {
    font-weight: bold;
}

p {
    line-height: 1.4;
    margin-bottom: 10px;

    a {
        text-decoration: underline;
    }
}

blockquote {
    line-height: 1.4;
}

caption {
    font-size: 1.2rem;
}

.ct-view {
    &:not(.ct-listing-collections) {
        overflow-x: hidden; //safari overflow
    }
}
//
//  Magento base style
//  _____________________________________________

.hidden {
    border:      0;
    display:     block;
    font-size:   0;
    height:      0;
    line-height: 0;
    margin:      0;
    overflow:    hidden;
    padding:     0;
    width:       0;
}

.nobr { white-space: nowrap; }
.wrap { white-space: normal; }
.a-left { text-align: left; }
.a-center,
.al-center { text-align: center; }
.a-right { text-align: right; }
.upper { text-transform: uppercase; }
.lower { text-transform: none; }
.v-top { vertical-align: top; }
.v-middle { vertical-align: middle; }
.f-left,
.left { float: left; }
.f-right,
.right { float: right; }
.f-none { float: none; }
.f-fix {
    float: left;
    width: 100%;
}

.no-display { display: none; }
.no-margin { margin: 0; }
.no-padding { padding: 0; }
.no-bg { background: none; }
.clear { clear: both; }
.c-black { color: $c-black; }
.c-black-light { color: tint($c-black, 20); }
.c-white { color: white; }
.cur-p { cursor: pointer; }
.d-block { display: block; }
.d-inline { display: inline; }
.ov-h { overflow: hidden; }
.p-r { position: relative; }
.underline {
    text-decoration: underline;
    &:hover { text-decoration: none; }
}
