// ==============================================
// UI COMPONENTS - Toolbar
// ==============================================

//
//  Configuration
//  _____________________________________________

$toolbar-mode-bg-color: $c-grey;
$toolbar-mode-active-bg-color: #4B4B4B;

//
//  Magento native reset
//  _____________________________________________

.page-products .toolbar .limiter,
.page-products #toolbar-top .pages,
.ct-listing-collections .toolbar-top,
.page-products #toolbar-bottom .toolbar-sorter {
    display: none;
}

.products.wrapper ~ .toolbar-products {

    .pages {
        display: block;
    }

    .modes,
    .toolbar-amount {
        display: none;
    }
}

//
//  Listing products toolbar
//  _____________________________________________

.toolbar-products {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    @include breakpoint(only-md) {
        flex-wrap: wrap;

        // Children order
        .filter-switcher {
            order: 1;
            width: 100%;
        }
        .toolbar-amount {
            order: 2;
        }
        .modes {
            order: 3;
        }
        .toolbar-sorter {
            order: 4;
        }
    }

    // Grid or list buttons
    .modes {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include breakpoint(only-md) {
            flex-grow: 1;
            flex-basis: 0;
            margin: 0 5px;
        }

        .modes-label {
            margin-right: 10px;
        }

        .modes-mode {

            & + .modes-mode {
                margin-left: 8px;

                @include breakpoint(lg) {
                    margin-left: 12px;
                }
            }

            &,
            &.mode-gridx4 > span {
                display: flex;
                font-size: 0;

                &::before {
                    content: "";
                    display: block;
                    background-color: $toolbar-mode-bg-color;
                    width: 13px;
                    height: 13px;
                    margin: 0 1px;

                    @include breakpoint(lg) {
                        margin: 0 2px;
                    }
                }

                @include breakpoint(lg) {
                    &::after {
                        content: "";
                        display: block;
                        background-color: $toolbar-mode-bg-color;
                        width: 13px;
                        height: 13px;
                        margin: 0 2px;
                    }
                }
            }

            &.active {
                &,
                > span {
                    display: flex;

                    &::before,
                    &::after {
                        background-color: $toolbar-mode-active-bg-color;
                    }
                }
            }
        }
    }

    // Page items info
    .toolbar-amount {
        display: none;
        margin-bottom: 0;
        font-size: 1.2rem;
        font-weight: $fw-benton-M;

        @include breakpoint(md) {
            font-size: 1.4rem;
            width: 250px;
        }

        @include breakpoint(lg) {
            width: 350px;
        }
    }

    // Sort by filter
    .toolbar-sorter {
        display: flex;
        align-items: center;

        @include breakpoint(md) {
            margin-left: 20px;
        }

        @include breakpoint(lg) {
            margin-left: 50px;
        }

        // Sort by label
        .sorter-label {
            //@extend .visually-hidden;
            margin-right: 10px;
            font-family: $f-bentonSC;
            font-weight: $fw-benton-B;
            font-size: 1.4rem;
        }

        .sorter-options {
            flex: 1;
            border: 0;
            font-weight: $fw-benton-M;
            font-size: 1.4rem;
            padding: 0 20px 0;
            background-position: 100%;
        }

        .sorter-action {
            display: none;

            // Arrow bottom
            &::before {
                font-size: 16px;
            }

            &.sort-asc {
                transform: scaleY(-1);
            }

            // Hide label
            span {
                @extend .visually-hidden;
            }
        }
    }
}

