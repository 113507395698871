// ==============================================
// FORMS - Select
// ==============================================

.select:not(.action),
select {
    // Reset
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand { display: none; } /* hide default down arrow in IE10 */
    &:focus { outline: none; }
    &:focus { outline: none; }
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

    // Custom
    position: relative;
    display: block;
    width: 100%;
    background-clip: padding-box;
    border: 1px solid $input-border-color;
    border-radius: $radius-input;
    padding: 18px 40px 18px 30px;
    vertical-align: baseline;
    color: $c-black;
    font-size: 1.4rem;
    line-height: 1.8;
    text-overflow: '';
    background: $input-background url('../images/icon/arrow_down.svg') no-repeat calc(100% - 16px) center;
    @include only_ie {
        background-position: 99% center;
    }
    background-size: auto 12px;
    transition: border-color .2s;

    &:hover {
        border-color: $c-base;
        transition: border-color .2s;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    &:disabled {
        opacity: 0.4 !important;
    }

    option {
        color: $c-black;
    }

    & + .label {
        display: none;
    }


    &:-webkit-autofill {
        // @use !important due to style for remove other border on autofill
        -webkit-box-shadow: 0 0 0 1000px $c-white inset;
        -webkit-text-fill-color: $c-base;
        &:hover,
        &:focus {
            // @use !important due to style for remove other border on autofill
            border-bottom: 1px solid $c-base !important;
        }
    }
}