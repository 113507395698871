// ==============================================
// PUBLIC - Login
// ==============================================

.customer-account-login {


    .login-container {
        padding-left: 15px;
        border-left: 1px solid $c-light6;

        // Field
        .field {
            &.note {}

            &.email {
                margin-top: 0;
            }

            &.password {}
        }

        // Action
        .actions-toolbar {

            .secondary {
                @include breakpoint(only-md) {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                }

                @include breakpoint(md) {
                    text-align: right;
                }

                .action {
                    font-size: 1.4rem;

                    &.newcus {
                        @extend .main-link;
                        margin-left: 20px;

                        @include breakpoint(md) {
                            border-bottom: 1px solid;
                        }
                    }
                }
            }

            > .primary {
                margin-top: 40px;

                @include breakpoint(md) {
                    margin-top: 60px;
                }
            }
        }
    }
}
