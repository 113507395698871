// ==============================================
// MIXINS - Media Queries
// ==============================================

@mixin breakpoint($class) {
    @if $class == xs {
        @media (min-width: $extra-small) { @content; }
    }

    @else if $class == only-xs {
        @media (max-width: $extra-small-max) { @content; }
    }

    @else if $class == sm {
        @media (min-width: $small) { @content; }
    }

    @else if $class == only-sm {
        @media (max-width: $small-max) { @content; }
    }

    @else if $class == md {
        @media (min-width: $medium) { @content; }
    }

    @else if $class == only-md {
        @media (max-width: $medium-max) { @content; }
    }

    @else if $class == lg {
        @media (min-width: $large) { @content; }
    }

    @else if $class == only-lg {
        @media (max-width: $large-max) { @content; }
    }

    @else if $class == xl {
        @media (min-width: $extra-large) { @content; }
    }

    @else if $class == only-xl {
        @media (max-width: $extra-large-max) { @content; }
    }

    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
    }
}