// ==============================================
// UI COMPONENTS - Tooltip
// ==============================================

//
//  Configuration
//  _____________________________________________

$tooltip-btn-width:             14px;
$tooltip-btn-border-color:      $c-black;
$tooltip-btn-bg-color:          $c-white;
$tooltip-btn-icon-color:        $c-black;
$tooltip-btn-icon-size:         1.2rem;

$tooltip-box-width:             270px;
$tooltip-box-border-color:      $c-brown;
$tooltip-box-bg-color:          $c-brown;
$tooltip-box-color:             $c-white;
$tooltip-box-radius:            $radius-input;
$tooltip-box-padding:           10px;

.control._with-tooltip {
    position: relative;
    overflow: initial;

    input {
        margin-right: 10px;
        padding-right: 30px;
    }

    .note {
        display: block;
        margin-top: 10px;
    }
}

.field-tooltip {
    @extend .field-icon;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .label {
        margin: 0;
        span {
            @extend .visually-hidden;
        }
    }

    // Button
    .field-tooltip-action {
        display: inline-block;
        text-decoration: none;
        cursor: pointer;

        // Button icon
        &::before {
            content: "\e927";
            color: #000;
            font-size: 2.1rem;
            @extend %icon-icomoon;
        }
    }

    // On button click
    &._active {
        .field-tooltip-content {
            display: block;
        }
    }

    // Tooltip box
    .field-tooltip-content {
        display: none;
        position: absolute;
        right: 0;
        bottom: 28px;
        width: $tooltip-box-width;
        max-width: calc(100vw - #{$gutter});
        font-size: 1.2rem;
        line-height: 1.2;
        padding: $tooltip-box-padding;
        word-wrap: break-word;
        border-radius: $tooltip-box-radius;
        border: 1px solid $tooltip-box-border-color;
        background: $tooltip-box-bg-color;
        color: $tooltip-box-color;
        z-index: 2;

        &.left-position {
            right: 50%;

            &:before {
                right: 0;
                border-width: 15px 20px 15px 0;
                border-color: transparent $tooltip-box-bg-color transparent transparent;
            }
        }

        &.right-position {
            left: 50%;

            &:before {
                left: -1px;
                border-width: 15px 0 15px 20px;
                border-color: transparent transparent transparent $tooltip-box-bg-color;
            }
        }

        &:before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            bottom: -11px;
        }
    }
}


// Set tooltip's position inside modal
.modal-popup .form-shipping-address {
    .field-tooltip .field-tooltip-content {
        left: auto;
        right: 50%;

        &:before {
            left: auto;
            right: -1px;
            border-width: 10px 15px 10px 0;
            border-color: transparent $tooltip-box-bg-color transparent transparent;
        }
    }
}