// ==============================================
// VIEW - Bundle
// ==============================================
//
//  Configuration
//  _____________________________________________

$bundle-options-width:                  56%;
$bundle-summary-width:                  40%;
$bundle-border-color:                   darken($c-white, 10%);
$bundle-price-font-size:                3.6rem;
$bundle-price-line-height:              1;
$bundle-price-font-weight:              bold;
$bundle-price-subtitle-font-size:       2.6rem;
$bundle-price-subtitle-line-height:     1.1;
$bundle-price-subtitle-font-weight:     light;

//
//  Product Info Main
//  _____________________________________________

.product-info-main {

    .bundle-actions {
        margin-bottom: 20px;
    }
}

//
//  Bundle Options Details
//  _____________________________________________

.bundle-options-container {
    clear: both;
    margin-bottom: 40px;

    .product-add-form {
        display: none;

        > form {
            display: flex;
            justify-content: space-between;

            @include breakpoint(only-md) {
                flex-direction: column;
            }
        }
    }

    .bundle-options-wrapper {
        width: $bundle-options-width;

        @include breakpoint(only-md) {
            width: 100%;
        }
    }

    .product-options-wrapper {
        margin-bottom: 30px;

        .fieldset-bundle-options {

            .field {

                &.choice {

                    .price-notice {

                        .price-excluding-tax {
                            display: inline-block;
                            font-size: 1.4rem;

                            + .price-excluding-tax {
                                font-size: 1.1rem;
                            }

                            &:before {
                                content: '(' attr(data-label) ': ';
                            }

                            &:after {
                                content: ')';
                            }
                        }
                    }
                }
            }
        }

        .fieldset {

            > .field {
                padding-top: 20px;
                border-top: 1px solid $bundle-border-color;

                &:first-of-type {
                    border-top: 0;
                }
            }
        }
    }

    .action {

        &.back {

            &.disabled,
            &[disabled] {
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
            }
        }
    }

    p {
        &.required {
            color: $c-error;
        }
    }

    .price {
        font-weight: 600;
    }

    .nested {}

    .product-options-bottom {
        padding-top: 20px;
        margin: 0 0 40px;
        border-top: 1px solid $bundle-border-color;

        @include breakpoint(only-md) {
            margin: 0 0 20px;
        }
    }

    .legend {

        &.title {
            font-size: $bundle-price-subtitle-font-size;
            line-height: $bundle-price-subtitle-line-height;
            font-weight: $bundle-price-subtitle-font-weight;
        }
    }

    .block-bundle-summary {
        position: relative;
        width: $bundle-summary-width;

        @include breakpoint(only-md) {
            width: 100%;
        }

        .product-image-container,
        .product.name,
        .stock {
            @extend .visually-hidden;
        }

        > .title {
            margin-bottom: 20px;

            > strong {
                margin-bottom: 20px;
                font-size: $bundle-price-subtitle-font-size;
                line-height: $bundle-price-subtitle-line-height;
                font-weight: $bundle-price-subtitle-font-weight;
            }
        }

        .price-box {
            margin-bottom: 20px;

            .price-label {
                display: block;
                margin-bottom: 5px;
            }

            .price-wrapper,
            .price-wrapper > .price {
                font-size: $bundle-price-font-size;
                line-height: $bundle-price-line-height;
                font-weight: $bundle-price-font-weight;
            }
        }

        .bundle-summary {

            &.empty {
                display: none;
            }

            > .subtitle {
                display: block;
                margin-bottom: 20px;
                font-size: 2.6rem;
                line-height: 1.1;
                font-weight: 300;
            }
        }

        .bundle {

            &.items {

                > li {

                    > div {

                        div {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        .price-including-tax {

            + .price-excluding-tax {

                .price {
                    font-size: 1.4rem;
                    line-height: 1.1;
                }
            }
        }

        .box-tocart {
            margin-bottom: 30px;
        }
    }
}

//
//  Page Layout with 2 & 3 columns
//  _____________________________________________

.page-layout-2columns-left,
.page-layout-2columns-right,
.page-layout-3columns {

    .bundle-options-container {

        .bundle-options-wrapper,
        .block-bundle-summary {
            width: 48%;

            @include breakpoint(only-md) {
                width: 100%;
            }
        }
    }
}
