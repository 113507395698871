// ==============================================
// SLIDESHOW - Common
// ==============================================

.slick-slider {
    // Flex override

    &:not(.slick-vertical):not(.stage-slideshow):not(.strate-image-simple-list):not(.strate-pictos-list) {
        .slick-track {
            display: flex;

            .slick-slide {
                display: flex !important;
                height: auto;
                float: none;

                // Fix Slide Item Content Width
                & > div {
                    width: 100%;
                }
            }
        }
    }

    &.slick-vertical .slick-slide {
        border: none;
    }

    // Navigation arrows
    .slick-prev,
    .slick-next {
        position: absolute;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 0;
        cursor: pointer;
        border: none;
        outline: none;
        font-size: 0;
        line-height: 0;
        color: $c-white;
        background: $c-black;
        transform: translateY(-50%);
        border-radius: 50%;
        z-index: 1;
        transition: background .3s;

        @include breakpoint(lg) {
            width: 42px;
            height: 42px;

            &:hover {
                background: $c-orange;
            }
        }

        &.slick-arrow {
            &.slick-disabled {
                opacity: 0;
            }
        }

        // Arrows
        &::before {
            @extend %icon-icomoon;
            content: "\e907";
            font-size: 1.4rem;
        }
    }

    .slick-prev {
        left: 10px;

        &::before {
            display: block;
            transform: scaleX(-1);
        }
    }

    .slick-next {
        right: 10px;
    }

    // Dots
    .slick-dots {
        display: flex;
        justify-content: center;
        width: fit-content;
        margin: 20px auto 0;

        @include breakpoint(lg) {
            margin: 30px auto 0;
        }

        li {
            + li {
                margin-top: 0;
            }

            button {
                display: block;
                width: 6px;
                height: 6px;
                margin: 5px;
                background: #c4c4c4;
                border-radius: 50%;
                font-size: 0;
                cursor: pointer;
                transition: background .3s;

                &:hover {
                    background: $c-orange;
                }
            }

            &.slick-active {
                button {
                    background: $c-black;
                }
            }
        }
    }
}

// Navigation outside
.slick-arrows-outside {
    display: flex;
    flex-wrap: wrap;

    // Reorder children
    .slick-list {
        order: 1;
    }
    .slick-arrow {
        order: 2;
    }

    // Custom css
    .slick-list {
        width: 100%;
    }

    .slick-arrow {
        position: static;
        width: 50px;
        height: 50px;
        transform: none;
        margin-top: 30px;

        @include breakpoint(md) {
            margin-top: 50px;
            width: 60px;
            height: 60px;
        }

        &::before {
            font-size: 1.6rem;
        }
    }

    .slick-next {
        margin-left: 10px;
    }
}


// Navigation arrows border
.slick-arrows-border {

    .slick-arrow {
        background: $c-white;
        border: 1px solid $input-border-color;
        color: $c-base;
    }


}


//
//  Fix display before Slick init
//  _____________________________________________

// Hide all slide except the first one
[data-role="slide-item"]{
    opacity: 0;

    &:not(:first-of-type) {
        display: none !important;
    }
}

// Show all slides
.slick-initialized {
    [data-role="slide-item"] {
        opacity: 1;
        display: flex !important;
    }
}


//
//  Slick list image cropping
//  _____________________________________________

@mixin slick-list-cropping {
    padding-right: 100px;
}

.slick-list-cropping {
    @include slick-list-cropping;
}