// ==============================================
// JADE - Icons
// ==============================================

/**
 * Based on native Luma kit icons
 * Created with icomoon
 */

@font-face {
    font-family: 'jade-icons';
    src:  url('../fonts/jade-icons.eot?fbijhr');
    src:  url('../fonts/jade-icons.eot?fbijhr#iefix') format('embedded-opentype'),
    url('../fonts/jade-icons.woff2?fbijhr') format('woff2'),
    url('../fonts/jade-icons.ttf?fbijhr') format('truetype'),
    url('../fonts/jade-icons.woff?fbijhr') format('woff'),
    url('../fonts/jade-icons.svg?fbijhr#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@mixin icon-jade {
    text-transform: none;
    font-family: 'jade-icons' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icon-jade {
    @include icon-jade;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'jade-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-card:before {
    content: "\e900";
}
.icon-delivery:before {
    content: "\e901";
}
.icon-heart:before {
    content: "\e600";
}
.icon-pen:before {
    content: "\e601";
}
.icon-warning:before {
    content: "\e602";
}
.icon-refresh:before {
    content: "\e603";
}
.icon-garbage:before {
    content: "\e604";
}
.icon-star:before {
    content: "\e605";
}
.icon-wheel:before {
    content: "\e606";
}
.icon-arrow1-down:before {
    content: "\e607";
}
.icon-arrow2-right:before {
    content: "\e608";
}
.icon-uniE609:before {
    content: "\e609";
}
.icon-pin:before {
    content: "\e60a";
}
.icon-list:before {
    content: "\e60b";
}
.icon-informations:before {
    content: "\e60c";
}
.icon-grid:before {
    content: "\e60d";
}
.icon-validation1:before {
    content: "\e60e";
}
.icon-uniE60F:before {
    content: "\e60f";
}
.icon-validation2:before {
    content: "\e610";
}
.icon-cart:before {
    content: "\e611";
}
.icon-calendar:before {
    content: "\e612";
}
.icon-arrow3-up:before {
    content: "\e613";
}
.icon-arrow3:before {
    content: "\e614";
}
.icon-magnifying:before {
    content: "\e615";
}
.icon-close:before {
    content: "\e616";
}
.icon-arrow2-left:before {
    content: "\e617";
}
.icon-arrow1-up:before {
    content: "\e618";
}
.icon-arrow1-right:before {
    content: "\e619";
}
.icon-arrow1-left:before {
    content: "\e61a";
}
.icon-flag:before {
    content: "\e61b";
}
.icon-plus:before {
    content: "\e61c";
}
.icon-mail:before {
    content: "\e61d";
}
.icon-compare:before {
    content: "\e61e";
}
.icon-alert:before {
    content: "\e61f";
}
.icon-bubble:before {
    content: "\e620";
}
.icon-arrow2-up:before {
    content: "\e621";
}
.icon-arrow2-down:before {
    content: "\e622";
}
.icon-question:before {
    content: "\e623";
}
.icon-print:before {
    content: "\e624";
}
.icon-star-empty:before {
    content: "\e625";
}
.icon-download:before {
    content: "\e626";
}
.icon-customer:before {
    content: "\e627";
}
.icon-back:before {
    content: "\e628";
}
.icon-uniE629:before {
    content: "\e629";
}
.icon-gift:before {
    content: "\e62a";
}
.icon-calendar2:before {
    content: "\e62b";
}