// ==============================================
// BASE - List
// ==============================================

.list-1 {

    li {
        position: relative;
        line-height: 1.5;
        margin-bottom: 0;
        padding-left: 16px;

        a {
            color: $c-black;
        }

        & + li {
            margin-top: 5px;
            margin-bottom: 0;
        }

        // Bullet
        &:before {
            content: "-";
            position: absolute;
            top: 8px;
            left: 0;
            line-height: 0.5;
        }
    }
}

.list-2 {

    li {
        position: relative;
        line-height: 1.5;
        margin-bottom: 0;
        padding-left: 16px;

        a {
            color: $c-black;
        }

        & + li {
            margin-top: 5px;
            margin-bottom: 0;
        }

        // Bullet
        &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            width: 6px;
            height: 6px;
            background-color: $c-black;
            border-radius: 50%;
        }
    }
}


// Custom list
.list-custom {
    li {
        position: relative;
        padding-left: 22px;

        &::before {
            position: absolute;
            top: 1px;
            left: 0;
            @extend %icon-icomoon;
            content: "\e91a";
            font-size: 1.1rem;
        }

        + li {
            margin-top: 6px;
        }

        strong {
            font-weight: $fw-benton-M;

            span {
                white-space: nowrap;
            }
        }
    }
}

.list-custom-color li {

    &.available {
        color: $c-available;
    }

    // Unavailable information
    &.unavailable {
        color: $c-unavailable;

        &::before {
            content: "\e91c";
        }

        strong {
            font-weight: $fw-benton-R;
        }
    }
}