// ==============================================
// UI COMPONENTS - Notifications
// ==============================================

//
//  Configuration
//  _____________________________________________

$notification-bar-padding:          14px;
$notification-bar-radius:           $radius-btn;
$notification-success-bg-color:     $c-success;
$notification-info-bg-color:        $c-white;
$notification-info-border-color:    $c-info;
$notification-warning-bg-color:     $c-warning;
$notification-error-bg-color:       $c-error;

.notification {
    position: relative;
    padding: $notification-bar-padding;
    padding-left: 60px;
    line-height: 1.4;
    color: $c-black;
    background-color: lighten($c-black, 80%);
    border-radius: $notification-bar-radius;
    margin-top: 20px;

    @include breakpoint(only-sm) {
        font-size: 1.4rem;
        padding-left: 50px;
    }

    // Icon
    &::before {
        position: absolute;
        top: 10px;
        left: $notification-bar-padding;
        @extend %icon-icomoon;
        font-size: 2.4rem;

        @include breakpoint(md) {
            font-size: 3rem;
        }
    }

    a {
        text-decoration: underline;
    }

    &.notification--success {
        background-color: $notification-success-bg-color;

        &, a, a::before {
            color: $c-white;
        }

        // Icon
        &::before {
            content: "\e91b";
        }
    }

    &.notification--information {
        background-color: $notification-info-bg-color;
        border: 1px solid $notification-info-border-color;

        &, a, a::before {
            color: $notification-info-border-color;
        }

        // Icon
        &::before {
            content: "\e934";
        }
    }

    &.notification--warning {
        background-color: $notification-warning-bg-color;

        &, a, a::before {
            color: $c-white;
        }

        // Icon
        &::before {
            content: "\e904";
        }
    }


    &.notification--error {
        background-color: $notification-error-bg-color;

        &, a, a::before {
            color: $c-white;
        }

        // Icon
        &::before {
            content: "\e96b";
        }
    }
}

//
//  Magento natives notifications extends
//  _____________________________________________

.message {
    & + .message {
        margin-top: 10px;
    }

    &:nth-last-of-type(1){
        margin-bottom: 20px;
    }

    &.message-success {
        @extend .notification;
        @extend .notification--success;
    }

    &.notice,
    &.info,
    &.message-notice,
    &.message-progress,
    &.message-spinner,
    &.message-in-rating-edit {
        @extend .notification;
        @extend .notification--information;
    }

    &.message-warning {
        @extend .notification;
        @extend .notification--warning;
    }

    &.message-error,
    &.error {
        @extend .notification;
        @extend .notification--error;
    }

    &.demo {
        text-align: center;
        padding: 12px;
        font-size: 1.3rem;
        color: darken($notification-warning-bg-color, 50%);
        background-color: $notification-warning-bg-color;

        p {
            margin-bottom: 0;
        }
    }
}

.mage-error {
    color: $c-error;
    font-size: 1.3rem;
    font-style: italic;
}

.cms-index-index {
    .column.main {
        > .messages {
             .messages {
                margin-top: 20px;
            }
        }
    }
}

.page-layout-1column {
    .column.main {
        > .messages {
            @extend .container;
        }
    }
}


//
//  Custom validation message
//  _____________________________________________
.custom-validation-message {
    color: $c-error;
    font-size: 1.4rem;
    margin-left: $gutter-large;
    margin-top: $gutter/2;
}


//
//  Notification
//  _____________________________________________

.notification-text {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: $fw-benton-B;
    color: $c-black;

    @include breakpoint(md) {
        font-size: 1.6rem;
    }

    // Icon
    &::before {
        display: inline-block;
        margin-right: 8px;
        @extend %icon-icomoon;
        font-size: 2.4rem;
    }

    &.success {
        color: $c-brown;

        // Icon
        &::before {
            content: "\e906";
        }
    }

    &.information::before {
        content: "\e911";
    }

    &.warning::before {
        content: "\e904";
    }

    &.error {
        color: $c-error;

        // Icon
        &::before {
            content: "\e908";
        }
    }
}