// ==============================================
// STRATE 4 X 1
// ==============================================

.ct-view-strate_4_x_1 {

    &.liseret-oui {
        .slick-slide .item {
            padding-left: 15px;
            padding-right: 15px;
            border-left: 1px solid $c-light6;
        }
    }

    .block-content {
        @extend .container;
        padding-right: 0;
        padding-bottom: $gutter;

        @include breakpoint(xs) {
            padding-right: $gutter;
        }

        @include breakpoint(lg) {
            padding-bottom: 60px;
        }
    }

    .items {
        display: flex;
        margin-right: calc(calc(-100vw + 100%)/2) !important; //to overwrite the rule from strate-media-full-width
    }

    .strate-item-title{
        font-size:1.4rem;
    }
    
    .item {
        padding-right: $gutter-large;
        flex-direction: column;
        height: 100%;

        @include breakpoint(lg) {
            padding-right: $gutter*2;

            // Item animation
            .redirect-content {
                &:hover,
                &:focus {
                    img {
                        transform: scale(1.04);
                    }
                }

                img {
                    transform: scale(1);
                    transition: transform .2s linear;
                }
            }

            // Desktop animation
            .picture {
                overflow: hidden;
                margin-bottom: $gutter-large;
            }

            .strate-item-titles {
                margin-bottom: 0;
                position: relative;
                top: -$gutter-large/2;
                transition: top .2s linear;
            }

            .strate-item-title{
                font-size:1.8rem;
            }

            .strate-item-ontitle {
                display: block;
                margin-bottom: 4px;
            }
        }

        .strate-item-titles {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        
        .title-btn{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        .picture {
            margin-bottom: $gutter-large;
        }

        .redirect-content.btn {
            margin-top: auto;
        }
    }

    // Slick slideshow
    .slick-slider {
        @extend .slick-arrows-outside;
        margin-right: -$gutter;
    }
}

.ct-view-strate-media-full-width{
    .ct-view-strate_4_x_1{
        .items{
            margin:40px 0 0 0;
        }
    }
}
