// ==============================================
// ONEPAGE - Success
// ==============================================

.checkout-onepage-success {
    background-color: $c-light7;

    // Hide native Magento
    .block-order-details-view,
    .order-details-items {
        display: none;
    }

    // Main container
    .page-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: $gutter;

        @include breakpoint(lg) {
            margin-top: 100px;
        }

        & > img {
            display: none;

            @include breakpoint(lg) {
                display: block;
                max-width: 130px;
            }
        }
    }

    // Header
    .page-header {
        @include breakpoint(lg) {
            background: none;
        }
    }

    .page-title-wrapper {
        text-align: center;
        margin-top: $gutter/2;

        .page-title {
            @extend .title-1;
            margin-bottom: 30px;
        }

        a.print {
            @extend .link-hover-black;
        }
    }

    // Success message
    .checkout-success {
        text-align: center;
        max-width: 455px;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.2;

        .decoration {
            display: block;
            padding-bottom: $gutter;
            text-align: center;
        }

        .icon {
            position: relative;
            font-size: 3.5rem;

            // Icon check
            &::before {
                position: absolute;
                top: -7px;
                left: 5px;
            }

            // Icon border radius
            &::after {
                display: inline-block;
                width: $gutter-large;
                height: $gutter-large;
                border: 1px solid $input-border-color;
                border-radius: 100%;
                content: "";
            }
        }

        .success-text {
            display: block;
            margin-bottom: $gutter-large;
            font-weight: $fw-benton-B;
            font-size: 2.2rem;
        }
    }


    // Hide footer reinsurance
    .mdp-footer-reinsurance {
        display: none;
    }

    // Actions
    .actions-toolbar {
        margin-top: $gutter-large;

        & > .primary {}

        & > .secondary {
            margin-top: $gutter-large;
        }

    }
}


// One page failure
.checkout-onepage-failure {
    background-color: $c-light7;

    // Header
    .page-header {
        @include breakpoint(lg) {
            background: none;
        }
    }

    .page-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .page-title-wrapper {
            font-size: 2rem;
            margin-bottom: $gutter-large;

            @include breakpoint(md) {
                font-size: 3rem;
                margin-bottom: $gutter-large*2;
            }
        }

        p {
            text-align: center;
        }
    }
}