// ==============================================
// STRATE AGENDA MONNETAIRE
// ==============================================

.ct-view-strate_agenda_mon_taire {

    .strate-title {
        padding-bottom: 10px;
    }

    .strate-title-link {
        top: 15px;

        @include breakpoint(only-lg) {
            top: 20px;
        }
    }

    .block-content {
        @extend .container;
    }

    .content {
        padding:20px 10px 0px 10px;;

        @include breakpoint(only-lg) {
            margin: 0 -20px;
        }
        
        @include breakpoint(lg) {
            padding:50px 10px;
        }
    }

    .items {
        display: flex;
        flex-wrap: wrap;
    }

    .item {
        width: calc(50% - 20px);
        margin-left:20px;
        margin-bottom:20px;
        
        @include breakpoint(lg) {
            width: calc(25% - 40px);
            margin-left: 40px;
            margin-bottom:0;

            &:nth-child(4n){
                .item-border{
                    &:before{
                        display:none;
                    }
                }
            }

            .item-border{
                &:after{
                    display:none;
                }
            }
        }

        @include breakpoint(only-lg) {
            // first 2 el
            &:nth-child(-n+2){
                .item-border{
                    padding-bottom:20px;
                }
            }

            // last 2 el
            &:nth-last-child(-n+2){
                .item-border{
                    &:after{
                        display:none;
                    }
                }
            }

            // 1 out of 2 els, starting 2nd
            &:nth-child(2n){
                .item-border{
                    &:before{
                        display:none;
                    }
                }
            }

            &:nth-child(3){
                .item-border{
                    &:before{
                        bottom:0;
                    }
                }
            }
        }

        .item-border{
            display: flex;
            flex-flow: column;
            position:relative;
            height:100%;
            padding-right: 20px;

            @include breakpoint(lg) {
                padding-right:40px;
            }

            //border right
            &:before{
                content:'';
                position:absolute;
                right:0;
                left:auto;
                bottom:0;
                top:0;
                width:1px;
                background-color: $c-black;

                @include breakpoint(only-lg) {
                    bottom:20px;
                }
            }

            //border bottom
            &:after{
                content:'';
                position:absolute;
                right:20px;
                left:0;
                bottom:0;
                top:auto;
                height:1px;
                background-color: $c-black;
            }

        }

        .date {
            display: block;
            margin-bottom: $gutter-large/2;
            color: $c-black;
            @extend .title-1;
            font-size: 2rem;
            line-height:1;

            @include breakpoint(lg) {
                font-size: 4rem;
            }
        }

        .collection-name {
            display: block;
            margin-bottom: 5px;
            font-size: 1.4rem;
            font-weight: $fw-benton-B;

            @include breakpoint(lg) {
                font-size: 2rem;
            }
        }

        .description {
            margin-bottom:5px;
            font-size: 1.2rem;
            line-height:1.4;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /* autoprefixer: off */
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            @include breakpoint(lg) {
                margin-bottom:20px;
                font-size: 1.4rem;
            }
        }

        .link{
            display:block;
            align-self: flex-start;
            margin-top: auto;
            font-weight: $fw-benton-B;
            font-size: 1.6rem;
            line-height:1.4;
            text-decoration: none;
            background-image: linear-gradient(currentColor, currentColor);
            background-size: 0% 1px;
            background-position: 0 100%;
            background-repeat: no-repeat;
            transition: background-size .5s ease-out;
            border-bottom: none;

            &:hover{
                background-size: 100% 1px;
            }
        }


    }

    // Slick slideshow
    .slick-slider {
        @extend .slick-arrows-outside;
    }

    // Color variants
    &.universe-green{
        .item-border{
            &:before, &:after{
                background-color:$c-green;
            }
        }

        .content{
            background-color: $c-green-light;
        }

        .date{
            color: $c-green;
        }
    }

    &.universe-blue{
        .item-border{
            &:before, &:after{
                background-color:$c-blue2-dark;
            }
        }

        .content{
            background-color:$c-blue2-light;
        }

        .date{
            color:$c-blue2-dark;
        }
    }

    &.universe-yellow{
        .item-border{
            &:before, &:after{
                background-color:$c-yellow-dark;
            }
        }

        .content{
            background-color: $c-yellow-light;
        }

        .date{
            color: $c-yellow-dark;
        }
    }
}
