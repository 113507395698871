.account {
    .orders-history,
    .orders-recent {

        .order-row {
            position: relative;
            padding: 45px 15px 0;
        }

        .table-order-items .invoice {
            @include breakpoint(md) {
                text-align: center;
            }
        }

        .table-order-items .order-row {
            .col.total {
                order: 0;
            }
            .col.date {
                order: 1;
            }
            .col.status {
                order: 2;
            }
            .col.invoice,
            .col.actions {
                order: 5;
            }

            .col.actions.see-more {
                padding: 11px 15px 11px 0;
                display: flex;
                align-items: center;

                @include breakpoint(md) {
                    padding: 30px 15px;
                }

                a {
                    margin-right: 30px;
                    padding: 10px;

                    @include breakpoint(md) {
                        padding: 0;
                        margin-right: 20px;
                    }
                }
            }

            @include breakpoint(md) {
                td {
                    font-weight: $fw-benton-M;
                    font-size: 1.4rem;

                    &:nth-child(even):not(last-child) {
                        color: $c-grey3;
                        font-weight: $fw-benton-R;
                    }
                }

                .col.invoice {
                    text-align: center;

                    .action {
                        justify-content: center;
                    }
                    .icon-download {
                        font-size: 21px;
                    }
                    .action-text {
                        display: none;
                    }
                }
            }

            .col.actions {
                padding: 0;

                .action {
                    display: inline-block;
                    padding: 3px 0;

                    @include breakpoint(only-md) {
                        padding: 15px;
                    }

                    span {
                        @extend .main-link;
                    }
                }
            }
        }

        .table-order-items .order-details {
            @include breakpoint(md) {
                .col.product {
                    position: relative;

                    .product-image-photo {
                        width: auto;
                        max-height: 100px;
                    }
                }

                .order-details-col {
                    padding: 0;

                    table tbody tr {
                        padding-top: 10px;
                        padding-bottom: 10px;

                        & + tr {
                            border-top: 1px solid $c-light7;
                        }
                    }
                }
            }

            .col.product {
                position: relative;

                @include breakpoint(only-md) {
                    flex-direction: row;
                    align-items: center;
                }

                &::before {
                    display: none;
                }

                .product-item-image {
                    padding-bottom: 10px;
                    max-width: 100px;
                    display: inline-block;
                    vertical-align: middle;
                }

                .product-item-details {
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: $gutter/2;
                    font-weight: $fw-benton-M;
                    max-width: calc(100% - 100px);

                    span {
                        display: block;
                        line-height: 1.2;
                    }
                }
            }
        }

        // button download invoice
        .order-row .invoice {

            @include breakpoint(only-md) {
                width: 100%;
                margin-top: 20px;
            }

            &::before {
                content: none !important;
            }

            .action {
                display: flex;

                &-text {
                    text-decoration: underline;
                    margin-left: 3px;
                }
            }
        }

        //panel deployed
        .order-details {
            @include breakpoint(only-md) {
                .col-product-infos,
                .col-product-infos .col {
                    width: 100%;
                    padding-top: 10px;

                    .unit-price,
                    .qty {
                        width: 50%;
                    }
                }
            }

            & > td {
                width: 100%;
            }

            .col-total-infos {
                width: 100%;

                tbody {
                    width: 100%;

                    tr td,
                    tr td:first-child,
                    tr td:nth-child(2) {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }

            }

            .col-address-infos {
                .title {
                    font-weight: $fw-benton-M;
                    margin-bottom: 10px;
                    margin-top: 10px;
                }
            }

            .order-details-col {
                width: 100%;

                @include breakpoint(only-md) {
                    padding: 0;
                }

                > table {
                    margin-bottom: 10px;
                }

                tbody {
                    width: 100%;

                    tr td {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }

                // Reset product item
                .product-image-container {
                    display: inline-block;
                    text-align: center;
                    width: 100% !important;
                }
                .product-image-wrapper {
                    display: inline-block;
                    padding-bottom: 0 !important;
                }

            }
        }
    }
}


.account .orders-history .order-details > td {
    width: 100%;
}

.account .table-order-items {
    background-color: $c-white;
}

// =====================
// Order cancellation
// =====================

.container-plus {
    display: inline-block;
    position: relative;

    div[data-trigger="actions"] {
        display: inline-block;
        color: $c-black;
        transition: color .2s;
        border-bottom: 1px solid currentColor;

        &:hover {
            color: $c-orange;
            cursor: pointer;
        }

        &::before {
            @extend %icon-icomoon;
            content: "\e970";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -20px);
            font-size: 20px;
        }
    }

    .order-cancel:hover, .order-tracking:hover {
        cursor: pointer;
    }

    .action.order-cancel.disable,
    .action.order-tracking.disable,
    .action.order-return.disable{
        color: $c-grey3;
        display: flex;
        align-items: center;

        &::after {
            @extend %icon-icomoon;
            content: "\e972";
            color: $c-black;
        }
    }
}

.history-see {
    position: relative;

    &::before {
        @extend %icon-icomoon;
        content: "\e971";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -20px);
        font-size: 20px;
    }
}

.account .orders-history .table-order-items .order-row .col.actions .actions-wrapper .action {
    padding: 0;
}

.actions-wrapper {
    position: absolute;
    width: 22rem;
    left: -7.5rem;
    top: 3rem;
    z-index: 1;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px #00000033;
    background-color: $c-white;
    animation: movingBottomToTop .3s;
    line-height: 25px;

    li {
        padding: 0;
    }

    @include breakpoint(md) {
        left: -14.5rem;
    }

    @include breakpoint(lg) {
        left: -12.5rem;
    }

    &.hidden {
        padding: 0;
    }

    &::before {
        content: "";
        position: absolute;
        right: 10.5rem;
        top: 0;
        padding: 0.7rem;
        transform: translate(0, -6px) rotate(45deg);
        box-shadow: 0px 0px 10px #00000033;
        background-color: $c-white;

        @include breakpoint(md) {
            right: 3.5rem;
        }

        @include breakpoint(lg) {
            right: 5.5rem;
        }
    }

    &::after {
        content: "";
        position: absolute;
        right: 9rem;
        top: 0;
        padding: 0.7rem 2rem;
        background-color: $c-white;

        @include breakpoint(md) {
            right: 2rem;
        }

        @include breakpoint(lg) {
            right: 4rem;
        }
    }
}


// Hide modal cancel before init
.modal-cancel,
.modal-cancel-error,
.modal-return,
.modal-tracking-error {
    display: none;
}
.modal-content {
    .modal-cancel,
    .modal-cancel-error,
    .modal-return,
    .modal-tracking-error {
        display: block;
    }
}

.modal-return-step,
.modal-cancel-step {
    text-align: center;

    button.action {
        width: auto;
    }

    div {
        margin-bottom: 25px;
    }

    table {
        tr {
            td {
                @include breakpoint(md) {
                    &:nth-last-child(1) {
                        text-align: right;
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    table.return-product-table,
    table.annulation-product-table {
        background-color: white;
        text-align: left;
        width: 100%;

        @include breakpoint(only-md) {
            &, thead, tbody {
                display: block;
            }
        }

        strong {
            font-size: 18px;
        }

        img {
            width: 100px;
        }

        .return-qty{
            width: 100px;
        }

        > thead {
            @include breakpoint(md) {
                display: table-header-group;

                th:first-child span {
                    display: none;
                }
            }

            th {
                background-color: $c-light7;
                padding: 10px;
                font-weight: 500;
                white-space: nowrap;
                vertical-align: middle;

                @include breakpoint(only-md) {
                    display: none;
                }

                &[colspan="2"] {
                    width: 50%;
                }

                &:first-child span {
                    vertical-align: middle;
                }

                input[type="checkbox"] + label {
                    vertical-align: middle;
                }

                label::before, label::after {
                    left: 50%;
                    top: 50%;
                }

                label::before {
                    transform: translate(-50%, -50%);
                }
            }
        }

        > tbody {
            tr {
                .product-item-details {
                    margin : 0;
                    vertical-align: middle;
                    span {
                        display: block;
                        font-size: 14px;
                        font-weight: $fw-benton-M;
                    }
                }

                @include breakpoint(only-md) {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    position: relative;
                    padding-left: 40px;
                    padding-top: 15px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #CCCCCC;
                }

                &.disabled {
                    background-color: $c-light7;
                }

                &.table-refound {
                    background-color: $c-light7;

                    > td:first-child {
                        background-color: $c-light7;

                        @include breakpoint(md) {
                            background-color: $c-white;
                        }
                    }

                    tr {
                        background-color: $c-light7;
                    }
                }
            }

            > tr:not(.table-refound) > td {
                @include breakpoint(only-md) {
                    display: block;
                    align-items: center;
                    font-size: 1.4rem;
                    font-weight: 500;
                    width: 50%;

                    // Checkbox
                    &:first-child {
                        position: absolute;
                        top: 40px;
                        left: 0;
                        width: auto;
                    }

                    &:nth-child(even) {
                        width: 100px;
                    }
                    &:nth-child(odd):not(:first-child) {
                        width: calc(100% - 100px);
                        padding-left: 10px;
                    }

                    // Field with label
                    &[data-th] {
                        &::before {
                            display: block;
                            margin-bottom: 5px;
                            content: attr(data-th);
                            width: 150px;
                            font-size: 1.2rem;
                            color: #777;
                            font-family: $f-bentonSC;
                        }
                    }
                }
            }
        }

        thead th:nth-last-child(1) {
            text-align: right;
        }

        tr {
            td {
                vertical-align: middle;
                padding: 10px 0;

                @include breakpoint(md) {
                    padding: 10px;
                }

                label::before, label::after {
                    left: 50%;
                    top: 50%;
                }

                label::before {
                    transform: translate(-50%, -50%);
                }

                img {
                    width: 70px;
                    min-width: 70px;
                }
            }
        }

        td.total-table-container {
            @include breakpoint(md) {
                padding: 0;
            }

            &::before {
                content: none;
            }

            table.total-refound {
                background-color: $c-light7;
                width: 100%;

                @include breakpoint(md) {
                    margin-left: auto;
                }

                tbody {
                    display: table-row-group;

                    tr {
                        display: table-row;

                        td {
                            display: table-cell;

                            &::before {
                                display: none;
                            }

                            &:nth-child(2) {
                                text-align: right;
                            }
                        }
                    }
                }

                tfoot {
                    background-color: $c-white;

                    tr {
                        display: table-row;
                        background-color: $c-white;
                        height: 80px;

                        @include breakpoint(md) {
                            height: 55px;
                        }

                        td {
                            display: table-cell;

                            @include breakpoint(md) {
                                vertical-align: bottom;
                            }

                            &::before {
                                display: none;
                            }

                            &:nth-child(2) {
                                text-align: right;
                            }

                            &:first-child strong {
                                white-space: break-spaces;

                                @include breakpoint(md) {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }

                tr {
                    &.new-total {
                        position: relative;
                        height: 50px;

                        &::after {
                            content: "";
                            width: calc(100% - 20px);
                            position: absolute;
                            height: 1px;
                            left: 10px;
                            top: 0;
                            background-color: $c-green;
                        }
                    }

                    td {
                        white-space: nowrap;

                        @include breakpoint(md) {
                            &:nth-child(1) {
                                text-align: left;
                            }

                            &:nth-child(2) {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }

        // Step validation
        &.step-validation {
            > thead > tr:not(.table-refound) > th:first-child,
            > tbody > tr:not(.table-refound) > td:first-child {
                display: none;
            }

            tbody tr {
                padding-left: 0;
            }

            .table-refound {
                @include breakpoint(only-md) {
                    padding: 0;
                    border: none;
                    .total-table-container {
                        width: 100%;
                        padding: 0;
                    }
                    .total-refound {
                        tr {
                            padding-left: 0;
                            td {
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-step-validate {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 0;

        a {
            margin-right: 20px;
        }
    }

    &.step1 {
        @include breakpoint(only-md) {
            th:not(:first-child) {
                display: none;
            }

            td:first-child::before {
                display: none;
            }
        }
    }

    &.step2 {
        display: none;
        animation: hideBeforeShowing .3s;

        @include breakpoint(md) {
            .modal-step-validate {
                justify-content: right;
            }
        }
    }

    &.step3 {
        display: none;
        animation: hideBeforeShowing .3s;

        div:nth-child(1) {
            color: $c-success;
        }
    }

    .step-data {
        color: orange;
        font-weight: 500;
    }

    .mage-error {
        display: none;
    }
}

.modal-cancel-error, .modal-tracking-error {
    text-align: center;

    .modal-cancel-error-title {
        display: block;
        margin-bottom: 25px;
    }
}


/* Pour simuler une modal
    padding: 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 15px #0000003b;
    margin-top: 30px;
    margin-bottom: 30px;
 */
