// ==============================================
// UI COMPONENTS - Pager
// ==============================================

//
//  Configuration
//  _____________________________________________

$pager-item-padding:            10px;
$pager-items-space:             10px;
$pager-current-bg-color:        $c-gold;
$pager-items-bg-color:          transparent;
$pager-actions-bg-color:        lighten($c-black, 95%);

.pages {

    .label {
        // Hide labels
        @extend .visually-hidden;
    }

    ul.items {
        display: flex;
        align-items: center;

        li.item {

            & + li {
                margin-left: $pager-items-space;
            }

            &.current,
            a.next,
            a.previous,
            a.page {
                display: block;
                padding: $pager-item-padding;
            }

            a.page {
                background-color: $pager-items-bg-color;
            }

            &.current {
                font-weight: bold;
                background-color: $pager-current-bg-color;
                color: $c-white;
            }

            a.next,
            a.previous {
                background-color: $pager-actions-bg-color;
                transition: background-color .3s linear;

                &:hover {
                    background-color: $pager-current-bg-color;
                    color: $c-white;
                }

                // Arrow
                &::after {
                    font-size: 20px;
                }
            }
        }
    }
}