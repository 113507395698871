// ==============================================
// STRATE MEDIA FULL WIDTH
// ==============================================



.ct-view-strate-media-full-width {
    &.not-fullpage{
        .block-content{
            @extend .container;
            padding-bottom: $gutter;
        }
    }

    @include breakpoint(only-sm) {
        &.not-fullpage{
            .block-content{
                padding:0;
            }
        }
    }

    @include breakpoint(lg) {
        &.not-fullpage{
            .block-content{
                padding-bottom: 60px;
            }
        }
    }
    //&.ct-view .block-content {
    //}

    @include breakpoint(lg) {
        &.not-fullpage{
            .block-content{
                padding-bottom: 60px;
            }
        }
    }
    //&.ct-view .block-content {
    //}

    .items {
        //margin-bottom: 60px;
        display:flex;
        flex-wrap: wrap;
        margin:0  -$gutter/4;
    }

    .item {
        position: relative;
        & + .item {
            margin-top: $gutter/4;
        }
        @include breakpoint(lg) {
            & + .item {
                margin-top: $gutter/4;
            }
        }

        .title, .ontitle {
            @include breakpoint(lg) {
                max-width:75%;
            }
            &:first-letter{
                text-transform: uppercase;
            }
        }
        .title{
            font-family: $source-serif-pro;
        }
        .ontitle{
            font-family: $f-benton;
        }
        &.full{
            .title{
                font-size:1.8rem;
                @include breakpoint(md) {
                    font-size:3rem;
                }
            }
        }
        &.half{
            .title{
                font-size:1.4rem;
                @include breakpoint(md) {
                    font-size:2rem;
                }
            }
        }
    }

    .item__content {
        @extend .overlay-content-block;

        .btn{
            margin-top:10px;
        }
    }
    @include breakpoint(md) {
        .item.full .item__content {
            padding-bottom:50px;
            padding-left:60px;
        }

        .item.half .item__content {
            padding-bottom:35px;
            padding-left:60px;
        }
    }

    @media screen and (min-width: $ultra-large){
        &.fullpage .item__content{
            padding-left: 120px !important;
        }
        &.fullpage .full .item__content{
            padding-right: 120px !important;
        }
    }

    // First picture mobile
    @include breakpoint(only-md) {
        .item.full {
            height: calc(100vh - 190px);

            .picture-wrapper {
                height: 100%;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }
    }

    // Picture
    .picture-wrapper {
        width: 100%;

        img {
            display: block;
            width: 100vw;
            height: auto;
            object-fit: contain;
            margin: auto;

            @include breakpoint(md) {
                height: auto;
            }

            @include breakpoint(lg) {
                height: auto;
            }
        }
    }

    .video-wrapper{
        padding-bottom:calc(90vh - 190px);

        @include breakpoint(only-sm) {
            padding-bottom:170%;
        }

        .playpause{
            position: absolute;
            display:none;
            align-items: center;
            justify-content: center;
            width:50px;
            height:50px;
            bottom: 25px;
            right: 25px;
            z-index: 3;
            font-size: 4.5rem;
            line-height: 0;
            color: #fff;

            @include breakpoint(md) {
                right: 60px;
                bottom: 50px;
            }

            @media screen and (min-width: 1440px){
                right:120px;
            }

            &.displayMobile{
                @include breakpoint(only-md) {
                    display:flex;
                }
            }

            &.displayDesktop{
                @include breakpoint(md) {
                    display:flex;
                }
            }

            .icon{
                transition: all .3s;
                transform-origin: center;
                cursor: pointer;

                &:hover, &:focus{
                    opacity:.8;
                    transform:scale(.95);
                }
            }

            .icon-pause{
                display:inline-block;
                width:35px;
                height:35px;
                line-height:0;
            }
        }
    }

    // Variant for strate bienvenue
    .container.container-image{
        .picture-wrapper{
            img{
                height:40vw;
            }
        }

        @include breakpoint(only-sm) {
            margin:0;
            padding:0;
            .picture-wrapper{
                img{
                    height: auto;
                }
            }

        }
        .item__content {
            padding-bottom:60px;
            @include breakpoint(md) {
                padding-bottom:45px;
                padding-left:100px;
            }
        }
    }
}


.ct-view-strate_players .item {
    width: 100%;
    margin:0 $gutter/8;
    background-color: $c-black;

    @include breakpoint(md) {
        display: flex;
        overflow: hidden;
        align-items: center;
        max-height: calc(90vh - 85px);
    }

    @include breakpoint(lg) {
      max-height: calc(90vh - 122px);
    }

    @include breakpoint(md) {
        &.half{
            width: calc(50% - 5px);
            max-height: 45vh;
        }
    }
}
