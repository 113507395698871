// ==============================================
// AGENDA - Item
// ==============================================


//
//  Configuration
//  _____________________________________________

$agenda-item-tag-gutter:    2px;

.agenda-content-item {
    display: flex;
    min-height: 414px;
    position: relative;
    background-color: $c-light7;
    width: 100%;

    &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $c-grey5;
        bottom: -15px;
        left: 0;
        right: 0;
    }

    @media(min-width: 710px) {
        max-width: 320px;

        &:after {
            height: 100%;
            width: 1px;
            right: -15px;
            left: unset;
            bottom: unset;
        }
        &:nth-of-type(2n):after {
            display: none;
        }
    }

    @media(min-width: 1080px) {
        &:nth-of-type(2n):after {
            display: block;
        }
        &:nth-of-type(3n):after {
            display: none;
        }
    }

    @media(min-width: 1430px) {
        &:nth-of-type(3n):after {
            display: block;
        }
        &:nth-of-type(4n):after {
            display: none;
        }
    }

    &:last-of-type:after {
        display: none;
    }
}


// Tags time/duration & info
.agenda-item__time {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -$agenda-item-tag-gutter;
    margin-right: -$agenda-item-tag-gutter;

    & > div {
        padding: 5px 10px;
        background-color: $c-grey5;
        border-radius: 30px;
        font-size: 1.2rem;
        font-weight: $fw-benton-M;
        line-height: 1.4;
        margin: 0 $agenda-item-tag-gutter $agenda-item-tag-gutter*2;
    }
}


// Description
.agenda-item__time + .agenda-item__description {
    margin-top: $gutter/2;
}

.agenda-item__description {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: keep-all;
    line-height: 26px;
    max-height: 104px;
    margin-bottom: 19px;
}

.agenda-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:after, &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 176px;
        display: block;
    }

    &:after {
        background: linear-gradient(92deg, $c-orange2 16.8%, #ffa08b 81.53%);
    }

    .ct-image-link {
        display: block;
        width: inherit;
        height: 176px;
        z-index: 1;
        position: relative;
    }

    .ct-field-image {
        overflow: hidden;
        height: 176px;
    }

    .ct-image {
        width: 100%;
        height: inherit;
        object-fit: cover;
        transition: .6s;
    }

    &:hover {
        .ct-image {
            transform: scale(1.05);
        }
    }
}

.agenda-information {
    display: flex;
    flex-direction: column;
    padding: 19px 18px;
    font-family: $f-benton, sans-serif;
    height: calc(100% - 176px);
}

.agenda-item__name {
    margin-bottom: 12px;

    .ct-field {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
    }
}

.agenda-item__read-more {
    margin-top: auto;

    .read-more-link {
        display:inline-block;
        border-radius: 60px;
        background-color: $c-dark;
        padding: 10px 21px;
        font-size: 15px;
        line-height: 21px;
        font-weight: 500;
        color: $c-white;
        width: fit-content;
        transition: .3s;

        &:hover {
            background-color: $c-orange2;
        }

        &.disabled {
            pointer-events: none;
            background-color: $c-grey5;
            color: $c-grey3;
        }
    }
}

.agenda-item__sticker {
    position: absolute;
    top: 13px;
    left: 11px;
    background-color: $c-black;
    border-radius: 3px;
    z-index: 1;

    .ct-field {
        font-family: $f-bentonSC, sans-serif;
        padding: 2px 5px;
        color: $c-white;
        font-size: 10px;
        line-height: 14px;
        font-weight: bold;
    }
}

.agenda-item__date {
    position: absolute;
    top: 176px;
    transform: translateY(-100%);
    left: 0;
    background-color: $c-black;
    box-sizing: border-box;
    padding: 13px 27.5px;
    z-index: 1;

    .ct-field {
        font-size: 16px;
        line-height: 106.7%;
        font-family: $f-benton, sans-serif;
        color: $c-white;
        font-weight: bold;

        &:first-of-type {
            margin-bottom: 2px;
        }
    }
}


.agenda-information {

    .atcb_initialized {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
    }

    .atcb_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $gutter-large;
        height: $gutter-large;
        background-color: $c-black;
        border-radius: 100%;
        color: $c-white;
        transition: background-color .2s ease-out;

        &::before {
            @extend %icon-icomoon;
            content: "\e912";
            font-size: 1.5rem;
        }

        &:hover,
        &.active {
            background-color: $c-orange;
            cursor: pointer;
        }

        svg,
        .atcb_text {
            @extend .visually-hidden;
        }

    }

    .atcb_list {
        display: flex !important;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 35px;
        right: -2px;
        min-width: 200px;

        &[style="display: block;"] .atcb_list_item {
            animation-name: movingRightToLeft;
            animation-duration: .6s;
        }

        &[style="display: none;"]  {
            @extend .visually-hidden;
        }
    }

    .atcb_list_item {
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        font-size: 1.2rem;
        padding: 5px 10px;
        margin-bottom: 5px;
        background-color: $c-white;
        border-radius: $radius-btn;
        transition: background-color .2s ease-out, color .2s ease-out;
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.5);

        &:hover {
            cursor: pointer;
            background-color: $c-orange;
            color: $c-white;
        }

        // Label
        .atcb_text {
            white-space: nowrap;
        }

        // Icon
        .atcb_icon {
            margin-left: 5px;
        }

        svg {
            width: 20px;
            height: auto;
        }
    }
}