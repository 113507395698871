// ==============================================
// FORMS - Common
// ==============================================

form {
    legend {
        @extend .title-2;
    }

    label,
    .label {
        display: block;
        font-size: 1.4rem;
        text-align: left;
        font-weight: $fw-benton-M;
        font-family: $f-bentonSC;

        @include breakpoint(lg) {
            font-size: 1.6rem;
        }

        & + select,
        & + textarea {
            margin-top: $gutter/2;
        }
    }

    //Specific to Mes informations form, where the phone-error message is BEFORE the label
    .field input[name$="phone"] ~ label{
        transform: translate(0, -5.5rem) scale(0.8) !important;
        transform-origin: 0 !important;
        @include breakpoint(lg) {
            transform: translate(0, -6.5rem) scale(0.8) !important;
        }
    }

    // Phone field label has to always stay above the input (targeted when input has a name containing phone).
    .field input[name$="phone"] + label{
        color: #828282;
        font-size: 1.4rem !important;
        transform: translate(0, -3rem) scale(0.8) !important;
        transform-origin: 0 !important;

        @include breakpoint(lg) {
            font-size: 1.6rem !important;
            transform: translate(0, -4rem) scale(0.8) !important;
        }
    }

    .required {
        em {
            margin-left: 1px;
            font-style: normal;
        }
    }

    .fieldset {
        border: 0 none;

        + .fieldset {
            margin-top: $gutter;
        }

        // Note
        .note + .field {
            margin-top: 15px;
        }
    }

    .group-2 .field {
        margin-top: $gutter;
    }

    .field {
        position: relative;

        & + .field {
            margin-top: $gutter;
        }

        // Password (with strength meter)
        &.password {
            .control {
                display: flex;
                flex-direction: column;

                .mage-error {
                    order: 2;
                }

                .input-text {
                    order: 0;
                }
            }

            .password-message {
                margin-top: 10px;
                margin-left: 30px;
                font-size: 1.2rem;
                line-height: 1.5;

                @include breakpoint(md) {
                    font-size: 1.4rem;
                }
            }
        }

        // Prefix
        &.field-name-prefix {
            margin-bottom: 20px;

            @include breakpoint(md) {
                margin-bottom: 30px;
            }

            .prefix-choice-item {
                &:first-child {
                    label {
                        margin-left: 0 !important;
                    }
                }

                input[type="radio"] + label {
                    font-size: 1.6rem !important;
                    font-weight: $fw-benton-M;

                    @include breakpoint(only-md) {
                        margin-left: 0;
                    }
                }

                @include breakpoint(only-md) {
                    display: block;

                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }

                    label {
                        margin-left: 0 !important;
                    }
                }
            }
        }

        // Add-on
        &.with-addon {
            .addon {
                display: flex;
                align-items: center;

                input {
                    flex-grow: 1;
                    margin-right: 10px;
                }
            }
        }

        // Captcha
        &.captcha {


            .captcha-image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 10px;

                @include breakpoint(md) {
                    justify-content: normal;
                }
            }

            .captcha-reload {
                display: inline-block;
                width: auto;
                padding: 10px;
            }
        }

        // Required
        &.required,
        &._required {
            & > .label,
            & > .control .label {
                span::after {
                    content:"*";
                }
            }
            & > .control .additional .label {
                span::after {
                    content: initial; 
                }
            }
        }

        // Message Error spacing inserted between field
        + .mage-error {
            margin-bottom: $gutter;
            margin-top: $gutter/2;
        }

        // DOB
        &.date {
            margin-bottom: $gutter;

            > .label {
                margin-bottom: 5px;
                margin-left: 30px;
                font-family: $f-bentonSC;
            }

            .customer-dob {
                position: relative;
            }

            input {
                padding-top: 18px;
                padding-bottom: 18px;
            }

            .ui-datepicker-trigger {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;

                > span {
                    display: none;
                }

                &::before {
                    @extend %icon-icomoon;
                    content: "\e911";
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    font-size: 1.8rem;

                    @include breakpoint(lg) {
                        height: 70px;
                    }
                }
            }
        }

        &.choice {
            margin-top: $gutter-large;
        }

        &.comment {
            margin-top: $gutter-large;
        }

        &.phone{

        }
    }

    // Horizontal multiple fields
    .select-box {
        @include breakpoint(md) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @include gutter-revert(10px);
        }

        > select {
            @include breakpoint(md) {
                flex: 1;
                margin: 0 10px;
            }

            + select {
                @include breakpoint(only-md) {
                    margin-top: 20px;
                }
            }
        }
    }

    // Error
    .mage-error {
        color: $c-error;

        & + .label {
            color: $c-error;
        }
    }

    // Error message only
    div.mage-error  {
        margin-top: $gutter/2;
        font-size:  1.2rem;
        padding-left: $gutter-large;
    }

    // Actions
    .actions-toolbar {
        margin-top: 20px;

        .secondary {
            margin-top: 10px;

            .action.back {
                display: none;
            }
        }

        .remind {
            @extend .main-link;
        }
    }
}

.message + form {
    margin-top: 20px;
}


// Others errors message
.field-error {
    color: $c-error;
    font-size:  1.2rem;
    margin-top: $gutter/2;
    padding-left: $gutter-large;
}
.field-error-mobile {
    font-size:  1.6rem;
    margin-bottom: 1rem;
    text-align:left;
}



//
//  Mixin - Field icon position
//  _____________________________________________

$input-picto-color:     $c-black;

@mixin field-icon {
    color: $input-picto-color;
    position: absolute;
    right: 22px;
    top: 20px;
    font-size: 2.1rem;
    @extend %icon-icomoon;

    @include breakpoint(lg) {
        top: 25px;
    }
}
.field-icon {
    @include field-icon;
}