// ==============================================
// BASE - Links
// ==============================================

//
//  Configuration
//  _____________________________________________

$link-color:              $c-base;


@mixin main-link {
    display: inline-block;
    color: $link-color;
    transition: color .2s;
    border-bottom: 1px solid currentColor;

    &:hover {
        color: $c-orange;
        cursor: pointer;
    }
}

.main-link {
    @include main-link;
}



@mixin link-hover-black {
    display: inline-block;
    text-decoration: none;
    color: $link-color;
    transition: color .2s, border-color .2s;
    border-bottom: 1px solid transparent;

    &:hover,
    &:focus {
        border-bottom-color: currentColor;
        cursor: pointer;
    }
}

.link-hover-black {
    @include link-hover-black;
}


// Link arrow
@mixin link-arrow {
    @include link-hover-black;
    display: inline-block;
    position: relative;
    margin-right: 40px;
    font-weight: $fw-benton-M;

    &::after {
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translateY(-50%);
        transition: right .3s ease-out;
        content: "\e907";
        display: inline-block;
        font-size: 1.8rem;
        vertical-align: middle;
        @include icon-icomoon;
    }

    &:hover::after {
        right: -40px;
    }
}

.link-arrow {
    @include link-arrow;
}


// Link arrow back
@mixin link-arrow-back {
    display: inline-block;
    position: relative;
    margin-left: 30px;

    &::before {
        position: absolute;
        top: 50%;
        left: -30px;
        transition: left .3s ease-out;
        content: "\e907";
        display: inline-block;
        font-size: 1.8rem;
        vertical-align: middle;
        transform: translateY(-50%) scale(-1);
        @include icon-icomoon;
    }

    &:hover::before {
        left: -40px;
    }
}

.link-arrow-back {
    @include link-arrow-back;
}


// Border bottom animation
@mixin animated-border {
    position: relative;
    display: inline;
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 0% 1px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .5s ease-out;
    border-bottom: none;
    white-space: pre-line;

    &:hover {
        background-size: 100% 1px;
        border-bottom: none;
    }
}

.animated-border {
    @include animated-border;
}