// ==============================================
// FONTS IMPORT
// ==============================================

// Benton Sans
@font-face {
    font-family: 'BentonSans';
    src: url('../fonts/benton-sans/BentonSans-Regular.woff2') format('woff2'), url('../fonts/benton-sans/BentonSans-Regular.woff') format('woff'),  url('../fonts/benton-sans/BentonSans-Regular.svg#BentonSans') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans';
    src: url('../fonts/benton-sans/BentonSans-Medium.woff2') format('woff2'), url('../fonts/benton-sans/BentonSans-Medium.woff') format('woff'),  url('../fonts/benton-sans/BentonSans-Medium.svg#BentonSans') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans';
    src: url('../fonts/benton-sans/BentonSans-Bold.woff2') format('woff2'), url('../fonts/benton-sans/BentonSans-Bold.woff') format('woff'),  url('../fonts/benton-sans/BentonSans-Bold.svg#BentonSans') format('svg');
    font-weight: bold;
    font-style: normal;
}

// Benton Sans SC
@font-face {
    font-family: 'BentonSans SC';
    src: url('../fonts/benton-sans/BentonSans-RegularSC.woff2') format('woff2'), url('../fonts/benton-sans/BentonSans-RegularSC.woff') format('woff'),  url('../fonts/benton-sans/BentonSans-RegularSC.svg#BentonSans SC') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans SC';
    src: url('../fonts/benton-sans/BentonSans-MediumSC.woff2') format('woff2'), url('../fonts/benton-sans/BentonSans-MediumSC.woff') format('woff'),  url('../fonts/benton-sans/BentonSans-MediumSC.svg#BentonSans SC') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans SC';
    src: url('../fonts/benton-sans/BentonSans-BoldSC.woff2') format('woff2'), url('../fonts/benton-sans/BentonSans-BoldSC.woff') format('woff'),  url('../fonts/benton-sans/BentonSans-BoldSC.svg#BentonSans SC') format('svg');
    font-weight: bold;
    font-style: normal;
}

// Source Serif Pro
@font-face {
    font-family: 'Source Serif Pro';
    src: url('../fonts/source-serif-pro/source-serif-pro.woff2') format('woff2'), url('../fonts/source-serif-pro/source-serif-pro.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Source Serif Pro';
    src: url('../fonts/source-serif-pro/source-serif-pro-bold.woff2') format('woff2'), url('../fonts/source-serif-pro/source-serif-pro-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}