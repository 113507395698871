// ==============================================
// ONEPAGE - Common
// ==============================================

//
//  Configuration
//  _____________________________________________

$opc-sidebar-width: 30%;
$opc-sidebar-padding: 20px;
$opc-sidebar-margin: 9.55%;

//
//  Global onepage page
//  _____________________________________________

.checkout-index-index {
    background-color: $c-light7;

    &.sticky .page-header {
        background-color: $c-white;
    }

    .page-header {
        margin-bottom: $gutter*2;
        background-color: $c-white;

        @include breakpoint(lg) {
            background: none;
        }

        @include breakpoint(only-lg) {
            position: static;
            top: 0;
            transform: translateY(0);

            &::before {
                display: none;
            }
        }
    }

    .page-main {
        max-width: $container-width;
    }

    .page-title {
        @extend .visually-hidden;
    }

    // Addresses
    .billing-address-details,
    .shipping-information-content,
    .field.addresses {
        line-height: 1.2;
    }

    // Secure payment
    .checkout-secure-payment {
        position: absolute;
        z-index: 10;
        top: 50px;
        right: 20px;
        display: flex;
        align-items: center;

        .checkout-secure-payment-label {
            margin-right: 10px;
        }

        .icon-lock {
            font-size: 2.4rem;
        }

        @include breakpoint(only-lg) {
            top: 30px;
        }
    }

    .payment-mention-block{
        padding-top:20px;
        font-size: 1.4rem;
    }
}

//
//  Estimated Wrapper
//  _____________________________________________

.opc-estimated-wrapper {
    display: none;
    border-bottom: 1px solid $c-light6;
    border-top: 1px solid $c-light6;
    padding: 15px;
}

//
//  Main content
//  _____________________________________________

.opc-wrapper {
    @include breakpoint(lg) {
        float: left;
        width: calc(100% - #{$opc-sidebar-width} - #{$opc-sidebar-margin});
        max-width: 800px;
    }

    // OPC Steps Block
    .opc {
        display: flex;
        flex-direction: column;

        > li {
            margin-bottom: 40px;

            &.checkout-shipping-method {
                order: -1;
            }

            &.checkout-payment-method {}
        }
    }

    // Step Title
    .step-wrapper-title {
        margin-bottom: $gutter-large;

        @include breakpoint(lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom:  $gutter-large/2;
            margin-bottom: $gutter-large/2;
            border-bottom: 1px solid $c-light6;
        }

        .step-title {
            margin-bottom: $gutter;

            @include breakpoint(lg) {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .notice {
            font-size: 1.4rem;
            font-weight: $fw-benton-R;

            @include breakpoint(lg) {
                font-size: 1.6rem;
            }

        }
    }

    .step-title {
        font-size: 2rem;
        font-weight: $fw-benton-B;
        border-bottom: 1px solid $c-light6;
        padding-bottom:  5px;
        margin-bottom: $gutter-large;

        @include breakpoint(lg) {
            font-size: 2.2rem;
            padding-bottom:  $gutter-large/2;
            margin-bottom: $gutter-large/2;
        }
    }
}


// Mobile sticky checkout step cta
@include breakpoint(only-md) {

    .checkout-index-index .page-wrapper {
        padding-bottom: 80px;
    }

    form.methods-shipping,
    form.payments {
        .actions-toolbar {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: $gutter-large/2 $gutter-large;
            background-color: $c-white;
            z-index:11;
        }
    }
}