// ==============================================
// VARIABLES - Panel
// ==============================================

$panel-max-width:                       375px;

$panel-desktop-padding-top-bottom:      15px;
$panel-desktop-padding-left-right:      22px;

$panel-mobile-padding-top-bottom:       20px;
$panel-mobile-padding-left-right:       12px;