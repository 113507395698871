// ==============================================
// SWATCHES
// ==============================================

//
//  Configuration
//  _____________________________________________

$swatch-tooltip-border-color:           lighten($c-black, 80%);
$swatch-tooltip-bg-color:               $c-white;
$swatch-tooltip-text-color:             lighten($c-black, 50%);

.swatch {

    &-opt {
        margin: 20px 0;

        // Bugfix for Add To Cart button
        &-listing {
            margin-bottom: 10px;
        }
    }

    &-attribute {
        & + .swatch-attribute {
            margin-top: 20px;
        }

        // Labels (on full view product)
        &-label {
            position: relative;
            display: inline-flex;
            font-weight: bold;
            margin-bottom: 14px;
        }

        &.required {
            padding-right: 10px;
        }

        &[data-required="1"]::after {
            position: absolute;
            content: '*';
            right: -11px;
            top: -2px;
            color: $c-error;
            font-weight: bold;
            font-size: 1rem;
        }

        // Selected label (on full view product)
        &-selected-option {
            color: #646464;
            padding-left: 16px;
        }

        // Options list
        &-options {
            display: flex;
            flex-wrap: wrap;

            &.size,
            &.manufacturer {
                .swatch-option {
                    background: rgb(240, 240, 240);
                    color: rgb(148, 148, 148);

                    &.selected {
                        color: black;
                        background: #fff;
                        border: 1px solid #fff;
                    }
                }
            }
        }
    }

    // Option item
    &-option {
        position: relative;
        min-width: 30px;
        max-width: 90px;
        height: 20px;
        padding: 1px 2px;
        margin: 0 10px 5px 0;
        text-align: center;
        cursor: pointer;
        border: 1px solid rgb(218, 218, 218);
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: unset;

        &-loading {
            content: url("../images/loader-2.gif");
        }

        &.text {
            background: #F0F0F0;
            color: #686868;
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;
            padding: 4px 8px;
            min-width: 22px;
            margin-right: 6px;

            &.selected {
                background-color: #FFF !important;
            }

            @include only_ie {
                line-height: 1;
            }
        }

        &.selected {
            outline: 2px solid #ff5501;
            border: 1px solid #fff;
            color: #333;
        }

        &:not(.disabled):hover {
            outline: 1px solid #999;
            border: 1px solid #fff;
            color: #333;
        }

        &.image,
        &.color {
            &:not(.disabled):hover {
                outline: 2px solid #ee0000;
                border: 1px solid #fff;
            }
        }

        &.disabled {
            cursor: default;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: linear-gradient(to left top,
                    rgba(255,255,255,0) 0%,
                    rgba(255,255,255,0) 42%,
                    rgba(255,255,255,1) 43%,
                    rgba(255,255,255,1) 46%,
                    rgba(255,82,22,1) 47%,
                    rgba(255,82,22,1) 53%,
                    rgba(255,255,255,1) 54%,
                    rgba(255,255,255,1) 57%,
                    rgba(255,255,255,0) 58%,
                    rgba(255,255,255,0) 100%
                );
            }
        }

        &-disabled {
            outline: none !important;
            cursor: default;
            border: 0;

            &::after {
                content: '';
                position: absolute;
                width: 42px;
                height: 2px;
                background: red;
                transform: rotate(-30deg);
                z-index: 995;
                left: -4px;
                top: 10px;
            }
        }

        //
        //  Tooltip
        //  _____________________________________________

        &-tooltip {
            position: absolute;
            display: none;
            max-width: 140px;
            /*max-height: 160px;*/
            max-height: 100%;
            min-height: 20px;
            min-width: 20px;
            padding: 5px;
            background: $swatch-tooltip-bg-color;
            color: rgb(148, 148, 148);
            border: 1px solid $swatch-tooltip-border-color;
            text-align: center;
            z-index: 999;

            &,
            &-layered {
                // Bottom arrow
                .corner {
                    position: absolute;
                    left: 0;
                    transform: translateY(100%);
                    bottom: 3px;
                    height: 8px;

                    &::before,
                    &::after {
                        content: '';
                        position: relative;
                        height: 0;
                        width: 0;
                        border-style: solid;
                        font-size: 1px;
                    }

                    // Arrow fill
                    &::after {
                        top: -1px;
                        left: -15px;
                        border-width: 7px 7.5px 0 7.5px;
                        border-color: $swatch-tooltip-bg-color transparent transparent transparent;
                    }

                    // Arrow bottom border
                    &::before {
                        top: 0;
                        left: 0;
                        border-width: 8px 8.5px 0 8.5px;
                        border-color: $swatch-tooltip-border-color transparent transparent transparent;
                    }
                }

                .image {
                    display: block;
                    height: 130px;
                    width: 130px;
                    margin: 0 auto;
                }
            }

            &-layered {
                display: none;
                position: absolute;
                width: 140px;
                left: -47px;
                background: #fff;
                color: rgb(148, 148, 148);
                border: 1px solid lighten($c-black, 15%);
                z-index: 999;

                .title {
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    bottom: -5px;
                    left: 0;
                    color: rgb(40, 40, 40);
                    text-align: center;
                    margin-bottom: 10px;
                }
            }

            // Tooltip text
            .title {
                max-width: 140px;
                min-height: 20px;
                max-height: 200px;
                color: $swatch-tooltip-text-color;
                text-align: center;
                display: block;
                overflow: hidden;
            }
        }

        /* Layered Features */
        &-link-layered {
            position: relative;
            margin: 0 !important;
            padding: 0 !important;

            &:focus > div {
                box-shadow: 0 0 3px 1px #68a8e0;
            }

            &:hover > .swatch-option-tooltip-layered {
                display: block;
            }
        }
    }

    &-more {
        display: inline-block;
        position: static;
        margin: 2px 0;
        text-decoration: none !important;
        z-index: 1;
    }

    &-visual-tooltip-layered {
        height: 160px;
        top: -170px;
    }

    &-textual-tooltip-layered {
        height: 30px;
        top: -40px;
    }

    &-input {
        @extend .visually-hidden;
    }
}

















