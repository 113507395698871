// ==============================================
// ACCOUNT - Dashboard
// ==============================================

.customer-account-index {

    //
    //  Dashboard
    //  _____________________________________________

    .column.main {
        @include breakpoint(only-md) {
            margin-bottom: 30px;
        }
    }
}
