// ==============================================
// HEADER - Menu
// ==============================================

//
//  Configuration
//  _____________________________________________
:root {
    //iOS fix to a 100vh nav
    --app-height: 100%;
}

$nav-section-mobile-position-top: 0;
$nav-section-mobile-width: 100vw;
$nav-section-mobile-height: 100vh;
$nav-sections-bg-color: $c-white;

$nav-link-hover-bg-color: lighten($c-black, 80%);
$nav-link-border-color: $c-black;

$main-menu-link-desktop-padding: 10px 15px;
$main-menu-link-mobile-padding: 13px 25px;

$submenu-desktop-min-width: 230px;
$submenu-desktop-bg-color: $nav-sections-bg-color;
$submenu-desktop-border-color: #d1d1d1;
$submenu-mobile-bg-color: $nav-sections-bg-color;
$submenu-mobile-item-border-color: $input-border-color;

$submenu-container-xs-padding: 15px;
$submenu-container-padding: 25px;
$submenu-container-desktop-padding: 35px;
$submenu-link-desktop-padding: 5px 0;
$submenu-link-mobile-padding: 6px 10px;
$submenu-link-mobile-font-size: 1.4rem;
$submenu-link-mobile-line-height: 1.4;

$push-menu-width-xs-img: 115px;
$push-menu-width-img: 125px;
$push-menu-width-img-desktop: 115px;

//
//  Magento default
//  _____________________________________________

.nav-sections-item-title {
    @extend .visually-hidden;
}

//
//  Common devices
//  _____________________________________________

// Navigation logo
.navigation-wrapper__logo {
    margin-bottom: 20px;

    img {
        width: 70px;
    }
}

// Navigation common elements
.nav-sections .navigation::-webkit-scrollbar,
.nav-sections::-webkit-scrollbar,
.nav-sections .submenu::-webkit-scrollbar {
    display: none;
  }

.nav-sections,
.nav-sections .submenu {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.nav-sections .navigation > ul li {

    // Highlited
    &.highlited {
        & > a span {
            color: $c-orange;
        }
    }

    // Nav item label tags (Using flex properties to change order tags elements)
    & > a {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .submenu-wrapper li > a {

        span {
            order: 1;
            margin-right: $gutter;
        }

        .label-flag {
            @extend .tag-item;
            @extend .black-tag;
            order: 2;
            font-size: 1.2rem;
        }
    }

    // Element with border bottom
    &.bottom-separator {
        &::after {
            display: block;
            content:"";
            padding-bottom: $gutter/2;
            border-bottom: 1px solid $c-brown-grey;
            margin-bottom: 15px;

            @include breakpoint(only-lg) {
                margin-left: $submenu-container-padding;
                margin-right: $submenu-container-padding;
            }
        }
    }

    // Pro Area
    &.bottom-separator + .pro-area-label {
        &::before {
            display: none;
        }
    }
    &.pro-area-label {
        &::before {
            display: block;
            content:"";
            padding-top: 8px;
            border-top: 1px solid $c-brown-grey;
            margin-top: 15px;
            @include breakpoint(only-lg) {
                margin-left: 25px;
                margin-right: 25px;
            }
        }

        span {
            display: block;
            padding: $main-menu-link-mobile-padding;
            font-size: 1.2rem;
            line-height: 1.6;
            text-transform: uppercase;
            color: $c-pro-area;

            @include breakpoint(lg) {
                font-size: 1.4rem;
                line-height: 1.9;
                padding: $gutter/2 0;
            }
        }
    }

    &.pro-space {

    }

}


// Push collections
.push-products-menu,
.menu-acm-content {
    > li {
        padding-bottom: 10px;
        border-bottom: 1px solid $input-border-color;

        @include breakpoint(only-lg) {
            margin-left: $submenu-container-padding;
            margin-right: $submenu-container-padding;
        }

        @include breakpoint(lg) {
            @include animated-block-border;
        }

        & + li {
            margin-top: 10px;
        }

        // Inner content
        .product-item__inner,
        .content-item__inner {
            display: grid;
            grid-template-columns: 150px auto;
            background-color: $c-white;
            padding: $gutter/2;

            @include breakpoint(lg) {
                padding-bottom: 20px;
            }
        }

        // Metas
        .product-item-metas {
            margin-bottom: 0;
        }

        // Image
        .product-item-photo {
            grid-column: 1;
            grid-row: 1 / 3;
            padding: 0;
            max-width: 150px;

            .hover-classic {
                left: 0;
                right: 0;
                bottom: auto;
            }
        }

        .content-item__inner > img {
            grid-column: 1;
            grid-row: 1 / 4;
        }

        // Common cta
        .cta > a,
        .action.tocart {
            @extend .btn-smaller;
            min-width: 110px;

            &::before,
            &::after {
                font-size: 1.8rem;
            }
        }
    }
}

// Push collection's products
.push-products-menu {

    > li {

        .product-item__inner {
            &::before {
                display: none;
            }

            .product-item-link {
                margin-bottom: 0;
            }

            .product-item-metas {
                display: none;
            }
        }

        form[data-role="tocart-form"] {
            order: 99;
            margin-top: 10px;
        }

        .price-box {
            margin-top: 15px;
        }

        // Image
        .product-item-photo {
            margin-bottom: 0;

            img {
                max-width: 100%;
            }
        }

        // Product name
        .product-item-name {
            font-size: 1.4rem;

            @include breakpoint(lg) {
                font-size: 1.5rem;
            }
        }

        // Remove border animation on push product menu
        .product-item-details,
        .product-item-link > * {
            &::before {
                display: none;
            }
        }


        .action {
            width: auto;
            margin-top: 10px;

            &.tocart {
                // Hide cta labels & old price
                .price-label,
                .old-price {
                    @extend .visually-hidden;
                }

                .price-box {
                    display: inline-block;
                }

            }
        }
    }
}

// Push collection's content
.menu-acm-content {

    .content-item__inner {
        display: flex;
        flex-direction: column;

        @include breakpoint(lg) {
            padding-left: $push-menu-width-img-desktop+20px;
            padding-bottom: 20px;
        }

        // Order item

        & > a {
            order: 1;
        }
        .text {
            order: 2;
        }
        .title {
            order: 3;
        }
        .cta {
            order: 4;
        }

        // Image
        & > a {
            overflow: hidden;

            img {
                object-fit: cover;
                object-position: center;
            }
        }

        // Text & title
        .text,
        .title {
            display: block;
            font-size: 1.4rem;
            line-height: 1.45;

            @include breakpoint(lg) {
                font-size: 1.5rem;
            }
        }

        // Text
        .text {
            // Reset Milople lightbox
            padding: 0;
        }

        // Title
        .title {
            font-weight: $fw-benton-M;
        }

        // Cta
        .cta {
            margin-top: 10px;

            a {
                padding-right: 10px;
                padding-left: 13px;

                @media (max-width: 369px) {
                    font-size: 1rem;
                    padding-right: 4px;
                    padding-left: 10px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }
    }
}



//
//  Desktop
//  _____________________________________________

@include breakpoint(lg){

    .nav-sections {
        position: fixed;
        top: $nav-section-mobile-position-top;
        bottom: 0;
        left: -100%;
        width: 100%;
        max-width: $nav-section-mobile-width;
        height: $nav-section-mobile-height;
        height: var(--app-height);
        transition: left .3s ease-in;
        z-index: 100;

        &.is-active {
            left: 0;
        }

        .nav-sections-items {
            position: relative;
            height: 100%;
            width: 34vw;
            background-color: $nav-sections-bg-color;
            //overflow-y: auto;
            //overflow-x: hidden;
        }

        .nav-sections-item-content {
            padding: $submenu-container-desktop-padding*2 $submenu-container-desktop-padding;
        }

        // Submenu logo
        .navigation-wrapper__logo {
        }

        // Close cta menu
        &.level1-active .mdp-header-menu-close {
            left: 34vw + 33vw;
        }
        &.level2-active .mdp-header-menu-close {
            left: 100vw;
        }

        .mdp-header-menu-close {
            position: absolute;
            top: 70px;
            left: 34vw;
            padding: 10px;
            transform: translateX(calc(-100% - #{$gutter} ));
            transition: left .3s linear;
            cursor: pointer;
            border-radius:30px;
            transition: all .3s linear;
            
            &:hover {
                cursor: pointer;
            }

            &:focus {
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
            }
        }


        // Content navigation
        .navigation {
            overflow-y: auto;
            max-height: calc(100vh - 200px);
            padding-right: $gutter-large;

            // Dropdown - Level 0

            li.level0 {
                &.is-active > .submenu-wrapper {
                    display: block;
                    animation-name: movingTopToBottom;
                    animation-duration: .5s;
                }

                > .submenu-wrapper {
                    display: none;
                }
            }


            // Switch Panel - Submenu under level 0
            li.level1 {

                .submenu-wrapper {
                    padding-left: $submenu-container-desktop-padding;
                    padding-right: $submenu-container-desktop-padding;
                }
            }

            .level0.submenu-wrapper {

                // Common submenu animations
                .is-active > .submenu-wrapper {
                    visibility: visible;
                    transform: translate(0);
                    transition: transform .5s ease-out;
                    opacity: 1;

                    &.level1,
                    &.level2 {
                        transform: translate(0);
                    }
                }

                .submenu-wrapper {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    padding-top: 162px;
                    min-width: $submenu-desktop-min-width;
                    visibility: hidden;
                    opacity: 0;
                    transform: translateX(-100%);
                    background-color: $submenu-desktop-bg-color;

                    &.level0 {
                        width: 34vw;
                        padding-top: 0;
                        z-index: 10;
                    }
                    &.level1 {
                        width: 33vw;
                        left: 34vw;
                        transform: translateX(-($submenu-container-desktop-padding - 10px));
                        z-index: -1;
                    }
                    &.level2 {
                        width: 33vw;
                        left: 33vw;
                        transform: translateX(-($submenu-container-desktop-padding - 10px));
                        z-index: -1;
                    }
                }

                .submenu {
                    max-height: calc(100% - 220px);
                    overflow-y: auto;
                    width: 100%;
                    padding-top: $gutter/2;
                    padding-bottom: $gutter-large;
                }
            }


            // See all category link
            .all-category {

            }

            // LEVEL 1 - Main menu
            > ul {

                li {
                    span{
                        transition: all .3s ease-out !important;
                    }
                    // Level 1 color hover effect
                    &:not(.is-active){
                        &.universe-yellow > a span:hover,
                        &.universe-yellow > a:focus span{
                            color: $c-yellow;
                        }
                        &.universe-green > a span:hover,
                        &.universe-green > a:focus span{
                            color: $c-green;
                        }
                        &.universe-blue > a span:hover,
                        &.universe-blue > a:focus span{
                            color: $c-blue2;
                        }
                    }

                    // Set end of animation link on parent hover
                    &.is-active {
                        & > a span {
                            border-bottom-color: currentColor;

                            &::before {
                                right: 0;
                            }
                        }
                    }

                    > a {
                        padding: $submenu-link-desktop-padding;
                        font-size: 1.8rem;
                        line-height: 1.4;

                        // Set begin of animation link on parent hover
                        &:hover span::before {
                            right: 0;
                        }

                        span {
                            @include animated-border();
                        }
                    }

                    &.universe-yellow > a span {
                        background-image: linear-gradient($c-yellow, $c-yellow);
                    }
                    &.universe-green > a span {
                        background-image: linear-gradient($c-green, $c-green);
                    }
                    &.universe-blue > a span {
                        background-image: linear-gradient($c-blue2, $c-blue2);
                    }
                }

                > li {

                    // Category links level0
                    &.is-active > a span {
                        background-size: 100% 3px;
                    }
                    > a {
                        font-size: 2rem;
                        font-weight: $fw-benton-B;
                        line-height: 1.8;

                        span {
                            background-size: 0% 3px;

                            &:hover {
                                background-size: 100% 3px;
                            }
                        }
                    }

                    // LEVEL 2 - Submenu
                    > .submenu-wrapper {

                        > ul > li {

                            > a {
                                text-align: left;

                                &:focus > span{
                                    font-weight: $fw-benton-B;
                                }
                                
                                > span{
                                    background: 0 0 transparent;
                                    
                                    &:hover {
                                        background: 0 0 transparent;
                                        font-weight: $fw-benton-B;
                                    }
                                }
                            }

                            // LEVEL 3 - Sub submenu
                            > .submenu-wrapper {
                                > ul > li {
                                    > a {

                                        &:focus > span{
                                            font-weight: $fw-benton-B;
                                        }

                                        > span{
                                            background: 0 0 transparent;
                                            
                                            &:hover {
                                                background: 0 0 transparent;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Highlited
                    &.highlited {
                        margin-left: $gutter/2;
                        padding-left: $gutter;

                        & > a {
                            position: relative;

                            &::before {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: -$gutter;
                                content: "";
                                height: $gutter;
                                border-left: 1px solid $input-border-color;
                            }
                        }

                        & > a span {
                            margin-right: 0;
                        }
                    }

                    &.universe-yellow {

                        // LEVEL 2 - Submenu
                        > .submenu-wrapper > ul > li {

                            > a > span:hover,
                            > a:focus > span {
                                font-weight: $fw-benton-B;
                                color: $c-yellow;
                            }

                            // LEVEL 3 - Sub submenu
                            > .submenu-wrapper > ul > li > a > span:hover,
                            > .submenu-wrapper > ul > li > a:focus > span {
                                font-weight: $fw-benton-B;
                                color: $c-yellow;
                            }
                        }
                    }

                    &.universe-green {

                        // LEVEL 2 - Submenu
                        > .submenu-wrapper > ul > li {

                            > a > span:hover,
                            > a:focus > span {
                                font-weight: $fw-benton-B;
                                color: $c-green;
                            }

                            // LEVEL 3 - Sub submenu
                            > .submenu-wrapper > ul > li > a > span:hover,
                            > .submenu-wrapper > ul > li > a:focus > span {
                                font-weight: $fw-benton-B;
                                color: $c-green;
                            }
                        }
                    }

                    &.universe-blue {

                        // LEVEL 2 - Submenu
                        > .submenu-wrapper > ul > li {

                            > a > span:hover,
                            > a:focus > span {
                                font-weight: $fw-benton-B;
                                color: $c-blue2;
                            }

                            // LEVEL 3 - Sub submenu
                            > .submenu-wrapper > ul > li > a > span:hover,
                            > .submenu-wrapper > ul > li > a:focus > span {
                                font-weight: $fw-benton-B;
                                color: $c-blue2;
                            }
                        }
                    }
                }
            }
        }

        .panel.wrapper {
            padding-left: 34px;
            padding-right: 34px;
        }
    }
}


//
//  Tablets & Mobiles
//  _____________________________________________

@include breakpoint(only-lg){

    .nav-sections {
        position: fixed;
        top: $nav-section-mobile-position-top;
        bottom: 0;
        left: -100%;
        width: 100%;
        max-width: $nav-section-mobile-width;
        height: $nav-section-mobile-height;
        height: var(--app-height);
        background-color: $nav-sections-bg-color;
        overflow-y: auto;
        overflow-x: hidden;
        transition: left .3s ease-in;
        z-index: 100;

        &.is-active {
            left: 0;
            padding-bottom: 100px;

            .mdp-header-menu-close {
                display: block;
            }
        }

        // Navigation close cta
        .mdp-header-menu-close {
            display: none;
            position: absolute;
            top: 12px;
            right: 18px;
            z-index: 999;
            font-size: 1.6rem;
            padding: 12px;
            color: $c-base;
        }

        &.stop-overflow {
            overflow-y: hidden;
        }

        .nav-sections-item-content {
            margin-bottom: 10px;
        }

        .navigation-wrapper__logo {
            position: relative;
            z-index: 20;
            padding: 20px $submenu-container-padding 10px;
            background-color: $nav-sections-bg-color;

            // Use to hide logo under level2
            &.hidden {
                position: absolute;
                padding: 0;
            }
        }

        .navigation {

            // Previous nav item

            .level1 .previous-nav {
                padding: $submenu-container-padding;
                margin-bottom: 80px;
                
            }

            .previous-nav {
                display: flex;
                align-items: center;
                padding: 10px $submenu-container-padding 30px;
                font-weight: $fw-benton-M;
                font-size: 1.4rem;
                cursor: pointer;

                &::before {
                    content: "\e907";
                    font-size: 1.8rem;
                    transform: scale(-1);
                    @include icon-icomoon;
                    margin-right: 10px;
                }
            }

            > ul {


                // Category links level0
                & > li.level0 > a {
                    font-size: 2rem;
                    font-weight: $fw-benton-B;
                    line-height: 1.5;
                }

                // Common
                li {

                    span{
                        transition: all .3s ease-out !important;
                    }
                    
                    // Level 1 color hover effect
                    &:not(.is-active){
                        > a span:hover{
                            background: 0 0;
                        }
                        &.universe-yellow > a span:hover{
                            color: $c-yellow;
                        }
                        &.universe-green > a span:hover{
                            color: $c-green;
                        }
                        &.universe-blue > a span:hover{
                            color: $c-blue2;
                        }
                    }

                    > a {
                        padding: $main-menu-link-mobile-padding;
                        font-size: 1.6rem;
                        font-weight: $fw-benton-M;
                        line-height: 1.4;
                    }

                    // Submenu
                    &.is-active > .submenu-wrapper {
                        transform: translateX(0);
                    }

                    .submenu-wrapper {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        overflow: hidden;
                        transform: translateX(-100%);
                        transition: transform .3s ease-in;
                        z-index: 10;
                        background-color: $submenu-mobile-bg-color;

                        // Height
                        height: $nav-section-mobile-height;
                        height: var(--app-height);

                        &.init-overflow {
                            //overflow: auto;
                            position: fixed;

                            > li a,
                            > li.previous-nav,
                            > li.mdpmenu-title,
                            > li.pro-area-label {
                                position: static;
                                opacity: 1;
                            }
                        }

                        > li a,
                        > li.previous-nav,
                        > li.mdpmenu-title,
                        > li.pro-area-label {
                            position: absolute;
                            opacity: 0;
                        }
                    }

                    .submenu {
                        max-height: calc(100% - 235px);
                        overflow: auto;

                        // First submenu
                        &.level0 {
                            max-height: calc(100% - 125px);
                        }
                    }
                }

                // LEVEL 1 - Main menu
                & > li {

                    // LEVEL 2 - Submenu

                    & > .submenu-wrapper {
                        height: $nav-section-mobile-height;
                        height: var(--app-height);
                        padding-top: 150px;


                        & > li {

                            // LEVEL 3 & 4 - Submenu
                            .submenu-wrapper {
                                top: 0;
                                padding-top: $submenu-container-padding;

                                .previous-nav {
                                    margin-bottom: 90px;
                                }
                            }
                        }
                    }

                    &.universe-yellow {

                        // LEVEL 2 - Submenu
                        > .submenu-wrapper > ul > li {

                            > a > span:hover {
                                font-weight: $fw-benton-B;
                                color: $c-yellow;
                            }

                            // LEVEL 3 - Sub submenu
                            > .submenu-wrapper > ul > li > a > span:hover {
                                font-weight: $fw-benton-B;
                                color: $c-yellow;
                            }
                        }
                    }

                    &.universe-green {

                        // LEVEL 2 - Submenu
                        > .submenu-wrapper > ul > li {

                            > a > span:hover {
                                font-weight: $fw-benton-B;
                                color: $c-green;
                            }

                            // LEVEL 3 - Sub submenu
                            > .submenu-wrapper > ul > li > a > span:hover {
                                font-weight: $fw-benton-B;
                                color: $c-green;
                            }
                        }
                    }

                    &.universe-blue {

                        // LEVEL 2 - Submenu
                        > .submenu-wrapper > ul > li {

                            > a > span:hover {
                                font-weight: $fw-benton-B;
                                color: $c-blue2;
                            }

                            // LEVEL 3 - Sub submenu
                            > .submenu-wrapper > ul > li > a > span:hover {
                                font-weight: $fw-benton-B;
                                color: $c-blue2;
                            }
                        }
                    }
                }
            }
        }

        .panel.wrapper {
            padding-left: $submenu-container-padding;
            padding-right: $submenu-container-padding;

            & > .header {
                padding-top: 33px;
                border-top: 1px solid $submenu-mobile-item-border-color;
            }

            .actions.dropdown > .toggle {
                padding-left: 0;
            }
        }

        .actions.dropdown > ul.dropdown {
            background-color: transparent;
        }
    }
}


//
//  Components
//  _____________________________________________


//  See Link
.see-link,
.mdpmenu-title {
    display: block;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;

    @include breakpoint(only-lg) {
        padding: $main-menu-link-mobile-padding;
    }

    span {
        font-size: 2rem;
        font-weight: $fw-benton-B;
        line-height: 1.8;
        border-bottom: 3px solid $c-brown-grey;
    }
}

.universe-yellow {
    .see-link,
    .mdpmenu-title {
        span {
            border-bottom-color: $c-yellow;
        }
    }
}

.universe-green {
    .see-link,
    .mdpmenu-title {
        span {
            border-bottom-color: $c-green;
        }
    }
}

.universe-blue {
    .see-link,
    .mdpmenu-title {
        span {
            border-bottom-color: $c-blue2;
        }
    }
}


.mdpmenu-title {
    display: block;

    @include breakpoint(lg) {
        display: none;
    }
}