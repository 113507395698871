// ==============================================
// LAYOUT - Content
// ==============================================

//
//  Containers
//  _____________________________________________

.container {
    width: 100%;
    max-width: $container-width-xl;
    padding: 0 $gutter;
    margin: 0 auto;

    @include breakpoint(lg) {
        padding: 0 $gutter-large*2;
    }

    @media screen and (min-width: $ultra-large){
        padding: 0 $gutter-large*4;
    }
}

.page-main {
    @extend .container;
    position: relative;
    min-height: 60vh;
}


// Reset page main max width
.cms-home {

    .page-main {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

    .page-title-wrapper {
        @extend .visually-hidden;
    }

    .columns {
        margin-top: 0;
        margin-bottom: 0;
    }
}
