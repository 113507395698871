// ==============================================
// AMASTY - COOKIE
// ==============================================

//
//  Configuration
//  _____________________________________________

.amgdprcookie-table th,
.amgdprcookie-table td {
    padding: 5px 10px;
    text-align: left;
    line-height: 1.3;
}

.amgdprcookie-table th {
    font-weight: bold;
}

.amgdprcookie-link {
    cursor: pointer;
}

.amgdprcookie-row {
    padding-bottom: 10px;
}

.amgdpr-block-content .amgdpr-cookie-link {
    margin-top: 10px;
}

body .gdpr-cookie-modal_setup-modal {
    .amgdprcookie-done {
        margin: 15px;
    }
}

body .amgdprcookie-modal-container .modal-inner-wrap,
body .amgdprcookie-groups-modal .modal-inner-wrap {
    width: 670px;
    max-width: 100%;
    border-radius: 0;
    padding: 0;
    max-height: 65vh;
    height: auto;

    .subtitle-1 {
        margin-bottom: 0;
    }

    .amgdprcookie-modal-block {
        position: relative;
        padding-top: 60px;
    }

    .amgdprcookie-cookie-container .amgdprcookie-options {
        display: block;
        color: #389dfe;
        text-align: right;
        text-transform: capitalize;
        letter-spacing: .3px;
        font-size: 1.2rem;
        margin-top: 0;
        padding: 0;
        background: none;

        &::before,
        &::after {
            content: none;
        }
    }

    .action-close:not(.refuse-close) {
        display: none;
    }

    .refuse-close {
        position: absolute;
        width: auto;
        top: 4rem;
        right: 3rem;
        text-decoration: underline;
        cursor: pointer;

        &::after {
            font-size: 1.2rem;
            @extend [class^="icon-"];
            content: " \e907";
            text-decoration: none;
        }
    }

    .modal-content {
        padding: $gutter $gutter/2;
        margin-bottom: 0;

        @include breakpoint(md) {
            padding: 25px $gutter-large;
        }

    }
    .amgdprcookie-button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .amgdprcookie-button {

            &.-save {
                margin-left: $gutter/2;

                @include breakpoint(sm) {
                    margin-left: $gutter;
                }
            }

            &.-decline {
                margin-left: $gutter/2;
                background-color: $c-error;
                border-color: $c-error;

                @include breakpoint(sm) {
                    margin-left: auto;
                }

                &:hover, &:focus{
                    color: $c-white;
                    background-color: $c-error;
                    border-color: $c-error;
                }
            }

            &.-save{
                background-color: $c-success;
                border-color: $c-success;

                &:hover:not(:disabled), &:focus:not(:disabled){
                    background-color: $c-success;
                    border-color: $c-success;
                }
            }
        }

        .amgdprcookie-button-partner {
            //order: 1;
            padding: 0;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            background: unset !important;
            border: unset;
            border-bottom: 1px solid;
            border-radius: 0;
            box-shadow: unset !important;
            @extend .main-link;
        }
    }

    .amgdprcookie-button,
    .amgdprcookie-buttons-block button {
        @extend .btn;



        &--border {
            @extend .btn--border;
        }

        &.-allow,
        &.amgdprcookie-done{
            background-color: $c-success;
            border-color: $c-success;

            &:hover:not(:disabled), &:focus:not(:disabled){
                background-color: $c-success;
                border-color: $c-success;
            }
        }
    }

    .amgdprcookie-policy-container {
        box-shadow: none;
        padding: 0;
        margin: 0 0 5px;

        .amgdprcookie-text-container {
            max-height: 39vh;
            overflow-y: auto;
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
    .amgdprcookie-groups-container {
        display: none;
    }

    .amgdprcookie-groups-container,
    .amgdprcookie-cookie-container {
        box-shadow: none;
        overflow-y: auto;
        height: auto !important;

        input[type="checkbox"] + label::before {
            content: none;
        }

        input[type="checkbox"] + label::after {
            opacity: 1;
            transform: none;
        }

        .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled])+.amgdprcookie-label {
            background-color: $c-success;
        }
    }

    .visible {
        display: block;

        .amgdprcookie-cookie-container {
            overflow-y: initial;
        }
    }

    .amgdprcookie-buttons-block {
        background-color: $c-white;
        display: flex;
        justify-content: space-between;
        padding-top: 0;
        margin-top: 10px;

        &.-settings {
            justify-content: flex-end;
        }

        .amgdprcookie-button.-save {
            margin-left: 0;
        }

        .amgdprcookie-button,
        .amgdprcookie-button.-save {
            flex-basis: auto;
        }

        .amgdprcookie-button {
            letter-spacing: 1.35px;
            text-transform: uppercase;
            &.-param {
                color: #000;
                background-color: transparent;
                border-color: #000;
                &:hover {
                    color: #FFF;
                    background-color: #000;
                    border-color: #000;
                }
            }
            &.-settings {
                background-color: #68AC92;
                border-color: #68AC92;
                &:hover {
                    background-color: #68AC92;
                    border-color: #68AC92;
                }
            }
        }
    }

    .amgdprcookie-modal-block.is-open {
        .amgdprcookie-buttons-block {
            padding-top: 0;
            margin-bottom: 15px;
        }
    }

    // Partner list
    .amgdprcookie-partner-container {
        display: none;
        margin-top: $gutter-large;
        margin-bottom: $gutter-large;
        text-align: left;

        &.-show {
            display: block;
            animation-name: movingTopToBottom;
            animation-duration: .5s;
        }

        p,
        ul, li {
            line-height: 1.4;
        }

        ul {
            @extend .list-1;
        }
    }
}
// footer in all pages
.amgdprcookie-button-partner-infooter {
    order: 1;
    @extend .main-link;
}
.amgdprcookie-partner-container-infooter {
    display: none;
    margin-top: $gutter-large;
    margin-bottom: $gutter-large;
    text-align: left;

    &.-show {
        display: block;
        animation-name: movingTopToBottom;
        animation-duration: .5s;
    }

    p,
    ul, li {
        line-height: 1.4;
    }

    ul {
        @extend .list-1;
    }
}


//gdpr page
.gdpr-customer-settings {
    button.action.primary {
        @extend .btn;
    }

    .amgdpr-link,
    .amasty-gdpr-consent a {
        @extend .main-link;
        margin-bottom: 10px;
    }

    .policy-content,
    .amasty-gdpr-consent {
        margin-bottom: 15px;
    }

    .field:not(.search):not(.qty):not(.choice):not(.field-select-billing):not(.newsletter):not(.amcoupons-input):not(.check):not(.choice):not(.field-select-billing) label {
        //margin-left: 0;
    }
}

.amasty-gdpr-consent .action a {
    @extend .main-link;
}


//fix inline overwrite on chrome for link color
.amgdprcookie-modal-template,
.amgdprcookie-groups-modal{
    .amgdprcookie-cookie-container .amgdprcookie-link{
        color: #0990DF;
        text-transform:none;
    }
}

.amgdprcookie-table{
    .amgdprcookie-cell.-name{
        line-break: anywhere;
    }
}

.amgdprcookie-main-wrapper{
    padding-bottom:40px !important;
}


.amgdprcookie-groups-modal {
    .modal-title {
        margin-top: 20px;
    }
    &.modal-popup .modal-content {
        max-height: calc(65vh - 192px);
    }
    .amgdprcookie-table {
        font-size: 14px;
    }
}
