// ==============================================
// UI COMPONENTS - Datepicker
// ==============================================

.ui-datepicker {
    display: none;
    z-index: 9;
    width: auto;
    min-width: 270px;
    padding: 15px;
    border: $c-white 4px solid;
    box-sizing: border-box;
    background: $c-light7;
    box-shadow: 5px 5px 10px 0px rgba($c-black, 0.35);

    .ui-datepicker-header {
        position: relative;
        padding: .2em 0 10px;
        background: transparent;
        border: none;
    }

    .ui-datepicker-title {
        display: flex;
        margin: 0 32px;
        text-align: center;

        .ui-datepicker-month {
            width: 47%;
            margin-right: 5%;
        }

        .ui-datepicker-year {
            width: 48%; // 2000 / 2006... don't fit in less
        }

        select {
            padding: 4px 25px 5px 10px;
            margin: 1px 0;
        }
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        position: absolute;
        top: 5px;
        width: 32px;
        height: 32px;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -8px;
            top: 50%;
            margin-top: -5px;
            font-size: 0px;
            width: 0px;
            height: 0px;
            border: solid;
            border-width: 5px 8px 5px 0;
            border-color: transparent $c-orange transparent transparent;
        }
    }

    .ui-datepicker-next-hover,
    .ui-datepicker-prev-hover {
        span {
            border-color: transparent $c-orange transparent transparent;
        }
    }

    .ui-datepicker-prev {
        left: 2px;
    }

    .ui-datepicker-next {
        right: 2px;

        span {
            margin-left: 0px;
            top: 50%;
            margin-top: -5px;
            border-width: 5px 0 5px 8px;
            border-color: transparent transparent transparent $c-orange;
        }
    }

    table {
        width: 100%;
        font-size: 1.4rem;
    }

    .ui-datepicker-buttonpane {
        overflow: hidden;
        padding-top: 10px;
        border-top: $c-light6 1px solid;
        text-align: right;

        button {
            @extend .btn--border;
            @extend .btn-light;


            &.ui-datepicker-current {
                float: left;
            }
        }
    }

    .ui-datepicker-calendar {
        background: $c-white;
        border: $c-light6 1px solid;
        border-collapse: collapse;

        .ui-state-disabled {
            background: #f9f9f9;

            span {
                color: #959595;
            }
        }

        td {
            border: $c-light6 1px solid;

            span,
            a {
                display: block;
                padding: 8px 5px 5px;
                text-align: center;
                text-decoration: none;
                color: $c-black;
            }

            .ui-state-hover {
                background: $c-black;
                color: $c-white;
            }
        }

        th {
            background: $c-light7;
            border: $c-light6 1px solid;
            padding: 10px 5px 6px;
            text-transform: uppercase;
        }

        .ui-datepicker-week-col {
            text-align: center;
            border: $c-light6 1px solid;
        }

        .ui-datepicker-today {
            background: lighten($c-orange, 10%);

            span {
                color: $c-white;
            }
        }

        .ui-state-active {
            background: $c-gold2;
        }
    }
}

.ui-timepicker-div {
    padding: 10px 0 5px 0;

    .ui-widget-header {
        margin-bottom: 8px;
    }

    dl {
        text-align: left;

        dt {
            height: 25px;
            margin-bottom: -22px;
        }

        .ui_tpicker_time_label {
            margin-bottom: -25px;
        }

        dd {
            margin: 0 10px 10px 65px;
        }
    }

    td {
        font-size: 90%;
    }
}

.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.ui-slider {
    position: relative;
    text-align: left;

    .ui-slider-handle {
        position: absolute;
        z-index: 2;
        cursor: default;
    }
}

.ui-slider-horizontal {
    height: 10px;
    border-radius: 10px;
    border: none;
    background: #dadada;

    .ui-slider-handle {
        margin-left: -5px;
    }
}

.ui-slider-handle {
    height: 10px;
    width: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $c-orange;
    display: block;
    position: absolute;
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
    position: absolute; /*must have*/
    z-index: -1; /*must have*/
    filter: mask(); /*must have*/
    top: -4px; /*must have*/
    left: -4px; /*must have*/
    width: 200px; /*must have*/
    height: 200px; /*must have*/
}
