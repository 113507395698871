// ==============================================
// KALIOP - FAQ
// ==============================================

// Configuration


// Content

.soon_faq-index-index {

    .page-main {
        margin-top: 30px;
        margin-bottom: 50px;
        max-width: 1024px;
    }

    .page-title {
        @extend .title-1;
        text-align: center;

        @include breakpoint(lg) {
            font-size: 7.5rem;
        }
    }

    .column.main {
        max-width: 1000px;

        > .faq-toggle + .faq-toggle {
            margin-top: 100px;
        }

        .faq-toggle {
            @extend %collapsible-full;

            > div + div {
                margin-top: 30px;
            }

            .faq-title[data-role="title"] {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 12px;
                padding-top: 18px;
                font-weight: $fw-benton-M;

                @include breakpoint(md) {
                    padding-bottom: 20px;
                    padding-top: 25px;
                }

                &[aria-selected="true"] {
                    &::after {
                        content: "\e941";
                    }
                }

                &::after {
                    content:"\e94d";
                    display: inline-block;
                    @extend %icon-icomoon;
                    font-size: 2.2rem;
                    color: $c-gold;
                    //transform: scaleY(1); // Reset Mixin Collapsible
                }

                &:hover {
                    cursor: pointer;
                }
            }

            h3.faq-title {
                border-bottom: 1px solid $c-grey;
                font-size: 1.4rem;
                text-transform: uppercase;

                @include breakpoint(md) {
                    font-size: 1.8rem;
                }
            }

            h4.faq-title {
                font-size: 1.3rem;

                @include breakpoint(md) {
                    font-size: 1.6rem;
                }

            }

            .faq-content,
            .faq-question {
                display: none;
            }

            .faq-question {
                margin-bottom: 20px;
            }
        }
    }
}
