// ==============================================
// CHECKOUT - Authentication
// ==============================================

.authentication-wrapper {
    position: absolute;
    right: $gutter;
    top: 0;
    max-width: 50%;
    z-index: 1;

    @include breakpoint(md) {
        text-align: right;
        width: 33.33%;
    }

    // Action Toggle
    .action-auth-toggle {
        margin-bottom: 5px;
    }

    // Close Dropdown
    .action-close {
        cursor: pointer;
        font-size:  1.5rem;
        padding: 15px 20px;
        position: absolute;
        top: 0;
        right: 0;

        & > span {
            @extend .visually-hidden;
        }

        &::before {
            color: lighten($c-black, 60%);
            @extend %icon-jade;
            content: "\e616";
        }
    }

    // Authentication Dropdown Container
    .authentication-dropdown {
        @include breakpoint(md) {
            background-color: #ffffff;
            border: 1px solid lighten($c-black, 80%);
            position: absolute;
            text-align: left;
            top: 100%;
            transform: scale(1, 0);
            transform-origin: 0 0;
            transition: transform linear .2s, visibility .2s linear;
            visibility: hidden;
            width: 100%;
        }

        // Display Authentication Dropdown Container
        &._show {
            z-index: 100;
            transform: scale(1, 1);
            visibility: visible;
        }

        .modal-inner-wrap {
            padding: 25px;
        }

        // Mobile Content Slide
        @include breakpoint(only-md) {
            &.custom-slide {
                bottom: 0;
                min-width: 0;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                opacity: 0;
                visibility: hidden;
                transition: visibility 0s .3s, opacity .3s ease;
                z-index: 900;

                &._show {
                    visibility: visible;
                    overflow-x: hidden;
                    overflow-y: auto;
                    opacity: 1;
                    transition: opacity .3s ease;

                    .modal-inner-wrap {
                        transform: translateX(0);
                    }
                }

                .modal-inner-wrap {
                    position: static;
                    width: auto;
                    height: auto;
                    min-height: 100%;
                    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
                    opacity: 1;
                    pointer-events: auto;
                    overflow-y: auto;
                    transform: translateX(100%);
                    transition: transform 0.3s ease-in-out;
                    background-color: #f4f4f4;
                }
            }
        }
    }

    // Block Title
    .block-title {
        @extend .title-2;
    }
}