// ==============================================
// VIEW - Downloadable Product
// ==============================================

//
//  Configuration
//  _____________________________________________

$product-add-form-width:       40%;

@mixin product-add-form-float($float) {
    @if $float == right {
        float: right;
        width: $product-add-form-width;

        @include breakpoint(only-md) {
            float: none;
            width: 100%;
        }
    }

    @else if $float == none {
        clear: both;
    }
}

//
//  Common
//  _____________________________________________

.page-product-downloadable {

    .product-add-form {
        @include product-add-form-float(right);
        margin-bottom: 30px;
    }

    .price-box {
        margin-bottom: 10px;
    }

    .product-info-main {
        margin-bottom: 0;
    }

    .box-tocart {
        margin-bottom: 20px;
    }
}