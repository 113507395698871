// ==============================================
// STRATE WELCOME
// ==============================================

.ct-view {

    &.ct-view-strate_bienvenue {

        .block-content .item + .ct-view-strate_4_x_1 {
            margin-top: $gutter;

            @include breakpoint(lg) {
                margin-top: $gutter-large*2;
            }
        }
    }
}
