// ==============================================
// ACCOUNT - Loyalty
// ==============================================

.mdployalty-loyalty-index {
    .loyalty-subtitle {
        margin-bottom: 15px;
    }

    .loyalty-block:not(.no-loyalty) {
        background-color: $c-white;
        padding: 15px 30px;
    }

    .loyalty-info {
        margin-top: 5px;
        font-weight: $fw-benton-M;
    }

    .no-loyalty.error {
        font-size: 1.4rem;
        margin-top: 24px 0;
        font-weight: $fw-benton-M;
        color: $c-orange;
    }

    .separator {
        border: 0;
        margin: 15px 0;
        width: 100%;
        height: 1px;
        background-color: $c-light6;

        &--large {
            margin: 24px 0;
        }
    }
}