// ==============================================
// UI WIDGETS - Collapsible
// ==============================================

//
//  Collapsible inline
//  _____________________________________________

@mixin collapsible-inline {
    > [data-role="title"]{
        position: relative;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 1.4;

        // Arrow icon
        &::after {
            display: inline-block;
            content: "\e622";
            @extend %icon-jade;
            margin-left: 10px;
            font-size: 1.2rem;
            color: lighten($c-black, 30%);
            transform: scaleY(1);
            transition: transform .1s;
        }

        // Active
        &[aria-selected="true"] {
            &::after {
                transform: scaleY(-1);
                transition: transform .1s;
            }
        }
    }

    > [data-role="content"]{
        margin-top: 10px;
    }
}

%collapsible-inline {
    @include collapsible-inline;
}

//
//  Collapsible full
//  _____________________________________________

@mixin collapsible-full {
    @include collapsible-inline;

    > [data-role="title"]{
        display: block;
        padding-right: 30px;

        // Arrow icon
        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            margin-left: 0;
            transform: translateY(-50%) scaleY(1);
            transition: transform .1s;
        }

        // Active
        &[aria-selected="true"] {
            &::after {
                transform: translateY(-50%) scaleY(-1);
                transition: transform .1s;
            }
        }
    }
}

%collapsible-full {
    @include collapsible-full;
}


//
//  Collapsible content animation
//  _____________________________________________
*[data-role="content"]{

    // Active
    &[aria-hidden="false"] {
        animation-name: hideBeforeShowing,movingTopToBottom;
        animation-duration: .6s;
    }
}