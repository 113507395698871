// 
// page404 style
//
.cms-no-route {
    .page-title {
        @extend .title-2;
        margin: auto;
        text-align: center;
        margin: 40px 0;
    }
    .main {
        line-height: 1.4;
        @extend p;
    }
}