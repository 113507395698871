// ==============================================
// AVIS VERIFIES
// ==============================================

//
// Reviews wrapper
//
#netreviews_reviews_tab {
    &.product-view-content-block {

    }

    .block-content.with-label-link {
        @include breakpoint(only-lg) {
            padding-bottom: $gutter*2;
            position: relative;
        }
    }

    .block-title {
        margin-bottom: $gutter;

        .counter {
            margin-left: $gutter/2;
            margin-right: auto;
        }
    }

    .netreviews-rating-section {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid $input-border-color;
        padding-bottom: $gutter-large/2;
    }
    .netreviews_note_generale {
        line-height: 1;
        margin-bottom: 0;
        font-size: 3.2rem;
        font-family: $f-bentonSC;
        font-weight: $fw-benton-B;
    }

    .netreviews_button {
        @extend .strate-title-link;
    }

    .netreviews_button_wrapper {
        text-align: center;
        padding-top: 3rem;
    }
    .netreviews_button_load {
        @extend .btn;
        font-size: 1.4rem;
    }


    //
    // Views
    //
    .netreviews_reviews_section {
        @include breakpoint(only-md) {
            border-bottom: 1px solid $input-border-color;
        }

        // Mobile slideshow - Display the left side of next slide
        .slick-list {
            @include breakpoint(only-md) {
                padding-right: $gutter;

            }
        }
    }

    //
    // List items reviews
    //

    .netreviews_review_items {


        // Mobile
        @include breakpoint(only-md) {
            padding-top: $gutter-large;

            .netreviews_review_part {
                padding: 24px $gutter/4 24px $gutter-large/2;
            }

            .stars {
                position: absolute;
                top: 24px;
                right: $gutter/2;
            }

            .name {
                padding-right: 100px;
            }
        }
    }

    .netreviews_review_item {

        @include breakpoint(md) {}
    }

    .netreviews_review_part {
        position: relative;

        @include breakpoint(lg) {
            display: flex;
            padding-top: $gutter + $gutter-large;
            padding-bottom: $gutter-large;
            border-bottom: 1px solid $input-border-color;

            .part-head {
                flex: 1 1 0;
                padding-right: $gutter-large;
            }
            .part-comment {
                flex: 2 1 0;
            }
        }

        // Response part
        &_response {
            padding-top: $gutter;
            font-size: 1.1rem;
            line-height: 1.2;

            @include breakpoint(md) {
                font-size: 1.4rem;
            }

            .response-content {
                margin-left: 4rem;
                padding-left: 2.5rem;
                border-left: 1px solid $c-black;
            }

            .content-head {
                margin-bottom: 1.8rem;
                font-weight: bold;
            }
            .content-message {
                br:last-child {
                    display: none;
                }
            }
        }

        // Part head
        .name {
            font-size: 1.8rem;
            line-height: 1.2;

            @include breakpoint(md) {
                font-size: 2rem;
            }
        }
        .list {
            @extend .list-custom;
            font-size: 1.4rem;
        }

        // Part comment
        .review-text {
            margin-top: $gutter-large;
            margin-bottom: 0;
            font-size: 1.4rem;

            @include breakpoint(md) {
                font-size: 1.6rem;
            }

            strong {
                font-size: 1.8rem;

                @include breakpoint(md) {
                    font-size: 2.2rem;
                }
            }
        }
    }

    // Mobile slideshow style
    .netreviews_review_items {
        @include breakpoint(only-md) {
            margin-right: -$gutter;

            .netreviews_review_item {
                opacity: 0;
            }

            .slick-slide .netreviews_review_item {
                opacity: 1;
            }
        }
    }
}

//
// Stars wrapper
//
.netreviews_bg_stars_big,
.netreviews_review_part {


    .nr-icon {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;

        &.grey {
            color: #D1D1D1 !important;
        }

        &.gold {
            color: $c-black !important;
        }
    }

    .nr-star {

        &::before {
            content: "\e95f";
        }

        &.gold::before {
            content: "\e95e";
        }
    }
}

div.netreviews_bg_stars_big {
    position: relative;
    width: auto;
    max-width: none;

    div {
        font-size: 20px;
        position: static; // Reset absolute position
    }

    div + div {
        position: absolute;
        top: 0;
    }

    // Product view details
    & > a {
        display: flex;
        align-items: center;
        text-decoration: none;

        .counter {
            display: flex;
            align-items: center;
            margin-left: 5px;

            span {
                text-decoration: underline;
            }
        }
    }
}

//
// Wishlist stars wrapper
//
.wishlist-index-index {
    .product-view-top-rating-custom {
        background-color: #F8F7F3;
        display: inline-block;
        margin-top: 5px;
        padding: 2px 4px 2px 4px;
        border-radius: 3px;
        .netreviews-stars a {
            line-height: 23px;
        }

        a.netreviews-stars-link::before {
            text-decoration: none;
            display: inline-block;
            content: '( ';
        }
        a.netreviews-stars-link::after {
            text-decoration: none;
            display: inline-block;
            content: ' )';
        }
    }
}
