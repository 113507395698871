// ==============================================
// HEADER - Sticky header
// ==============================================


//
//  Desktop
//  _____________________________________________

@include breakpoint(lg) {
    body.init-sticky {
        .page-header {
            position: sticky;
             top: -122px;

            &.fixed {
                position: fixed;
                width: 100%;
                top: 0;
            }
        }
    }
    body.sticky {
        .page-header {
            position: sticky;
            top: 0;
            -webkit-box-shadow: 0 0 10px 0 rgba(100,100,100,0.5);
            -moz-box-shadow: 0 0 10px 0 rgba(100,100,100,0.5);
            box-shadow: 0 0 10px 0 rgba(100,100,100,0.5);

            &.fixed {
                position: fixed;
                width: 100%;
                top: 0;
            }
        }
    }
}


//
//  Tablets & Mobiles
//  _____________________________________________

@include breakpoint(only-lg){

    body.init-sticky {
        .page-header {
            position: sticky;
            top: -200px;
            transition: top .3s linear;
        }
    }

    body.sticky .page-header {
        position: sticky;
        top: 0;
        z-index: 10;
        opacity: 1;
        -webkit-box-shadow: 0 0 10px 0 rgba(100,100,100,0.5);
        -moz-box-shadow: 0 0 10px 0 rgba(100,100,100,0.5);
        box-shadow: 0 0 10px 0 rgba(100,100,100,0.5);
    }
}
