// ==============================================
// AGENDA - Filters
// ==============================================

.agenda-date-filter {
    font-family: $f-bentonSC, sans-serif;
    margin-bottom: 30px;

    @media(min-width: $small) {
        margin-bottom: 52px;
    }

    .date-filters {
        display: flex;
        overflow-x: auto;
        margin: 0 -20px;
        padding: 0 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        & > li {
            margin-right: 15px;

            &:last-of-type {
                margin-right: 0;
            }

            a {
                display: block;
                padding: 19px 21px;
                border-radius: 60px;
                font-size: 16px;
                line-height: 22px;
                border: 1px solid $c-light6;
                color: $c-dark;
                font-weight: 500;
                white-space: nowrap;

                &.current {
                    border-color: $c-dark;
                    color: $c-white;
                    background-color: $c-dark;
                }

                &[href="#"] {
                    padding: 0;

                    span {
                        display: block;
                        padding: 19px 21px;
                        font-weight: inherit;
                        color: inherit;
                        line-height: inherit;
                        font-family: inherit !important;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.selected-date {
    border-bottom: 1px solid $c-light6;
    @media(min-width: $medium) {
        margin-bottom: 45px;
    }

    h1 {
        font-family: $f-benton, sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 10px;
        @media(min-width: $small) {
            font-size: 34px;
            line-height: 70px;
            margin-bottom: 0;
        }
    }
}
