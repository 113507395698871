// ==============================================
// STRATE TEXTE SIMPLE
// ==============================================

.ct-view-strate_texte_simple{

    &.nomargin{
        .strate-background{
            margin-bottom:0;
        }
    }

    &.two-columns{
        .strate-texte-simple{
            @include breakpoint(lg) {
                column-count:2;
                column-gap: 40px;
            }
        }
    }

    .strate-title{
        font-size:1.7rem;

        @include breakpoint(lg) {
            font-size:2.8rem;
        }
    }

    .strate-texte-simple{
        font-size:1.4rem;

        @include breakpoint(lg) {
            font-size:1.6rem;
        }
    }

    .strate-background{
        margin-bottom: 50px;
    }

    .block-content{
        padding-top:30px;
        padding-bottom:30px;

        @include breakpoint(lg) {
            padding-top:50px;
            padding-bottom:50px;
        }
    }

    .strate-container{
        max-width:900px;
        margin:auto;

        @include breakpoint(only-lg){
            padding:0 50px;
        }

        @media (min-width: $ultra-large) {
            max-width: none;
            padding:0 155px;
        }
    }

    .strate-title{
        margin-bottom:0px;
        border-bottom:0;
    }

    .strate-texte-simple{
        font-family: $source-serif-pro;
        line-height: 1.7;
    }

    .strate-link-container{
        display:block;

        .btn{
            display:inline-block;
            margin-top:10px;
        }
    }
}