// ==============================================
// UTILITIES
// ==============================================

//
//  Visible / Hidden class
//  _____________________________________________

$breakpoints: xs sm md lg xl;

// Visible only on mobile/tablet
@each $breakpoint in $breakpoints {
    .visible-#{$breakpoint} {
        @include breakpoint(#{$breakpoint}) {  display: none !important;  }
        @include breakpoint(only-#{$breakpoint}) {  display: block;  }
    }
}

// Visible only on desktop
@each $breakpoint in $breakpoints {
    .hidden-#{$breakpoint} {
        @include breakpoint(#{$breakpoint}) {  display: block;  }
        @include breakpoint(only-#{$breakpoint}) {  display: none !important;  }
    }
}

//
//  SEO friendly hidden
//  _____________________________________________

@mixin visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}

._hidden,
.visually-hidden {
    @include visually-hidden;
}

//
//  Floats clearer
//  _____________________________________________

@mixin clearer {
    &::after {
        display: block;
        content: "";
        clear: both;
        font-size: 0;
        line-height: 0;
        height: 0;
        overflow: hidden;
    }
}

%clearer {
    @include clearer;
}

//
//  Icomoon placeholder
//  _____________________________________________

@mixin icon-icomoon {
    text-transform: none;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-display: block;
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icon-icomoon {
    @include icon-icomoon;
}