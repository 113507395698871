// ==============================================
// UI COMPONENTS - Overlay
// ==============================================

.locked .overlay {
    display: block !important;
    pointer-events: auto;
}


.overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-color: $c-overlay;
    pointer-events: none;

    // Panel
    &.panel-is-open {
        z-index: 12;
        pointer-events: auto;
    }
}

// ==============================================
// CUSTOM - Overlay
// ==============================================

// Navigation desktop overlay
@include breakpoint(lg) {
    .nav-is-open .navigation-overlay {
        display: block !important;
        pointer-events: auto;
    }

    .navigation-overlay {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // z-index: -1;
        background-color: $c-overlay;
        pointer-events: none;
    }
}
