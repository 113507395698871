// ==============================================
// STRATE DAMIER
// ==============================================

.damiers {
    

    .damier {
        margin-bottom: 20px;
        
        @include breakpoint(lg) {
            margin-bottom: 0px;
        }

        .damier-container{
            max-width: 950px;
            margin-left: auto;
            margin-right: auto;
            padding: 30px 20px;

            @include breakpoint(lg) {
                padding: 70px 30px;
            }
        }

        // Item title
        .title {
            @extend .title-3;
            font-family: $f-benton;
            font-size: 1.7rem;
            margin-bottom: $gutter / 2;

            @include breakpoint(lg) {
                font-size: 2.8rem;
            }
        }

        .content {
            @include breakpoint(only-lg) {
                display:flex;
                flex-flow:column;
            }
            
            &.cta-position-right .action {
                text-align: right;
            }

            @include breakpoint(md) {
                &.image-position-left{
                    .picture,
                    .video-wrapper {
                        text-align:left;
                    }
                }

                &.image-position-right{
                    .picture,
                    .video-wrapper {
                        text-align:right;
                    }
                }
            }
        }

        .picture,
        .video-wrapper {
            margin-bottom: $gutter-large;
            text-align: center;
        }

        .video-wrapper {
            @include breakpoint(md) {
                padding-bottom: 27%;
            }
        }

        .content-text {
            display: flex;
            flex-flow: column;
            justify-content: center;
            margin-bottom: 0 !important;
            
            @include breakpoint(only-md) {
                position: relative;

                .action {
                    min-height: 35px; // Height of cta

                    // a {
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    //     right: 0;
                    //     bottom: 0;
                    //     display: flex;
                    //     align-items: flex-end;
                    // }
                }
                
            }

            &,
            p {
                font-family: $source-serif-pro;
                font-size: 1.4rem;
                line-height: 1.4;
                margin-bottom: $gutter / 2;
                
                @include breakpoint(lg) {
                    font-size: 1.6rem;
                }
            }
        }
        @include breakpoint(only-md) {
            .content {
                display: flex;
                flex-flow: column;

                .picture,
                    .video-wrapper{
                        margin-top: 0;
                        margin-bottom: $gutter;
                    }

                &.image-position-right,
                &.video-position-right {
                    .picture,
                    .video-wrapper{
                        order: 1; 
                        margin-top: $gutter;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

// Alternate position only for "strate damier" page
@include breakpoint(md) {

    .ct-view-strate_damier {
        .damiers {}

        .damier {
            .content {

                &.with-image-and-text,
                &.with-video-and-text {
                    display: flex;

                    .picture{
                        width:45%;
                    }

                    .video-wrapper,
                    .content-text {
                        width: 55%;
                    }

                    .picture,
                    .video-wrapper {
                        margin-bottom: 0;
                    }

                    // Image position
                    &.image-position-left,
                    &.video-position-left {
                        flex-direction: row;

                        .picture,
                        .video-wrapper {
                            padding-right: $gutter/2;
                        }

                        .content-text {
                            padding-left: $gutter;
                        }
                    }

                    &.image-position-right,
                    &.video-position-right {
                        flex-direction: row-reverse;

                        .picture,
                        .video-wrapper {
                            padding-left: $gutter/2;
                        }

                        .content-text {
                            padding-right: $gutter;
                        }
                    }
                }
            }
        }
    }
}


// Alternate desktop position if including in two columns container
.strate-2cc-main {

    .damier {

        // Item content
        .content {
            @include breakpoint(only-lg) {
                display:flex;
                flex-flow:column;
            }

            &.with-image-and-text,
            &.with-video-and-text {

                // Alternate design for desktop
                @include breakpoint(lg) {
                    display: flex;

                    .picture{
                        width:45%;
                    }
                    
                    .content-text {
                        width: 55%;
                    }

                    &.image-position-left,
                    &.video-position-left {
                        flex-direction: row;

                        .picture,
                        .video-wrapper {
                            padding-right: $gutter/2;
                        }

                        .content-text {
                            padding-left: $gutter;
                        }
                    }

                    &.image-position-right,
                    &.video-position-right {
                        flex-direction: row-reverse;

                        .picture,
                        .video-wrapper {
                            padding-left: $gutter/2;
                        }

                        .content-text {
                            padding-right: $gutter;
                        }
                    }
                }

                @include breakpoint(only-lg) {
                    &.image-position-right,
                    &.video-position-right {
                        order:1;

                        .picture,
                        .video-wrapper {
                            padding-top: $gutter/2;
                        }

                        .content-text {
                            padding-top: $gutter;
                        }
                    }
                }
            }
        }

        .picture {

            @include breakpoint(lg) {
                margin-bottom: 0;
            }
        }
    }
}