// ==============================================
// BASE - Titles
// ==============================================

.title-1 {
    font-family: $f-benton;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 1.4;
    margin-bottom: 10px;

    @media (min-width: 370px) {
        font-size: 1.7rem;
    }

    @include breakpoint(xs) {
        font-size: 1.7rem;
    }

    @include breakpoint(md) {
        font-size: 2.8rem;
        margin-bottom: 20px;
    }
}

.title-2 {
    font-family: $f-bentonSC;
    font-weight: $fw-benton-B;
    font-size: 2.6rem;
    line-height: 1.4;
    margin-bottom: 10px;

    @include breakpoint(lg) {
        font-size: 3.4rem;
        margin-bottom: 20px;
    }
}

.title-3 {
    font-weight: $fw-benton-B;
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 10px;

    @include breakpoint(lg) {
        font-size: 3.4rem;
        margin-bottom: 20px;
    }
}


.subtitle-1 {
    font-weight: $fw-benton-B;
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 10px;

    @include breakpoint(lg) {
        font-size: 1.8rem;
        margin-bottom: 20px;
    }
}

.footer-title {
    font-weight: $fw-benton-B;
    font-size: 2rem;
    margin-bottom: 10px;

    @include breakpoint(lg) {
        font-size: 2.2rem;
    }
}
