// ==============================================
// ACCOUNT - Customer informations
// ==============================================

.customer-account-edit {

    // billet
    .layus-billeterie {
        margin-bottom: 25px;
    }

    // Form
    .form.form-edit-account {
        @include breakpoint(md) {
            max-width: $container-small;
        }

        // Legend
        .legend {
            @extend .visually-hidden;
        }

        // Input
        input {
            //max-width: 424px;
        }

        // Fieldset
        .fieldset {
            &.info {
                > .choice {
                    margin-top: 15px;

                    @include breakpoint(md) {
                        display: inline-flex;
                        margin-top: 25px;
                        margin-right: 50px;
                    }

                    // Remove LoginAsCustomer checkbox
                    &:last-of-type {
                        display: none;
                    }

                    label {
                        margin-left: 0;
                        font-family: $f-base;
                        font-size: 1.4rem;
                    }
                }
            }

            &.password {
                margin-top: 25px;
                margin-bottom: 30px;
            }
        }
    }
}
