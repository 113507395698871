// ==============================================
// STRATE BLOG
// ==============================================

.ct-view-strate_articles_de_blog {

    .block-content {
        @extend .container;
        padding-bottom: $gutter;
        padding-top: $gutter;

        @include breakpoint(lg) {
            padding-bottom: 70px;
            padding-top: $gutter-large;
        }
    }

    // Items
    .items {
        display: flex;
        margin-bottom: 35px;
        margin-right: -$gutter;

        @include breakpoint(lg) {
            margin-right: -$gutter-large;

            & > .item {
                width: 25%;
            }
        }
    }

    // Item
    .item {
        padding: 0 15px;
        border-left: 1px solid $input-border-color;
        line-height: 1.4;

        .category-title {
            display: block;
            font-size: 1.8rem;
        }

        .post-title {
            font-weight: $fw-benton-B;
            font-size: 2rem;
            position: relative;
            display: inline;
            text-decoration: none;
            background-image: linear-gradient(currentColor, currentColor);
            background-size: 0% 1px;
            background-position: 0 100%;
            background-repeat: no-repeat;
            transition: background-size .5s ease-out;

            @include breakpoint(lg) {
                font-size: 2.2rem;
            }

            &:hover,
            &:focus {
                background-size: 100% 1px;
            }
        }
    }

    .posts-links {
        @extend .link-arrow;
    }
    
    // Slick slideshow
    .slick-slider {
        @extend .slick-arrows-outside;


    }
}
