// ==============================================
// TABLES - Orders
// ==============================================

//
//  Configuration
//  _____________________________________________

$account-table-padding-col:         11px 15px;

%order-table {
    .table-wrapper {
        width: 100%;

        table {
            width: 100%;

            tr {
                th, td {
                    text-align: left;
                }
            }

            thead {
                th {
                    padding: 11px 15px;
                    font-family: $f-bentonSC;
                    font-size: 1.4rem;
                    font-weight: $fw-benton-M;
                    vertical-align: bottom;
                }
            }

            @include breakpoint(md) {
                tbody {
                    tr {
                        background-color: $c-white;

                        + tr {
                            border-top: 10px solid $c-light7;
                        }
                    }
                }
            }

            td {
                padding: 10px;
                font-size: 1.4rem;
                line-height: 1.2;
                vertical-align: middle;

                @include breakpoint(md) {
                    padding: 15px;
                }

                @include breakpoint(md) {
                    padding: 30px 15px;
                }
            }
        }

        // Table wrapper mobile
        @include breakpoint(only-md) {

            table {
                thead {
                    tr {
                        th {
                            display: none;
                        }
                    }
                }

                tbody {
                    display: block;

                    tr {
                        display: flex;
                        flex-wrap: wrap;

                        + tr {
                            margin-top: 15px;
                            border-top: 1px solid $c-light6;
                        }

                        td {
                            display: flex;
                            flex-direction: column;
                            width: 50%;
                            // padding: 12px 30px;
                            background-color: $c-white;
                            font-weight: $fw-benton-M;
                            border-bottom: none;
                            font-size: 1.4rem;
                            word-break: break-word;

                            // Show data-th
                            &:not(.actions) {
                                &::before {
                                    margin-bottom: 5px;
                                    content: attr(data-th);
                                    display: block;
                                    font-family: $f-bentonSC;
                                    font-size: 1.1rem;
                                    font-weight: $fw-benton-M;
                                    color: #777;
                                }
                            }

                            &:first-child,
                            &:nth-child(2) {

                                @include breakpoint(md) {
                                    padding-top: 25px;
                                }
                            }

                            &.actions {
                                flex-direction: row;
                                width: 100%;
                                padding-top: 30px;
                                padding-bottom: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
