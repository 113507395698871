// ==============================================
// ONEPAGE - Shipping
// ==============================================

.opc-wrapper {
    // Login Form
    .form-login {
        border-bottom: 1px solid lighten($c-black, 80%);
        padding-bottom: 30px;
        margin-bottom: 30px;

        .actions-toolbar a.action {
            display: inline-block;
            width: auto;
        }
    }

    .form-login,
    .form-shipping-address {
        max-width: 600px;
    }

    // Shipping addresses
    .field.addresses {
        & + .action-show-popup {
            margin-top: 20px;
        }
    }

    // Shipping Methods
    .methods-shipping {

            .disabled {
                background-color: $c-grey3;

                span {
                    color: $c-white;
                }
            }

        .checkout-shipping-method-load {
            @include breakpoint(lg) {
                border-bottom: 1px solid $input-border-color;
                padding-bottom: $gutter-large/2;
            }
        }

        // Method Shipping Table
        .table-checkout-shipping-method {
            width: 100%;

            thead {
                display: none;
            }

            tbody {

                > tr {
                    background: $c-white;
                    cursor: pointer;

                    &.active {
                        background: $c-dark;
                        color: $c-white;
                    }

                    + tr {
                        border-top: 15px solid $c-light7;

                        @include breakpoint(lg) {
                            border-top-width: 10px;
                        }
                    }
                }

                td {
                    vertical-align: middle;
                    padding: 30px 20px;

                    @include breakpoint(lg) {
                        padding: 40px;
                    }

                    &.col-method {
                        padding-right: 0;

                        input[type="radio"] + label {
                            width: 20px;
                            padding: 0;
                        }
                    }

                    &.col-carrier-and-method {
                        padding-left: 10px;

                        @include breakpoint(lg) {
                            padding-left: 24px;
                        }
                    }

                    &.col-price {
                        text-align: right;
                    }
                }

                .row-shipping {
                    &.active {

                    }
                }
            }
        }
    }
}

// Sidebar Shipping Information
.opc-block-shipping-information {
    margin-top: 40px;

    .ship-to {
        margin-bottom: 20px;

        @include breakpoint(lg) {
            margin-bottom: 30px;
        }
    }

    .shipping-information-title {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 20px;
        border-bottom: 1px solid $c-light6;
        font-size: 1.6rem;
        font-weight: $fw-benton-M;

        // Edit button
        .action-edit {
            margin-left: auto;
            padding-left: 20px;
        }
    }

    .shipping-information-content {
        font-size: 1.4rem;
    }
}



//
//  Colissimo - MondialRelay
//  _____________________________________________

div.mgx-popup-inner {
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
    border: none;

    @include breakpoint(only-md) {
        top: 50% !important;
        transform: translateY(-50%);
        height: 100vh;
    }

    // Header
    .colissimo-header,
    .mondialrelay-header {
        display: none;

        @include breakpoint(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            background: $c-gold;

            & > h1 {
                width: 100%;
            }
        }
    }

    // Content
    .colissimo-content,
    .mondialrelay-content {
        box-sizing: border-box;
        padding: 10px 10px 30px;

        input[type=radio]+label {

            &::before,
            &::after {
                display: none;
            }
        }
    }

    // Search Header
    .colissimo-address,
    .mondialrelay-address {
        float: none;
        display: flex;
        align-items: flex-end;
        width: 100%;
        padding: 0;
        background: none;
        border: none;

        @include breakpoint(only-md) {

            .colissimo-street,
            .colissimo-postcode,
            .colissimo-city,
            .colissimo-country,
            .mondialrelay-postcode,
            .mondialrelay-country {
                width: 100%;
            }

            p {
                margin: 0 5px 10px;
            }
        }

        p {
            float: none;
            margin: 0;
            padding-left: 0;
            padding-right: 0;

            @include breakpoint(md) {
                padding-right: 10px;
            }

            &#mr-address-code {
                display: none;
            }
        }

        label {
            margin: 0;
            width: auto;
        }

        input {
            font-size: 1.2rem;

            @include breakpoint(md) {
                width: auto;
            }
        }

        .colissimo-country {
            font-size: 1.2rem;

            @include breakpoint(md) {
                width: 150px;
            }
        }

        .mondialrelay-actions,
        .colissimo-actions {
            float: none;
            margin-left: auto;
            margin-bottom: 0;

            @include breakpoint(md) {
                margin-top: 0;
            }
        }

        .mondialrelay-pickup-code {

            input[type=radio]+label {

                &:before {
                    top: 5px;
                    left: 3px;
                }

                &:after {
                    top: 12px;
                    left: 10px;
                }
            }

            label {
                height: 28px;
                font-size: 1.2rem;
                font-weight: $fw-benton-M;
                white-space: nowrap;

                &.active {
                    background-color: $c-brown;
                    border: none;
                }
            }
        }
    }

    .colissimo-address {
        flex-wrap: wrap;
        justify-content: space-between;

        p,
        div {
            @include breakpoint(only-md) {
                width: 48%;
            }
        }

        .colissimo-next,
        .colissimo-next > span {
            width: 100%;
        }
    }


    //
    // Store list Wrapper
    //


    // Apply container height
    .mondialrelay-list {
        @include breakpoint(only-md) {
            height: 56vh;
        }

        & > ul,
        .mondialrelay-map {
            @include breakpoint(md) {
                height: 400px;
            }
        }
    }

    .colissimo-list {
        @include breakpoint(only-md) {
            height: 50vh;
        }

        & > ul,
        .colissimo-map {
            @include breakpoint(md) {
                height: 360px;
            }
        }
    }


    .mondialrelay-list,
    .colissimo-list {
        width: 100%;
        display: flex;

        @include breakpoint(only-md) {
            flex-direction: column;

            // Order of children
            .mondialrelay-map,
            .colissimo-map {
                order: 1;
            }

            & > ul {
                order: 2;
            }

            .colissimo-telephone,
            .mondialrelay-actions,
            .colissimo-actions {
                order: 3;
            }

        }

        @include breakpoint(md) {
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        // Store list
        & > ul {
            width: 100%;
            float: none;
            margin-top: 10px;
            position: relative;

            @include breakpoint(md) {
                width: calc(100% - 520px);
                margin-top: 0;
            }

            li {

                label {
                    padding: 5px 15px 10px;
                    margin: 0;

                    @include breakpoint(md) {
                        padding: 10px 15px;
                    }

                    a {
                        background-color: $c-brown;
                    }
                }

                input[type=radio]+label {
                    &::before {
                        top: 16px;
                        left: 3px;
                    }

                    &::after {
                        top: 23px;
                        left: 10px;
                    }
                }
            }

            .mondialrelay-show-info {
                display: inline-block;
                margin-top: 5px;
            }
        }

        // Map
        .mondialrelay-map,
        .colissimo-map {
            display: block;
            float: none;

            @include breakpoint(only-md) {
                width: 100%;
            }
        }

        // Colissimo phone information
        .colissimo-telephone {
            height: auto;
            padding: 0;
            background: none;
            border: none;
            display: flex;
            align-items: center;

            @include breakpoint(only-md) {
                justify-content: space-between;
            }

            label {
                padding-right: 10px;
                margin: 0;
            }
        }

        // Cta action
        .mondialrelay-actions,
        .colissimo-actions {
            margin-top: 20px;
            width: 100%;

            @include breakpoint(md) {
                margin-top: 10px;
            }
        }
    }


    // Action
    .mondialrelay-actions,
    .colissimo-actions {
        position: static;
    }
    .colissimo-previous {
        margin-left: $gutter/2;

        &,
        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
        }

        span {
            @extend .link-arrow-back;
            padding: 0;
            background: none;
        }
    }
    .colissimo-next span {
        @extend .btn;
    }

    button {
        & > span {
            display: inline-block;
            line-height: 3rem;
            font-size: 1.2rem;
            font-weight: $fw-benton-M;
        }
    }
}
