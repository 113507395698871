// ==============================================
// PUBLIC - Password
// ==============================================

.customer-account-createpassword {}

.customer-account-forgotpassword {

    // Form
    .form.password.forget {
        // Field
        .field {
            &.note {
                font-size: 1.4rem;
                line-height: 1.3;
            }

            &.email {}
        }

        // Toolbar
        .actions-toolbar {
            margin-top: 40px;

            @include breakpoint(md) {
                margin-top: 60px;
            }
        }
    }
}
