// ==============================================
// MIRASVIT - SEARCH ULTIMATE
// ==============================================


body {
    &.searchautocomplete__active {
        @include breakpoint(only-lg) {
            overflow: hidden;

            .overlay {
                display: block;
            }
        }
    }
}

// Search Autocomplete Container
.searchautocomplete__active {
}

// Mirasvit - Search Autocomplete Container
.mst-searchautocomplete__autocomplete {
    position: static !important;
    z-index: 4;
    background-color: $c-white;
    width: 100%;
    padding: 0 !important;
    margin-top: 40px !important;
    margin-left: auto;
    margin-right: auto;

    .meta .title {
        font-weight: $fw-benton-M;
    }

    .mst-searchautocomplete__close{
        position: fixed;
        right:10px;
        display:flex;
        align-items: center;
        top:10px;
        font-size: 4rem;

        @include breakpoint(lg) {
            top: 90px !important;
            right:110px;
        }

        &:before{
            content:'Fermer';
            margin-right: $gutter;
            display:inline-block;
            font-size:1.4rem;
        }
    }
}

.mst-searchautocomplete__wrapper {
    max-width: 1190px;
    height: 100%;
    max-height: calc(100vh - 200px);
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: $f-benton;
}

.mst-searchautocomplete__index {

    // Push Product
    &.magento_catalog_product {
        .mst-searchautocomplete__index-title {
            font-family: $f-bentonSC;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.1em;
            margin-bottom: 7px;
        }

        ul > li {
            @extend .product-item-info;
            display: flex;

            @include breakpoint(only-lg) {
                border-width: 0 !important; // Reset extend product item info

                & + li {
                    border-top-width: 1px !important;
                }

                .status-titles-grid {
                    display: none;
                }
            }

            & + li {
                @include breakpoint(lg) {
                    border-top: transparent;
                }
            }

            .picture {
                width: 70px;

                @include breakpoint(lg) {
                    width: 180px;
                }

                img {
                    width: 120px;
                }
            }

            .meta {
                @include breakpoint(only-lg) {
                    .stock,
                    .delivery-label {
                        font-size: 1.1rem;
                        display: inline-block;
                    }

                    .stock:before {
                        margin-right: 6px;
                    }
                }
            }

            .delivery-label {
                &::before {
                    content: "-";
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }
}

.mst-searchautocomplete__index-title {
    font-size: 1.4rem;
    font-weight: $fw-benton-B;
}

.mst-search-categories {
    display: flex;
    flex-direction: column;

    @include breakpoint(lg) {
        flex-direction: row;
        flex-wrap: wrap;
        margin: -10px -35px;
    }

    li {

        @include breakpoint(only-lg) {
            + li {
                margin-top: 10px;
            }
        }

        @include breakpoint(lg) {
            width: calc(100% / 3);
            padding: 10px 35px;
        }

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 20px;
            border: 1px solid currentColor;
            font-size: 1.6rem;
            font-weight: $fw-benton-B;
            color: $c-gold;

            &:hover,
            &:focus {
                color: $c-gold2;
            }

            &.category-link {
                position: relative;

                &:hover,
                &:focus {
                    [class*="icon-"] {
                        background-color: $c-orange;
                    }
                }

                [class*="icon-"] {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 60px;
                    padding: 15px;
                    background-color: $c-light;
                    color: $c-white;
                    font-size: 3rem;
                    transition: background-color .3s;
                }

                .category-name {
                    padding-left: 60px;
                }
            }
        }
    }
}

// Cta Show all
.mst-searchautocomplete__show-all {
    float: right;
    padding: 0;

    > a {
        @extend .link-hover-black;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        font-family: $f-benton;
    }
}

.mst-searchautocomplete__close {
    padding: 10px;
    font-size: 26px;
    position: absolute;
    right: 12px;
    top: 7px;

    &:hover {
        cursor: pointer;
    }

    @include breakpoint(lg) {
        display: none;
    }
}

.mst-searchautocomplete__inner {
    @include breakpoint(md) {
        display: flex;
    }
}

.mst-searchautocomplete__products {
    display: flex;
    overflow-x: auto;
    padding-bottom: 20px;

    @include breakpoint(lg) {
        display: flex;
        flex-wrap: wrap;
    }

    & > li {
        padding: 0 8px 7px 10px;
        flex: 1 1 0;

        @include breakpoint(md) {
            flex: none;
            width: 50%;
            margin-bottom: $gutter/2;
        }

        &:hover {
            .mst-product-image-inner:after {
                height: calc(100% + 136px) !important;
                top: -136px !important;
            }
        }

        .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
        }

        .store-mobile {
            @extend .btn;
            padding: 4px 12px;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;

            span.price {
                color: $c-white !important;
                display: flex;

                &:after {
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 17'%3E%3Cdefs/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-width='1.5' d='M9.511 5.19048V4c0-1.65685-1.24196-3-2.77399-3-1.53204 0-2.774 1.34315-2.774 3v1.19048'/%3E%3Crect width='11.9737' height='10.9445' x='.75' y='5.30566' stroke='%23fff' stroke-width='1.5' rx='1.25'/%3E%3C/svg%3E");
                    width: 13px;
                    position: relative;
                    top: 2px;
                    margin-left: 6px;
                }
            }
        }
    }
}

.mst-searchautocomplete__item {
    display: flex;
    flex-direction: column;
}

.mst-product-image-wrapper {
    width: 100%;
    margin-bottom: 10px;

    img {
        width: 126px;
        height: 126px;
        object-fit: cover;
        margin: 0 auto;
        display: block;
    }
}


.mst-searchautocomplete__right-side {
    flex: 1 1 0;

    @include breakpoint(only-lg) {
        position: relative;
    }

    @include breakpoint(lg) {
        margin-left: 45px;
    }

    .mst-searchautocomplete__index-title {
        @include breakpoint(only-lg) {
            margin-bottom: 27px !important;
        }
    }

}

.mst-product-image-inner {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;

    &:after {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 1px;
        left: -10px;
        top: 0;
        bottom: 0;
        background-color: $c-light6;
        transition: .3s;
    }

    .meta {
        margin-bottom: 10px;
    }

    .title a {
        font-size: 14px;

        &:hover {
            text-decoration: underline;
        }

    }
}

.mst-searchautocomplete__left-side {
    width: 240px;

    .mst-searchautocomplete__index {
        margin-bottom: 50px;
    }

    .magento_catalog_product {
        display: none;
        visibility: hidden;
        width: 1px;
        height: 1px;
        margin: 0;
    }

    .mst-searchautocomplete__index-title {
        font-family: $f-bentonSC !important;
        line-height: 25px !important;
        font-size: 14px !important;
        margin-bottom: 27px;
    }

    ul > li {
        padding: 10px 18px 12px 11px;
        border: 1px solid $c-light6;
        border-radius: 10px;
        margin-bottom: 8px;

        @include breakpoint(only-lg) {
            max-width: 236px;
            width: 100%;
        }

        &:hover {
            cursor: pointer;

            a {
                text-decoration: underline;
            }
        }

        a {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            font-family: $f-benton;
            width: fit-content;

            i {
                margin: 0 5px;
            }
        }
    }
}
