// ==============================================
// STRATE COLLECTION TABS
// ==============================================

//
//  Configuration
//  _____________________________________________

$collection-tab-bg-color: $c-light7;

.ct-view-strate_collection_tab {
    background-color: $collection-tab-bg-color;

    // Set background color on next strate
    & + .ct-view-strate_articles_de_blog {
        background-color: $collection-tab-bg-color;
    }

    .block-content {
        @extend .container;

        @include breakpoint(only-xs) {
            padding-right: 0;

            .strate-title,
            .tabs-wrapper {
                margin-right: $gutter;
            }
        }
    }

    .tabs-target-wrapper {
         margin-right: 0; // Reset gutter less
     }

    .content-target {
        display: flex;
    }

    .collection-part {
        display: none;

        @include breakpoint(md) {
            display: block;
            //flex: 1 1 0;
            width: 70%;
            max-width: 790px;
            margin-right: $gutter-large/2;
            position: relative;
        }

        .picture-wrapper {
            flex: 1 1 0;
            height: 100%;

            img {
                object-fit: cover;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    .product-part {
        width: 100%;
        min-width: 400px;

        @include breakpoint(md) {
            width: 50%;
            padding: $gutter-large;
            background-color: $c-white;

            .slick-list {
                max-width: 1050px;
                margin: 0 auto;

                .slick-slide {
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                } 
            }
        }
    }

    // Product list
    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .collection-part {
            display: flex;
            flex-direction: column;
            padding-right: 20px;

            @include breakpoint(md) {
                display: none !important;
            }
        }
    }

    .product-item {
        visibility: hidden;
        padding-right: 20px;

        form[data-role="tocart-form"] {
            display: none;
        }
    }

    .product-item__inner {
        padding: 25px;
        background-color: $c-white;

        &::before {
            display: none;
        }
    }


        // Slick slideshow
    .slick-slider {
        @extend .slick-arrows-border;

        .product-item {
            visibility: visible;
        }

        // Arrows
        .slick-arrow {
            display: none;

            @include breakpoint(md) {
                display: block;
            }
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }
    }

}
