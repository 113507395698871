
    .conteneur_vr           {
        position: relative;
        height:700px;
    }
    .conteneur_vr iframe           {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /* mobile generic */
    .mobile .conteneur_vr           {
        position: relative;
        height:450px;
    }

    /* iPhone 4/4S */
    @media
    only screen and (min-device-width: 320px)
    and (max-device-width: 480px)
    and (-webkit-device-pixel-ratio: 2)
    and (device-aspect-ratio: 2/3)
    {
        .mobile .conteneur_vr           {
            position: relative;
            height:367px;
        }

    }
    /* iPhone5+ */
    @media
    only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5){
        .mobile .conteneur_vr           {
            position: relative;
            height:455px;
        }
    }

    /* iPhone 6 portrait */
    @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 667px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 2)
    {
        .mobile .conteneur_vr           {
            position: relative;
            height:554px;
        }
    }

    /* iPhone 6 Plus portrait */
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 3)
    {
        .mobile .conteneur_vr           {
            position: relative;
            height:622px;
        }
    }


