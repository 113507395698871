// ==============================================
// FORMS - Newsletter
// ==============================================

form {
    &.subscribe {

        .field {
            &.check {
                flex-flow: row;
                align-items: center;
                margin-bottom: 15px;

                .nl-type {
                    @include breakpoint(md) {
                        display: flex;
                    }
                }

                .nl-type-choice {
                    + .nl-type-choice {
                        @include breakpoint(only-md) {
                            margin-top: 14px;
                        }

                        @include breakpoint(md) {
                            margin-left: 30px;
                        }
                    }
                }

                input {
                    &[type="checkbox"] {

                        + label {
                            padding-left: 30px;

                            &::after {
                                font-size: 1.2rem;
                            }
                        }

                        &:checked {
                            + label {
                                &::before {
                                    background-color: $c-black;
                                    border-color: $c-black;
                                }

                                &::after {
                                    color: $c-white;
                                    transform: scale(1) translate(-50%, -50%);
                                }
                            }
                        }
                    }
                }
            }

            &.newsletter {
                display: block;
                max-width: 374px;
                margin-bottom: 0;

                .label {
                    @extend .visually-hidden;
                }

                .control {
                    position: relative;
                    margin: 0;

                    input[type="email"] {
                        //max-width: 330px;
                        height: 40px;
                        padding: 0 20px !important;
                        line-height: 40px;
                        background-color: #EBEAE3;
                        border-color: #EBEAE3;
                        font-size: 1.4rem;

                        &::placeholder {
                            font-size: 1.4rem;
                            color: $c-black;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .actions {
           position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            .action.subscribe {
                background-color: inherit;
                border: inherit;
                border-radius: inherit;
                font-size: 1.6rem;
                color: $c-black;
            }
        }
    }
}
