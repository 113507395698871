// ==============================================
// STRATE 4 X 1 TEXTE
// ==============================================

.ct-view-strate_4x1_texte {
    padding-bottom:50px;

    @include breakpoint(lg) {
        padding-bottom:70px;
    }
    
    .strate-background{
        padding: $gutter*1.5 0;

        @include breakpoint(md) {
            padding:50px 0;
        }

        @include breakpoint(lg) {
            margin:0;
        }
    }
    

    .strate-4x1-texte--contenu{
        display:flex;
        flex-flow:column;
        width:100%;

        @include breakpoint(md) {
            flex-flow: row;
            margin:0 -20px;
            padding-top: 20px;
        }
    }
    
    .strate-4x1-section{
        width:100%;
        padding: 12px 0;

        @include breakpoint(md) {
            width:25%;
            padding: 0 20px;
            border-right:1px solid $c-black;
            
            &:last-child(){
                border-right: none !important;
            }
        }
    }

    .strate-item-title{
        font-family: $f-benton;
        font-size:1.4rem;
        line-height: 1.4;
        text-transform: uppercase;

        @include breakpoint(md) {
            font-size:1.8rem;
        }
    }

    .strate-item-text{
        font-size:1.4rem;
        line-height: 1.4;

        @include breakpoint(md) {
            font-size:1.8rem;
        }
    }

    &.universe-blue{
        .strate-4x1-section{
            @include breakpoint(md) {
                border-right:1px solid $c-blue2-dark;
            }
        }
    }
    &.universe-green{
        .strate-4x1-section{
            @include breakpoint(md) {
                border-right:1px solid $c-green;
            }
        }
    }
    &.universe-yellow{
        .strate-4x1-section{
            @include breakpoint(md) {
                border-right:1px solid $c-yellow-dark;
            }
        }
    }
}
