// ==============================================
// UI COMPONENTS - Breadcrumbs
// ==============================================

//
//  Configuration
//  _____________________________________________

$breadcrumbs-margin:            20px 0;
$breadcrumbs-items-space:       4px;
$breadcrumbs-font-size:         1.3rem;
$breadcrumbs-font-color:        $c-black;
$breadcrumbs-arrow-color:       $c-black;
$breadcrumbs-arrow-size:        1rem;

.breadcrumbs {
    @extend .container;

    .items {
        font-size: 0; // Hack to handle space between items
        font-weight: $fw-benton-R;

        @include breakpoint(md){
            margin: $breadcrumbs-margin;
        }

        > li {
            display: inline-flex;
            align-items: center;

            &,
            > a,
            > strong {
                color: $breadcrumbs-font-color;
                font-size: $breadcrumbs-font-size;
                line-height: 1.4;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }

            > strong {
                font-weight: $fw-benton-B;
            }

            & + li {
                padding-left: $breadcrumbs-items-space;

                &::before {
                    position: relative;
                    top: 1px;
                    content: "\e907";
                    @extend %icon-icomoon;
                    font-size: $breadcrumbs-arrow-size;
                    color: $breadcrumbs-arrow-color;
                    padding-right: $breadcrumbs-items-space;
                }
            }
        }
    }
}


// Product View breadcrumbs mobile
.catalog-product-view .breadcrumbs {

    @include breakpoint(only-md) {
        .items {}

        .item {
            display: none;

            &:nth-last-child(2) {
                display: flex;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: $fw-benton-M;

                &::before {
                    content: "\e907";
                    @include icon-icomoon;
                    top: 0;
                    padding-right: 0;
                    transform: rotate(180deg);
                }
            }

            > a {
                display: inline-block;
                padding: 8px 5px;
                font-size: 1.6rem;
            }
        }
    }

}