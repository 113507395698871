// ==============================================
// LAYOUT - Columns
// ==============================================

.columns {
    @extend %clearer;
    padding-bottom: 30px;

    @include breakpoint(md) {
        padding-bottom: 40px;
    }
}

//
//  1 column
//  _____________________________________________

.col1-layout {

    .section {
        clear: both;
        overflow: hidden;
    }

    @include breakpoint(md) {
        .full {
            width: $w-full;
        }

        .half {
            float: left;
            width: $w-half;
        }

        .third {
            float: left;
            width: $w-third;
        }

        .quarter,
        .fourth {
            float: left;
            width: $w-fourth;
        }

        .three-quarter {
            float: left;
            width: $w-fourth * 3;
        }

        .two-thirds {
            float: left;
            width: $w-third * 2;
        }
    }
}

//
//  2 columns left
//  _____________________________________________

.page-layout-2columns-left {

    .columns {
        @include breakpoint(md){
            .column.main {
                width: $main-column-width;
                float: right;
            }

            .sidebar-main,
            .sidebar-additional {
                float: left;
                width: $sidebar-width;
                padding-right: 2%;
            }

            .sidebar-additional {
                clear: left;
                padding-left: 0;
            }
        }
    }
}

//
//  2 columns right
//  _____________________________________________

.page-layout-2columns-right {

    .columns {
        @include breakpoint(md){
            .column.main {
                width: $main-column-width;
                float: left;
            }

            .sidebar {
                float: right;
                width: $sidebar-width;
                padding-left: 2%;
            }
        }
    }
}

//
//  3 columns
//  _____________________________________________

.col3-layout {

    @include breakpoint(md){
        .col-main,
        .col-left {
            float: left;
        }

        .col-right {
            float: right;
        }
    }
}

//
//  Col2-set styles
//  _____________________________________________

.col2-set {
    @extend .container;

    @include breakpoint(md){

        .col-1,
        .col-2 {
            float: left;
        }

        .col-1 {
            width: 50%;
            padding: 0 $gutter/2;
        }

        .col-2 {
            width: 50%;
            padding: 0 $gutter/2;
        }
    }
}

//
//  3 columns Content manager
//  _____________________________________________

.page-layout-3columns{
    &[class^="contentmanager-contentlist"]{

        @include breakpoint(md){
            .columns {
                display: flex;
                flex-direction: row;
            }

            .main {
                order: 2;
                width: $main-column-width;
            }

            .ct-sidebar-additional {
                order: 3;
                width: $sidebar-width;
            }

            .sidebar-main {
                order: 1;
                width: $sidebar-width;
            }
        }

        @include breakpoint(only-md){
            .sidebar-main{
                display: none;
            }
        }
    }
}