// ==============================================
// TABLES - Data
// ==============================================

table.data {
    &.table {
        .table-caption {
            @extend .visually-hidden;
        }
    }

    // Totals
    &.totals,
    &.table-totals{
        width: 100%;

        tr {
            display: flex;

            th, td {
                display: flex;
                flex-direction: column;
                padding: 6px 0;
                text-align: left;
                line-height: 1.4;
            }

            th {
                flex: 2;
                align-items: flex-start;
            }

            td {
                flex: 1;
                align-items: flex-end;
                padding-left: 10px;
                text-align: right;
            }

            &.grand.totals {
                margin-top: 4px;

                th, td {
                    padding: 10px 0;
                    font-size: 1.6rem;
                    border-top: 1px solid lighten($c-black, 80%);
                }

                strong {
                    font-weight: $fw-benton-M;
                }
            }
        }
    }
}
