// ==============================================
// BUTTONS - Custom
// ==============================================

//
//  Configuration
//  _____________________________________________

$button-color:              $c-base;
$button-color-hover:        $c-orange;

// Reset global buttons
button {
    font-size: 1.4rem;
    padding: 0;
    border: 0 none;
    background-color: transparent;
    font-family: $f-benton;
}

//
//  Main button styles
//  _____________________________________________

@mixin btn {
    display: inline-block;
    padding:10px 20px;
    background-color: $button-color;
    border: 1px solid $button-color;
    border-radius: $radius-btn;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0);
    color: $c-white;
    font-size:1.2rem;
    font-family: $f-benton;
    font-weight:$fw-benton-B;
    line-height:1;
    text-align: center;
    text-decoration: none;
    transition: background-color .3s, border-color .3s, color .3s, box-shadow .3s;

    &:first-letter{
        text-transform: uppercase;
    }

    &:hover:not(:disabled) {
        cursor: pointer;
        transition: background-color .3s, border-color .3s, color .3s;
        background-color: $button-color-hover;
        border-color: $button-color-hover;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    }
}

.btn {
    @include btn;

    &:disabled,
    &.disabled {
        background-color: $c-light7;
        border-color: $c-light7;
        color: $c-grey3;
        pointer-events: none;
        padding: 10px 16px; // Put again to reset jcrop.css styles
    }

    // Button with reduced space and pictogram
    &.btn-smaller {
        padding: 5px 16px;
        font-size: 1.2rem;

        &::before,
        &::after {
            font-size: 1.3rem;
        }
    }

    // Button with light border color
    &.btn-light {
        border-color: $c-light6;
    }

    &.btn-secondary{
        color: $c-white;
        background-color: $c-black;
        border-color: $c-white;
        margin-left: 20px;

        &:hover, &:focus{
            color: $c-black;
            background-color:$c-white;
            border-color:$c-black;
        }
    }
}


//
//  Extended default styles
//  _____________________________________________


// Extend default styles
.btn--border,
.btn--border-white,
.btn--icon {
    @extend .btn;
}

// Without filling (only border)
@mixin btn--border {
    background-color: transparent;
    border-color: $button-color;
    color: $c-base;

    &:hover:not(:disabled) {
        background-color: $c-base;
        border-color: $button-color;
        color: $c-white;
    }
}
.btn--border {
    @include btn--border;
}

// Without filling (only border)
@mixin btn--border-light {
    background-color: transparent;
    border-color: $c-light6;
    color: $c-base;

    &:hover:not(:disabled) {
        background-color: $c-base;
        border-color: $button-color;
        color: $c-white;
    }
}
.btn--border-light {
    @include btn--border-light;
}

@mixin btn--border-white {
    background-color: transparent;
    border-color: $c-white;
    color: $c-white;

    &:hover:not(:disabled) {
        background-color: $c-white;
        border-color: $c-white;
        color: $c-orange;
    }
}
.btn--border-white {
    @include btn--border-white;
}


// Btn icons
.btn--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before,
    &::after {
        margin-left: 10px;
        display: inline-block;
        font-size: 1.8rem;
        @extend %icon-icomoon;
    }
}

// Add to cart Icon
.btn--cart {
    @extend .btn--icon;

    &.btn-smaller::after {
        margin-left: 5px;
    }

    &::after {
        content:"\e90b";
    }
}

// Edit Icon
.btn--edit {
    @extend .btn--icon;

    &::before {
        content: "\e925";
        margin-left: 0;
        margin-right: 10px;
    }
}

// Download Icon
.btn--download {
    @extend .btn--icon;

    &::after {
        content: "\e924";
    }
}

// Note Icon
.btn--note {
    @extend .btn--icon;

    &::after {
        content: "\e944";
    }
}

// Billet Icon
.btn--ticket {
    @extend .btn--icon;

    &::after {
        content: "\e90e";
    }
}

// Mail Icon
.btn--mail {
    @extend .btn--icon;

    &::after {
        content: "\e93a";
    }
}

// Anchor Icon
.btn--anchor {
    @extend .btn--icon;

    &::after {
        content: "\e909";
        transform: rotate(90deg);
    }
}

// Target Icon
.btn--target {
    @extend .btn--icon;

    &::after {
        content: "\e954";
    }
}

// Map Icon
.btn--map {
    @extend .btn--icon;

    &::after {
        content: "\e965";
    }
}


// Agenda Icon
.btn--agenda {
    @extend .btn--icon;

    &::after {
        content: "\e911";
    }
}

//double arrow circle variant
.btn--double-arrows{
    width: 26px;
    height: 26px;
    border: 1px solid $c-black;
    border-radius: 16px;
    padding: 5px;
    background-color:$c-white;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0);
    transition: background-color .3s, border-color .3s, box-shadow .3s;

    @include breakpoint(md){
        width: 32px;
        height: 32px;
        padding: 7px;
    }

    &:hover, &:focus{
        background-color:$c-black;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
        .double-arrows-icon{
            background-color:$c-white;
        }
    }
}

.double-arrows-icon{
    mask-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M9.007 7.405 2.277.684A.965.965 0 0 0 .92.734a.982.982 0 0 0 0 1.31l6.04 6.054-6.04 6.055a.97.97 0 0 0 .051 1.36.976.976 0 0 0 1.305 0L8.981 8.79c.384-.385.384-1 .026-1.386Z'/><path d='M15.712 7.405 10.44 2.12a.967.967 0 0 0-1.356.129 1.001 1.001 0 0 0 0 1.231l4.606 4.618-4.606 4.618a.97.97 0 0 0 .051 1.36.976.976 0 0 0 1.305 0l5.272-5.31c.384-.36.384-.976 0-1.36Z'/></svg>");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $c-black;
    display: block;
    width: 14px;
    height: 14px;
    transition: background-color .3s;

    @include breakpoint(md){
        width: 16px;
        height: 16px;
    }
}

// Universe variants
.universe-none{
    .btn{
        color: $c-black;
        background-color:$c-white;
        border-color:$c-black;

        &:hover, &:focus{
            color: $c-white;
            background-color: $c-black;
            border-color: $c-black;
        }

        &.btn-secondary{
            color: $c-white;
            background-color: $c-black;
            border-color: $c-white;

            &:hover, &:focus{
                color: $c-black;
                background-color:$c-white;
                border-color:$c-black;
            }
        }
    }
    .btn--double-arrows{
        background-color:$c-white;
        border-color: $c-black;

        &:hover, &:focus{
            background-color:$c-black;
            .double-arrows-icon{
                background-color:$c-white;
            }
        }
    }
}
.universe-yellow{
    .btn{
        color: $c-black;
        background-color:$c-gold4;
        border-color:$c-gold4;

        &:hover, &:focus{
            background-color:$c-white;
            border-color:$c-gold4;
        }

        &.btn-secondary{
            color: $c-gold4;
            background-color: $c-black;
            border-color: $c-gold4;

            &:hover, &:focus{
                color: $c-black;
                background-color:$c-gold4;
                border-color:$c-gold4;
            }
        }
    }

    .btn--double-arrows{
        background-color:$c-gold4;
        border-color: $c-gold4;

        &:hover, &:focus{
            background-color:$c-white;
            .double-arrows-icon{
                background-color:$c-gold4;
            }
        }
    }
}
.universe-blue{
    .btn{
        color: $c-black;
        background-color:$c-blue;
        border-color:$c-blue;

        &:hover, &:focus{
            background-color:$c-white;
            border-color:$c-blue;
        }

        &.btn-secondary{
            color: $c-blue;
            background-color: $c-black;
            border-color: $c-blue;

            &:hover, &:focus{
                color: $c-black;
                background-color:$c-blue;
                border-color:$c-blue;
            }
        }
    }
    .btn--double-arrows{
        background-color:$c-blue;
        border-color: $c-blue;

        &:hover, &:focus{
            background-color:$c-white;
            .double-arrows-icon{
                background-color:$c-blue;
            }
        }
    }
}
.universe-green{
    .btn{
        color: $c-black;
        background-color:$c-green;
        border-color:$c-green;

        &:hover, &:focus{
            background-color:$c-white;
            border-color:$c-green;
        }

        &.btn-secondary{
            color: $c-green;
            background-color: $c-black;
            border-color: $c-green;

            &:hover, &:focus{
                color: $c-black;
                background-color:$c-green;
                border-color:$c-green;
            }
        }
    }
    .btn--double-arrows{
        background-color:$c-green;
        border-color: $c-green;

        &:hover, &:focus{
            background-color:$c-white;
            .double-arrows-icon{
                background-color:$c-green;
            }
        }
    }
}
