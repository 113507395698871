// ==============================================
// BASE - Wysiwyg
// ==============================================

.cms-page-view:not(.cms-styleguide),
.cms-index-index {

    //
    //  Title wrapper
    //  _____________________________________________

    .page-title-wrapper {
        @extend .container;

        .page-title {
            @extend .strate-title;

        }

        @include breakpoint(only-md) {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 10px;
        }
    }

    &:not(.with-topfooter) {
        .mdp-footer-reinsurance {
            @extend .visually-hidden;
        }
    }
}

.cms-page-view {

    &:not(.cms-styleguide) {
        .page-main {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .columns {
        margin-top: $gutter;

        @include breakpoint(md) {
            margin-top: $gutter-large;
        }
    }
}


//
//  Page builder
//  _____________________________________________

*[data-content-type="row"] {

    //
    // Layout
    //

    // Container with max-width
    &[data-appearance="contained"] {
        //max-width: $container-width;
        //margin-left: auto;
        //margin-right: auto;
    }

    // Full width with gutter
    &[data-appearance="full-width"] {
        padding-left: $gutter;
        padding-right: $gutter;

        @include breakpoint(md) {
            padding-left: $gutter-large;
            padding-right: $gutter-large;
        }
    }

    // Full bleed (full width)
    &[data-appearance="full-bleed"] {

    }


    // Parallax row (Using important to reset inline style)
    &[data-enable-parallax="1"] {
        margin-bottom: $gutter*2 !important;

        @include breakpoint(lg) {
            margin-bottom: $gutter*2 + $gutter-large !important;
        }

        *[id^="jarallax-container"] > div {
            margin: 0 !important;
        }
    }
}

// Heading Page builder

h1[data-content-type="heading"] {
    @extend .title-1;
    @extend .strate-title;
}

h2[data-content-type="heading"] {
    @extend .title-2;
    @extend .strate-title;
}

h3[data-content-type="heading"],
h4[data-content-type="heading"] {
    @extend .title-3;
}

h5[data-content-type="heading"],
h6[data-content-type="heading"] {
    @extend .subtitle-1;
}

.text-wysiwyg,
*[data-content-type="text"] {

    // Hn Tag in wysiwyg
    h1 {
        @extend .title-1;
    }
    h2 {
        @extend .title-2;
    }
    h3 {
        @extend .title-3;
    }

    & > p {
        a {
            @extend .link-hover-black;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    a {
        @extend .animated-border;
    }

    em,
    i {
        font-style: italic;
    }

    // Lists
    ol:not(.items):not(.slick-dots):not(.strate-list):not(.products),
    ul:not(.items):not(.slick-dots):not(.strate-list):not(.products) {
        padding-left: $gutter;
        list-style: revert;

        li {
            padding: 5px 0;
        }
    }


    // Tables
    &.table {
        @include breakpoint(only-md) {
            overflow-x: auto;

            table td {
                min-width: 250px;
            }
        }
    }

    table {
        max-width: 1440px;
        padding-left: $gutter;
        padding-right: $gutter;
        margin-left: auto;
        margin-right: auto;
        table-layout: fixed;

        &,
        td {
            border: 2px solid;
        }

        thead {
            td {
                font-weight: $fw-benton-B;
                text-align: center;
                vertical-align: middle;
            }
        }

        tr,
        td {
            padding: $gutter-large $gutter;
        }
    }
}

*[data-content-type="text"] {
    margin-bottom: $gutter*2;

    @include breakpoint(lg) {
        margin-bottom: $gutter*2 + $gutter-large;
    }
}

// Spacings between elements

h1[data-content-type="heading"],
h2[data-content-type="heading"],
h3[data-content-type="heading"],
h4[data-content-type="heading"],
h5[data-content-type="heading"],
h6[data-content-type="heading"],
*[data-content-type="text"] > p,
*[data-content-type="text"] > ul {
    & + h1, & + h2 {
        margin-top: 30px;
    }

    & + h3, & + h4, & + h5, & + h6 {
        margin-top: 30px;
    }

    & + p:not(.strate-desc),  & + ul {
        margin-top: 5px;
        margin-bottom: 0;
    }

    & + ul {
        margin-top: 10px;

        li {
            & + li {
                margin-top: 0;
            }
        }
    }

    img {
        margin-bottom: 0;
    }
}


// Responsive images
figure {
    &[data-appearance="full-width"] img {
        width: 100%;
    }
}

.pagebuilder-mobile-hidden {
    display: none;

    @include breakpoint(md) {
        display: inline-block;
    }
}

.pagebuilder-mobile-only {
    @include breakpoint(md) {
        display: none;
    }
}


// Buttons

.pagebuilder-button-primary {
    @extend .btn;
}
.pagebuilder-button-secondary {
    @extend .btn--border;
}
.pagebuilder-button-link {
    @extend .main-link;
}


//
// GDRP Mentions
//

.gdrp-mentions-wrapper {
    margin-top: $gutter-large;
    font-size: 14px;
    opacity: 0.8;
}