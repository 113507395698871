// ==============================================
// STRATE SLIDER IMAGE SIMPLE
// ==============================================

$slick-list-padding: 60px;
$slick-list-padding-md: 120px;
$slick-list-padding-lg: 180px;

$slick-slide-margin: 30px;
$slick-slide-margin-lg: 30px;

$slick-arrow-position: 10px;

.strate_slider_image_simple .slick-arrows-outside .slick-arrow {
    margin-top: 70px;
}

.strate-image-simple-list {
    margin-right: -$gutter;

    @include breakpoint(lg) {
        margin-right: calc(((100vw - 100% + 60px)/2)* -1);
    }

    @media screen and (min-width: $ultra-large){
        margin-right: calc(((100vw - 100% + 120px)/2)* -1);
    }

    &.slick-slider {
        display: flex;
    }

    .strate-image-simple-list-item {
        display: none;
        font-weight: $fw-benton-B;

        .strate-image-simple-image {
            margin-bottom: 15px;
        }

        .slick-initialized & {
            display: block;
        }
    }

    // List
    .slick-list {
        padding-right: $slick-list-padding;

        @include breakpoint(md) {
            padding-right: $slick-list-padding-md;
        }

        @include breakpoint(lg) {
            padding-right: $slick-list-padding-lg;
        }
    }

    // Slide
    .slick-slide {
        + .slick-slide {
            margin-left: $slick-slide-margin;

            @include breakpoint(lg) {
                margin-left: $slick-slide-margin-lg;
            }
        }

        img {
            margin: 0;
        }
    }

    // Nav
    .slick-arrow {

        &.slick-next {
            right: calc(#{$slick-list-padding} + #{$slick-slide-margin} + #{$slick-arrow-position});

            @include breakpoint(md) {
                right: calc(#{$slick-list-padding-md} + #{$slick-slide-margin} + #{$slick-arrow-position});
            }

            @include breakpoint(lg) {
                right: calc(#{$slick-list-padding-lg} + #{$slick-slide-margin-lg} + #{$slick-arrow-position});
            }
        }
    }

    // Dots
    .slick-dots {
        padding-right: $slick-list-padding;

        @include breakpoint (md) {
            padding-right: $slick-list-padding-md;
        }

        @include breakpoint (lg) {
            padding-right: $slick-list-padding-lg;
        }
    }
}
