// ==============================================
// PRODUCT ITEM
// ==============================================

.product-item__inner {
    position: relative;

    @include breakpoint(sm) {
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus,
        &:active {
            &::before {
                top: 0;
            }

            .product-item-link {
                > * {
                    background-size: 100% 1px;
                }
            }

            .product-item-photo {
                .hover-classic {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        // Left line animation
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            bottom: 0;
            width: 1px;
            background-color: $c-light6;
            transition: top .5s ease-out;
        }

        // Product title line animation
        .product-item-link {
            > * {
                @include animated-border;
            }
        }
    }

    //
    //  Visual
    //  _____________________________________________

    .product-item-photo {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        padding-left: $gutter-large/2;
        padding-right: $gutter-large/2;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: pointer;
        }

        @include breakpoint(sm) {
            position: relative;
        }

        @include breakpoint(md) {
            margin-bottom: 50px;
        }

        img.photo {
            display: block;
            width: 100%;
        }

        .product-image-container {
            width: 100% !important;
            text-align: center;
        }

        .product-image-wrapper {
            padding-bottom: 0 !important;
        }

        // Hover image

        .hover-classic {
            display: none;

            @include breakpoint(sm) {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                z-index: 1;
                left: $gutter-large/2;
                right: $gutter-large/2;
                top: 0;
                bottom: 0;
                visibility: hidden;
                opacity: 0;
                transition: opacity .3s ease-in, visibility .3s ease-in;
                text-align: center;
                background-color: $c-white;
            }
        }

        .hover-image {
            max-height: 100%;
        }
    }

    //
    //  Details
    //  _____________________________________________

    .product-item-details {
        display:flex;
        flex-flow:column;
        padding-left: $gutter-large/2;
        padding-right: $gutter-large/2;
        position: relative;
        

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background: $c-light6;
        }

        @include breakpoint(sm) {
            flex: 1;
            display: flex;
            flex-direction: column;

        }
    }

    // Link
    .product-item-link {
        margin-bottom: 20px;
        font-family: $f-benton;
        font-size: 1.6rem;
        line-height: 1.5;

        @include breakpoint(md) {
            margin-bottom: 30px;
        }
    }

    // Name
    .product-item-name {
        font-weight: $fw-benton-M;
    }

    .product-item-name-caracteristic {
        display: block;
        font-weight: $fw-benton-R;
    }

    // Price
    .price-box {
        @include breakpoint(sm) {
           margin-top: auto;
        }
    }

    // Actions
    .product-item-actions {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    .product-item-metas {
        margin-bottom: 14px;
        min-height: 25px;
        padding-left: $gutter-large/2;
    }

    .add-to-wishlist {
        position: relative;
        z-index: 1;
        margin-left: $gutter/2;
        min-height:28px;
        margin-bottom:15px;

        .action {
            padding: $gutter/2;
            font-size: 1.8rem;

            @include breakpoint(lg) {
                font-size: 2.4rem;
            }
        }
    }

    // Form cta
    .action.tocart {
        margin-top: 10px;
    }

    .product-item-discover-link{
        display:none;
    }

}

//
//  Fake item
//  _____________________________________________

.product-fake-item-details {
    overflow: hidden;
    position: relative;

    // Link
    .product-fake-item-link {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        &:hover,
        &:focus,
        &:active {
            img {
                transform: scale(1.05);
            }

            .product-fake-item-fake-link {
                background-color: $c-white;
                border-color: $c-white;
                color: $c-orange;
            }
        }
    }

    // Image
    .product-image-container {
        display: flex;
        justify-content: center;
        width: 100% !important;
        height: 100%;

        > .product-image-wrapper {
            padding-bottom: 0 !important;
            width: 100%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            transition: transform 1s;
            object-fit: cover;
        }
    }

    // Short desc
    .product-fake-item-content {
        z-index: 1;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px 30px 25px;
        background-color: $c-black;

        > .product-fake-item-short-desc {
            width: 100%;
            font-family: $f-bentonSC;
            font-size: 2.6rem;
            line-height: 1.2;
            font-weight: $fw-benton-B;
            color: $c-white;

            > * {
                margin: 0;
                line-height: inherit;

                + * {
                    margin-top: 1em;
                }
            }
        }

        > .product-fake-item-fake-link {
            margin-top: 20px;
        }
    }
}


// Global product item metas
.product-item-metas {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

// Product item (Listing / cross-sell / up-sell)
.product-item__inner .product-item-metas {
    justify-content: flex-end;

    .labels,
    .discount-percent {
        //margin-top: $gutter/2;
    }

    .labels {
        margin-right: auto;

        & + .discount-percent {
            margin-left: auto;
        }
    }
}

#amasty-shopby-product-list .product-item__inner .product-item-photo .product-image-photo {
    max-height: 275px;
    width: auto;
}