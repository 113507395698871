// ==============================================
// VARIABLES - Layout
// ==============================================

//
//  Containers
//  _____________________________________________

$container-width-xl:        1680px;
$container-width-l:         1440px;
$container-width:           1200px;
$inner-container-width-xl:  1024px;
$inner-container-width:     730px;
$container-small:           440px;
$gutter:                    20px;
$gutter-large:              30px;

//
//  Columns
//  _____________________________________________

$sidebar-width:             20%;
$main-column-width:         80%;

//
//  Blocks width
//  _____________________________________________

$w-full:        100%;
$w-half:        $w-full / 2;
$w-third:       $w-full / 3;
$w-fourth:      $w-full / 4;

//
//  Border radius
//  _____________________________________________

$radius-box:        10px;
$radius-input:      100px;
$radius-btn:        100px;

//
//  Overlay
//  _____________________________________________

$c-overlay:         rgba(47, 47, 47, 0.55);
