// ==============================================
// PRODUCT GALLERY
// ==============================================

//
//  Configuration
//  _____________________________________________

$gallery-nav-thumb-padding:                         4px;
$gallery-nav-thumb-margin:                          5px;
$gallery-nav-thumb-border-width:                    1px;
$gallery-nav-thumb-border-color:                    darken($c-white, 10%);
$gallery-nav-thumb-active-border-width:             2px;
$gallery-nav-thumb-active-border-color:             lighten($c-black, 20%);
$gallery-nav-thumb-border-radius:                   $radius-btn;

$gallery-nav-thumb-size:                            65px;

$gallery-nav-thumb-array-color:                     lighten($c-black, 60%);
$gallery-nav-thumb-array-background-color:          $c-white;

$gallery-nav-thumb-marker-border-color:             $c-black;
$gallery-nav-thumb-marker-background:               transparent;

$gallery-nav-array-width:                           80px;
$gallery-nav-array-color:                           lighten($c-black, 60%);
$gallery-nav-array-background-color:                transparent;

$gallery-product-zoom-circle-width:                 40px;
$gallery-product-zoom-background-color:             lighten($c-black, 60%);
$gallery-product-zoom-color: $c-white;;

$gallery-play-icon-circle-width-large:              46px;
$gallery-play-icon-circle-width-small:              25px;
$gallery-play-icon-color: $c-white;
$gallery-play-icon-background-color:                lighten($c-black, 60%);

$gallery-fullpage-icon-width:                       80px;
$gallery-fullpage-icon-height:                      80px;
$gallery-fullpage-icon-hover-background-color:      darken($c-white, 5%);
$gallery-fullpage-icon-color:                       lighten($c-black, 60%);

//
//  Base Markup
//  _____________________________________________

.catalog-product-view {

    .action-skip-wrapper {
        display: none;
    }

    // Fotorama override
    .fotorama {

        .fotorama__wrap {

            .fotorama__caption,
            .fotorama__thumb-border {
                display: none;
            }

            // Reset prev/next shadow
            .fotorama__stage.fotorama__shadows--left:before,
            .fotorama__stage.fotorama__shadows--right:after,
            .fotorama__stage.fotorama__shadows--top:before,
            .fotorama__stage.fotorama__shadows--bottom:after,
            .fotorama__nav.fotorama__shadows--left:before,
            .fotorama__nav.fotorama__shadows--right:after,
            .fotorama__nav.fotorama__shadows--top:before,
            .fotorama__nav.fotorama__shadows--bottom:after {
                background-image: none;
                background-position: 0;
                bottom: 0;
            }

            // Reset icon shadow
            .fotorama__fullscreen-icon:focus {
                box-shadow: none;
                &:after {
                    box-shadow: 0 0 0 0 transparent;
                }
            }

            .fotorama__stage {}

            .fotorama__stage__frame {
                //pointer-events: none;
            }

            // Thumbs
            .fotorama__thumb {
                background-color: transparent;
            }

            .fotorama__thumb-border {
                border-color: $gallery-nav-thumb-marker-border-color;
                background: $gallery-nav-thumb-marker-background;
            }

            // Vertical thumbs arrows
            .fotorama__thumb__arr {
                background-color: $gallery-nav-thumb-array-background-color;
                transition: all .5s;

                // Arrow
                &::after {
                    @include absolute-center;
                    font-size: 2.6rem;
                    color: $gallery-nav-thumb-array-color;
                    z-index: 100;
                }

                // Arrow up
                &.fotorama__thumb__arr--left {
                    &::after {
                        content: "↑";
                    }
                }

                // Arrow bottom
                &.fotorama__thumb__arr--right {
                    &::after {
                        content: "↓";
                    }
                }
            }

            .fotorama__arr--disabled {
                opacity: 0;
                transition: all .5s;
            }

            // Thumbs icons navigation
            .fotorama__thumb--icon {
                display: none;
            }

            // Thumbs navigation
            .fotorama__nav {
                overflow: initial;
            }
            .fotorama__nav-wrap {

                &.fotorama__nav-wrap--horizontal {

                    .fotorama__nav__frame {
                        & + .fotorama__nav__frame {
                            margin-left: $gallery-nav-thumb-margin;
                        }
                        &::after {
                            transform: rotate(-90deg) translate(0px, 5px);
                            top: -20px;
                            right: 50%;
                        }
                    }
                }

                &.fotorama__nav-wrap--vertical {
                    .fotorama__nav__frame + .fotorama__nav__frame {
                        margin-top: $gallery-nav-thumb-margin;
                    }

                    // reset
                    .fotorama__nav__shaft {
                        width: auto;
                    }
                }
            }

            .fotorama__nav--thumbs {
                display: flex;
                align-items: center;
                width: 100px !important;
                max-height: 550px;
            }

            .fotorama__nav__frame {
                padding: $gallery-nav-thumb-padding !important;

                &:hover {
                    cursor: pointer;
                }

                @include breakpoint(lg) {

                    &::after {
                        content: "\e907";
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -20px;
                        transform: translateY(-50%);
                        opacity: 0;
                        visibility: hidden;
                        font-size: 1.4rem;
                        pointer-events: none;

                        @include icon-icomoon;
                    }

                    &.fotorama__active {
                        &::after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }

            // Dots
            .fotorama__active .fotorama__dot {
                background-color: $c-base;
            }

            .fotorama__dot {
                display: block;
                position: relative;
                top: 12px;
                left: 6px;
                height: 6px;
                width: 6px;
                border-radius: 6px;
                background-color: $c-grey;
                border: none;
            }

            // Video box container
            .fotorama-video-container {
                &::before,
                &::after {
                    @include absolute-center;
                }

                // Play icon
                &::after {
                    content: "›";
                    font-size: 2.4rem;
                    color: $gallery-play-icon-color;
                    z-index: 2;
                }

                // Play circle
                &::before {
                    content: "";
                    @include circle($gallery-play-icon-circle-width-large);
                    background-color: $gallery-play-icon-background-color;
                    z-index: 1;
                }

                &.video-unplayed {
                    img {
                        transition: opacity .2s;
                    }

                    &:hover {
                        img {
                            opacity: .6;
                            transition: opacity .2s;
                        }
                    }
                }
            }

            // Video box
            .product-video {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 75%;
                margin: auto;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9999;
                }
            }

            // Video thumb
            .video-thumb-icon .fotorama__thumb {
                &::before,
                &::after {
                    @include absolute-center;
                }

                // Play icon
                &::after {
                    content: "›";
                    font-size: 1.4rem;
                    color: $gallery-play-icon-color;
                    z-index: 2;
                }

                // Play circle
                &::before {
                    content: "";
                    @include circle($gallery-play-icon-circle-width-small);
                    background-color: $gallery-play-icon-background-color;
                    z-index: 1;
                }
            }
        }
    }

    // Zoom / Magnifier
    .product.media {
        position: relative;
    }


    //
    //  Fotorama Reset width thumbnails
    //  _____________________________________________

    .fotorama__thumb-border,
    .fotorama__nav__frame,
    .fotorama__nav__frame .fotorama__img {
        @include breakpoint(md) {
            width: $gallery-nav-thumb-size !important;
            height: $gallery-nav-thumb-size !important;
        }
    }
}


//
//  Not Fotorama fullscreen only
//  _____________________________________________

html:not(.fotorama__fullscreen) {
    .catalog-product-view .fotorama .fotorama__wrap .fotorama__stage {

        @include breakpoint(md) {
            max-height: 550px !important;
            left: 100px !important;
        }
    }
}



//
//  Fotorama fullscreen
//  _____________________________________________

html.fotorama__fullscreen {

    // Zoom buttons
    .fotorama__fullscreen-icon,
    .fotorama__zoom-in,
    .fotorama__zoom-out {
        width: $gallery-fullpage-icon-width;
        height: $gallery-fullpage-icon-height;
        background-image: none;

        &:hover {
            background-color: $gallery-fullpage-icon-hover-background-color !important;
        }

        &:before {
            @include absolute-center;
            font-size: 3.6rem;
            color: $gallery-fullpage-icon-color;
        }
    }

    .fotorama__fullscreen-icon {

        &:before {
            content: "\e616";
            @extend %icon-jade;
        }
    }

    .fotorama__zoom-in {

        &:before {
            content: '+';
        }
    }

    .fotorama__zoom-out {

        &:before {
            content: '−';
        }
    }

    .fotorama__stage,
    .fotorama__nav--thumbs {
        max-height: none;

        @include breakpoint(md) {
            padding-left: 0
        }
    }

    .catalog-product-view .fotorama {

        .fotorama__wrap {
            .fotorama__nav--thumbs {
                width: auto !important;
                justify-content: center;
            }

            .fotorama__stage {
                left: auto !important;
            }
        }
    }

}