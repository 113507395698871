// ==============================================
// FORMS - Radios
// ==============================================

//
//  Configuration
//  _____________________________________________

$radio-width:                   18px;
$radio-height:                  18px;
$radio-border-weight:           1px;

$radio-checked-color:          $c-base;

.choice {
    input[type="radio"] {
        & + label {
            font-size: 1.4rem;
        }
    }
}

body {

    input[type="radio"] {
        @extend .visually-hidden;

        & + label {
            position: relative;
            display: inline-block;
            padding-top: 4px;
            padding-left: 36px;
            min-height: 24px;
            cursor: pointer;

            &::before,
            &::after {
                position: absolute;
                content: "";
                cursor: pointer;
                border-radius: 100%;
                transition: all .2s;
            }

            // Fake radio
            &::before {
                left: 0;
                top: 0;
                width: $radio-width;
                height: $radio-height;
                border: $radio-border-weight solid $c-light6;
                background-color: $c-white;
            }

            // Round
            &::after {
                width: calc(#{$radio-width} - 8px);
                height: calc(#{$radio-height} - 8px);
                left: calc(calc(#{$radio-width} + #{$radio-border-weight} * 2) / 2);
                top: calc(calc(#{$radio-height} + #{$radio-border-weight} * 2) / 2);
                background-color: $radio-checked-color;
                opacity: 0;
                transform: scale(0.5) translate(-100%, -100%);
            }
        }

        &:not(:checked):hover {
            & + label {
            }
        }

        &:checked {
            & + label {
                // Fake radio
                &::before {

                }

                // Round
                &::after {
                    opacity: 1;
                    transform: scale(1) translate(-50%, -50%);
                    transition: all .2s;
                }
            }
        }
    }

}
