// ==============================================
// PUBLIC - Common
// ==============================================

.customer-account-logoutsuccess,
.customer-account-create,
.customer-account-createpassword,
.customer-account-forgotpassword,
.customer-account-login,
.contact-index-index,
.cms-page-view.collection-form {
    background-color: $c-light7;

    .page-header {
        background-color: $c-light7;
    }

    .page-main {
        max-width: $container-width;
    }

    .page-title {
        @extend .title-1;
        margin-top: 30px;
        border-bottom: 1px solid $c-light6;

        @include breakpoint(md) {
            margin-top: 40px;
        }
    }

    .block-title {
        @extend .title-2;
    }

    .columns .column.main {
        width: 100%;
        max-width: $container-small;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;

        @include breakpoint(md) {
            margin-top: 100px;
        }

        input {
            //max-width: 424px;
        }
    }
}

.customer-account-forgotpassword,
.customer-account-login {
    // Layout
    .columns {
        padding-bottom: 125px;

        @include breakpoint(md) {
            padding-bottom: 230px;
        }
    }
}

[class*="customer-account-"] {
    .login-container,
    .form.password.forget,
    .form.create.account.form-create-account {
        padding-left: 15px;
        border-left: 1px solid $c-light6;
    }
}

