// ==============================================
// ACCOUNT - Search advanced
// ==============================================

.my-orders-search-advanced {

    + .filter-divider {
        height: 1px;
        margin: 20px 0 30px;
        background-color: $c-light6;
    }

    select {
        padding-top: 18px;
        padding-bottom: 17px;
    }

    @include breakpoint(only-md) {
        select {
            padding-left: 20px;
        }

        input {
            padding-right: 15px;
            padding-left: 15px;

            + label {
                margin-left: 20px !important;
            }
        }
    }

    // Fieldset
    fieldset {
        &.orders-search-bar {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include breakpoint(xs) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }

        &.extra-order-search {
            display: none;

            &[style="display: block;"],
            &[style="display: flex;"]{
                margin-top: 10px;
                animation: hideBeforeShowing .5s;

                @include breakpoint(only-md) {
                    margin-top: 10px;
                }

                @include breakpoint(md) {
                    display: flex !important;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-top: 20px;

                    > .field {
                        width: calc(50% - 10px);
                        margin-top: 20px;

                        &.field-order-totals {
                            margin-bottom: -10px;
                        }
                    }
                }

                .field-order-total .label {
                    white-space: nowrap;
                }
            }
        }

        &.filter-summary {
            > ul {
                display: flex;
                flex-wrap: wrap;
                margin: 20px -5px 0;
                font-size: 1.2rem;

                li {
                    &:last-of-type {
                        width: 100%;
                        padding: 0;
                        margin-top: 15px;
                        border: inherit;
                        border-radius: inherit;
                    }

                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: 6px 10px 6px 30px;
                    margin: 5px;
                    border: 1px solid $c-light6;
                    border-radius: $radius-btn;
                    line-height: 1.5;
                    transition: border-color .3s;

                    &:hover {
                        border-color: $c-black
                    }

                    > a {
                        transition: color .3s;

                        &:hover {
                            color: $c-orange;
                        }

                        &:not(.action-clear-all) {
                            position: absolute;
                            content: '';
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            &::before {
                                @extend %icon-icomoon;
                                content: "\e91c";
                                position: absolute;
                                top: 50%;
                                left: 10px;
                                transform: translateY(-50%);
                            }
                        }

                        &.action-clear-all {
                            border-bottom: 1px solid currentColor;
                            font-size: 1.4rem;
                        }
                    }

                    > span {
                        &.label {
                            margin-right: 10px;
                            font-size: inherit;
                            font-weight: $fw-benton-M;
                        }

                        &.sub-label {
                            margin-right: 5px;
                            font-weight: $fw-benton-M;

                            + span {
                                margin-right: 10px;
                            }
                        }

                        // Prices
                        &[data-name="order-total-min"],
                        &[data-name="order-total-max"] {
                            &::after {
                                content:"€";
                            }
                        }

                        &[data-name="order-total-min"] + *[data-name="order-total-max"] {

                            &::before {
                                content:"-";
                                margin-left: 5px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    // Field
    .field {
        &.search-sku {
            position: relative;
            flex: 1;
            max-width: 480px;

            @include breakpoint(xs) {
                margin-right: $gutter;
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 60px;
                font-size: 2rem;
                color: $c-light6;
            }
        }

        &.filters-collapse {
            margin-top: $gutter;

            @include breakpoint(xs) {
                margin-top: 0;
            }
        }

        &.field-order-status,
        &.field-dates,
        &.field-created-by {
            margin-bottom: 10px;

            @include breakpoint(md) {
                margin-bottom: 0;
            }

            > label {
                margin-bottom: 5px;
                margin-left: 0 !important;
            }
        }

        &.field-dates {
            > .label[for="order-date-from"] {
                margin-left: 0;
            }

            .range {
                .control {
                    display: flex;
                    align-items: center;

                    > .sub-label {
                        margin-right: 5px;
                        font-family: $f-bentonSC;
                        font-size: 1.1rem;
                    }
                }
            }
        }

        &.field-date {
            margin-top: 0;

            @include breakpoint(only-md) {
                + .field-date {
                    margin-top: 10px;
                }
            }

            > .control {
                position: relative;

                > input[type="text"] {
                    &[id="order-date-from"],
                    &[id="order-date-to"] {
                        padding-bottom: 20px;
                    }
                }
            }

            .ui-datepicker-trigger {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: $input-height;
                width: 40px;
                font-size: 0;
                cursor: pointer;

                @include breakpoint(lg) {
                    height: $input-height-desktop;
                }

                &::before {
                    @extend %icon-icomoon;
                    content: "\e911";
                    font-size: 1.6rem;
                }
            }
        }
    }

    // Range
    .range {
        @include breakpoint(md) {
            display: flex;
            justify-content: space-between;

            > .field {
                width: calc(50% - 10px);
            }
        }
    }

    // Toolbar
    .actions-toolbar {
        width: 100%;
        margin: 20px 0;

        > .primary {
            display: flex;
            flex-direction: column;

            @include breakpoint(md) {
                flex-direction: row;
            }

            a {
                margin-bottom: 10px;

                @include breakpoint(md) {
                    margin-bottom: 0;
                    margin-right: 20px;
                }
            }

            button {
                text-align: center;
            }
        }
    }
}
