@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon/icomoon.eot?qpsivs');
    src:  url('../fonts/icomoon/icomoon.eot?qpsivs#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.woff2?qpsivs') format('woff2'),
    url('../fonts/icomoon/icomoon.ttf?qpsivs') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?qpsivs') format('woff'),
    url('../fonts/icomoon/icomoon.svg?qpsivs#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-response:before {
    content: "\e973";
}
.icon-info-2:before {
    content: "\e972";
}
.icon-dots:before {
    content: "\e970";
}
.icon-eye-2:before {
    content: "\e971";
}
.icon-luggage:before {
    content: "\e96d";
  }
  .icon-flag:before {
    content: "\e96e";
  }
  .icon-institution:before {
    content: "\e96f";
  }
  .icon-bag:before {
    content: "\e90b";
  }
  .icon-billet:before {
    content: "\e90e";
  }
  .icon-menu:before {
    content: "\e940";
  }
  .icon-search:before {
    content: "\e957";
  }
  .icon-user:before {
    content: "\e969";
  }
  .icon-heart-1:before {
    content: "\e92e";
  }
  .icon-star-1:before {
    content: "\e95e";
  }
  .icon-add-document:before {
    content: "\e900";
  }
  .icon-add-to-cart:before {
    content: "\e901";
  }
  .icon-alarm-clock:before {
    content: "\e902";
  }
  .icon-alert-1:before {
    content: "\e903";
  }
  .icon-alert:before {
    content: "\e904";
  }
  .icon-archive:before {
    content: "\e905";
  }
  .icon-archive2:before {
    content: "\e906";
  }
  .icon-arrow:before {
    content: "\e907";
  }
  .icon-arrow2:before {
    content: "\e908";
  }
  .icon-arrow3:before {
    content: "\e909";
  }
  .icon-attachment:before {
    content: "\e90a";
  }
  .icon-basket:before {
    content: "\e90c";
  }
  .icon-billet-close:before {
    content: "\e90d";
  }
  .icon-block:before {
    content: "\e90f";
  }
  .icon-bookmark:before {
    content: "\e910";
  }
  .icon-calendar:before {
    content: "\e911";
  }
  .icon-calendar2:before {
    content: "\e912";
  }
  .icon-call:before {
    content: "\e913";
  }
  .icon-camera:before {
    content: "\e914";
  }
  .icon-cart:before {
    content: "\e915";
  }
  .icon-change:before {
    content: "\e916";
  }
  .icon-change2:before {
    content: "\e917";
  }
  .icon-chat:before {
    content: "\e918";
  }
  .icon-chat2:before {
    content: "\e919";
  }
  .icon-check-1:before {
    content: "\e91a";
  }
  .icon-check:before {
    content: "\e91b";
  }
  .icon-close:before {
    content: "\e91c";
  }
  .icon-credit-card:before {
    content: "\e91d";
  }
  .icon-dashboard:before {
    content: "\e91e";
  }
  .icon-delete:before {
    content: "\e91f";
  }
  .icon-delivery-box:before {
    content: "\e920";
  }
  .icon-delivery-truck:before {
    content: "\e921";
  }
  .icon-diamond:before {
    content: "\e922";
  }
  .icon-direction:before {
    content: "\e923";
  }
  .icon-download:before {
    content: "\e924";
  }
  .icon-edit:before {
    content: "\e925";
  }
  .icon-eye:before {
    content: "\e926";
  }
  .icon-faq:before {
    content: "\e927";
  }
  .icon-filter-1:before {
    content: "\e928";
  }
  .icon-filter:before {
    content: "\e929";
  }
  .icon-food:before {
    content: "\e92a";
  }
  .icon-fullscreen:before {
    content: "\e92b";
  }
  .icon-gift:before {
    content: "\e92c";
  }
  .icon-happy-chat:before {
    content: "\e92d";
  }
  .icon-heart:before {
    content: "\e92f";
  }
  .icon-history:before {
    content: "\e930";
  }
  .icon-Home:before {
    content: "\e931";
  }
  .icon-idea:before {
    content: "\e932";
  }
  .icon-import:before {
    content: "\e933";
  }
  .icon-info:before {
    content: "\e934";
  }
  .icon-insurance:before {
    content: "\e935";
  }
  .icon-jewelry:before {
    content: "\e936";
  }
  .icon-left-right:before {
    content: "\e937";
  }
  .icon-like:before {
    content: "\e938";
  }
  .icon-lock:before {
    content: "\e939";
  }
  .icon-mail:before {
    content: "\e93a";
  }
  .icon-map:before {
    content: "\e93b";
  }
  .icon-medal-of-honor:before {
    content: "\e93c";
  }
  .icon-medal:before {
    content: "\e93d";
  }
  .icon-medical:before {
    content: "\e93e";
  }
  .icon-megaphone:before {
    content: "\e93f";
  }
  .icon-minus:before {
    content: "\e941";
  }
  .icon-minus2:before {
    content: "\e942";
  }
  .icon-money:before {
    content: "\e943";
  }
  .icon-new-note:before {
    content: "\e944";
  }
  .icon-no-eye:before {
    content: "\e945";
  }
  .icon-notepad:before {
    content: "\e946";
  }
  .icon-pen:before {
    content: "\e947";
  }
  .icon-picture:before {
    content: "\e948";
  }
  .icon-pin:before {
    content: "\e949";
  }
  .icon-pin2:before {
    content: "\e94a";
  }
  .icon-play:before {
    content: "\e94b";
  }
  .icon-plus:before {
    content: "\e94c";
  }
  .icon-plus2:before {
    content: "\e94d";
  }
  .icon-printer:before {
    content: "\e94e";
  }
  .icon-refresh:before {
    content: "\e94f";
  }
  .icon-repeat:before {
    content: "\e950";
  }
  .icon-return:before {
    content: "\e951";
  }
  .icon-rotate:before {
    content: "\e952";
  }
  .icon-rss:before {
    content: "\e953";
  }
  .icon-sacle:before {
    content: "\e954";
  }
  .icon-sale:before {
    content: "\e955";
  }
  .icon-science:before {
    content: "\e956";
  }
  .icon-send:before {
    content: "\e958";
  }
  .icon-setting:before {
    content: "\e959";
  }
  .icon-settings:before {
    content: "\e95a";
  }
  .icon-settings2:before {
    content: "\e95b";
  }
  .icon-share:before {
    content: "\e95c";
  }
  .icon-shuffle:before {
    content: "\e95d";
  }
  .icon-star:before {
    content: "\e95f";
  }
  .icon-stopwatch:before {
    content: "\e960";
  }
  .icon-student:before {
    content: "\e961";
  }
  .icon-support:before {
    content: "\e962";
  }
  .icon-tag:before {
    content: "\e963";
  }
  .icon-target:before {
    content: "\e964";
  }
  .icon-target2:before {
    content: "\e965";
  }
  .icon-text-document:before {
    content: "\e966";
  }
  .icon-trash:before {
    content: "\e967";
  }
  .icon-unlink:before {
    content: "\e968";
  }
  .icon-wallet:before {
    content: "\e96a";
  }
  .icon-warning:before {
    content: "\e96b";
  }
  .icon-wifi:before {
    content: "\e96c";
  }
