// ==============================================
// STRATE - Files upload
// ==============================================


.ct-view-fichiers_joints {

    .title {
        @extend .title-3;
        margin-bottom: $gutter*2;
    }

    .subtitle {
        display: block;
        margin-bottom: $gutter-large/2;
    }

    .files {

        .item {

            & + .item {
                margin-top: $gutter-large;
            }
        }

        .redirect-content {
            @extend .btn--download;
        }
    }
}