// ==============================================
// ACCOUNT - Wishlist
// ==============================================

.wishlist-index-index {

    @include breakpoint(lg) {
        &.account.page-layout-2columns-left .column.main {
            max-width: 860px;
            padding-left: 70px;
        }
    }

    // Share
    .wishlist-share-container {
        display: flex;
        justify-content: flex-end;

        @include breakpoint(md) {
            margin-bottom: 20px;
        }

        .wishlist-share-btn {
            display: flex;
            align-items: center;
            cursor: pointer;

            > span:not([class*="icon-"]) {
                margin-left: 10px;
                border-bottom: 1px solid;
                line-height: 1.5;
            }
        }
    }

    //  Grid view
    .products-grid {
        &.wishlist {
            padding-top: 0;

            .product-image-photo {
                display: block;
            }
        }

        // Item
        .product-items {
            li.product-item {
                + li.product-item {
                    padding-top: 10px;
                    margin-top: 10px;
                    border-top: 1px solid $c-light6;

                    @include breakpoint(md) {
                        padding-top: 15px;
                        margin-top: 15px;
                    }
                }

                /*@include breakpoint(only-md) {
                    &:first-child {
                        padding-top: 10px;
                        margin-top: 20px;
                        border-top: 1px solid $c-light6;
                    }
                }*/
            }
            // tooltip bar
            .product-item-tooltip {
                padding-right: 100px;
                .action.details.tooltip,
                .subtitle,
                .values {
                    display: none;
                }

                .label {
                    display: inline-block;

                    &:not(:first-child)::before {
                        content: "-";
                        margin: 0 2px;
                    }
                }

            }

            //toolbars
            .toolbar.wishlist-toolbar,
            .actions-toolbar {
                display: none;
            }

            .product-item-info {
                position: relative;
                padding: 15px 10px;
                background-color: $c-white;

                @include breakpoint(md) {
                    padding: 15px 20px 30px 20px;
                }

                &::after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }

            // Stickers
            .product-item-metas {
                padding-right: $gutter-large;
            }

            .labels,
            .discount-percent {
                display: inline-block;
            }

            // Img
            .product-item-photo {
                float: left;
                width: 100px;
                margin-bottom: 10px;
                margin-right: 15px;

                @include breakpoint(md) {
                    width: 200px;
                    margin-right: 40px;
                    margin-bottom: 0;
                }

                .product-image-wrapper {
                    padding-bottom: 0 !important;
                }
            }

            // Name
            .product-item-name {
                display: block;

                margin-bottom: 15px;
                font-size: 1.4rem;
                font-weight: $fw-benton-M;
                line-height: 1.5;

                @include breakpoint(md) {
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                }
            }

            .product-item-name,
            .nombis-caracteristiques {

                @include breakpoint(md) {
                    padding-right: 80px;
                }
            }

            // Price
            .price-box {
                font-size: 1.4rem;
                font-weight: $fw-benton-M;
                position: absolute;
                right: 20px;
                bottom: 80px;

                @include breakpoint(md) {
                    font-size: 1.6rem;
                    top: 55px;
                    right: 16.5px;
                }

                p {
                    display: flex;
                    margin-bottom: 0;

                    @include breakpoint(only-md) {
                        flex-direction: column;
                        align-items: flex-end;

                        .special-price {
                            margin-left: 0;
                        }
                    }
                }
            }

            // Add to cart
            .box-tocart {

                @include breakpoint(only-md) {
                    .field.qty {
                        width: 100px;
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                }

                .field.qty {

                    @media (min-width: 376px) {
                        margin-left: 110px; // With of image and gutter
                    }

                    @include breakpoint(md) {
                        margin-left: 0;
                    }

                }


                .product-item-actions {
                    margin-top: 20px;
                }

                .tocart {
                    @include breakpoint(only-md) {
                        font-size: 1.2rem;
                    }
                }

                .action {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }

            .product-item-inner {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-top: 20px;
                min-height: 100px;

                @include breakpoint(only-md) {
                    clear: both;
                }

                > .product-item-actions {
                    position: absolute;
                    top: 15px;
                    right: 20px;
                    margin-top: 0;

                    > .action {
                        color: $c-black;

                        > span {
                            @extend .visually-hidden;
                        }

                        &.edit {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // Form actions
    .form-wishlist-items {
        > .actions-toolbar {
            .action {
                 @include breakpoint(only-md) {
                     font-size: 1.2rem;

                     &.update {
                         margin-bottom: 15px;
                     }

                     &.btn--border {
                         width: 100%;
                     }
                 }

                @include breakpoint(md) {
                    &.update {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    // Toolbar
    .toolbar.wishlist-toolbar {
        font-size: 1.2rem;

        @include breakpoint(md) {
            font-size: 1.4rem;
        }
    }
}
