// ==============================================
// MILOPLE - Personalized Rich
// ==============================================

//
// Elements displaying
//

body.catalog-product-view {

    &.init-personalized-product {
        .start-personalized,
        .edit-personalized,
        .box-tocart > .fieldset,
        .box-tocart > .actions {
            @extend .visually-hidden;
        }
    }

    &:not(.init-personalized-product) {
        .personalized-container {
            #content-template,
            .complete-personalized {
                @extend .visually-hidden;
            }
        }

        .gallery-placeholder.popup-gallery {
            opacity: 0;
            visibility: hidden;
        }
    }
}


// Gallery personalization
body.catalog-product-view .product {
    &.media {
        overflow: hidden !important; // Reset

        .gallery-placeholder img {
            @include breakpoint(only-md) {
                max-width: 100% !important;
                max-height: 100% !important;
                height: auto !important;
                width: 100% !important;
            }
        }

        .popup-gallery {
            position: absolute;
            z-index: 1;
            display: flex;
            align-items: flex-start;
            //height: 100%;
            background: #FFF;
            transition: visibility .3s ease-out, opacity .3s ease-out;
            top: 50px;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;

            @include breakpoint(md) {
                //padding-left: 100px;
                //padding-right: 100px;
                top: 80px;
                bottom: 0;
            }

            .custom-personalized-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 100% !important;
                height: auto !important;
                max-width: 100% !important;
                max-height: 100% !important;

                div#container,
                .designAreasDiv {
                    display: flex;
                    justify-content: center;
                    height: 100% !important;
                    width: 100% !important;
                }
            }

            img {
                height: auto !important;
                width: 100% !important;
                float: none;

                @include breakpoint(md) {
                    max-width: 500px !important;
                    max-height: 500px !important;
                }
            }

            .canvas-container,
            canvas.canvas {
                height: 100% !important;
                width: 100% !important;

                @include breakpoint(md) {
                    max-width:500px !important;
                    max-height: 500px !important;
                }
            }
        }
    }
}

// Form personalization

div.personalized-container {
    background: none;
    width: auto !important;
    left: initial !important;

    &.tab-view {

        .section {
            background: none;
            border: none;
        }

        // Hide elements
        .directional-pad {
            //@extend .visually-hidden;
        }

    }

    // Actions
    .personalized-options {
        background: none;
        float: none;
        margin-bottom: $gutter;

        &.start-personalized {
            @extend .btn--border;
            @extend .btn--note;

            @include breakpoint(lg) {
                font-size: 1.8rem;
            }
        }

        &.complete-personalized {
            @extend .btn;

            @include breakpoint(lg) {
                font-size: 1.8rem;
            }
        }
    }

    .edit-personalized {
        display: inline-flex;
        align-items: center;
        width: auto;
        padding: 0;
        font-size: 1.4rem;
        font-weight: $fw-benton-R;
        margin-bottom: $gutter;
        margin-top: 0;

        &:hover {
            cursor: pointer;
            color: $c-orange;
        }

        @include breakpoint(lg) {
            font-size: 1.6rem;
        }

        span {
            text-decoration: underline;
        }

        &::before {
            @extend %icon-icomoon;
            content: "\e944";
            margin-right: 10px;
            font-size: 1.8rem;
        }
    }

    .home {
        margin-bottom: $gutter-large;
        border-bottom: 1px solid $input-border-color;
        height: auto;

        #home {
            background: none;
            cursor: pointer;
        }
    }


    // Tabs label
    & > input[type="radio"] + label {
        @extend .visually-hidden;
        border: none;

        &::after {
            display: none;
        }
    }

    // Template Preview & Pad

    #content-template,
    #directional-pad {
        display: none !important;
    }

    #content-template {
        max-height: none;
        background: none;
        border: none;
        padding: 0;
        overflow: visible;

        .information-text {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 1.4rem;
            font-style: italic;
            line-height: 1.2;
        }
    }

    .imageTemplate {
        display: flex;
        flex-direction: column;
        margin-top: $gutter/2;

        // Loader
        span.a-center {
            display: block;
            margin: 0;
        }

        // Inputs / select
        input,
        select,
        .info {
            width: 100%;

            & + .label {
                margin-top: $gutter;
            }
        }


        // Labels
        input:checked+label {
            color: $c-base;
            border: none !important;

        }

        span.label {
            margin-bottom: 5px;
            padding-left: $gutter;
            display: block;

            & + select {
                margin-top: 0;
            }
        }

        // Image view
        .resizeimagediv {
            width: 100%;
            text-align: center;

            .image-wrapper {
                display: inline-block;
                overflow: hidden;
                border: 1px solid transparent;
                transition: border-color .2s linear;

                &:hover {
                    border-color: $input-border-color;

                    .preview-image {
                        transform: scale(1.4);
                    }
                }
            }
        }

        .preview-image {
            transform: scale(1);
            transition: transform .2s linear;
        }

        // Form text info
        .info {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;

            .characters-left,
            .lblMaxLength {
                float: none;
            }

        }


        // Text effects
        #text-effects-container {
            margin-top: $gutter;
        }
        .text_effects {

            li {
                padding-left: 0;

                &::before {
                    display: none;
                }


                &.field {
                    float: none;
                    margin-top: $gutter;
                    font-family: $f-bentonSC;
                    font-weight: $fw-benton-M;
                }
            }
        }
    }
}


//
// Engraving options list
//
.engraving-options {
    margin-top: $gutter/2;
    font-weight: $fw-benton-R;
    font-size: 1.2rem;

    @include breakpoint(md) {
        font-size: 1.4rem;
        margin-top: $gutter;
    }
}

.engraving-options__label {
    font-family: $f-bentonSC;
}

.engraving-options__list {
    margin-top: 5px;
}