// ==============================================
// GUESTVIEWS - REVIEWS
// ==============================================

//
// Reviews iframe wrapper
//

#guestviews-widget {
    display: block;
    width: 100% !important; // Override iframe inline value
    height: $container-small !important; // Override iframe inline value
    margin: $gutter-large auto;
    max-width: $inner-container-width-xl;
}
