// ==============================================
// ONEPAGE - Payment
// ==============================================

.opc-wrapper {
    // Payment Method
    .checkout-payment-method {

        .payment-method {
            padding: 30px 20px;
            background: $c-white;

            @include breakpoint(lg) {
                padding: 34px 40px;
            }

            &._active {

                .payment-method-content {
                    display: block;
                }
            }

            + .payment-method {
                margin-top: 15px;

                @include breakpoint(lg) {
                    margin-top: 10px;
                }
            }

            // Hide
            & > div:not(.payment-method-title):not(.payment-method-content) {
                display: none;
            }
        }

        .amasty-gdpr-consent {
            margin-top: 25px;
        }

        // Title
        .payment-method-title {
            margin-top: 0;

            li.item {
                display: inline-block;
                > img {
                    width: 75px;
                }
            }

            > label {
                display: flex;
                align-items: center;
                margin-left: 0;
                font-family: $f-bentonSC;
                font-size: 1.8rem;
                font-weight: $fw-benton-M;

                // Radio
                &::before,
                &::after {
                    top: 50%;
                    transform: translateY(-50%);
                }
                &::after {
                    transform: translate(-50%, -50%);
                }

                // Image
                .payment-icon {
                    + span {
                        display: none;
                    }
                }

                // Icon
                > span {
                    &::before {
                        content:"";
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: $gutter/2;
                    }
                }

                // Bank transfert
                &[for=banktransfer] > span::before {
                    background: url("../images/icon/bank-transfert.svg") no-repeat;
                    background-size: 30px;
                    width: 30px;
                    height: 30px;
                }

                // Check
                &[for=checkmo] > span::before {
                    background: url("../images/icon/check-payment.svg") no-repeat;
                    background-size: 30px;
                    width: 30px;
                    height: 30px;
                }

                // Oney
                &[for=oney_3x] > span,
                &[for=oney_4x] > span {
                    font-size: 0;

                    &::before {
                        background-image: url('../images/logo/ooney.png');
                        background-repeat: no-repeat;
                        height: 40px;
                        width: 190px;
                        background-size: contain;
                    }
                }

                // Lydia
                &[for=lydia] > span {
                    font-size: 0;

                    &::before {
                        background-image: url('../images/logo/lydia.png');
                        background-repeat: no-repeat;
                        height: 30px;
                        width: 100px;
                        background-size: contain;
                    }
                }

                // Paypal description
                > a {
                    display: none;
                }
            }
        }

        .payments .legend {
            @extend .visually-hidden;
            & + br {
                display: none;
            }
        }

        // Content
        .payment-method-content {
            display: none;
            margin-top: 40px;

            @include breakpoint(lg) {
                margin-top: 54px;
            }

            // Address
            .checkout-billing-address {

                .action-edit-address {
                    margin-top: 15px;
                    cursor: pointer;

                    span {
                        text-decoration: none;
                        transition: border-color .2s;
                        border-bottom: 1px solid transparent;

                        &:hover,
                        &:focus {
                            border-color: $c-black;
                        }
                    }
                }

                label,
                .billing-address-details {
                    font-size: 1.4rem;
                    line-height: 1.5;

                    @include breakpoint(md) {
                        font-size: 1.6rem;
                    }
                }
            }

            .billing-address-details,
            .billing-address-form,
            .field-select-billing {
                margin-top: 20px;
                margin-left: 36px;
            }

            // Select address
            .field-select-billing {
                > label {
                    margin-bottom: 10px;
                    font-family: $f-bentonSC;
                    font-size: 1.4rem ;
                    font-weight: $fw-benton-M;
                }
            }

            // Action
            .actions-toolbar {

                @include breakpoint(md) {
                    margin-top: 30px;
                    margin-left: 36px;
                }

                .action {
                    &.action-update {
                        @extend .btn;
                    }

                    &.action-cancel {
                        @extend .btn--border;
                        margin-top: 10px;

                        @include breakpoint(md) {
                            margin-top: 0;
                            margin-left: 20px;
                        }
                    }
                }
            }

            // Details
            .billing-address-details {
                font-size: 1.4rem;
            }

            // Notes
            .payment-method-note {
                margin-left: 36px;
            }

            // Oney 
            .oney-mensual-amount {
                margin-bottom: 25px;
            }
        }

        .payment-option {
            margin-top: 20px;

            &.giftcardaccount,
            &.discount-code {
                display: none;
            }

            .payment-option-title {
                font-weight: bold;
                padding: 14px 40px 14px 0;
            }

            .label {
                margin-bottom: $gutter/2;
            }
        }
    }
}
