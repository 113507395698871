// ==============================================
// KALIOP - Styleguide
// ==============================================

//
//  Configuration
//  _____________________________________________

$styleguide-title-color:            lighten($c-black, 50%);
$styleguide-title-border-color:     lighten($c-black, 50%);
$styleguide-section-spacement:      80px;

.styleguide {

    .styleguide__part + .styleguide__part {
        margin-top: $styleguide-section-spacement;
    }

    // Title
    .section-title {
        color: $styleguide-title-color;
        padding-left: 10px;
        margin-bottom: 40px;
        border-left: 5px solid $styleguide-title-border-color;
    }

    // Common list
    .styleguide-list {
        li {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .toggle-menu {
        @extend %collapsible-inline;
    }

    // Icons list
    .icons-list {
        [class^="icon-"] {
            display: inline-block;
            margin: 0 30px 20px 0;
            font-size: 3rem;
        }

        span {
            display: none;
            position: absolute;
            color: $c-success;
            font-weight: bold;
        }
    }

    // Tags list
    .tags-list > li + li {
        margin-top: $gutter/2;
    }
}