// ==============================================
// MIRASVIT - RESULT PAGE
// ==============================================

.catalogsearch-result-index {

    // Search text information
    .search.results {

        // Hide elements
        dl.block {
            @extend .visually-hidden;
        }
    }

    &.page-products .page-title-wrapper {
        padding-bottom: 0;

        @include breakpoint(lg) {
            border: none;
        }
    }
}

// Cms Content
.mst-search__index-blackbird_contentmanager_content {

    .mst-search__index_items {
        display: flex;
        flex-wrap: wrap;
        @include gutter-revert($gutter);
    }

    .mst-search__index_item {
        padding-left: $gutter;
        padding-right: $gutter;
        margin-bottom: $gutter-large + $gutter;
        width: 100%;

        @include breakpoint(md) {
            width: 50%;
            border-right: 1px solid $input-border-color;

            &:nth-child(2n) {
                border: none;
            }
        }

        @include breakpoint(lg) {
            width: 25%;

            &:nth-child(2n) {
                border-right: 1px solid $input-border-color;
            }

            &:nth-child(4n) {
                border: none;
            }
        }
    }

    .item-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: $gutter-large $gutter-large/2;
        height: 100%;
        background-color: $c-light7;
    }

    .item-title {
        margin-top: $gutter;
        margin-bottom: $gutter;
        display: flex;
        align-items: center;
        flex: 1 1 0;
        font-size: 2rem;
        font-weight: $fw-benton-B;
        line-height: 1.4;
    }

    .item-description {
        font-size: 1.6rem;
        line-height: 1.6;
    }

    .item-action {
        display: inline-block;
        margin-top: $gutter;
        @extend .link-arrow;
        @extend .animated-border;
    }

}