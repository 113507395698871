// ==============================================
// ACCOUNT - Common
// ==============================================

//
//  Configuration
//  _____________________________________________

$account-sidebar-width:             25%;
$account-sidebar-gutter:            2%;

.account {
    background-color: $c-light7;

    .page-wrapper {
        @include breakpoint(only-md) {
        padding-bottom: 100px;
        }
    }

    .page-header {
        background-color: $c-light7;

        // Account
        .mdp-top-links {
            @include breakpoint(lg) {
                .account-link {
                    color: $c-white;
                    background-color: black;
                }
            }
        }
    }

    .page-main {
        max-width: $container-width;
    }

    //
    //  Main columns
    //  _____________________________________________

    .columns {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 45px;

        @include breakpoint(md) {
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-bottom: 75px;
        }
    }

    @include breakpoint(md) {
        &.page-layout-2columns-left {
            .sidebar-main,
            .sidebar-additional {
                float: none;
                width: $account-sidebar-width;
                padding-right: $account-sidebar-gutter;
            }

            .column.main {
                float: none;
                width: 100%;
                max-width: 900px;
                padding-left: 15px;
                border-left: 1px solid $c-light6;
            }
        }
    }

    .page-title {
        @extend .title-1;
        line-height: 1;
    }

    .page-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        margin-top: 30px;
        margin-bottom: 40px;
        border-bottom: 1px solid $c-light6;

        @include breakpoint(md) {
            margin-top: 40px;
            margin-bottom: 100px;
        }

        .nav {
            list-style: none;

            @include breakpoint(only-md) {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                background: $c-white;
                padding: 15px 28px;
                text-align: center;
                z-index: 5;

                .account-log-out {
                    text-align: center;
                }
            }

            .account-log-out {
                @include btn;
                color: $c-white;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;

                &::after {
                    content: "\e924";
                    font-family: 'icomoon' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    color: $c-white;
                    transform: rotateZ(-90deg);
                    font-size: 20px;
                    display: inline-block;
                    margin-left: 4px;
                }
            }
        }
    }

    .column.main {
        > .block .block-title {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 25px;
            border-bottom: 1px solid $c-light6;
            @extend .title-2;

            @include breakpoint(md) {
                margin-bottom: 40px;
            }

            strong {
                padding-right: 20px;
            }

            a {
                font-family: $f-benton;
                font-weight: $fw-benton-R;
                font-size: 1.4rem;

                @include breakpoint(md) {
                    font-size: 1.6rem;
                }

                span {
                    @extend .link-hover-black;
                }
            }
        }

        .block + .block {
            margin-top: 30px;

            @include breakpoint(md) {
                margin-top: 60px;
            }
        }

        // Block dashboard
        .block {
            &.block-addresses-default,
            &[class*="block-dashboard-"] {

                .box-title {
                    display: block;
                    margin-bottom: 15px;
                    font-weight: $fw-benton-B;
                    font-size: 1.6rem;

                    @include breakpoint(md) {
                        font-size: 1.8rem;
                        margin-bottom: 20px;
                    }
                }

                .block-content {
                    display: flex;
                    justify-content: space-between;

                    @include breakpoint(only-md) {
                        flex-direction: column;
                    }

                    > .box {

                        @include breakpoint(md) {
                            display: flex;
                            flex-direction: column;
                            width: 48%;
                        }

                        @include breakpoint(only-md) {
                            + .box {
                                margin-top: 30px;
                            }
                        }
                    }

                    .box-content {
                        margin-bottom: 15px;
                        font-size: 1.4rem;

                        @include breakpoint(md) {
                            flex: 1;
                            margin-bottom: 20px;
                            font-size: 1.6rem;
                        }

                        + .box-actions {
                            @include breakpoint(md) {
                                margin-top: auto;
                            }

                            a {
                                margin-right: 30px;
                                font-size: 1.4rem;
                                color: $c-black;

                                @include breakpoint(md) {
                                    font-size: 1.6rem;
                                }

                                span {
                                    @extend .link-hover-black;

                                    @include breakpoint(only-md) {
                                        border-bottom: 1px solid;
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .actions-toolbar {
        @include breakpoint(only-md) {
            text-align: center;
        }

        @include breakpoint(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        margin-top: 30px;

        > div {
            + div {
                margin-top: 20px;

                @include breakpoint(md) {
                    margin-left: 16px;
                    margin-top: 0;
                }
            }
        }

        .action.back {
            @extend .link-arrow-back;
        }
    }

    .toolbar {
        margin-top: 30px;

        @include breakpoint(md) {
            margin-top: 40px;
        }
    }

    //
    //  Pager
    //  _____________________________________________

    .pager {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(only-md) {
            font-size: 1.2rem;
        }

        // Amount number
        .toolbar-amount {
            margin-bottom: 0;
        }

        .limiter {
            display: flex;
            align-items: center;

            .limiter-label {
                padding: 10px;
            }

            // Limiter select
            .limiter-options {
                flex: 1;
                margin-right: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 10px;
            }
        }
    }
}
