// ==============================================
// ACCOUNT - Customer address
// ==============================================

// Index
.customer-address-index {
    .column.main {

        // Table
        .table-additional-addresses-items {
            td {
                &.actions {
                    min-width: 95px;

                    a {
                        display: inline-flex;
                        justify-content: center;
                        width: 30px;

                        span {
                            @extend .visually-hidden;
                        }

                        &::before {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }

        // Toolbar
        >.actions-toolbar {
            .action.primary.add {
                @extend .btn;
            }

            > .secondary {
                display: none;
            }
        }
    }
}

// Form
.customer-address-form {
    .form-address-edit {
        max-width: $container-small;

        legend {
            @extend .visually-hidden;
        }

        .field {
            &.region {
                margin-bottom: 11px;

                input {
                    &[style="display: none;"] {
                        + label {
                            display: none;
                        }
                    }
                }
            }

            &.zip {
                label {
                    display: block !important;
                }
            }

            &.choice {
                margin-top: 30px;
                margin-left: 0;

                + .choice {
                    margin-top: 10px;
                }

                label {
                    font-family: $f-benton;
                    font-size: 1.4rem;
                }
            }
        }

        input,
        select {
            //max-width: 424px;
        }

        .actions-toolbar {

            .action {
                &.back {
                    @extend .link-hover-black;
                }
            }
        }
    }
}
