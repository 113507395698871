// ==============================================
// STRATE INVESTIR
// ==============================================

.strate-investir {

    &__media {
        display: block;
        width: 100%;
    }
}
