// ==============================================
// UTILITIES
// ==============================================

//
//  Toggle displaying animation
//  _____________________________________________

@keyframes hideBeforeShowing {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes movingLeftToRight {
    from {
        transform: translateX(-15px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes movingRightToLeft {
    from {
        transform: translateX(15px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes movingTopToBottom {
    from {
        transform: translateY(-10px);
    }

    to {
        transform: translateY(0);
    }
}
@keyframes movingBottomToTop {
    from {
        transform: translateY(10px);
    }

    to {
        transform: translateY(0);
    }
}

//
//  Responsive displaying
//  _____________________________________________

.s-hide {
    display: none;
    @include breakpoint( lg) {
        display: block;
    }
}
.s-show {
    @include breakpoint(lg) {
        display: none;
    }
}

.mobile-hidden {
    display: none;

    @include breakpoint(md) {
        display: block;
    }
}

.mobile-only {
    @include breakpoint(md) {
        display: none;
    }
}


//
//  Colors helpers
//  _____________________________________________
.bg-white {
    background-color: $c-white;
}



//
//  Fonts helpers
//  _____________________________________________
.fw-R {
    font-weight: $fw-benton-R;
}

.fw-M {
    font-weight: $fw-benton-M;
}

.fw-B {
    font-weight: $fw-benton-B;
}


//
//  Animating block border
//  _____________________________________________
@mixin animated-block-border {
    display: block;
    position: relative;

    &::before {
        content:"";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 100%;
        background-color: currentColor;
        height: 1px;
        transition: right .5s ease-out;
    }

    &:hover::before {
        right: 0;
    }
}

.animated-block-border {
    @include animated-block-border;
}


//
//  Tabs elements
//  _____________________________________________


// Tabs wrapper
 /* Hide scrollbar for Chrome, Safari and Opera */
 .tabs-wrapper::-webkit-scrollbar {
    display: none;
  }

.tabs-wrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    border-bottom: 1px solid $input-border-color;
    margin-bottom: 55px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @include breakpoint(lg) {
        position: relative;
        margin-bottom: $gutter-large*2;
        padding-right: 200px;
    }

    .tab {
        @extend .title-2;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        & + .tab {
            &::before {
                content: "/";
                margin: 0 15px;
            }
        }

        &.is-active {
            .tab__name {
                border-bottom-color: currentColor;
                transition: border-bottom-color .3s linear;
            }

            .tab__link {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .tab__name {
        border-bottom: 3px solid transparent;
        white-space: nowrap;

        @include breakpoint(md) {
            border-bottom-width: 4px;
        }

        > * {
            font-family: $f-bentonSC;
            font-weight: $fw-benton-B;
            font-size: 1.6rem;
            line-height: 1.4;
            margin: 0;

            @media (min-width: 370px) {
                font-size: 2.6rem;
            }

            @include breakpoint(lg) {
                font-size: 3.4rem;
            }
        }
    }

    .tab__link {
        display: block;
        position: absolute;
        bottom: 0;
        left: $gutter;
        opacity: 0;
        visibility: hidden;
        font-size: 1.6rem;
        font-weight: $fw-benton-M;
        font-family: $f-benton;
        @extend .link-arrow;

        @include breakpoint(lg) {
            bottom: auto;
            right: 0;
            left: auto;
        }
    }
}

// Content targets wrapper
.with-tabs .tabs-target-wrapper {
    position: relative;
    overflow: hidden;
    margin-right: -$gutter;

    @include breakpoint(lg) {
        margin-right: -$gutter-large;
    }

    // Content target
    .content-target {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;

        &.is-active {
            position: relative;
            transform: translateY(0);
            transition: opacity .3s linear, visibility .3s linear;
            opacity: 1;
            visibility: visible;
        }
    }
}

//
//  Iframe Video responsive
//  _____________________________________________

.iframe-video-container {
    position: relative;
    padding-bottom: 47.5%;
    padding-top: 1.5%;
    height: 0;
    overflow: hidden;
}
.iframe-video-container iframe,
.iframe-video-container object,
.iframe-video-container embed,
.iframe-video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


//
//  Video responsive
//  _____________________________________________

.video-wrapper {
    width: 100%;
    position: relative;
    // make aspect ratio 16/9 with 100% * 9 / 16
    padding-bottom: 55%;
    padding-top: 1.25%;
    height: 0;
    overflow: hidden;

    @include breakpoint(only-md) {
        margin-bottom: -1px;
    }

    iframe,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.video-desktop{
            display:none;
        }

        @include breakpoint(md) {
            &.video-desktop{
                display:block;
            }
            &.video-mobile{
                display:none;
            }
        }
    }
}


//
//  Tags / Stickers
//  _____________________________________________

// Default item
.tag-item {
    display: inline-block;
    padding: 5px 10px;
    background-color: $c-white;
    border: 1px solid $c-white;
    font-size: 1.2rem;
    line-height: 1.4;
    font-family: $f-benton;
    font-weight: 700;

    @include breakpoint(lg) {
        font-size: 1.4rem;
    }

    // Custom colors
    &.orange-tag{
        background-color: $c-orange;
        border: 1px solid $c-orange;
        color: $c-white;
    }

    &.black-tag{
        background-color: $c-black;
        border: 1px solid $c-black;
        color: $c-white;
    }

    &.light-tag{
        background-color: $c-light;
        border: 1px solid $c-light;
    }

    &.light-beige{
        background-color: $c-gold4;
        border: 1px solid $c-gold4;
    }

    &.gold-tag{
        background-color: $c-gold;
        border: 1px solid $c-gold;
        color: $c-white;
    }

    &.grey-tag{
        background-color: $c-light6;
        border: 1px solid $c-light6;
    }

    // Extend colors
    &.introductory-offer,
    &.discount30 {
        @extend .orange-tag;
    }

    &.new,
    &.nouveau,
    &.pre-order,
    &.idee-cadeau {
        @extend .black-tag;
    }

    &.free-engraving {
        @extend .light-tag;
    }

    &.sold-out,
    &.fairmined {
        @extend .gold-tag;
    }

    &.unavailable,
    &.indisponible {
        @extend .grey-tag;
    }
}

.strate-item-button{
    background-color:$c-white;
    border: 1px solid $c-black;
}
.universe-none{
    .labels > span.new, .tag-item.new{
        color: $c-white;
        background-color:$c-black;
        border: $c-black;
    }
    .strate-item-button{
        background-color:$c-white;
        border: 1px solid $c-black;
    }
}
.universe-yellow{
    .labels > span.new, .tag-item.new{
        color: $c-black;
        background-color:$c-gold4;
        border:$c-gold4;
    }
    .strate-item-button{
        background-color:$c-gold4;
    }
}
.universe-blue{
    .labels > span.new, .tag-item.new{
        color: $c-black;
        background-color:$c-blue;
        border:$c-blue;
    }
    .strate-item-button{
        background-color:$c-blue;
    }
}
.universe-green{
    .labels > span.new, .tag-item.new{
        color: $c-black;
        background-color:$c-green;
        border:$c-green;
    }
    .strate-item-button{
        background-color:$c-green;
    }
}



//
//  Tooltips - basic css
//  _____________________________________________

.tooltip-wrapper {
    position: relative;
    display: inline-block;

    &:hover {
        cursor: pointer;
    }

    &:hover,
    &:focus {
        // Show content text
        .icon + * {
            visibility: visible;
            animation-name: movingTopToBottom;
            animation-duration: .5s;
        }
    }

    // Icon
    .icon {
        padding-top: 5px;
        padding-bottom: 5px;

        &::before {
            content: "\e934";
            font-size: 1.4rem;
            @extend %icon-icomoon;
        }
    }

    // Content text
    .icon + * {
        display: block;
        position: absolute;
        bottom: calc(100% + #{$gutter/2});
        left: -15px;
        padding: 8px 12px;
        visibility: hidden;
        background-color: $c-black;
        color: $c-white;
        border-radius: $radius-box/2;
        font-size: 1.1rem;
        line-height: 1.4;
        min-width: 165px;
        max-width: 300px;

        // Tooltip wrapper Js detection position
        &.left {
            left: auto;
            right: -15px;
            // Triangle (didn't use mixin cause design)
            &::after {
                left: auto;
                right: 10px;
            }
        }

        // Triangle (didn't use mixin cause design)
        &::after {
            content:"";
            display: block;
            position: absolute;
            top: 100%;
            left: 18px;
            border-width: 10px 10px 0 5px;
            border-color: $c-black transparent transparent transparent;
            width: 0;
            height: 0;
            border-style: solid;
        }

        a,
        .link {
            color: $c-orange;
            text-decoration: underline;
            font-weight: $fw-benton-M;
            font-size: 1.1rem;
        }
    }

    // White content text (@extend .white)
    &.white .icon + * {
        @include triangle(bottom, 20px, 10px, $c-white);
        background-color: $c-white;
        color: $c-black;

        &::after {
            left: calc(50%);
            transform: translateX(-50%);
            bottom: 10px;
            border-width: 20px 10px 0 10px;
            border-color: $c-white transparent transparent transparent;
        }
    }
}


//
//  Content text with legend
//  _____________________________________________

.graverword {
    font-size: 1.6rem;
    margin-bottom: $gutter-large;
    margin-top: $gutter-large;

    @include breakpoint(md) {
        font-size: 1.8rem;
    }

    p {
        font-style: italic;
    }
}



//
//  Simple progress bar
//  _____________________________________________

.progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: #282828;
    transition: all linear 0.1s;
    min-width: 1%;
}
