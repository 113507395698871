// ==============================================
// MIRASVIT - TABS
// ==============================================

.mst-search__result-tabs {
    display: none;
    margin-top: $gutter-large;
    margin-bottom: $gutter-large/2;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: 1px solid $c-light6;

    @include breakpoint(md) {
        margin-top: 50px;
        margin-bottom: $gutter-large;
    }

    li {
        font-size: 2.6rem;
        line-height: 38px;
        font-weight: bold;
        font-family: $f-bentonSC;

        @include breakpoint(lg) {
            font-size: 3.4rem;
        }

        &:not(:last-of-type) {
            &:after {
                content: '/';
                margin: 0 15px;
            }
        }

        a {
            display: inline-block;
            padding-top: 5px;
            padding-bottom: 5px;
            position: relative;

            &.active {
                &:after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: $c-black;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.mst-search__index-magento-catalog-category {
    li {
        margin-bottom: 5px;

        a {
            @extend .link-hover-black;
        }

        .mst-search__highlight {
            font-weight: bold;
        }
    }
}
