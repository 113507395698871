// ==============================================
// FORMS - Password meter
// ==============================================

//
//  Configuration
//  _____________________________________________

$password-strength-weak-color:              #ffafae;
$password-strength-medium-color:            #ffd6b3;
$password-strength-strong-color:            #c5eeac;
$password-strength-very-strong-color:       #81b562;

.password-strength-meter {
    position: relative;
    padding: 10px;
    font-size: 1.3rem;
    background-color: lighten($c-black, 90%);
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: -1;
    }
}

// Weak
.password-weak {
    color: darken($password-strength-weak-color, 40%);

    .password-strength-meter::before {
        background-color: $password-strength-weak-color;
        width: 25%;
    }
}

// Medium
.password-medium {
    color: darken($password-strength-medium-color, 40%);

    .password-strength-meter::before {
        background-color: $password-strength-medium-color;
        width: 50%;
    }
}

// Strong
.password-strong {
    color: darken($password-strength-strong-color, 40%);

    .password-strength-meter::before {
        background-color: $password-strength-strong-color;
        width: 75%;
    }
}

// Very strong
.password-very-strong {
    color: darken($password-strength-very-strong-color, 40%);

    .password-strength-meter::before {
        background-color: $password-strength-very-strong-color;
        width: 100%;
    }
}