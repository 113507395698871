// ==============================================
// STRATE 2 COLONNES CONTENUS
// ==============================================

$strate-2cc-max-width: 1026px;
$strate-2cc-sidebar-width: 280px;
$strate-2cc-main-width: 686px;
$strate-2cc-main-margin: percentage(60 / 1026);

.ct-view-strate_2_colonnes_contenu {
    .block-content {
        padding-top: 0;
    }
}

.strate-2cc-title {
    max-width: $strate-2cc-max-width;
    margin-bottom: 40px;
    font-family: $f-benton;
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: $fw-benton-B;

    @include breakpoint(md) {
        margin-bottom: 55px;
        font-size: 3.2rem;
    }
}

.strate-2cc {
    max-width: $strate-2cc-max-width;

    @include breakpoint(xl) {
        max-width: 75vw;
    }
}

.strate-2cc-sidebar {
    .strate-item-link .btn {
        min-width: 210px;
        max-width: 100%;
        text-align: center;
        justify-content: center;
    }

    @include breakpoint(only-md) {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid $c-light6;
    }

    @include breakpoint(md) {
        width: $strate-2cc-sidebar-width;

        position: relative;

        & > div {
            //position: sticky;
            //top: $gutter + $header-height-desktop;
        }
    }
}

.strate-2cc-main {
    @include breakpoint(md) {
        //max-width: $strate-2cc-main-width;
    }

    .text{
        font-family: $source-serif-pro;
        line-height:1.4;
    }

    &--media {

        @include breakpoint(only-md) {
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid $c-light6;
        }

        img {
            display: block;
        }
    }

    // File upload strate integre
    .ct-view-fichiers_joints .container {
        padding-left: 0;
        padding-right: 0;
    }
}

.strate-2cc-sidebar-section {

    + .strate-2cc-sidebar-section {
        margin-top: 28px;

        @include breakpoint(md) {
            margin-top: 30px;
        }
    }

    .section-title {
        margin-bottom: 10px;
        font-family: $f-bentonSC;
        font-size: 1.6rem;
        line-height: 1.3;
        font-weight: $fw-benton-M;

        @include breakpoint(md) {
            margin-bottom: 20px;
        }
    }

    .section-content {
        margin-bottom: 30px;

        > * {
            margin: 0;

            + * {
                margin-top: 1.5em;
            }
        }

        strong {
            font-weight: $fw-benton-B;
        }

        ul {
        }
    }

    .section-link {

    }
}

@supports not (display: grid) {

    @include breakpoint(only-md) {
        .strate-2cc {
            display: flex;
            flex-direction: column;
        }

        .strate-2cc-main--media {
            order: -1;
        }
    }

    @include breakpoint(md) {
        .strate-2cc {
            position: relative;
        }

        .strate-2cc-sidebar-section {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
        }

        .strate-2cc-main {
            margin-left: calc(#{$strate-2cc-sidebar-width} + #{$strate-2cc-main-margin});
        }
    }
}

@supports (display: grid) {

    .strate-2cc {
        display: grid;
        grid-template-areas: 'media'
                             'sidebar'
                             'content'
                             'content';

        @include breakpoint(md) {
            grid-template-areas: 'sidebar media'
                                 'sidebar content'
                                 'sidebar content'
                                 'sidebar content';
        }
    }

    .strate-2cc-main--media {
        grid-area: media;

        @include breakpoint(md) {
            margin-bottom: 60px;
        }
    }

    .strate-2cc-sidebar {
        grid-area: sidebar;
    }

    .strate-2cc-main--text {
        grid-area: content;


        &-mobile {
            @include breakpoint (only-md){
                grid-area: content;
            }
        }

        .text {

            &,
            p {
                line-height: 2;
            }

            // Checkerboard
            & + .damiers {
                margin-top: $gutter * 2;
            }
        }
    }
    @include breakpoint(md) {
        .strate-2cc-main {
            margin-left: $strate-2cc-main-margin;
        }
    }
}

