// ==============================================
// Dotmailer Tel Input
// ==============================================


div.field {
    .iti {
        display: block;

        label + label {
            @extend .visually-hidden;
        }
    }
    .iti__selected-flag {
        padding: 0 0 0 12px;
    }
}

.field:not(.search):not(.qty):not(.choice):not(.field-select-billing):not(.newsletter):not(.amcoupons-input) {
    &:not(.check):not(.choice):not(.field-select-billing) {
        .iti label {
            margin-left: 52px;
        }
    }
}



