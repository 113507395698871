// ==============================================
// CART - Orders
// ==============================================

//
//  Configuration
//  _____________________________________________

$checkout-mobile-product-visual-width:  20vw;

//
//  Table orders
//  _____________________________________________


table.cart {
    width: 100%;
    margin-bottom: 20px;

    // Table header
    > thead {
        display: none;
    }

    // Table content
    > tbody {
        display: block;
        position: relative;
        background-color: $c-white;

        &:not(:last-of-type) {
            border-bottom: 32px solid $c-light7;

            &:after {
                content: '';
                position: absolute;
                top: calc(100% + 15px);
                display: block;
                width: 100%;
                height: 1px;
                background-color: $c-light6;
            }
        }

        > tr {
            display: block;

            &.item-info {
                > td {
                    vertical-align: top;
                    padding: 15px;
                    padding-left: 10px;
                    font-size: 1.4rem;

                    @include breakpoint(lg) {
                        padding: 20px 24px;
                    }

                    // Product
                    &.item {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;

                        // Stickers
                        .product-item-metas {
                            width: 100%;

                            .labels > span,
                            .discount-percent {
                                font-size: 0.9rem;

                                @include breakpoint(lg) {
                                    font-size: 1.2rem;
                                }
                            }

                        }

                        // Visual
                        .product-item-visual {
                            width: 100px;
                            margin-right: 10px;

                            @include breakpoint(lg) {
                                width: 200px;
                                margin-right: 30px;
                            }

                            > a {
                                display: block;
                                width: 100%;

                                > .product-image-container {
                                    display: block;
                                    width: 100% !important;

                                    > .product-image-wrapper {
                                        display: block;
                                        padding-bottom: 0 !important;

                                        > .product-image-photo {
                                            display: block;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        // Content
                        .product-item-content {
                            display: flex;
                            flex-direction: column;
                            flex: 1;

                            // Order children
                            .product-item-details {
                                order: 1;
                            }
                            .product-item-error {
                                order: 3;
                            }
                            .product-item-qty-and-price {
                                order: 2;
                            }
                            .actions-toolbar {
                                order: 4;
                            }
                        }

                        // Title
                        .product-item-details {
                            max-width: calc(100% - 40px);
                            margin-bottom: 20px;
                            line-height: 1.4;
                        }

                        .product-item-name {
                            font-size: 1.4rem;
                            font-weight: $fw-benton-M;

                            @include breakpoint(lg) {
                                font-size: 1.6rem;
                            }

                            .preview-link {
                                @extend .visually-hidden;
                            }
                        }

                        // Errors
                        .product-item-error {
                            margin-top: $gutter/2;
                        }

                        .message.error {
                            display: flex;
                            background: none;
                            border-radius: 0;
                            color: $c-error;
                            padding: 10px 0 0;
                            margin: 0;
                            font-size: 1.2rem;

                            @include breakpoint(lg) {
                                font-size: 1.4rem;
                            }

                            & + .message.error {
                                margin: 0;
                            }

                            &::before {
                                content: "\e904";
                                position: static;
                                margin-right: 10px;
                                font-size: 1.8rem;
                            }
                        }

                        .item-options {
                            @include clearer;

                            dt,
                            dd {
                                float: left;
                                margin-top: 6px;
                            }

                            dt {
                                clear: left;
                                font-weight: bold;
                                margin-right: 6px;

                                &::after {
                                    content: " :";
                                }
                            }
                        }
                    }

                    // Price
                    .product-item-price {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        font-size: 1.4rem;


                        @include breakpoint(lg) {
                            font-size: 1.6rem;
                        }
                    }

                    .product-item-error + .product-item-qty-and-price {
                        .soon-qty-updater-wrap {
                            border-color: $c-error;
                        }
                    }

                    .product-item-qty-and-price {
                        display: flex;
                        align-items: center;

                        .product-item-price-block {
                            padding-left: 20px;
                            margin-left: auto;
                        }
                    }

                    // Quantity
                    .product-item-qty {
                        .label {
                            @extend .visually-hidden;
                        }
                    }

                    // Actions
                    .actions-toolbar {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        .action {
                            width: auto;
                            margin-bottom: 0;
                            transition: color .3s;

                            &:hover {
                                color: $c-orange;
                            }

                            // Icon
                            &::before {
                                font-size: 1.6rem;
                            }

                            &.action-delete {
                                position: absolute;
                                top: 6px;
                                right: 6px;
                                padding: 10px;

                                @include breakpoint(md) {
                                    top: 10px;
                                    right: 14px;
                                }

                                span {
                                    @extend .visually-hidden;
                                }
                            }

                            &.action-edit {
                                display: flex;
                                align-items: center;
                                line-height: 1.5;

                                > span {
                                    border-bottom: 1px solid;
                                    font-size: 1.2rem;

                                    @include breakpoint(md) {
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cart.main.actions {
    display: none;
}
