// ==============================================
// STRATE - Page Collection
// ==============================================

$anchor-transition-type: ease-out;


.ct-view-strate_page_collection {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    // Title
    .strate-title-container {
        .labels {
            margin-bottom: 8px;
        }
    }

    // Content
    .strate-page-collection {
        position: relative; // Use for anchor banner
    }

    // Picture landscape
    .collection-picture {
        position: relative;
        margin-bottom: $gutter*2;

        @include breakpoint(md) {
            margin-bottom: $gutter-large*2;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .collection-anchor-banner {
        padding: $gutter-large/2 $gutter-large;
        background-color: $c-white;

        @include breakpoint(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $gutter/2 $gutter-large;
            position: absolute;
            left: 0;
            right: 0;
            top: 20px;
            z-index: 1;
            background-color: transparent;
            transition: background-color .3s $anchor-transition-type;

            &.is-fixed {
                &.full {
                    background-color: $c-light7;

                    .content-title,
                    .progress-bar {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                &.is-stopped {
                    transform: translateY(-100%);
                }
            }
        }

        @include breakpoint(lg) {
            top: 50px;
        }

        @include breakpoint(xl) {
            top: 80px;
        }

        &.is-fixed {
            position: fixed;
            transform: translateY(0);
            top: 0;
            right: 0;
            left: 0;
            z-index: 11;
            border-bottom: 1px solid $input-border-color;
            transition: transform .3s $anchor-transition-type;

            @include breakpoint(md) {
                border-bottom: none;
            }

            &.is-stopped,
            &.is-removed {
                transform: translateY(-100%);
            }
        }

        // Hide banner elements
        .content-title,
        .progress-bar {
            display: none;

            @include breakpoint(md) {
                display: block;
                visibility: hidden;
                opacity: 0;
                transition: opacity .3s $anchor-transition-type;
            }

        }

        .btn--anchor {
            font-size: 1.5rem;
            line-height: 1.9;
            padding-top: 5px;
            padding-bottom: 5px;

            @include breakpoint(only-md) {
                width: 100%;
            }

            @include breakpoint(md) {
                margin-left: auto;
            }

            &::after {
                font-size: 1.5rem; // Reset btn icon size
            }
        }
    }

    .inner-container {
        @extend .container;

        @include breakpoint(md) {
            max-width: $inner-container-width;
        }
    }

    // Media player padding
    .media-player {
        @include breakpoint(only-md) {
            @include gutter-revert($gutter);
        }
    }


    // Strate Products

    & + .ct-view-strate_rich_slider_produits {

        .strate-title-container {
            @include breakpoint(lg) {
                padding-left: 55px;
                margin-left: auto;
                margin-right: auto;
                max-width: calc(50% + 50px);
            }
        }

        .strate-title {
            font-size: 3.2rem;
            font-family: $f-benton;
        }
    }
}
