// ==============================================
// PUBLIC - Create account
// ==============================================

.customer-account-create {

    // Form
    .form.create.account.form-create-account {

        // Legend
        .legend {
            margin-bottom: 0;
        }

        // Fieldset
        .fieldset {
            &.create {
                > .field.choice {
                    display: none;
                }
            }
            // Newsletter
            &.newsletter {
                .newsletter-message {
                    margin: 30px 0;
                    font-size: 1.4rem;
                    line-height: 1.5;
                }
            }
        }

        // Field
        .field {
            // Newsletter
            &.newsletter {
                display: block;

                > .control {
                    + .control {
                        margin-top: 15px;
                    }
                }

                label {
                    margin-left: 0;
                    font-family: $f-base;
                    font-weight: $fw-benton-R;
                    font-size: 1.4rem;
                }
            }
            // optin fidelity
            &.mdp_optin_fidelity {
                display: block;

                > .control {
                    + .control {
                        margin-top: 15px;
                    }
                }
                label {
                    margin-left: 0;
                    font-family: $f-base;
                    font-weight: $fw-benton-R;
                    font-size: 1.4rem;
                    span {
                        a {
                            color: -webkit-link;
                            text-decoration : underline;
                        }
                    }
                }
            }
        }

        // CTA
        .actions-toolbar {
            @include breakpoint(md) {
                margin-top: 40px;
            }
        }
    }

    .amgdpr-checkbox {

        .amgdpr-label {
            font-family: $f-benton;
            font-weight: $fw-benton-R;
        }

        .amgdpr-text a {
            text-decoration: underline;
        }
    }
}


.customer-account-confirmation {
    .page-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 100px;
    }
    .page-title {
        @extend .title-3;
    }

    .form .actions-toolbar {
        text-align: center;
    }
}
