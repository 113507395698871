// ==============================================
// CART - Common
// ==============================================

//
//  Configuration
//  _____________________________________________

$checkout-summary-width:                30%;
$checkout-summary-margin:               9.55%;
$checkout-mobile-product-visual-width:  20vw;

//
//  Global cart page
//  _____________________________________________

.checkout-cart-index {
    background-color: $c-light7;

    .page-header {
        background-color: $c-white;
    }

    .page-main {
        padding-left: 0;
        padding-right: 0;

        .page-title-wrapper,
        .page.messages,
        .columns {
            @extend .container;
            max-width: $container-width;
        }
    }

    .page-title {
        @extend .title-1;
        padding-bottom: 5px;
        margin-top: 50px;
        margin-bottom: 25px;
        border-bottom: 1px solid #000;
        line-height: 1;

        @include breakpoint(md) {
            margin-top: 70px;
            margin-bottom: 30px;
        }
    }

    .columns .cart-empty {
        min-height: 30vh;
    }
}

//
//  Layout
//  _____________________________________________

.cart-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(lg) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    > div {
        order: 3;
        width: 100%;
        margin-bottom: 20px;

        &[id="gift-options-cart"],
        &.cart-discount {
            display: none;
        }
    }

    .cart-summary {
        order: 2;

        @include breakpoint(lg) {
            width: $checkout-summary-width;
            max-width: 344px;
            margin-left: $checkout-summary-margin;
        }
    }

    .form-cart {
        order: 1;

        @include breakpoint(only-lg) {
            margin-bottom: 55px;
            border-bottom: 1px solid $c-light6;
        }

        @include breakpoint(lg) {
            width: calc(100% - #{$checkout-summary-width} - #{$checkout-summary-margin});
            max-width: 800px;
        }
    }
}


//
//  Product Upsell
//  _____________________________________________

.checkout-cart-index {

    .recommended-products {
        background-color: $c-white;

        .reco-wrapper {
            .strate-title {
                @extend .visually-hidden;
            }
        }

        // .tabs-wrapper {
        //     display: block;
        //     border: none;
        //     margin-bottom: 0;
        //     padding-right: 0;
        //     scroll-behavior: smooth;

        //     .tab {
        //         display: block;
        //         border-bottom: 1px solid $input-border-color;
        //         margin-bottom: $gutter*2;
        //     }

        //     .tab__name {
        //         border: none;
        //         pointer-events: none;
        //         cursor: initial;
        //         line-height: 1.05;

        //         @include breakpoint(lg) {
        //             margin-bottom: $gutter-large*2;
        //         }

        //         h4 {
        //             font-size: 3.4rem;

        //             @include breakpoint(lg) {
        //                 font-size: 6rem;
        //             }
        //         }
        //     }
        // }
    }
}