// ==============================================
// UI COMPONENTS - Filters
// ==============================================

//
//  Configuration
//  _____________________________________________

$filter-border:                     1px solid #e0e0e0;
$panel-filters-mobile-padding:      12px;
$panel-filters-desktop-padding:     25px;

// Ui component collapsible init loading
.filter [data-role='collapsible']:not([data-collapsible='true']) [data-role='content'] {
    display: none;
}

// Open filters
.mdp-filter-btn-open {
    @extend .btn--border;
}

// Mobile
@include breakpoint(only-md) {

    // Btn open filters
    .mdp-filter-btn-open-container {
        display: block;
        padding: 30px 0;
        background-color: $c-white;

        &.is-fixed {
            position: fixed;
            transform: translateY(0);
            top: 0;
            right: 0;
            left: 0;
            z-index: 11;
            padding: 25px 20px;
            border-bottom: 1px solid #eee;
            transition: transform .3s linear;

            &.is-removed {
                transform: translateY(-100px);
            }
        }
    }

    // Btn open filters
    .mdp-filter-btn-open {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        [class*="icon-"] {
            margin-left: 10px;
        }
    }

    // Filters header
    .mdp-filter-block-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 17px 10px 27px;

        .mdp-filter-block-header-title {
            font-family: $f-bentonSC;
            font-size: 1.4rem;
            font-weight: $fw-benton-M;
        }

        .mdp-filter-btn-close {
            padding: 10px;
        }
    }

    // Block filters
    .mdp-filter-block {
        position: fixed;
        top: -10px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        background-color: $c-white;

        z-index: -1;
        opacity: 0;
        visibility: hidden;

        &.is-open {
            z-index: 11;
            opacity: 1;
            visibility: visible;
            top: 0;
            transition: top .3s linear;
        }
    }

    // Submit
    .am_shopby_apply_filters {
        top: auto !important;
        right: 0;
        left: 0 !important;
        padding: 15px 27px !important;
        background-color: $c-white;
        border-top: $filter-border;

        &.visible {
            bottom: 0 !important;
        }

        .am-show-button {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            padding: 10px 16px;
            font-size: 1.4rem;
            font-family: $f-bentonSC;
            font-weight: $fw-benton-R;
            line-height: 1.8;
            color: $c-white;
            background-color: $button-color;
            border: 1px solid $button-color !important;
            border-radius: $radius-btn !important;
            text-decoration: none;

            &::before,
            &::after {
                display: none !important;
            }

            > * {
                padding: 0 5px;
                font-size: 1.4rem;
                color: currentColor;

                &.am-items {
                    font-size: inherit;
                }

                &.amshopby-button {
                    font-family: $f-bentonSC;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

// Desktop
@include breakpoint(md) {
    .mdp-filter-btn-open-container,
    .mdp-filter-block-header {
        display: none;
    }

    .mdp-filter-block {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        margin-bottom: 30px;
        background-color: $c-light7;

        > .filter-content {
            width: 100%;
            max-width: $container-width-xl;
            padding: 0 $gutter;
            margin: 0 auto;

            @media(min-width: $large) {
                padding: 0 60px;
            }

            @media(min-width: 1440px) {
                padding: 0 120px;
            }
        }

        &.is-fixed {

            &.is-removed > .filter-content {
                top: 80px;
            }

            > .filter-content {
                position: fixed;
                transform: translateY(0);
                top: 0;
                right: 0;
                left: 0;
                z-index: 9;
                border-bottom: 1px solid #eee;
                transition: transform .3s linear, top .3s linear;
                background-color: $c-light7;
            }
        }
    }
}

@include breakpoint(lg) {
    .mdp-filter-block.is-fixed.is-removed > .filter-content {
        top: 110px;
    }
}

//
//  Titles
//  _____________________________________________

.filter-title,
.block-subtitle.filter-subtitle {
    @extend .visually-hidden;
}

.filter-options-title {
    display: block;
}

//
//  Toggle filters panel
//  _____________________________________________

.filter-switcher {
    text-align: center;

    @include breakpoint(only-md) {
        margin-bottom: 15px;
    }

    @include breakpoint(md) {
        flex-grow: 1;
        flex-basis: 0;
    }

    > a {
        color: $c-gold;
        font-size: 1.4rem;
        font-weight: $fw-benton-B;
        text-decoration: underline;

        @include breakpoint(md) {
            font-size: 1.8rem;
        }
    }
}

//
//  Active filters
//  _____________________________________________

.current-filters-block {
    @include breakpoint(only-md) {
        flex-direction: column;
        border-top: $filter-border;

        .amshopby-filter-current {
            padding-top: 30px;
        }

    }

    @include breakpoint(md) {
        align-items: baseline;
        justify-content: space-between;
    }
}

.amshopby-filter-current.filter-current {
    @include breakpoint(md) {
        margin-bottom: 30px;
    }

    .filter-current-subtitle {
        @extend .visually-hidden;
    }

    .amshopby-items.items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: -5px;
        padding: 0;

        @include breakpoint(only-md) {
            margin-bottom: 20px;
        }

        > .amshopby-item {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding: 7px 5px 5px;
            margin: 5px;
            border: 1px solid $c-light6;
            border-radius: $radius-btn;
            font-family: $f-benton;
            font-size: 1.4rem;
            font-weight: $fw-benton-R;
            transition: border-color .3s;

            &:hover,
            &:focus,
            &:active {
                border-color: currentColor;
            }

            > * {
                padding: 0 3px;
            }

            .amshopby-filter-name {
                @extend .visually-hidden;
            }

            .amshopby-remove {
                position: static;
                width: inherit;
                height: inherit;
                padding-left: 10px;

                &::before,
                &::after {
                    background-color: transparent;
                }

                &::before {
                    @extend %icon-icomoon;
                    content: "\e91c";
                    position: static;
                    width: inherit;
                    height: inherit;
                    transform: inherit;
                    font-size: 1.4rem;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: inherit;
                    height: inherit;
                    transform: inherit;
                }
            }
        }
    }

    + .filter-actions {
        display:none;
    }
}


//
//  Options
//  _____________________________________________

div.filter-options {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(only-md) {
        padding-bottom: 100px; // Prevent submit btn fixed
    }

    @include breakpoint(md) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .filter-options-item {
        @include breakpoint(only-md) {
            padding: 0 !important;
            margin: 0 !important;
        }

        &.active {
            .filter-options-title {
                .icon-arrow {
                    transform: rotate(-90deg);
                }

                @include breakpoint(md) {
                    background-color: $c-white;
                    border: $filter-border;
                    border-bottom-width: 0;

                    &::after {
                        background-color: $c-white;
                    }
                }
            }

            .filter-options-content {
                padding: 0;
            }
        }

        @include breakpoint(md) {
            position: relative;
        }
    }

    // Title
    .filter-options-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: 1px; // Need to hide border overlay
        font-family: $f-bentonSC;
        font-size: 1.8rem;
        font-weight: $fw-benton-M;
        cursor: pointer;

        .icon-arrow {
            display: block;
            margin-left: 8px;
            transform: rotate(90deg);
        }

        @include breakpoint(only-md) {
            padding: 16px 27px;
        }

        @include breakpoint(md) {
            position: relative;
            padding: 30px 15px;
            border-bottom: 1px solid transparent;
            font-size: 1.6rem;

            &::after {
                content: '';
                position: absolute;
                z-index: 4;
                left: 0;
                right: 0;
                bottom: -1px;
                height: 2px;
                background-color: transparent;
            }
        }
    }

    // Content
    .filter-options-content {
        border-top: $filter-border;
        border-bottom: $filter-border;

        @include breakpoint(md) {
            position: absolute;
            z-index: 3;
            top: 100%;
            left: 0;
            min-width: 280px;
            background-color: $c-white;
            border: $filter-border;
            max-height: 500px;
            overflow: auto;
        }

        // Price ranges
        .am-ranges {

            .items {
                &.am-filter-items-attr_price {
                    max-width: inherit;
                }
            }

            .item {

            }
        }

        // Price input (From to)
        .am-fromto-widget {
            margin: $gutter/2 $gutter/4;

            .amshopby-input-wrapper {
                display: flex;
                align-items: center;

                // Currency
                .amshopby-currency {
                    margin-left: 5px;
                }
            }

            .am-filter-price {
                width: 80px;
                height: 50px;
                max-width: 100%;
                padding: 5px 10px;
            }

            // Action
            .am-filter-go {
                @extend .btn;
                width: auto;
                margin: 0 0 0 10px;
            }
        }


        li.item {

            > a {
                display: flex;
                justify-content: space-between;
                padding: 18px 27px;
                transition: background-color .3s ease-out;

                &:hover,
                &:focus,
                &:active,
                &.am_shopby_link_selected {
                    background-color: $c-light7;
                }

                .label {
                    margin-right: 10px;
                    margin-bottom: 0;
                }

                .count {
                    font-size: 1.4rem;

                    .filter-count-label {
                        @extend .visually-hidden;
                    }
                }
            }
        }

        .amshopby-slider-container {
            margin-top: 20px;
        }
    }
}
