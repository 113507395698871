// ==============================================
// MIXINS - Triangle
// ==============================================

/**
 * Becareful : when you switch to horizontal position (left and right),
 * width become height and height become width
 */

@mixin triangle ($position, $borderWidth: 20px, $borderHeight: 10px, $borderColor: currentColor) {

    $widthHalf: $borderWidth/2;
    $heighthHalf: $borderHeight/2;

    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;

        @if( $position == top) {

            top: $borderHeight * -1;
            left: calc(50%);
            transform: translateX(-50%);
            border-width: 0 $widthHalf $borderHeight $widthHalf;
            border-color: transparent transparent $borderColor transparent;

        } @else if( $position == right ) {

            top: calc(50%);
            transform: translateY(-50%);
            right: $borderWidth * -1;
            border-width: $heighthHalf 0 $heighthHalf $borderWidth;
            border-color: transparent transparent transparent $borderColor;

        } @else if( $position == bottom ) {

            left: calc(50%);
            transform: translateX(-50%);
            bottom: $borderHeight * -1;
            border-width: $borderHeight $widthHalf 0 $widthHalf;
            border-color: $borderColor transparent transparent transparent;

        } @else if ( $position == left ) {

            top: calc(50%);
            transform: translateY(-50%);
            left: $borderWidth * -1;
            border-width: $heighthHalf $borderWidth $heighthHalf 0;
            border-color: transparent $borderColor transparent transparent;
        }
    }
}