// ==============================================
// PANEL
// ==============================================



// Panel
@include breakpoint(only-md) {
    body.sticky {
        .bra-panel {
            position: fixed;
            top: 40px;
        }
    }
}

.mdp-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: $panel-max-width;
    background-color: $c-white;
    transform: translateX(100%);
    transition: transform .4s ease-out;
    overflow-y: hidden;
    z-index: 13;
    
    &.is-open {
        position: absolute;
        transform: translateX(0);
        transition: transform .4s ease-out;

        @include breakpoint(md) {
            position: fixed;
        }
    }
}

// Container
@include breakpoint(only-md) {
    .header-top-displayed .mdp-panel-container {
        height: calc(100vh - 60px);
    }
    .mdp-panel-container {
        height: calc(100vh - 40px);
    }
}

.mdp-panel-container {
    overflow-y: auto;
    padding: $panel-mobile-padding-top-bottom $panel-mobile-padding-left-right;

    @include breakpoint(xs) {
        padding: $panel-desktop-padding-top-bottom $panel-desktop-padding-left-right;
    }

    @include breakpoint(md) {
        height: 100vh;
    }
}

// Close button
.mdp-panel__close {
    position: absolute;
    top: 18px;
    right: 28px;
    width: 2.6rem;
    height: 2.6rem;
    cursor: pointer;
    z-index: 100;
    background-color: $c-white;

    .icon {
        font-size: 1.6rem;
    }
}