.strate-paralax {
    height: 400px;
    width: 100%;
    overflow: hidden;

    img {
        min-height: 700px;
        width: 100%;
        z-index: -1;
        will-change: transform;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
    }
}