// ==============================================
// STRATE PRODUCTS
// ==============================================

//
//  Configuration
//  _____________________________________________


.ct-view-strate_produits {
    
    .block-content {
        @extend .container;
        position:relative;
    }

    .strate-title {
        margin-bottom: 5px;
        border:none;
        @include breakpoint(only-lg) {
            padding-right:50%;
        }
        &.without-tabs{
            padding-right:0;
            border-bottom: 1px solid $c-black;
        }
    }

    .picture-wrapper {
        margin-bottom: $gutter-large;

        @include gutter-revert($gutter);

        @include breakpoint(lg) {
            margin-bottom: $gutter;
            @include gutter-revert($gutter-large);
        }

        img {
            width: 100%;
        }
    }

    .strate-desc {
        font-size: 1.6rem;
        line-height: 2;

        @include breakpoint(lg) {
            font-size: 1.8rem;
        }
    }

    .product-grid-header {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        overflow-x: auto;
        border-bottom: 1px solid $input-border-color;
        margin-bottom: 20px;

        @include breakpoint(md) {
            flex-direction: row;
            align-items: center;
        }

        @include breakpoint(lg) {
            position: relative;
            margin-bottom: 60px;
        }

        .title {
            @extend .title-2;
            margin-bottom: 0;
        }

        .link {
            padding: 15px 0;
            cursor: pointer;
            font-size: 1.6rem;
            font-weight: $fw-benton-M;
            font-family: $f-benton;
            @extend .link-arrow;

            @include breakpoint(lg) {
                padding: 0;
            }
        }
    }

    .products-grid {
        margin-right: -$gutter;

        &:not(:last-child) {
            margin-bottom: 60px;

            @include breakpoint(lg) {
                margin-bottom: 100px;
            }
        }

        // Reset grid mobile css
        .product-items.list li.product-item {

            & + li.product-item {
                @include breakpoint(only-sm) {
                    border-top: none;
                    padding-top: 0;
                    margin-top: 0;
                }
            }
        }

    }
    .tabs-target-wrapper {
        margin-left:-30px;
        margin-right:-30px;

        &.carousel-only-desktop{
            margin-left:-20px;
        }
    }
    
    // Product list
    .products {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &[data-slideshow="products-list-only-mobile"]{
            align-items: stretch;

            .product-item{
                height:auto;
            }
        }
        
        .product-item{
            height:100%;

            .product-item__inner {
                height:100%;
            }
        }
    }
    .products:not(.slick-slider){
        padding-left: 10px;
        padding-right: 30px;

        > .product-item {
            width: 100%;

            @include breakpoint(sm) {
                width: 50%;
            }

            @include breakpoint(lg) {
                width: 25%;
            }
        }
    }

    .product-item {
        form[data-role="tocart-form"] {
            order: 1;

            button{
                max-width: 145px;
                
                &:after{
                    display:none;
                    content: '';
                }
            }
        }
    }

    .slick-arrow {
        display: none !important;
        opacity: 0;
    }

    .tabs-wrapper{
        position:relative;
        padding-bottom:10px;
        overflow:visible;
        @include breakpoint(only-lg) {
            margin-bottom:$gutter;
        }
        .tab__name{
            transition:all .3s linear;
            
            &:focus{
                font-weight: $fw-benton-B;
            }
        }

        .tab__name > *, .tab{
            font-size: 1.5rem;
            font-family: $f-benton;
            font-weight: $fw-benton-R;
            @include breakpoint(lg) {
                font-size: 1.8rem;
            }
        }
        
        .tab__link:after{
            content:none;
        }

        .tab.is-active{
            .tab__link{
                top:0;
                right:0;
                bottom: auto;
                left: auto;
                margin-top: -35px;
                margin-right:0;
                text-decoration:underline;
                border-bottom:none;

                &:focus{
                    text-decoration:none;
                }
            }
        }

        .tab{

            &.is-active{
                .tab__name{
                    color: $c-gold4;
                    border-bottom:none;
                    h4{
                        font-weight: $fw-benton-B;
                    }
                }
            }
        }
    }

    .allproductsbtn-wrapper{
        padding-top:20px;
        display:flex;
        justify-content: center;

        @include breakpoint(lg) {
            padding-top:50px;
        }
    }

    // Remove product hover image
    .product-item__inner{
        @include breakpoint(sm) {
            &:hover,
            &:focus,
            &:active {
                .product-item-photo {
                    .hover-classic {
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
        }
        
        // Display CTA Découvrir
        .product-item-discover-link{
            display:block;
        }
    }
}
