// ==============================================
// ONEPAGE - Progress bar
// ==============================================

.opc-progress-bar {
    display: flex;
    position: relative;
    margin-bottom: 50px;
    border-bottom: 1px solid $c-light6;

    @include breakpoint(only-lg) {
        justify-content: space-between;
        border-bottom: none;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            height: 1px;
            margin-top: -2px;
            background-color: $c-black;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 1px;
            background-color: $c-light6;
            width: calc(100vw - #{$gutter*2});
        }
    }


    // Step
    li {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: $gutter/2;
        padding-bottom: $gutter/2;

        @include breakpoint(only-lg) {
            background-color: $c-light7;

            &:first-child {
                padding-right: 12px;
            }

            &:last-child {
                padding-left: 12px;
            }
        }

        @include breakpoint(lg) {
            &:first-of-type {
                margin-right: 60px;
            }
        }

        // Link
        & > span {
            flex: 1;
            font-family: $f-bentonSC;
            font-size: 1.8rem;
            font-weight: $fw-benton-B;
            color: $c-light6;

            @include breakpoint(lg) {
                font-size: 6rem;
            }
        }

        // Icon
        &::before {
            content: '';
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-right: 6px;
            border: 1px solid $c-light6;
            border-radius: 100%;
            font-size: 9px;

            @include breakpoint(lg) {
                width: 32px;
                height: 32px;
                margin-top: 8px;
                margin-right: 30px;
                font-size: 1.4rem;
            }
        }

        // Active step
        &._active,
        &._complete {

            // Step icon
            &::before {
                @extend %icon-icomoon;
                content: "\e91a";
                color: $c-white;
                border-color: $c-black;
                background-color: $c-black;
            }

            // Link
            span {
                color: $c-black;
            }
        }

        // Completed step
        &._complete {
            span {
                cursor: pointer;
            }
        }
    }
}
