// ==============================================
// CS BLOCKS - Common
// ==============================================

.cs-block {
    padding: 20px 0;

    @include breakpoint(md) {
        padding: 40px 0;
    }
}