// ==============================================
// STRATE 3X1 Collections
// ==============================================

.ct-view-strate_3x1_collections {
    @include breakpoint(lg) {
        margin-bottom:-160px; //compensate for hell 
    }

    .widget-content-grid {
        margin-left: -15px;
        margin-right: -15px;

        .content-item {
            padding: 0;
            max-width: none;
            border:none !important;

            .block.widget{
                box-shadow:none;
            }
            .labels {
                position:absolute;
                min-height: 38px;
                margin-top:-40px;
                font-size: 1rem;
            }

            .block-content{
                height: calc(100% - 100px);
                
                @include breakpoint(lg) {
                    padding-bottom: 90px;
                    overflow:hidden;
                }

                & > a{
                    margin: 40px 10px 10px 10px;
                    padding: 0;
                    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    
                    @include breakpoint(lg) {
                        margin-bottom: 30px;
                    }

                    img{
                        border-radius: 4px 4px 0 0;
                    }

                    .collection-informations{
                        padding-left:15px;
                        padding-right:15px;
                        padding-bottom:10px;

                        @include breakpoint(lg) {
                            position: relative;
                            top: -107px; //from -80
                            transition: top .3s ease-out;
                            z-index: 1;
                        }

                        .descriptif{
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            /* autoprefixer: off */
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            
            // Hover desktop animation
            @include breakpoint(lg) {
                &:hover {
                    z-index: 5;

                    &::before {
                        height: var(--hover-height);
                    }

                    .collection-photo {
                        border-radius :4px 4px 0 0;
                        
                        // Animation with hover image
                        &.images {
                            .image-hover {opacity: 1;}
                            .image {opacity: 0;}
                        }
                    }

                    .collection-photo + .collection-informations {
                        top: 0px; //from 0
                    }

                    .descriptif {
                        opacity: 0;
                    }

                    .title {
                        background-size: 100% 1px;
                    }
                }
            }
        }
        
    }

    & > .block-content {
        @extend .container;
        padding-bottom: $gutter;

        @include breakpoint(lg) {
            padding-bottom: 60px;
        }

        .ct-view-collections {
            margin-bottom: 0;
        }

        .block-content {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    .content {
        margin-right: -$gutter;
    }

    // Slick slideshow
    .slick-slider {
        @extend .slick-arrows-outside;

        margin-left: -8px;
        margin-right: -8px;

        @include breakpoint(lg) {
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    // .slick-slide {

    //     &:not(:last-of-type) .content-item,
    //     .content-item:not(:last-of-type) {

    //         @include breakpoint(sm) {
    //             border-right: 1px solid $input-border-color;
    //         }
    //     }
    // }
}
