// ==============================================
// UI - DROPDOWN
// ==============================================


.actions.dropdown {
    position: relative;
    z-index: 1;
    font-size: 1.4rem;

    &.active > ul.dropdown {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);

        @include breakpoint(only-md) {
            position: static;
        }
    }

    // Toggle element
    > .toggle {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px 20px;
        position: relative;
        z-index: 1;
        font-family: $f-bentonSC;
        font-weight: $fw-benton-M;

        &::after {
            display: inline-block;
            @extend %icon-icomoon;
            content: "\e907";
            margin-left: 8px;
            transform: rotate(90deg);
        }
    }

    // Content list
    > ul.dropdown {
        position: absolute;
        padding-top: 10px;
        padding-bottom: 10px;
        visibility: hidden;
        opacity: 0;
        background-color: $c-white;
        transform: translateY(-20px);
        transition: opacity .3s linear, transform .3s linear;

        @include breakpoint(md) {
            border: 1px solid $c-light6;
            border-radius: $radius-box;
            margin-top: -1px;
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 100%;

        }

        & > li a {
            display: block;
            padding: 10px 20px;

            @include breakpoint(md) {
                background-color: transparent;
                padding: 10px 20px;
                transition: background-color .3s linear, color .2s linear;

                &:hover {
                    background-color: $c-light7;
                }
            }
        }
    }
}