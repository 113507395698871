// ==============================================
// UI COMPONENTS - Sidebar menu
// ==============================================

//
//  Desktop
//  _____________________________________________

.block-collapsible-nav {

    @include breakpoint(md) {
        .sidebar & {
            margin-bottom: 0;
        }
    }

    // Title
    .title {

        @include breakpoint(md) {
            display: none;
        }
    }

    // Item
    li.item {
        position: relative;
        line-height: 1;
        margin: 0 0 0 35px;

        @include breakpoint(only-md) {
            padding: 10px 18px;
        }

        + li.item {
            display: flex;
            align-items: center;

            @include breakpoint(md) {
                margin-top: 34px;
            }
        }


        &::before,
        a::before,
        strong::before {
            @extend %icon-icomoon;
            position: absolute;
            left: -20px;
            display: inline-block;
            font-size: 2rem;

            @include breakpoint(md) {
                left: -35px;
            }
        }

        .account {
            &::before {
                content: "\e969"; //account
            }
        }

        .information {
            &::before {
                content: "\e947";
            }
        }

        .orders {
            &::before {
                content: "\e906";
            }
        }

        .address {
            &::before {
                content: "\e931"
            }
        }

        .wishlist {
            &::before {
                content: "\e92f";
            }
        }

        .loyalty {
            &::before {
                content: "\e92c"
            }
        }

        .option {
            &::before {
                content: "\e95a"
            }
        }

        .return {
            &::before {
                content: "\e951";
            }
        }

        .gdpr {
            &::before {
                content: "\e946";
            }
        }


        a,
        &.current > strong {
            font-size: 1.4rem;
            color: $c-black;;
            line-height: 1.5;

            @include breakpoint(md) {
                font-size: 1.6rem;
            }
        }

        &.current {
            > strong,
            a {
                display: inline-block;
                border-bottom: 1px solid;
            }
        }

        a {
            @extend .link-hover-black;
        }
    }
}

//
//  Tablets & Mobiles
//  _____________________________________________

// Configuration

$collapsible-nav-mobile-bg-color:       $c-white;
$collapsible-nav-mobile-title-padding:  20px 40px 20px 18px;
$collapsible-nav-mobile-border-color:   $c-light6;

@include breakpoint(only-md) {

    .block-collapsible-nav {
        z-index: 5;
        border: 1px solid $collapsible-nav-mobile-border-color;
        border-radius: 5px;
        background-color: $collapsible-nav-mobile-bg-color;

        // Title
        .title {
            position: relative;
            font-size: 1.4rem;
            padding: $collapsible-nav-mobile-title-padding;
            text-transform: uppercase;
            margin-bottom: 0;

            // Arrow
            &::after {
                position: absolute;
                display: inline-block;
                top: 50%;
                right: 10px;
                @include icon-icomoon;
                content: "\e907";
                font-size: 1.2rem;
                transform: rotate(90deg) translateX(-50%) scaleX(1);
                transition: all .1s linear;
            }

            &.active::after {
                transform: rotate(90deg) translateX(-50%) scaleX(-1);
            }
        }

        // Content
        .content {
            display: none;
            padding: 10px 0;
            border-top: 1px solid $collapsible-nav-mobile-border-color;

            &.active {
                display: block;
            }
        }
    }
}
