// ==============================================
// LAYOUT - Footer
// ==============================================

$footer-border:                         1px solid rgba(51, 51, 51, 0.25);
$footer-reinsurance-background-color:   #E6E5DE;

.page-footer {
    background-color: $c-white;
    
    @include breakpoint(md) {
        border-top: 1px solid #98A6A6;
    }

    > .mdp-footer-reinsurance {
        background-color: $footer-reinsurance-background-color;
    }

    .footer-reinsurance {
        @extend .container;
        padding-top: 40px;
        padding-bottom: 40px;

        @include breakpoint(md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    .reinsurance-list {

        @include breakpoint(md) {
            display: flex;
            margin: 0 -15px;
        }

        .reinsurance-list-item {

            @include breakpoint(only-md) {
                + .reinsurance-list-item {
                    margin-top: 20px;
                }
            }

            @include breakpoint(md) {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 15px;
            }
        }

        .reinsurance-item {
            flex: 1;
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 15px;
            border-left: $footer-border;
            font-weight: $fw-benton-B;
            font-size: 2.2rem;
            line-height: 1.3;
            text-align: left;
        }
    }

    .footer.content {
        @extend .container;
        padding-top: 46px;
        padding-bottom: 20px;

        @include breakpoint(md) {
            padding-top: 105px;
            padding-bottom: 70px;
        }

        .footer-social-container,
        .mdp-footer-links {
            @include breakpoint(md) {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;

                > div {
                    padding: 0 15px;

                    &[class*="mdp-footer-"] {
                        flex: 0 0 50%; // Fix IE11
                    }

                    &.column {
                        display: flex;
                        flex: 0 0 25%; // Fix IE11
                    }
                }
            }
        }

        // Page builder structure html
        .mdp-footer-links > div[data-content-type="html"] {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;

            @include breakpoint(only-md) {
                display: block;
            }

            > div {
                padding: 0 15px;

                &[class*="mdp-footer-"] {
                    flex: 0 0 50%; // Fix IE11
                }

                &.column {
                    display: flex;
                    flex: 0 0 25%; // Fix IE11

                      @include breakpoint(only-md) {
                        display: block;
                      }

                }
            }
        }


        // Links
        .footer-links-container {
            margin-top: 40px;

            @include breakpoint(only-md) {
                padding-top: 5px;
                padding-bottom: 5px;
                border-left: $footer-border;
            }

            @include breakpoint(md) {
                margin-top: 70px;
            }
        }

        // Newsletter
        .block.newsletter {
            padding-left: 15px;
            border-left: $footer-border;

            .newsletter-baseline {
                max-width: 550px;
                margin-bottom: 20px;
                font-size: 1.2rem;
            }
        }
    }
}

// Prez
.mdp-footer-prez {
    font-weight: $fw-benton-B;
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 60px;

    @include breakpoint(md) {
        max-width: 920px;
        font-size: 3.2rem;
        margin-bottom: 90px;
    }

    @include breakpoint(lg) {
        max-width: 70%;
        min-width: 920px;
    }
}

// Links
.mdp-footer-links {
     @include breakpoint(only-md) {
         .column {
             + .column {
                 margin-top: 15px;
             }
         }

         .footer-title {
             display: flex;
             justify-content: space-between;

             .icon-arrow {
                 transition: transform .3s;
             }
         }

         [data-role="title"] {
             &[aria-selected="true"] {
                 .icon-arrow {
                     transform: rotate(90deg);
                 }
             }
         }
     }

    @include breakpoint(md) {
        .footer-links-content {
            padding-left: 15px;
            border-left: $footer-border;
        }

        .footer-title {
            margin-bottom: 25px;
        }
    }

    .footer-list {

        li{
            padding: 4px 0;
        }

        a {
            @extend .link-hover-black;
            display:inline;
            line-height:1.4;
        }
    }
}

// Social & Avis verifies
.mdp-footer-social {
    @include breakpoint(only-md) {
        margin-top: 40px;
    }
    @include breakpoint(md) {
        display: flex;
    }
}

// Social
.mdp-social-links {
    padding-left: 15px;
    border-left: $footer-border;

    .footer-baseline {
        margin-bottom: 0;
        font-size: 1.4rem;

        @include breakpoint(md) {
            font-size: 1.6rem;
        }
    }

    // List
    .social-list {
        display: flex;
        margin: 30px -15px 0;

        @include breakpoint(md) {
            margin-top: 45px;
        }
    }

    .social-list-item {
        padding: 0 15px;

        & > a {
            @extend .animated-border;
        }
    }
}

// Avis verifies
.mdp-avis-verifies {

    @include breakpoint(only-md) {
        margin-top: 40px;

        img {
            width: auto;
            height: 90px;
        }
    }

    @include breakpoint(md) {
        margin-left: 70px;
    }
}
// reinsurance fix 
.category-l-institution .footer-reinsurance {
    display: none;
}


// Mentions
.mdp-copyright {
    padding-top: 30px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5;

    @include breakpoint(only-md) {
        width: 280px;
        margin-right: auto;
        margin-left: auto;
    }

    @include breakpoint(md) {
        font-size: 1.4rem;
    }

    @include breakpoint(lg) {
        padding-top: 90px;
        font-size: 1.5rem;
    }

    .footer-checkout & {
        padding-bottom: 30px;

        @include breakpoint(lg) {
            padding-top: 150px;
            padding-bottom: 80px;
        }
    }
}
