// ==============================================
// PUBLIC - Contact
// ==============================================

.contact-index-index,
.cms-page-view.collection-form {

    .page-main {
        padding-left: $gutter;
        padding-right: $gutter;

        @include breakpoint(md) {
            padding-left: $gutter-large*2;
            padding-right: $gutter-large*2;
        }
    }

    .columns {

        @include breakpoint(only-md) {
            margin: 0 -10px;
        }

        .column.main {
            max-width: 550px;

            @include breakpoint(md) {
                max-width: 650px;
            }
        }
    }

    form.contact {
        padding: 30px 15px;
        background-color: $c-white;

        @include breakpoint(xs) {
            padding: 30px;
        }

        @include breakpoint(md) {
            padding: 40px;
        }

        .note + select {
            margin-top: $gutter-large/2;
            margin-bottom: $gutter-large;
        }
    }
}


//
// Contact Form - Collection marketing (based on cms page)
//

.cms-page-view.collection-form {

    // Hidden elements
    .breadcrumbs {
        @extend .visually-hidden;
    }

    // Title
    .page-title-wrapper {
        padding: 0;

        .page-title {
            border-bottom-color: $c-light6;

            .base {
                font-family: $f-bentonSC;
            }
        }
    }

    .columns {
        .column.main {

            @include breakpoint(md) {
                max-width: 600px;
            }
        }
    }

    // Form
    form {

        legend {
            font-family: $f-benton;
            font-size: 1.8rem;

            @include breakpoint(md) {
                font-size: 2.2rem;
            }
        }

        .field {

            &.file_collection {
                display: flex;
                flex-direction: column-reverse;
                margin-top: $gutter-large;
                margin-bottom: $gutter-large;

                label,
                input {
                    &:hover {
                        cursor: pointer;
                    }
                }

                input {
                    padding-top: 0 !important; // Used !important cause css selector nesting too high
                    height: auto;
                    border: none;
                    text-indent: -140px;
                    margin-left: 35px;
                    max-width: 90%;

                    @include breakpoint(lg) {
                        text-indent: -150px;
                    }

                    &:valid {
                        position: static;
                        opacity: 1;
                    }

                    &:invalid {
                        position: absolute;
                        opacity: 0;
                    }
                }

                label {
                    display: flex;
                    align-items: center;
                    position: relative;

                    &::before {
                        @include icon-icomoon;
                        content:"\e90a";
                        display: inline-block;
                        font-size: 3rem;
                        margin-right: $gutter/2;
                    }

                    span {
                        &::after {
                            content: '*';
                        }
                    }
                }

                em {
                    display: none;
                }
            }

            &.note {

                strong  {
                    font-weight: $fw-benton-M;
                }
            }

            &.consent {
                display: flex;
                align-items: center;
                justify-content: space-between;

                label,
                input {
                    &:hover {
                        cursor: pointer;
                    }
                }

                label {
                    font-family: $f-benton;
                    font-weight: $fw-benton-R;
                    margin-left: 0 !important; // Used !important cause css selector nesting too high

                    &::before {
                        border-radius: 0;
                        border-color: $c-black;
                        top: 50%;
                        transform: scale(1) translate(0,-50%);
                    }

                    &::after {
                        top: 50%;
                        transform: scale(1) translate(-28%, -73%);
                    }
                }

                .btn {
                    padding: 0;
                    margin: 0 $gutter;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        font-size: 2rem;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    *[data-content-type="text"] {
        margin-bottom: $gutter;
        @include breakpoint(md) {
            margin-bottom: $gutter-large;
        }
    }

    .text-legal {
        margin-top: $gutter;
        font-size: 1.2rem;

        @include breakpoint(md) {
            margin-top: $gutter-large;
            font-size: 1.4rem;
        }
    }
}
