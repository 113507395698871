// ==============================================
// STRATE ASYMETRI
// ==============================================

.ct-view-strate_contenu_asym_trique {

    // Asymetrique grid
    &.asymetrique {
        .strate-list {
            @include breakpoint(only-sm) {
                > * + * {
                    padding-top: 15px;
                    margin-top: 45px;
                    border-top: 1px solid $c-light6;
                }
            }

            @include breakpoint(sm) {
                column-count: 2;
                margin-left: -15px;

                > * {
                    break-inside: avoid;
                    padding-left: 15px;
                    padding-bottom: 60px;
                    border-left: 1px solid $c-light6;
                }
            }

            @include breakpoint(md) {
                column-count: 3;
            }
        }
    }

    // Classical grid
    &.grille {
        .strate-list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -$gutter-large/2;
            margin-right: -$gutter-large/2;
            margin-bottom: 50px;

            @include breakpoint(md) {
                margin-bottom: $gutter-large;
            }
        }

        .strate-list-item {
            padding: 0 $gutter-large/2;

            @include breakpoint(only-sm) {

                .strate-item {
                    padding-bottom: $gutter + $gutter-large;
                }

                &:not(:first-child) .strate-item {
                    padding-top: $gutter-large/2;
                }

                &:not(:last-child) .strate-item {
                    border-bottom: 1px solid $input-border-color;
                }
            }

            @include breakpoint(sm) {
                width: 50%;

                .strate-item {
                    padding-bottom: $gutter + $gutter-large;
                    border-bottom: 1px solid $input-border-color;
                }

                // Two first elements
                &:nth-child(n+3) .strate-item {
                    padding-top: $gutter-large/2;
                }

                // Two last elements spacing and separator
                &:last-child .strate-item,
                &:nth-last-child(2) .strate-item {
                    border-bottom: none;
                    padding-bottom: 0;
                }

                // Vertical border on even elements
                &:nth-child(even) {
                    border-left: 1px solid $input-border-color;
                }
            }

            @include breakpoint(md) {
                width: calc(100% / 3);

                // Set vertical border and remove it for every three element (first element by line)
                border-left: 1px solid $input-border-color;

                &:nth-child(3n+1) {
                    border-left: none;
                }

                // Reset padding top on third element
                &:nth-child(3) .strate-item {
                    padding-top: 0;
                }

                // Three last elements spacing and separator
                &:last-child .strate-item,
                &:nth-last-child(2) .strate-item,
                &:nth-last-child(3) .strate-item {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }

}

.strate-asymetrique {
    overflow: hidden;

    .strate-item__redirect {
        display: block;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.04);
            }
        }

        img {
            display: block;
            transform: scale(1);
            transition: transform .3s linear;
        }
    }

    .strate-item-title .strate-item-link {
        //animation line (with background)
        position: relative;
        display: inline;
        text-decoration: none;
        background-image: linear-gradient(currentColor, currentColor);
        background-size: 0% 1px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        transition: background-size .5s ease-out;
        &:hover,
        &:focus {
            background-size: 100% 1px;
        }
    }
}