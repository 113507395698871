// ==============================================
// FORMS - Textarea
// ==============================================

textarea {
    display: block;
    width: 100%;
    padding: 10px 10px;
    font-size: 1.3rem;
    font-family: $f-base;
    line-height: 1.8;
    color: $c-base;
    background-color: $input-background;
    border: 1px solid $input-border-color;
    border-radius: $radius-box;
    resize:none;
}