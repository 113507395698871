// ==============================================
// CART - Summary
// ==============================================

//
//  Configuration
//  _____________________________________________

$checkout-summary-width:                30%;
$checkout-summary-margin:               30px;

//
//  Cart
//  _____________________________________________

.cart-container {
    @include breakpoint(lg) {
        margin-top: 20px;
        margin-bottom: 150px;
    }

    // Summary
    .cart-summary {

        > .summary.title {
            display: block;
            padding-bottom: 10px;
            margin-bottom: 30px;
            border-bottom: 1px solid $c-light6;
            font-weight: $fw-benton-B;
            font-size: 2rem;

            @include breakpoint(lg) {
                margin-bottom: 20px;
                font-size: 2.2rem;
            }
        }

        > .block {
            margin-bottom: 20px;

            > .title {
                margin-bottom: 8px;

                strong {
                    font-size: 1.6rem;
                    font-weight: $fw-benton-M;
                    font-family: $f-bentonSC;
                }
            }
        }

        > .shipping {
            @extend %collapsible-full;
        }

        > .discount {
            > .content {
                input {
                    padding: $gutter;
                    border-radius: 5px;
                    height: $input-height;

                    + label {
                        margin-left: 20px !important;
                    }
                }

                ::placeholder {
                    font-weight: $fw-benton-M;
                    color: #A1A1A1;
                }

                .fieldset {

                    #coupon-apply-form {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        > .field {
                            flex: 1;
                            margin-right: 18px;
                            order: 1;
                        }

                        > .actions-toolbar {
                            margin-top: 0;
                            order: 2;

                            button {
                                border-bottom: 1px solid;
                                transition: color .3s;
                                cursor: pointer;

                                &:hover {
                                    color: $c-orange;
                                }
                            }
                        }

                        .mage-error {
                            width: 100%;
                            padding-left: 0;
                            order: 3;
                        }
                    }


                    > .messages {
                        width: 100%;
                    }
                }

                .label {
                    color: $c-grey;
                    font-family: $f-bentonSC;
                    font-weight: $fw-benton-M;
                    font-size: 1.6rem;
                }
            }
        }

        > .cart-totals {
            margin-bottom: 20px;

            tr {
                th,
                td {
                    font-size: 1.4rem;

                    @include breakpoint(lg) {
                        font-size: 1.6rem;
                    }
                }

                &.grand.totals {
                    th,
                    td {
                        justify-content: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-top: inherit;
                        border-bottom: 1px solid $c-light6;
                    }

                    th {
                        @include breakpoint(only-lg) {
                            font-size: 1.4rem;
                        }
                    }

                    td {
                        font-size: 1.8rem;
                    }
                }
            }
        }

        // Actions
        > .checkout.methods {

            .action {
                &.checkout {
                    width: 100%;
                }

                &.multicheckout {
                    text-align: center;
                    @extend .link-hover-black;
                }
            }
        }

        // Logo banks
        .banks-logos-container {
            width: 100%;
            margin-top: 26px;
            max-width: 344px;
            height: auto;
            display: flex;
        }
        .banks-logos {
            height: auto;
            max-width: 55%;
        }
        .ooney-banks-logo {
            max-width: 45%;
        }
    }

    // Shipping form
    #shipping-zip-form {
        legend, br {
            @extend .visually-hidden;
        }
    }

    #co-shipping-method-form {
        margin-top: 20px;

        .item-options {
            margin-top: 10px;
        }

        .item-options + .item-title {
            margin-top: 10px;
        }
    }
}

// Reinsurrance
.cart-delivery-reinsurance {
    margin-bottom: $gutter-large*2;

    @include breakpoint(md) {
        margin-bottom: 25px;
    }

    li {
        + li {
            margin-top: 10px;
        }

        > span {
            font-size: 1.4rem;

            &.icon-check-1 {
                margin-right: 15px;
                font-size: 1rem;
                font-weight: $fw-benton-B;
            }
        }
    }
}