// ==============================================
// CATALOG TAGS
// ==============================================

//
//  Configuration
//  _____________________________________________


// Extends default style
.labels {

    > span {
        display: inline-block;
        margin-right: $gutter/2;
        margin-bottom: $gutter/2;
        @extend .tag-item;
    }
}

// Discount Tag
.discount-percent {
    @extend .tag-item;
    @extend .orange-tag;
}