// ==============================================
// STRATE RICH SLIDER PRODUCTS
// ==============================================

//
//  Configuration
//  _____________________________________________

$slideshow-right-spacing:           60px;
$slideshow-right-spacing-desktop:   25vw;
$slideshow-right-spacing-xl:        15vw;



.ct-view-strate_rich_slider_produits {

    .block-content {}

    .content {
        padding-left: $gutter;
        padding-right: $gutter;

        @include breakpoint(lg) {
            display: flex;
            align-items: center;
            padding-left: $gutter-large;
            padding-right: $gutter-large;
        }
    }

    .description {

        @include breakpoint(lg) {
            padding-right: 20px;
            max-width: calc(100% - 20px);
            width: 25%;
            display: flex;
            justify-content: flex-end;
        }

        .content-text {
            line-height: 1.4;
            margin-bottom: 50px;

            @include breakpoint(lg) {
                font-size: 1.8rem;
                text-align: right;
                max-width: 260px;
                margin-bottom: 0;
            }
        }
    }

    .products {
        display: flex;

        &.slick-slider {
            display: block;
            margin-right: -$gutter;
            margin-left: -8px;
        }

        @include breakpoint(lg) {
            width: 75%;
            border-left: 1px solid $input-border-color;
        }
    }

    // Remove product item border left
    .product-item__inner,
    .product-item-details {
        &::before {
            display: none;
        }
    }

    //
    //  Product item
    //  _____________________________________________

    .slick-slide .product-item {
        visibility: visible;
    }
    .product-item {
        visibility: hidden;

        .product-item-metas {
            display: none;
        }
    }

    // Main image
    .product-item__inner .product-item-photo {
        position: relative;
        padding-bottom: 10%;
        padding-left: 8px;
        padding-right: 8px;

        @include breakpoint(md) {
            margin-bottom: initial;
        }

        &:hover {
            .hover-detoure {
                bottom: $gutter;

                //removed iOS animation on hover
                @supports (background: -webkit-canvas(squares)) {
                    bottom: 0;
                }
            }
        }
    }

    .product-image-container {}

    // Second image
    .hover-detoure {
        position: absolute;
        width: 60%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom .3s ease-out;

        img {
            filter: drop-shadow(20px 0px 20px rgba(0, 0, 0, 0.55));

            @include breakpoint(lg) {
                filter: drop-shadow(40px 0px 40px rgba(0, 0, 0, 0.55));
            }

            //only for safari, smaller filter drop shadow.
            @supports (background: -webkit-canvas(squares)) {
                //-webkit-filter:none !important;
                filter: drop-shadow(20px 0px 40px rgba(0, 0, 0, 0.55));
            }
        }
    }

    .product-item-details {
        display: flex;
        flex-direction: column;

        // Hide before being the current element
        opacity: 0;
        transition: opacity .3s ease-out;

        // Reorder children
        .product-item-link {
            order: 1;
        }
        .price-box {
            order: 2;
        }
        form[data-role="tocart-form"] {
            order: 3;
            margin-top: 10px;
        }
    }

    // Slick slider
    .slick-slider {
        .slick-prev {
            left: 30px;
        }

        .slick-next {

            @include breakpoint(md) {
                right: calc(#{$slideshow-right-spacing-desktop} + #{$gutter-large});
            }

            @media (min-width: $ultra-large) {
                right: calc(#{$slideshow-right-spacing-xl} + #{$gutter-large});
            }
        }

        .slick-prev,
        .slick-next {
            top: 35%;
            background-color: $c-gold4;
            color: $c-black;
            border:1px solid $c-gold4;
            &:hover,
            &:focus{
                background-color: $c-white;
            }
        }

        // Hide on mobile
        .slick-prev,
        .slick-next,
        .slick-dots {
            visibility: hidden;
            @include breakpoint(md) {
                visibility: visible;
            }
        }
    }

    .slick-current .product-item-details,
    .slick-active .product-item-details {
        opacity: 1 !important;
    }

    .slick-list {
        padding-right: $slideshow-right-spacing;

        @include breakpoint(md) {
            padding-right: $slideshow-right-spacing-desktop;
        }

        @media (min-width: $ultra-large) {
            padding-right: $slideshow-right-spacing-xl;
        }
    }

    .slick-dots {
        @include breakpoint(md) {
            padding-right: $slideshow-right-spacing-desktop;
        }

        @media (min-width: $ultra-large) {
            padding-right: $slideshow-right-spacing-xl;
        }
    }
}