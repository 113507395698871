// ==============================================
// HEADER - Minicart
// ==============================================

//
//  Configuration
//  _____________________________________________

$minicart-width: 380px;
$minicart-min-height: 150px;
$minicart-padding: 20px;
$minicart--width-img: 110px;
$minicart-items-full-width: false;
$minicart-items-delimiter: 1px solid lighten($c-black, 90%);
$minicart-debug-mode: false;

//
//  Minicart menu
//  _____________________________________________

.minicart-wrapper {
    position: relative;
    height: 100%;

    .counter {
        // Counter
        &.qty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;
            width: 18px;
            margin-left: 2px;
            padding: 4px;
            color: $c-black;
            font-size: 1.1rem;
            border-radius: 50%;
            background-color: $c-gold4;
            transition: background-color .2s;
            position: absolute;
            top: 5px;
            right: 7px;
            text-indent: 0;

            // Hide qty if cart is empty
            &.empty {
                display: none;
            }

            @include breakpoint(lg) {
                height: 22px;
                width: 22px;
                font-size: 1.2rem;
                font-weight: bold;
                top: -6px;
                right: 6px;
            }
        }

        .counter-number {
            margin-top: 2px;
        }

        .counter-label {
            @extend .visually-hidden;
        }
    }

    &.active {
        .block-minicart {
            visibility: visible;
        }

        .counter {
            &.qty {
                background-color: lighten($c-black, 30%);
                transition: background-color .3s;
            }
        }
    }
}

//
//  Minicart popin
//  _____________________________________________

#minicart-wrapper {
    height: 100%;
}

.block-minicart {
    height: 100%;

    // Empty cart
    .minicart-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: $minicart-min-height;
        margin-bottom: $minicart-padding;
    }

    // Content wrapper
    .minicart-content-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .block-title {
        margin-bottom: 40px;

        .text {
            font-size: 1.6rem;
            font-family: $f-bentonSC;
            font-weight: $fw-benton-M;
            line-height: 2;
            padding: 0; // Reset lightbox milople
        }

        .qty {
            display: inline-block;
            vertical-align: text-bottom;
            height: 18px;
            width: 18px;
            margin-left: 5px;
            padding: 4px;
            color: $c-white;
            font-size: 1.1rem;
            border-radius: 50%;
            background-color: $c-orange;
            text-align: center;
        }
    }

    .block-content {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;

        .minicart-login {
            margin-bottom: 10px;

            a span {
                font-weight: bold;
                text-decoration: underline;
                line-height: 1.4;
            }
        }

        // Reorder children

        > .action.close {
            order: 1;
        }

        > .minicart-items-wrapper {
            order: 2;
        }

        > .subtotal {
            order: 3;
        }

        > .extra {
            order: 4;
        }

        > .actions {
            order: 5;
        }
    }

    // Remove Element (fix ajax refreshing design)
    .minicart-widgets,
    .subtitle {
        display: none;
    }

    // Items
    .minicart-items-wrapper {
        height: auto !important;
        max-height: calc(100vh - 400px);
        overflow-y: auto;

        @if $minicart-items-full-width {
            margin: 0 (-$minicart-padding);
        }

        // Products list
        .minicart-items {
            border-top: $minicart-items-delimiter;
        }

        .product-item {
            position: relative;
            padding-bottom: $minicart-padding;
            padding-top: $minicart-padding * 2;
            border-bottom: $minicart-items-delimiter;

            // Product
            > .product {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                min-height: $minicart-min-height;
            }

            // Visual
            .product-item-photo {
                margin-right: 10px;
                width: 110px;
            }

            .product-image-container {
                display: block;
                width: 100% !important;

                .product-image-wrapper {
                    display: block;
                    padding-bottom: 0 !important; // Reset inline styles
                }

                .product-image-photo {
                    width: $minicart--width-img !important;
                    max-width: 110px;
                }
            }

            .actions {
                width: 100%;


                .primary,
                .secondary {
                    margin: 0;
                }

                .primary .edit {
                    margin-top: $gutter;

                    &::before {
                        content: "\e944";
                    }

                    span {
                        text-decoration: underline;
                    }
                }

                .secondary {
                    position: absolute;
                    top: 2px;
                    right: 5px;

                    .delete {
                        padding: 10px;

                        &::before {
                            font-size: 1.2rem;
                        }
                    }

                    span {
                        @extend .visually-hidden;
                    }
                }
            }
        }

        // Stickers
        .product-item-metas {
        }

        .labels {
            display: flex;
        }

        .labels > span,
        .discount-percent {
            font-size: 0.9rem;
            line-height: 1;
        }
    }

    // Product item
    .product-item-details {
        flex: 1;
        font-size: 1.4rem;
        line-height: 1.4;

        // Title
        .product-item-name {
            display: inline-block;
            margin-bottom: 2px;
            font-family: $f-bentonSC;
        }

        .product-mdp-caracteristique,
        .product-mdp-nombis {

        }

        // Options
        > .options {
            margin-bottom: 20px;
            @extend %collapsible-inline;

            .list {
                @include clearer;

                .label,
                .values {
                    float: left;
                    margin-top: 6px;
                }

                .label {
                    clear: left;
                    font-weight: bold;
                    margin-right: 6px;

                    &::after {
                        content: " :";
                    }
                }
            }
        }

        // Price
        .product-item-pricing {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .price-container {
            flex: 1 1 0;
            padding-left: 10px;
            text-align: right;
        }

        .minicart-price {

            .price {
                font-size: 1.4rem;
                font-weight: $fw-benton-M;
            }

            .minicart-product-base-price {
                display: block;
                text-decoration: line-through;

                & + .minicart-product-final-price {
                    color: $c-orange;
                }
            }
        }


        // Quantity
        .details-qty {

            > label {
                @extend .visually-hidden;
            }

            > input {
            }

            .soon-qty-updater-wrap {
                width: 85px;
                height: 50px;
            }

            .update-cart-item {
                position: absolute;
                visibility: hidden;
            }
        }
    }

    // grand total
    .grand-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-top: 40px;
        font-size: 1.8rem;
        font-weight: $fw-benton-M;
        line-height: 1.4;

        .amount {
            margin-left: 10px;
        }
    }

    .subtotal {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: $gutter;
    }

    .extra {
        margin-bottom: 55px;

        .estimated-shipping,
        .k-discount-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: $gutter;
        }

        .grand-total {
            margin-top: $gutter;
            padding-top: $gutter;
            border-top: $minicart-items-delimiter;
        }
    }

    // Actions
    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;

        > .primary,
        > .secondary {
            flex: 1 1 0;
        }

        > .primary {
            margin-left: 5px;
        }

        > .secondary {
            margin-right: 5px;
        }

        .action {
            display: block;
            width: 100%;
        }
    }

    // Milople personalized rich preview img
    .itemPopupImage {
        color: $button-icons-color;
        text-decoration: none;
        font-size: 1.4rem;

        &::before {
            content: "";
            display: inline-block;
            @extend %icon-icomoon;
            content: "\e944";
        }

        span {
            margin-left: 4px;
            border-bottom: 1px solid;
        }
    }
}

// Ajax reloading
body.ajax-loading {
    .block-minicart .block-content {

        &::before {
            display: block;
            width: 200px;
            height: 200px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url("../images/icon/loader.svg") no-repeat;
            background-size: 200px;
            content: "";
        }

        > *:not(.actions) {
            opacity: 0;
            transition: opacity .3s linear;
        }
    }
}


// Debug mode
@if $minicart-debug-mode {
    .ui-widget-content {
        opacity: 1 !important;
    }
    .ui-dialog {
        display: block !important;
    }
    .block-minicart {
        visibility: visible !important;
    }
}
