// ==============================================
// STRATE 2x1 MARKETING
// ==============================================

.strate-2x1 {
    margin: 0 -10px;

    @include breakpoint(md) {
        display: flex;
        justify-content: space-between;

        > * {
            width: calc(50% - 10px);

            + * {
                position: relative;
            }
        }
    }
    @include breakpoint(only-sm) {
        .slick-list {
            padding-right: 50px;
        }
    }
}

.strate-2x1-section {
    display: flex;
    flex-flow: column;

    @include breakpoint(md) {
        padding:0 10px;
    }

    &--media {
        position: relative;

        img {
            display: block;
            width: 100%;
        }
    }

    // @include breakpoint(only-md) {
    //     &:not(:last-of-type) {
    //         padding-bottom: 15px;
    //         margin-bottom: 15px;
    //         border-bottom: 1px solid $c-light6;
    //     }
    // }

    &--txt {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 58px 25px;
        background-color: $c-light7;

        @include breakpoint(lg) {
            padding: 25px;
        }
    }
    .strate-2x1-section-content{
        display:flex;
        flex-flow:column;
        flex: 1;
        align-items: baseline;

        a{
            order:2;
            margin-top:auto !important;
        }
        @include breakpoint(md) {
            &.iframe-embed {
                &,
                .strate-item-text,
                .strate-item-text .text-wysiwyg,
                .strate-item-text .text-wysiwyg p{
                    height: 100%;
                }
                #guestviews-widget {
                    height: 100% !important;
                    margin-top: -30px;
                }
            }
        }
    }
    .strate-item-title {
        order:0;
        margin-bottom: 5px;
        font-size:1.8rem;

        a {
            @extend .animated-border;
        }

        & + .btn {
            margin-top: $gutter;
        }
    }

    .strate-item-subtitle{
        order:1;
        margin-bottom:20px;
        font-size:1.6rem;
    }
}

.strate-2x1-content {

    &--media {
        @extend .overlay-content-block;

        width:100%;
        padding-top:50px;

        @include breakpoint(lg) {
            width: calc(100% - 20px);
            padding: 80px 100px 30px 100px;
        }

        .btn{
            margin-top:10px;
        }
    }

    &--txt {
        max-width: 500px;
    }
}

.strate-2x1-content-title {
    font-family: $source-serif-pro;
    font-weight: 400;
    font-size: 1.6rem;

    @include breakpoint(lg) {
        font-size: 2rem;
    }

    &--big {
        font-family: $f-bentonSC;
        font-weight: $fw-benton-B;
        font-size: 29px;

        @include breakpoint(lg) {
            font-size: 50px;
        }

        span{
            color: $c-orange;
        }
    }
}

.strate-2x1-section-visual {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 61%;
    overflow: hidden;

    .tag-item {
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 25px;
    }

    img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .2s linear;
    }

    &:hover, &:focus, &:active{
        img{
            transform: scale(1.04);
        }
    }
}
