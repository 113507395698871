// ==============================================
// AGENDA - Header
// ==============================================

.agenda-list-header {
    margin-bottom: 30px;
    @media(min-width: $medium) {
        margin-bottom: 50px
    }
}

.agenda-list-header__container {
    margin-bottom: 40px;
    @media(min-width: $medium) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid $c-light6;
        margin-bottom: 30px;
    }
}

.agenda-list-header__title {
    font-family: $f-bentonSC, sans-serif;
    font-size: 34px;
    line-height: 36px;
    font-weight: bold;
    margin-right: 20px;
    border-bottom: 1px solid $c-light6;
    padding-bottom: 10px;
    margin-bottom: 40px;
    @media(min-width: $medium) {
        font-size: 60px;
        line-height: 70px;
        border-bottom: unset;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.agenda-switcher {
    font-family: $f-bentonSC, sans-serif;
    display: flex;
    border: 1px solid $c-light6;
    border-radius: 40px;
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
    @media(min-width: $medium) {
        margin: unset;
    }

    & > li {
        a {
            display: block;
            padding: 7px 34px 11px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            width: 150px;
            text-align: center;
            border-radius: 40px;
            position: relative;
        }

        &.active a {
            color: $c-white;
            background-color: $c-black;
        }
    }
}

.agenda-list-header__inner {
    p:last-of-type {
        margin-bottom: 0 !important;
    }
}
