// ==============================================
// PRODUCTS PRICES
// ==============================================

.price-box {
    display: flex;
    font-weight: $fw-benton-M;

    .price-label {
        @extend .visually-hidden;
    }

    .special-price {
        color: $c-orange;
        margin-left: $gutter-large/2;
        order: 2;
    }

    .old-price {
        text-decoration: line-through;
        order: 1;
    }
}
