// ==============================================
// ACCOUNT - Orders
// ==============================================

.account {
    //
    //  Table
    //  _____________________________________________

    @extend %order-table;

    // Fix for only addresses list on order detail
    .block-addresses-list {
        .block-content {
            flex-direction: column;
        }
    }

    //
    //  Title wrapper order details
    //  _____________________________________________

    .page-title-wrapper {

    }

    //
    //  Table on order details
    //  _____________________________________________

    .order-details-items {

        .order-title {
            display: none;
        }

        .table-wrapper {
            .data.table {
                > thead, > tbody, > tfoot {
                    tr {
                        th, td {
                            border: none
                        }
                    }
                }

                // Subtotal element
                .mark {
                    text-align: right;

                    @include breakpoint(only-md) {
                        float: left;
                        text-align: left;
                        width: 70%;
                    }
                }

                .amount {
                    text-align: right;

                    @include breakpoint(only-md) {
                        float: left;
                        text-align: right;
                        width: 30%;

                    }
                }
            }
        }

        // Add margin between "order-details-items" and "block-order-details-view"
        + .block-order-details-view {
            margin-top: 20px;
        }
    }

    //
    //  Status styles
    //  _____________________________________________

    .quote-status,
    .return-status,
    .order-status {
        border: 2px solid #f5f5f5;
        border-radius: 3px;
        padding: 12px 20px;
        text-transform: uppercase;
    }
}
