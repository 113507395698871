// ==============================================
// HEADER
// ==============================================

// Configuration
$header-height: 85px;
$header-height-desktop: 125px;

$header-search-max-width: 720px;
$header-search-border-radius: 40px;

$header-account-link-border-radius: 25px;
$header-cart-link-border-radius: 30px;

// Login as wrapper
.lac-notification-sticky {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    width: 100%;
}

.page-header {
    position: relative;
    z-index: 10;
    transition: top .3s linear;
    background-color: $c-white;

    @include breakpoint(lg) {
        padding: $gutter*1.25 0px;
    }

    &.fixed {
        position: fixed;
        width: 100%;
        top: 0;
    }

    &.fixed + .page-main {
        padding-top: 120px;
    }

    &.fixed + .breadcrumbs {
        padding-top: 120px;
    }

    //
    //  Panel Header Layout
    //  _____________________________________________

    > .panel {
        display: none;

        .mdp-header-landing{
            .landing-trigger{
                transition:all .3s linear;

                &:focus{
                    opacity: 0.6;
                }
            }

            .action {
                padding-right: 10px;
            }
        }

        @include breakpoint(lg) {
            display: block;
            margin-bottom: -35px;
            position: relative;
            z-index: 2;
        }

        & > .header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    //
    //  Main Header
    //  _____________________________________________
    > .header {

        @extend .container;
        max-width: none;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        position: relative;

        @include breakpoint(only-xs) {
            padding-left:10px;
            padding-right:10px;
        }

        @include breakpoint(only-lg) {
            flex-wrap: wrap;
            padding-top: $gutter;
            padding-bottom: $gutter;
        }

        @include breakpoint(lg) {
            padding-left: $gutter-large;
            padding-right: $gutter-large;

            // Reorder children
            .logo {order:1;}
            .mdp-header-selectors {order:2;}
            .mdp-header-menu-desktop {order:3;}
            .mdp-header-links {order:4;}
            .jd-burger {order:5;}
        }

        @include breakpoint(only-lg) {
            .mdp-header-selectors,
            .mdp-header-links,
            .topmenu-ctas,
            .logo{
                margin-left:0;
                margin-right:0;
            }

            .mdp-header-selectors{
                padding-left:25px;
                padding-right:25px;

                .switcher{
                    margin-top:10px;
                    padding-top:25px;
                    border-top:1px solid #998D83;
                    max-width: 300px;
                }

                .toggle,
                .toggle strong{
                    padding-left:0px;
                    font-family: $f-benton;
                    font-size:1.2rem;
                    font-weight: $fw-benton-R;
                }
            }
        }

        .jd-burger{
            margin-right:10px;
            margin-left: 5px;
            border-radius:40px;
            transition: all .3s linear;

            &:focus{
                opacity: 0.6;
            }

            .icon.icon-menu:before{
                font-size:2.5rem;
            }

            @include breakpoint(lg) {
                &:after {
                    content: 'Menu';
                    font-size: 1.2rem;
                    margin-top: 15px;
                }
            }

            @include breakpoint(only-lg) {
                span{
                    margin-top:0;
                }
            }
        }

        // Logo
        > .logo {
            display: block;
            align-self: center;

            @include breakpoint(only-lg) {
                flex: 1 1 0;
                text-align: center;
            }

            @include breakpoint(xl) {
                margin-right: $gutter;
            }

            @include breakpoint(only-xl) {
                margin-right: auto;
            }

            img {
                display: block;
                width: 70px;
                max-width: none;
                margin-left: auto;
                margin-right: auto;

                @include breakpoint(lg) {
                    width: 70px;
                }
            }
        }

        > .mdp-header-selectors {
            display: flex;
            align-items: center;
            white-space: nowrap;

            @include breakpoint(only-lg) {
                @include visually-hidden;
            }

            @include breakpoint(xl) {
                margin-right: 8%;
            }

            // Reset default padding
            .actions.dropdown > .toggle,
            .actions.dropdown > .toggle strong {
                padding: $gutter/2;
                font-family: $f-benton;
                font-size:1.2rem;
                font-weight: $fw-benton-R;
                text-transform: uppercase;

                @include breakpoint(only-lg) {
                    padding-left:0;
                }

                &:after{
                    font-size:2rem;
                }
            }
        }

        // Top customer links
        .mdp-header-links {
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint(only-lg) {
                margin-right: 10px;
            }
            @include breakpoint(lg) {
                justify-content: flex-end;

                // Reorder children
                .search-trigger {order: 1}
                .agenda-link {order: 2}
                .mdp-billet {order: 3}
                .account-link-container {order: 4}
                .minicart-wrapper {order: 5}
            }

            // Common style
            a,
            button {
                padding: 5px 10px;

                @include breakpoint(lg) {
                    flex-direction: column;
                }

                .icon {
                    height: 22px;
                    font-size: 2.5rem;
                }
                .label,
                .text {
                    display: none;
                    @include breakpoint(lg) {
                        display: block;
                        margin-top:5px;
                        padding: 0;
                        font-size: 1rem;
                        font-weight: 400;
                    }
                    @include breakpoint(xl) {
                        font-size: 1.2rem;
                    }
                }
            }

            .search-trigger {
                display:none;
                border-radius: 30px;

                @include breakpoint(lg) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .search-trigger,
            .mdp-top-links .account-link,
            .minicart-wrapper .showcart{
                transition: all .3s linear;

                &:focus{
                    opacity: 0.6;
                }
            }
            .account-link,
            .action.showcart {
                display: flex;
                align-items: center;
                justify-content: center;
                height:42px;
                border: 1px solid transparent;
                border-radius:5px;

                @include breakpoint(only-lg) {
                    margin: 0 3px;
                }
            }
        }

        .search-trigger {
            height: 40px;
            padding: 5px 8px;
            font-size: 2.5rem;
            align-items: center;
            justify-content: center;
            transition:all .3s linear;

            &:focus{
                opacity: 0.6;
            }

            &:hover{
                cursor:pointer;
            }

            @include breakpoint(only-lg) {
                &.mobile-only {
                    display: flex;
                }
            }
            @include breakpoint(lg) {
                font-size: 2.2rem;

                &.mobile-hidden {
                    display: flex;
                }
            }
        }
    }
}
//
//  Block Search
//  _____________________________________________


.block-search {
    display: none;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    justify-content: center;
    background-color: #fff;
    padding-left: $gutter;
    padding-right: $gutter;

    & > .block {
        flex: 1 1 0;
    }
    &.active{
        display:block;
    }
    .block-title,
    .action.advanced {
        @extend .visually-hidden;
    }

    // Search form
    form.minisearch {
        display:flex;
        position:relative;
        margin-top:80px;
        padding-bottom: 10px;

        .field.search{
            width:100%;

            .label{
                display:none;
            }

            .control{

                .input-text{
                    border-bottom:1px solid $c-black !important;
                    border-radius: 0;

                    @include breakpoint(md) {
                        max-width:$header-search-max-width;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .input-text,
                .input-text::placeholder{
                    border:none;
                    padding:0;
                    color:$c-black;
                    font-family: $f-benton;
                    font-weight: $fw-benton-B;
                    font-size:2.8rem;
                }
            }
        }

        .actions{
            position: absolute;
            top: 0;
            right:0;

            @include breakpoint(md) {
                right:50%;
                transform: translateX($header-search-max-width/2);
            }
        }

        .search .label,
        .action.search {
            display: flex;
            align-items: center;
            padding: 10px;
            height: 50px;
            background-color: transparent;
            line-height: 4rem;

            &:not([disabled]):hover {
                cursor: pointer;
            }

            span {
                @extend .visually-hidden;
            }

            &::before {
                content: "\e957";
                @extend %icon-icomoon;
                color: $c-black;
                font-size: 2.2rem;
            }
        }

        .action.search{
            padding-top: 30px;
        }
    }
}

//
// Agenda
//
// Header agenda link
.agenda-link {
    display: none;
    @include breakpoint(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 3px 0 3px;
        padding: 0;
    }

    @include breakpoint(xl) {
        margin: 0 7px 0 7px;
    }

    .agenda-link-txt {
        font-size: 12px;
        font-weight: 400;
        display: block;
    }
}

//
//  Buy ticket
//  _____________________________________________

.mdp-header-links .mdp-billet {
    @include breakpoint(only-lg) {
        @include visually-hidden;
    }
}


.mdp-billet {
    display: flex;
    width: 100%;

    @include breakpoint(only-lg) {
        padding-top:25px;
        padding-left:25px;
    }

    @include breakpoint(lg) {
        justify-content: center;
        width: auto;
    }
    a {
        @include breakpoint(only-lg) {
            @include btn;
            display: flex;
            font-weight: $fw-benton-R;
            background-color: transparent;
            border-color: $c-black;
            color: $c-black;

            &:hover,
            &:focus {
                &:not(:disabled) {
                    background-color: $c-black;
                    border-color: $c-black;
                    color: $c-white;
                }
            }
            .icon {
                margin-right: 10px;
                display: inline-block;
                font-size: 1.2rem;
            }
        }

        @include breakpoint(lg) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 1.2rem;
        }
    }
}

//
//  Switch Language
//  _____________________________________________

.switcher {
    @include breakpoint(only-lg) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
.switcher-label {
    @extend .visually-hidden;
}

.switcher-trigger {
    transition:all .3s linear;

    &:focus{
        opacity: 0.6;
    }

    strong {
        font-weight: $fw-benton-M;
        text-transform: capitalize;
    }
}


//
//  Switch Vous etes
//  _____________________________________________

.mdp-header-landing{
    @include breakpoint(lg) {
        display: none;
    }

    .landing-trigger{
        border-radius: 50px;
        transition:all .3s linear;

        &:focus{
            opacity: 0.6;
        }
    }
}

//
// Header menu ctas
//
.topmenu-ctas,
.mdp-header-menu-desktop {

    > li {
        flex: 1 1 0;
        max-width: 180px;
        padding:0 5px;

        @include breakpoint(lg) {
            padding:0 10px;
            white-space: nowrap;
        }

        &:nth-child(1){
            padding-left:0;
        }

        &:last-child {
            padding-right:0;
        }

        > a {
            @include btn;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding-left: 5px;
            padding-right: 5px;
            border: none;
            color: $c-base;
            text-align: left;

            @include breakpoint(lg) {
                padding-left: $gutter;
                padding-right: $gutter;
                padding-top: $gutter*0.5;
                padding-bottom: $gutter*0.5;
            }

            &:nth-child(1){
                margin-left:0;
            }

            &:last-child {
                margin-right:0;
            }

            &::before {
                @include icon-icomoon;
                font-size: 2rem;
                margin-right: 5px;
            }
        }

        &.universe-yellow {
            > a {
                background-color: $c-yellow;

                &::before {
                    content:"\e90b";
                }

                &:hover {
                    background-color: $c-yellow;
                }

                @include breakpoint(only-sm) {
                    span {
                        max-width: 60px;
                    }
                }
            }
        }

        &.universe-blue > a {
            background-color: $c-blue2;

            &::before {
                content:"\e90e";
            }

            @include breakpoint(only-sm) {
                span {
                    max-width: 50px;
                }
            }

            &:hover {
                background-color: $c-blue2;
            }
        }

        &.universe-green > a {
            background-color: $c-green;

            &::before {
                content:"\e96f";
            }

            &:hover {
                background-color: $c-green;
            }
        }
    }
}

//
//  Top menu ctas mobile
//  _____________________________________________

.topmenu-ctas {
    display: flex;
    justify-content: space-between;

    @include breakpoint(lg) {
        display: none;
    }

    @include breakpoint(only-lg) {
        width: 100%;
        margin-top: $gutter;
        margin-left:-15px;
        margin-right:-15px;
        padding-left: 0;
        padding-right: 0;
    }
        @include breakpoint(only-sm) {
            > li > a {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding-top: 4px;
                padding-bottom: 4px;

                &::before {
                    margin-right: 0;
                }

                span {
                    display: flex;
                    align-items: center;
                    height: 25px;
                }
            }
        }
}


//
// DESKTOP MENU NAVIGATION
//

.mdp-header-menu-desktop {
    display: none;
    @include breakpoint(lg) {
        display: flex;
        justify-content: center;
        margin-right:auto;
        margin-left:auto;

        // Level 0 - Main category
        > li {
            flex: 0;

            &.is-active {
                > a {
                    transform: translateY(-5px);
                    transition: transform .3s ease-out;

                    // Element to keep hover action
                    &:after {
                        content:"";
                        display: block;
                        position: absolute;
                        height: 60px;
                        width: 200%;
                        top: 70%;
                        transform: translateZ(-1px);
                        border-top-left-radius: 50%;
                        border-top-right-radius: 50%;
                    }
                    .icon {
                        transform: scale(-1) rotate(90deg);
                    }
                }
                .submenu-wrapper {
                    display: block;
                }
            }

            // Label Category
            > a {
                position: relative;
                transform: translateY(0);
                transition: transform .3s ease-out;
                font-size: 1rem;
                transform-style: preserve-3d;

                .icon {
                    transform: scale(1) rotate(90deg);
                    transition: transform .2s ease-out;
                    margin-left: 5px;
                }
            }

            &.universe-yellow {
                .submenu-wrapper {
                    border-top: solid $c-yellow 2px;

                    li.level1 > .category-label::before,
                    li > a:before {
                        background-color: $c-yellow;
                    }
                }
                .submenu-inner-left::before {
                    background-color: #FFF9EC;
                }
                .submenu-wrapper-inner .submenu-inner-right .push-img {
                    flex-direction: row;
                }
            }
            &.universe-blue {
                .submenu-wrapper {
                    border-top: solid $c-blue2 2px;

                    li.level1 > .category-label::before,
                    li > a:before {
                        background-color: $c-blue2;
                    }
                }
                .submenu-inner-left::before {
                    background-color: #DDE9F4;
                }
                .submenu-wrapper-inner .submenu-inner-right .push-img {
                    flex-direction: row;
                }

                // specific rule for cultural place
                .submenu-wrapper-inner {
                    .submenu-inner-right {
                        .submenu-inner-content {
                            display: flex;

                            ul.level0.submenu {
                                column-count: initial;
                                flex-grow: initial;
                                flex-shrink: initial;
                                flex-basis: initial;
                                width: 50%;
                            }
                        }
                    }
                }
            }
            &.universe-green {
                .submenu-wrapper {
                    border-top: solid $c-green 2px;

                    li.level1 > .category-label::before,
                    li > a:before {
                        background-color: $c-green;
                    }
                }
                .submenu-inner-left::before {
                    background-color: #E7EEEE;
                }
                .submenu-wrapper-inner .submenu-inner-right .push-img {
                    flex-direction: row;
                }
            }
        }

        // Submenu wrapper
        .submenu-wrapper {
            display: none;
            position: fixed;
            top: 120px;
            left: 0;
            z-index: 2;
            width: 100%;
            max-height: calc(100vh - 120px);
            padding: 0 20px;
            background-color: $c-white;

            .category-item {

            }
            .category-label {
                padding: 5px 0;
                white-space: normal;
                line-height: 1.2;
            }

            .see-all {
                a {
                    display: block;
                    padding: 5px 0;
                    font-weight: 700;
                    font-size: 1.4rem;
                    text-decoration: underline;
                }
            }

            .level0.submenu {
                > .level1.category-item {
                    overflow:hidden;
                }
            }

            // Label subcategory
            li.level1 {
                margin-bottom: 25px;

                > .category-label {
                    display: inline-block;
                    font-size: 1.6rem;
                    position: relative;
                    &::before {
                        content:"";
                        position: absolute;
                        top: 13px;
                        height: 12px;
                        left: 0;
                        width: 100%;
                        transform: translateX(-100%);
                        transition: transform .3s linear;
                    }
                    &:hover::before {
                        right: 0;
                        transform: translate(0);
                    }
                    strong {
                        position: relative;
                    }
                }
            }

            li.level2 > .category-label {
                display: flex;
                font-size: 1.4rem;
                line-height: 1;
                padding-right: 20px;
                transform: translateX(-10px);
                transition: transform 0.2s ease-in-out;

                &:hover {
                    transform: translateX(0);
                }

                &::before {
                    content: '';
                    transform-origin: left;
                    display: inline-block;
                    margin-right: 0;
                    width: 0;
                    width: 5px;
                    min-width: 5px;
                    margin-right: 5px;
                }

                &:hover {
                    &::before {

                    }
                }
            }
        }

        // Layout inner
        .submenu-wrapper-inner {
            display: flex;
            height: 100%;
            width: 100%;
            max-width: $container-width;
            margin-left: auto;
            margin-right: auto;

            .submenu-inner-left {
                width: 25%;
                position: relative;
                padding: 50px 50px 50px 0;

                &::before {
                    content:"";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 100vw;
                }
            }

            .submenu-inner-right {
                display: flex;
                align-items: flex-start;
                width: 75%;
                background-color: $c-white;
                padding: 50px 0 50px 50px;

                .push-img {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    flex-grow: 1;
                    padding-left: 10px;

                    > * {
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: 0;
                        position: relative;
                        padding: 30px 20px;
                        display: flex;
                        justify-content: end;
                        flex-direction: column;
                    }

                    div.superimposed-rectangle {
                        display: grid;
                        padding: 0;

                        a {
                            margin-bottom: 20px;

                            img.img-superimposed-rectangle {
                                position: relative;
                            }
                        }
                    }

                    div.superimposed-square {
                        overflow-y: auto;
                        overflow-x: hidden;
                        display: grid;
                        grid-auto-rows: max-content;
                        max-height: calc(100vh - 200px);

                        a {
                            margin-bottom: 20px;

                            img.img-superimposed-square {
                                position: relative;
                            }
                        }
                    }

                    .push-img-item {
                        position: relative;
                        overflow: hidden;

                        &:hover {
                            img {
                                transform: scale(1.1);
                            }
                            .content-text {
                                transform: translateY(0);

                                .btn {
                                    opacity: 1;
                                }
                            }
                        }

                        img {
                            display: block;
                            transform: scale(1);
                            transition: transform .3s ease-out;
                        }

                        .content-text {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            transform: translateY(35px);
                            transition: transform .2s ease-in-out;
                            z-index: 2;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            padding: 40px 25px 20px;
                            text-align: center;
                            color: #ffffff;
                            line-height: 1.4;
                            font-weight: $fw-benton-M;
                            overflow: hidden;
                            background-image: linear-gradient($c-transparent, $c-black);

                            .title {
                                font-size: 1.2rem;
                                text-transform: uppercase;
                            }

                            .text {
                                padding: 0;
                                font-size: 1.6rem;
                            }

                            .btn {
                                margin-top: 10px;
                                opacity: 0;
                                transition: opacity .2s ease-out;
                            }
                        }
                    }
                }

                .push-img.two-columns {
                    column-count: 2;
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc((100% / 3) * 2);

                    > div {
                        padding: 0;
                    }
                }

                .push-img.one-column {
                    column-count: 2;
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(100% / 3);

                    > div {
                        padding: 0 0 0 25px;
                    }
                }

                &[data-push-count="0"] {
                    .submenu-inner-content {
                        width: 100%;
                    }
                    .level0.submenu {
                        columns: 3;
                    }
                }

                &[data-push-count="1"] {
                    .submenu-inner-content {
                        width: calc(100% - (100%/3));
                    }
                    .level0.submenu {
                        column-count: 2;
                        flex-grow: 0;
                        flex-shrink: 0;
                        flex-basis: calc((100% / 3) * 2);
                    }
                }

                &[data-push-count="2"] {
                    .submenu-inner-content {
                        width: calc(100%/3);
                    }
                    .level0.submenu {
                        flex-grow: 0;
                        flex-shrink: 0;
                        flex-basis: calc(100% / 3);
                    }
                }
            }

            .submenu-inner-content {
                position: relative;
                overflow-y: auto;
                max-height: calc(100vh - 200px);
            }
        }

        // Animation opacity/translate header
        .submenu-inner-left > .submenu-inner-content {
            animation: 0.5s animationHeaderBlock-1;
        }

        .submenu-inner-right > .submenu-inner-content {
            animation: 0.5s animationHeaderBlock-2;
        }

        .push-img {
            animation: 0.5s animationHeaderBlock-3;
        }

        @keyframes animationHeaderBlock-1 {
            0% {
                opacity: 0;
                transform: translateY(-20px);
            }

            50% {
                opacity: 1;
                transform: translateY(0);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes animationHeaderBlock-2 {
            0% {
                opacity: 0;
                transform: translateY(-20px);
            }

            25% {
                opacity: 0;
                transform: translateY(-20px);
            }

            75% {
                opacity: 1;
                transform: translateY(0);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes animationHeaderBlock-3 {
            0% {
                opacity: 0;
                transform: translateY(-20px);
            }

            50% {
                opacity: 0;
                transform: translateY(-20px);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    @include breakpoint(xl) {
        > li > a {
            font-size: 1.2rem;
        }
    }
}
