// ==============================================
// UI COMPONENTS - Slider
// ==============================================

.amshopby-slider-container {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;    

    .ui-slider-horizontal {
        background: none;
    }

    .am-slider.ui-slider {
        height: 20px;

        .ui-slider-range {
            height: 3px;
            background-color: #000;
        }

        .ui-slider-handle {
            position: absolute;
            display: block;
            margin-top: -8px;
            background-color: #fff;
            border: 2px solid #000;

            &:first-of-type {
                margin-left: -2px;
            }

            &:last-of-type {
                margin-left: -10px;
            }

            &:hover,
            &:active {
                background-color: $c-gold;
            }
        }
    }
}
