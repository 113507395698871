// ==============================================
// ACCOUNT - Wishlist share
// ==============================================

.wishlist-index-share {

    .form.wishlist.share {
        @include breakpoint(md) {
            max-width: $container-small;
        }

        legend {
            @extend .visually-hidden;
        }

        .field {
            display: block;

            &.emails,
            &.text {
                padding: 0;

                label {
                    margin-left: 0;
                    margin-bottom: 10px;
                    font-size: 1.4rem;
                    font-weight: $fw-benton-M;
                }
            }

            &.text {
                margin: 0;
            }

            + .mage-error {
                margin-top: -10px;
                margin-bottom: 10px;
                margin-left: 0;
            }
        }
    }
}
