// ==============================================
// OUTDATED BROWSER - Settings
// ==============================================

// Configuration
$ob-popin-overlay-background:               rgba(0, 0, 0, 0.8);

$ob-popin-width:                            900px;
$ob-popin-main-background-color:            #FFF;
$ob-popin-radius:                           0;

$ob-popin-top-background-color:             #F4F5FC;
$ob-popin-top-padding:                      40px;

$ob-popin-debug-mode:                       false;