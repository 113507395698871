// ==============================================
// FORMS - Inputs
// ==============================================

//
//  Configuration
//  _____________________________________________

$input-height:          60px;
$input-height-desktop:  70px;
$input-border-color:    $c-black;
$input-background:      $c-white;
$label-margin-neg:      16px; // Correspond to height of label


//
//  Common
//  _____________________________________________

.input-text:not(textarea),
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="date"] {

    // Reset
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // Custom
    padding: 10px 30px;
    font-size: 1.4rem;
    font-family: $f-base;
    line-height: 1.8;
    color: $c-base;
    background-color: $input-background;
    border: 1px solid $input-border-color;
    border-radius: $radius-input;
    height: $input-height;

    @include breakpoint(lg) {
        height: $input-height-desktop;
        font-size: 1.6rem;
    }

    // Chrome/Opera/Safari
    &::-webkit-input-placeholder {
        color: lighten($c-black, 50%);
    }

    // Firefox 19+
    &::-moz-placeholder {
        color: lighten($c-black, 50%);
        opacity: 1;
    }

    // IE 10+
    &:-ms-input-placeholder {
        color: lighten($c-black, 50%);
    }

    // Firefox 18-
    &:-moz-placeholder {
        color: lighten($c-black, 50%);
        opacity: 1;
    }

    &.disabled-skin,
    &:disabled {
        background-color: #F9FAFC;
    }
}

.input-text:not(.qty),
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
    display: block;
    width: 100%;

    &.mage-error {
        border: 1px solid $c-error !important;
    }
}

// Disable status
.input-text,
input {
    &:disabled {
        opacity: 0.4 !important;
        &:hover {
            cursor: auto;
        }
    }
}

// Card number
.field.card-number .control {
    position: relative;

    &::after {
        @extend .field-icon;
        content:"\e939";
    }

    input {
        padding-right: 40px;
    }
}

// Qty
.field.qty {
    label,
    .label {
        @extend .visually-hidden;
    }
}

// Default input qty
input.qty {
    width: 80px;
    padding: 5px;
    text-align: center;
}

// Soon quantity updater
.soon-qty-updater-wrap {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    width: 80px;
    border-radius: 100px;
    background-color: $c-white;
    border: 1px solid $input-border-color;

    @include breakpoint(md) {
        width: 110px;
        height: 60px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        width: 100%;
        padding: 10px 0;
        text-align: center;
        border: none;
        border-radius: 0;
        height: auto;
        max-height: 100%;
        font-size: 1.2rem;

        @include breakpoint(md) {
            font-size: 1.4rem;
        }
    }
}
.soon-qty-updater {
    padding: 8px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }

    &.desc,
    &.dec {
        margin-left: 5px;
    }

    &.inc {
        margin-right: 5px;
    }
}


// Reveal Password
.reveal-password {
    @include field-icon;
    cursor: pointer;

    &.is-active {
        // Barred eye line
        &::after {
            content: "";
            background-color: $input-picto-color;
            width: 2px;
            height: 20px;
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: rotate(10deg);
            transform: rotate(30deg);
        }
    }
}


// Input with icon
input[type="password"],
input#input_cardnumber,
input#input_with_tooltip,
input#dob {
    padding-right: 50px;
}




/////////////// FIELDS LABEL ANIMATION ///////////////

.field:not(.search):not(.qty):not(.choice):not(.field-select-billing):not(.newsletter):not(.amcoupons-input) {
    /**
    * Add a transition to the label and input.
    */
    label,
    input:not([type="checkbox"]):not([type="radio"]):not([type="number"]):not([type="file"]) {
        transition: all 0.2s;
        touch-action: manipulation;
    }

    &.date label {
        position: relative;
        z-index: 1;
        cursor: text;
        max-width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 4.2rem) scale(1);
        margin-top: -$label-margin-neg;

        @include breakpoint(lg) {
            transform: translate(0, 4.8rem) scale(1);
        }
    }

    &:not(.check):not(.choice):not(.field-select-billing):not(.file_collection) {
        label {
            margin-left: 30px;
        }
    }

    /**
    * Translate down and scale the label up to cover the placeholder,
    * when following an input (with placeholder-shown support).
    */
    input:not([type="checkbox"]):not([type="radio"]):not([type="radio"]):not([type="file"]) {
        padding-top: 20px;
        padding-bottom: 0;

        &:placeholder-shown + label {
            cursor: text;
            max-width: 75%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform-origin: left bottom;
            transform: translate(0, -2.2rem) scale(1);
            margin-top: -$label-margin-neg;

            @include breakpoint(lg) {
                transform: translate(0, -2.8rem) scale(1);
            }
        }
    }

    &.no-label input:not([type="checkbox"]):not([type="radio"]):not([type="radio"]) {
        padding-top: 0;
    }

    // Captcha
    div.captcha input:not([type="checkbox"]):not([type="radio"]):not([type="radio"]) {
        &:placeholder-shown + label {
            margin-top: -24px;
            white-space: normal;
        }
    }

    /**
    * By default, the placeholder should be transparent. Also, it should
    * inherit the transition.
    */
    ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
    }

    ::-moz-placeholder {
        opacity: 0;
        transition: inherit;
    }

    ::placeholder {
        opacity: 0;
        transition: inherit;
    }

    /**
    * Phone : placeholder visible.
    */
    input[name$="phone"]::placeholder{
        opacity: 1;
    }

    /**
    * Show the placeholder when the input is focused.
    */
    input:not([type="checkbox"]):not([type="radio"]):not([type="number"]) {
        &:focus::-webkit-input-placeholder {
            opacity: 0;
        }
    }

    /**
    * When the element is focused, remove the label transform.
    * Also, do this when the placeholder is _not_ shown, i.e. when
    * there's something in the input at all.
    */
    input:not(:placeholder-shown):not([type="checkbox"]):not([type="radio"]):not([type="number"]):not([type="file"]) + label,
    input:not(:placeholder-shown):not([type="checkbox"]):not([type="radio"]):not([type="number"]):not([type="file"]) + .mage-error + label,
    input:not([type="checkbox"]):not([type="radio"]):not([type="number"]):not([type="file"]):focus + label,
    input:not([type="checkbox"]):not([type="radio"]):not([type="number"]):not([type="file"]):focus + .mage-error + label,
    &.date label.is-active {
        transform-origin: 0;
        transform: translate(0, -3rem) scale(0.8);
        margin-top: -$label-margin-neg;
        color: $c-grey3;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 75%;


        @include breakpoint(lg) {
            transform: translate(0, -4rem) scale(0.8);
        }
    }

    &.date label.is-active {
        transform: translate(0, 3rem) scale(0.8);
    }

    input:not(:placeholder-shown):not([type="checkbox"]):not([type="radio"]):not([type="number"]) + .mage-error + label,
    input.mage-error:not(:placeholder-shown):not([type="checkbox"]):not([type="radio"]):not([type="number"]) + label,
    input.mage-error:not(:placeholder-shown):not([type="checkbox"]):not([type="radio"]):not([type="number"]) + .mage-error + label,
    input.mage-error:not([type="checkbox"]):not([type="radio"]):not([type="number"]):focus + label,
    input.mage-error:not([type="checkbox"]):not([type="radio"]):not([type="number"]):focus + .mage-error + label {
        color: $c-error;
    }

    // Captcha
    div.captcha input:not(:placeholder-shown):not([type="checkbox"]):not([type="radio"]):not([type="number"]) + label {
        margin-top: -24px;
    }
}
