// ==============================================
// STRATE 3X1 Contenus
// ==============================================

.ct-view-strate_3x1_contenus{
    
    .strate-list{
        padding-top:20px;
    }

    .strate-item-visual{
        position:relative;
        padding-bottom:100%;
        overflow:hidden;

        img{
            position:absolute;
            display: block;
            top:0;
            right:0;
            bottom:0;
            left:0;
            width:100%;
            height:100%;
            object-fit: cover;
            transition: transform .2s linear;

            &:hover{
                transform: scale(1.04);
            }
        }
    }

    .strate-item-content{

        .strate-item-subtitle{
            margin-bottom: 10px;
            font-size: 1.6rem;
            color: $c-grey;
            text-transform: uppercase;
            font-weight: 700;
        }

        .strate-item-title{
            display: inline-block;
            align-self:flex-start;
            margin-bottom: 10px;
            font-size: 1.8rem;
            font-weight: 700;
            text-decoration: none;
            background-image: linear-gradient(currentColor, currentColor);
            background-size: 0% 1px;
            background-position: 0 100%;
            background-repeat: no-repeat;
            transition: background-size .5s ease-out;
            border-bottom: none;

            &:hover{
                background-size:100% 1px;
            }
        }

        .strate-item-desc{
            margin-bottom:20px;
            font-size: 1.4rem;
            line-height: 1.4;
            font-weight: 400;
        }

        .btn{
            align-self:flex-start;
            margin-top: auto;
        }
    }

    &.universe-green{
        .strate-item-content .strate-item-subtitle{
            background-color: $c-green-light;
        }
    }

    &.universe-blue{
        .strate-item-content .strate-item-subtitle{
            background-color:$c-blue2-light;
        }
    }

    &.universe-yellow{
        .strate-item-content .strate-item-subtitle{
            background-color: $c-yellow-light;
        }
    }
}
