// ==============================================
// ONEPAGE - Address
// ==============================================

.checkout-index-index {

    .shipping-address-items {

        // Item
        .shipping-address-item {
            position: relative;
            display: flex;
            padding: 20px;
            margin-bottom: 20px;
            background-color: $c-white;

            @include breakpoint(lg) {
                padding: 38px;
            }

            &.selected-item {
                .shipping-address-item-fake-check {
                    position: relative;

                    &::before {
                        opacity: 1;
                        transform: scale(1) translate(-50%, -50%);
                        transition: all .2s;
                    }
                }
            }

            .shipping-address-item-fake-check {
                width: 20px;
                height: 20px;
                border: 1px solid $c-light6;
                border-radius: 50%;
                margin-right: 20px;

                @include breakpoint(lg) {
                    margin-right: 24px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $c-base;
                    opacity: 0;
                    transform: scale(0.5) translate(-50%, -50%);
                }
            }

            .shipping-address-item-actions {
                align-self: flex-end;
                margin-left: auto;

                .edit-address-link {
                    position: relative;
                    z-index: 10;
                }

                @include breakpoint(lg) {
                    align-self: center;
                }

                button {
                    border-bottom: 1px solid currentColor;
                    transition: color .3s;
                    cursor: pointer;

                    &:hover {
                        color: $c-orange;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        cursor: pointer;
                    }

                    &.action-select-shipping-item {
                        span {
                            @extend .visually-hidden;
                        }
                    }
                }
            }
        }
    }

    .modal-footer .action-save-address + .action-hide-popup {
        display: none;
    }

    .new-address-popup {
        .action-show-popup {
            display: flex;
            align-items: center;
            padding: 0;
            background: inherit;
            border: inherit;
            border-radius: inherit;
            font-family: $f-benton;
            font-size: 1.4rem;
            font-weight: $fw-benton-R;
            color: $c-black;
            transition: color .3s;

            > span {
                border-bottom: 1px solid currentColor;
            }

            &:hover,
            &:focus,
            &:active {
                background: inherit;
                border: inherit;
                color: $c-orange;
            }

            &::before {
                @extend %icon-icomoon;
                content: "\e94d";
                margin-right: 8px;
                font-size: 2rem;
            }

            @include breakpoint(only-lg) {

            }
        }
    }
}
