// ==============================================
// AGENDA - List
// ==============================================

.agenda-list-grouped-item {
    margin-bottom: 50px;
    @media(min-width: $medium) {
        margin-bottom: 72px;
    }
}

.agenda-list-group-title {
    font-family: $f-bentonSC, sans-serif;
    font-weight: bold;
    font-size: 34px;
    line-height: 36px;
    padding-bottom: 10px;
    border-bottom: 1px solid $c-light6;
    margin-bottom: 32px;
    text-transform: capitalize;
    @media(min-width: $medium) {
        font-size: 60px;
        line-height: 70px;
        padding-bottom: 11px;
        margin-bottom: 50px;
    }
}

.agenda-list-items {
    display: grid;
    width: fit-content;
    margin: 0 auto;
    grid-template-columns: 100%;
    grid-gap: 30px;
    @media(min-width: 710px) {
        grid-template-columns: 320px 320px;
    }

    @media(min-width: 1080px) {
        grid-template-columns: 320px 320px 320px;
    }

    @media(min-width: 1430px) {
        grid-template-columns: 320px 320px 320px 320px;
    }

    .ct-view-agenda_mon_taire,
    .ct-view-agenda_culturel {
        width: 100%;
        overflow: initial;
    }
}
